import React, { useState, useEffect } from "react";
import { Box, Image, Heading, Button, Text, HStack, PinInput, PinInputField, Flex } from '@chakra-ui/react';
import loginBanner from '../../../assets/images/login-banner.svg';
import logo from '../../../assets/images/logo.png';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from "../../../redux/store";
import { authState, setAuthError, verifyOtp, resendOtp, loadUser } from "../../../redux/modules/auth";
import { toastr } from "react-redux-toastr";

import Loader from "../../../components/common/loader";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

export function Otp() {
    const dispatch = useDispatch();
    const auth: authState = useSelector((state: RootState) => state.auth);
    const [verfiyOtp, setVerfiyOtp] = useState('');


    const handleVerfiyOtp = (e: React.SyntheticEvent) => {
        e.preventDefault();
        if (!verfiyOtp) {
            toastr.warning("", "Please enter a OTP first");
            return;
        }
        if (verfiyOtp.length !== 6) {
            toastr.warning("", "Invalid OTP");
            return;
        }
        new Promise(() => {
            const data1: any = dispatch(verifyOtp({ userId: auth.user.userId, payload: { otp: verfiyOtp } }));
            data1.then(function (value) {
                if (value.type === 'auth/verifyOtp/fulfilled') {
                    dispatch(loadUser());
                }
            })
        })
    };

    const handleResendOtp = () => {
        new Promise(() => {
            const data2: any = dispatch(resendOtp({ userId: auth.user.userId }));
            data2.then(function (value) {
                if (value.type === "auth/regenerate/otp/fulfilled") {
                    toastr.success("", value.payload.message);
                }
            })
        })
    }

    useEffect(() => {
        if (auth?.error) {
            const { message } = auth.error;
            toastr.error('', message ? message : '');
        }
        return () => { dispatch(setAuthError(null)); }
    }, [auth.error, dispatch]);

    return (
        <>
            {auth.loginLoading.length !== 0 && <Loader />}
            <Box display='flex' minH='100vh'>
                <Box w={{ sm: '50%', lg: '45%', xxl: '40%' }} py='20px' px='20px' display='inline-flex' alignItems='center' flexDirection='column' justifyContent='center'>
                    <Carousel autoPlay interval={4500} infiniteLoop={true} stopOnHover={false} showArrows={false} showStatus={false}>
                        <Box display='inline-flex' alignItems='center' justifyContent='center' flexDirection='column'>
                            <Image maxH={{ sm: '82px', xxl: '102px' }} src={logo} alt='Sign Up Logo' mb={{ sm: '18px', xxl: '34px' }} />
                            <Flex minHeight='220px' alignItems='center' flexDir='column' justifyContent='flex-start' mb='40px'>
                                <Text fontSize={{ sm: '24px', xxl: '26px' }} textAlign='center'>"This inspection software is a BIG improvement from the one I had before! It takes me much less time to complete my inspection reports! Thank you."</Text>
                                <Text as='h3' mt='10px' fontSize={{ sm: '24px', xxl: '28px' }} fontWeight='600' textAlign='center'>F.Menesis</Text>
                            </Flex>
                        </Box>
                        <Box display='inline-flex' alignItems='center' justifyContent='center' flexDirection='column'>
                            <Image maxH={{ sm: '82px', xxl: '102px' }} src={logo} alt='Sign Up Logo' mb={{ sm: '18px', xxl: '34px' }} />
                            <Flex minHeight='220px' alignItems='center' flexDir='column' justifyContent='flex-start' mb='40px'>
                                <Text fontSize={{ sm: '24px', xxl: '26px' }} textAlign='center'>“I really like the fact that I can go to the Property without my clipboard and complete the whole inspection with Aurora.”</Text>
                                <Text as='h3' mt='10px' fontSize={{ sm: '24px', xxl: '28px' }} fontWeight='600' textAlign='center'>R. Dunn</Text>
                            </Flex>
                        </Box>
                    </Carousel>
                </Box>
                <Box w={{ sm: '50%', lg: '55%', xxl: '60%' }} pos='relative'
                    bg='#0A1F3E'
                    overflow='auto'
                    bgImg={loginBanner}
                    bgRepeat='no-repeat'
                    bgSize='cover'
                    d='flex'
                    alignItems='center'
                    justifyContent='center'
                    p='40px 0'>
                    <Box w='100%' maxW={{ sm: '310px', lg: '330px', xxl: '340px' }}>
                        <Heading as='h1' color='#fff' fontSize='25px' mb='50px'>OTP verification</Heading>
                        <Box mb='30px'>
                            <HStack>
                                <PinInput otp value={verfiyOtp} onChange={setVerfiyOtp} variant='simple' placeholder="">
                                    <PinInputField />
                                    <PinInputField />
                                    <PinInputField />
                                    <PinInputField />
                                    <PinInputField />
                                    <PinInputField />
                                </PinInput>
                            </HStack>
                        </Box>
                        <Button variant='primary' w='100%' onClick={handleVerfiyOtp}>Verify</Button>
                        <Box d='flex' justifyContent='center' mt='12px' alignItems='center' fontSize='xl' lineHeight='19px' flexWrap='wrap' position='relative'>
                            <Text as="span" cursor='pointer' color="#00AEEF" transition='0.4s all ease' _hover={{ color: '#51d0ff' }} onClick={handleResendOtp}>Resend OTP</Text>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </>
    )
}
export default Otp