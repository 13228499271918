import { Box, Flex, Text } from "@chakra-ui/react";

export default function HelpCenter() {
  return (
    <>
      <Box p='18px 30px 20px 20px' h='calc(100vh - 70px)' overflow='auto' w='100%'>
        <Text as='h1' mb='10px' color='black.lighten' fontSize='24px' lineHeight='30px' fontWeight='600'>Help Center</Text>
        <Text as='p' fontSize='18px' color='gray.primary'>Choose how you’d like to Help you</Text>
        <Box mt='25px'>
          <Box p='20px' borderRadius='12px' bg='white.primary' boxShadow='0px 0px 10px rgba(136, 136, 136, 0.12)' w='100%' mb='20px'>
            <Text fontSize='21px' as='h3' fontWeight='600' mb='16px' color='black.ligthen'>Contact Us</Text>
            <Text d='block' w='100%' fontSize='18px' as='span' color='blue.dark' mb='10px'>(770) 780 - 5532</Text>
            <Text d='block' w='100%' as='span' fontSize='18px' color='gray.primary'>info@myauroraportal.com</Text>
          </Box>
          <Box p='20px' borderRadius='12px' bg='white.primary' boxShadow='0px 0px 10px rgba(136, 136, 136, 0.12)' w='100%' mb='20px'>
            <Text fontSize='21px' as='h3' fontWeight='600' mb='16px' color='black.ligthen'>User Guide</Text>
            <Flex>
              <Text fontWeight='600'>Video Link- &nbsp;</Text>
              <a href='https://www.youtube.com/embed/9xwazD5SyVg' target='_blank' rel="noreferrer">
                https://www.youtube.com/embed/9xwazD5SyVg
              </a>
            </Flex>
          </Box>
        </Box>
      </Box>
    </>
  )
}