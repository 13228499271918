import { ComponentMultiStyleConfig } from "@chakra-ui/react";

const modal: ComponentMultiStyleConfig = {
  parts: ["overlay", "dialogContainer", "dialog"],
  variants: {
    centerModal: {
      dialogContainer: {
        alignItems: "center"
      },
      dialog:{
        alignItems: "center",
        background:"red"
    }
    },
   
  },
};

export default modal;
