import { Box, Icon, Input, Text } from "@chakra-ui/react";
import { MdPhotoCamera } from "react-icons/md";

export default function AddImagePlaceholder({ minH= 'unset', h= '175px', fileChange, name, multiple }) {
  return (
    <Box border='2px dashed' borderRadius='4px' borderColor='gray.primary' d='inline-flex' justifyContent='center' alignItems='center' flexDirection='column' h={h} minH={minH} w='100%'>
      <Box w='100%' h='100%'>
        <label>
          <Box d='flex' h='100%' alignContent='center' justifyContent='center' cursor='pointer' flexWrap='wrap'>
            <Icon fontSize='22px' color='gray.primary' as={MdPhotoCamera}></Icon>
            <Text w='100%' textAlign='center' fontSize='xxl' color='gray.primary' mt='8px'>
              Add Image
            </Text>
            <Input type='file' accept=".jpeg, .jpg, .png" name={name} w='0' h='0' border='none' outline='none' p='0' _focus={{ outline: "none" }} onChange={fileChange} multiple={multiple} />
          </Box>
        </label>
      </Box>
    </Box>
  );
}
