import { Box, Icon, Modal, ModalBody, ModalContent, ModalHeader, ModalOverlay, useDisclosure } from "@chakra-ui/react";
import { useEffect } from "react";
import { IoMdClose } from "react-icons/io";

export default function AgreementModal({ setOpen, setClose, title, description: desc }) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const closeModel = () => {
    setClose(false);
    onClose();
  }

  useEffect(() => {
    if (setOpen === true) {
      onOpen();
    }
  }, [onOpen, setOpen]);

  return (
    <Modal isOpen={isOpen} onClose={closeModel} size='5xl' scrollBehavior='inside'>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader p='30px 50px' fontSize='21px' lineHeight='26px' color='black.lighten' fontWeight='600'>{title}</ModalHeader>
        <Icon color='black.primary' fontSize='24px' as={IoMdClose} onClick={closeModel} cursor='pointer' position='absolute' top='29px' right='29px'></Icon>
        <ModalBody p='8px 50px 30px'>
          <Box dangerouslySetInnerHTML={{ __html: desc }}></Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}