import { ComponentMultiStyleConfig } from "@chakra-ui/react";

const PinInput: ComponentMultiStyleConfig = {
  parts: ['field'],
  variants: {
    simple: {
      field: {
        border: 'none',
        background: 'white',
        _focus: {
          outline: '0px',
          boxShadow: '0px',
        },
      }
    }
  },
}

export default PinInput