import { extendTheme, theme as base } from "@chakra-ui/react";
import { StepsStyleConfig as Steps } from 'chakra-ui-steps';
import Button from "./components/button";
import Input from "./components/input";
import Select from "./components/select";
import Checkbox from "./components/checkbox";
import Textarea from "./components/textarea";
import Switch from "./components/switch";
import Radio from "./components/radio";
import Accordion from "./components/accordion";
import PinInput from "./components/pininput";
import tab from "./components/tab";
import modal from "./components/modal";
import Table from "./components/table";
import './fonts.scss'
import './global.scss';

const theme = extendTheme({
  breakpoints: {
    sm: '320px',
    md: '991px',
    lg: '1025px',
    xl: '1380px',
    xxl: '1549px',
    xxxl: '1920px'
  },
  components: {
    Button,
    Input,
    Steps,
    Select,
    Checkbox,
    Textarea,
    Switch,
    Radio,
    Accordion,
    tab,
    modal,
    Table,
    PinInput
  },
  fonts: {
    body: `Source Sans Pro , ${base.fonts?.body}`,
    heading: `Source Sans Pro , ${base.fonts?.heading}`,
  },
  fontSizes: {
    xs: "8px",
    sm: "10px",
    md: "12px",
    lg: "14px",
    xl: "16px",
    xxl: "18px",
    xxxl: "20px",
    xxxxl: "22px",

  },
  fontWeights: {
    hairline: 100,
    thin: 200,
    light: 300,
    normal: 400,
    medium: 500,
    semibold: 600,
    bold: 700,
    extrabold: 800,
    black: 900,
  },
  colors: {
    blue: {
      primary: '#00AEEF',
      dark: '#223D78',
      dark1: '#383D4E',
      dark2: '#005FB0',
      dark3: '#354052',
      dark4: '#0A1F3E',
      dark5: '#1a5cd1',
      dark6: '#121723',
      dark7: '#111429',
      light: '#a1b7c9',
      light2: '#C3CEDF',
      light3: '#009EE1',
      light4: '#4E68A0', 
      light5: '#4dbfea',
      light6: '#1FB2F0',
      light7: '#e9f3fc',
      light8: '#424757',
      light9: '#00A8F0'
    },
    white: {
      primary: '#ffffff',
      lighten: '#FCFCFC',
      lighten2: '#FAFAFA',
      lighten3: '#F6F6F6',
      lighten4: '#F1F1F1',
      lighten5: '#F7F7F7',
      lighten6: '#F5F5F5',
      lighten7: '#F3F3F3',
      lighten8: '#f9f9f9'
    },
    gray: {
      primary: '#888888',
      darken: '#696969',
      darken2: '#595D65',
      darken3: '#59637C',
      light: '#F7F8FA',
      light2: '#E0E0E0',
      light3: '#DBDBDB',
      light4: '#C5C7D0',
      light5: '#949494',
      light6: '#676879',
      light7: '#F8F8F8',
      light8: '#778191',
      light9: '#FBFBFB',
      light10: '#C4C4C4',
      light11: '#C3C3C3',
      light12: '#606F7B',
      light13: '#999999',
      light14: '#E7E7E7',
      light15: '#e9e9e9'
    },
    black: {
      primary: '#000000',
      lighten: '#2A2A2A',
      lighten2: '#bfbfbf00',
      lighten3: '#000000b3',
      lighten4: '#D4D4D4'
    },
    red: {
      primary: '#FF0000',
      dark: '#FF0404',
      lighten: '#FFE8E8',
      lighten2: '#F74A00',
      lighten3: '#FF725E',
      ligthen4: '#f8ecee',
      ligthen5: '#ffaaaa',
      ligthen6: '#FF928E',
      darken2: '#c4295a'
    },
    green: {
      primary: '#4C5A52',
      lighten: '#35D17E',
      lighten2: '#EDFCED',
      ligthen3: "#01B574",
      ligthen4: "#1BD2A4",
      lighten5: '#D6F6E9',
      lighten6: '#e0f0e7',
      lighten7: '#00d9d9',
      lighten8: '#00CA72',
      lighten9: "#e5ffe5",
      lighten10: "#20bd63",
      darken: '#0c784a'
    },
    yellow: {
      primary: '#FFF3E4',
      lighten: '#FFD600',
      lighten2: '#FBB528',
      lighten3: '#FFE896',
      lighten4: '#FFDE1F',
      dark: '#FFA123',
      dark2: '#FF9900',
      dark3: '#B68000'
    },
    purple: {
      light: '#e9e4f7',
      dark: '#8b6ed1', 
      dark2: '#6E1FED',
    },
    orange: {
      primary: '#F89D2C',
      light: '#F89B36',
      light2: '#FCF4E6',
      light3: '#fce6bc',
      dark: "#D64000"
    },
  },
});

export default theme;
