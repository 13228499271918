import { Link, Box, Flex, Image, Text, Button } from '@chakra-ui/react';
import { useEffect } from 'react';
import logo from '../../assets/images/logo-svg.svg';
import houseLogo from '../../assets/images/logo-vector.png';
import { NavLink, useLocation } from 'react-router-dom';
import { Link as ReactLink } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { authState, setShowSidebar } from '../../redux/modules/auth';
import { resetTemplate, resetTemplateTabs } from '../../redux/modules/template';
import { subscriptionState } from '../../redux/modules/subscription';
import settingsIcon from '../../assets/images/settings-icon.svg';
import dashboardIcon from '../../assets/images/dashboard-icon.svg';
import templateIcon from '../../assets/images/template-icon.svg';
import reportsIcon from '../../assets/images/reports-icon.svg';
import addNewReportIcon from '../../assets/images/add-new-report-icon.svg';
import staffManagementIcon from '../../assets/images/staff-management-icon.svg';
import logoBackground from '../../assets/images/logo-background.svg';
import invoiceIcon from '../../assets/images/invoice-icon.svg';

export default function Sidebar() {
  const dispatch = useDispatch();
  const location = useLocation();
  const auth: authState = useSelector((state: RootState) => state.auth);
  const subscription: subscriptionState = useSelector((state: RootState) => state.subscription);

  useEffect(() => {
    if (auth.showSidebar) {
      dispatch(setShowSidebar(!auth.showSidebar));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, location]);

  const emptyTemplate = () => {
    dispatch(resetTemplate('all'));
    dispatch(resetTemplateTabs('all'));

    // We reload page so that form values
    // ... are reset automatically
    if (/edit-report/ig.test(location.pathname)) {
      window.location.assign('/add-report');
    }
  }

  return (
    <Box left='0' top='0' bottom='0' transition='0.3s all ease' transform={{ sm: `${auth.showSidebar ? '' : 'translateX(-250px)'}`, lg: 'translateX(0)' }} position={{ sm: 'fixed', lg: 'relative' }} zIndex='5' bg='blue.dark1' w={{ sm: `${!auth.collapseSidebar ? '245px' : '81px'}`, xxl: `${!auth.collapseSidebar ? '266px' : '81px'}` }} minW={{ sm: '81px', lg: `${!auth.collapseSidebar ? '245px' : '81px'}`, xxl: `${!auth.collapseSidebar ? '266px' : '81px'}` }} p={{ sm: '60px 0 8px', lg: '0' }} h='100vh' d='flex' alignContent='space-between' flexWrap='wrap' justifyContent='center' overflow='auto' overflowX='hidden'>
      <Box>
        <Flex bg={{ sm: 'transparent', lg: 'blue.light8' }} justifyContent='flex-start' m='0 0 18px' minH='70px' alignItems='center' position='relative'>
          {!auth.collapseSidebar && <Image ml='14px' src={logo} alt='Aurora Home Inspection' title='Aurora Home Inspection' maxW='156px' />}
          {auth.collapseSidebar && <Image mx='auto' src={houseLogo} alt='Aurora Home Inspection' title='Aurora Home Inspection' maxW='54px' />}
          <Image display={{ sm: 'none', lg: 'inline-block' }} position='absolute' bottom='0' right='0' src={logoBackground} />
        </Flex>
        <Flex flexWrap='wrap' mx='14px'>
          <Link aria-disabled={(auth?.user?.user_Type === "company_admin" || auth?.user?.user_Type === "home_inspector") ? !!subscription?.isSubscriptionExpire : false} mb='18px' _disabled={{ pointerEvents: 'none' }} w='100%' _hover={{ textDecoration: 'none' }} _focus={{ boxShadow: 'none' }} as={NavLink} to='/dashboard'>
            <Box w='100%' h='44px' d='inline-flex' alignItems='center' justifyContent={`${!auth.collapseSidebar ? 'flex-start' : 'center'}`} p={{ sm: `${!auth.collapseSidebar ? '0 15px' : '0'}`, xxl: `${!auth.collapseSidebar ? '0 15px' : '0'}` }} color='white.primary' textTransform='uppercase'>
              <Image mr={`${!auth.collapseSidebar ? '10px' : '0'}`} w='18px' h='18px' src={dashboardIcon} />
              {!auth.collapseSidebar && <Text fontSize='lg' lineHeight='20px' as='span' whiteSpace='nowrap'>Dashboard</Text>}
            </Box>
          </Link>
          {auth.user?.user_Type === 'super_admin' && <Link mb='18px' aria-disabled={(auth?.user?.user_Type === "company_admin" || auth?.user?.user_Type === "home_inspector") ? !!subscription?.isSubscriptionExpire : false} w='100%' _hover={{ textDecoration: 'none' }} _focus={{ boxShadow: 'none' }} as={NavLink} to='/users'>
            <Box w='100%' h='48px' d='inline-flex' alignItems='center' justifyContent={`${!auth.collapseSidebar ? 'flex-start' : 'center'}`} p={{ sm: `${!auth.collapseSidebar ? '0 15px' : '0'}`, xxl: `${!auth.collapseSidebar ? '0 15px' : '0'}` }} color='white.primary' textTransform='uppercase'>
              <Image mr={`${!auth.collapseSidebar ? '10px' : '0'}`} w='26px' h='26px' src={staffManagementIcon} />
              {!auth.collapseSidebar && <Text fontSize='lg' lineHeight='20px' as='span' whiteSpace='nowrap'>Users</Text>}
            </Box>
          </Link>}
          {auth.user?.user_Type === 'super_admin' && <Link mb='18px' aria-disabled={(auth?.user?.user_Type === "company_admin" || auth?.user?.user_Type === "home_inspector") ? !!subscription?.isSubscriptionExpire : false} w='100%' _hover={{ textDecoration: 'none' }} _focus={{ boxShadow: 'none' }} as={NavLink} to='/invoices'>
            <Box w='100%' h='48px' d='inline-flex' alignItems='center' justifyContent={`${!auth.collapseSidebar ? 'flex-start' : 'center'}`} p={{ sm: `${!auth.collapseSidebar ? '0 15px' : '0'}`, xxl: `${!auth.collapseSidebar ? '0 15px' : '0'}` }} color='white.primary' textTransform='uppercase'>
              <Image mr={`${!auth.collapseSidebar ? '10px' : '0'}`} w='26px' h='26px' src={invoiceIcon} />
              {!auth.collapseSidebar && <Text fontSize='lg' lineHeight='20px' as='span' whiteSpace='nowrap'>Invoice</Text>}
            </Box>
          </Link>}
          {auth?.user?.user_Type !== "super_admin" && <Link mb='18px' aria-disabled={(auth?.user?.user_Type === "company_admin" || auth?.user?.user_Type === "home_inspector") ? !!subscription?.isSubscriptionExpire : false} _disabled={{ pointerEvents: 'none' }} w='100%' _hover={{ textDecoration: 'none' }} _focus={{ boxShadow: 'none' }} as={NavLink} to='/add-report' onClick={emptyTemplate}>
            <Box w='100%' h='44px' d='inline-flex' alignItems='center' justifyContent={`${!auth.collapseSidebar ? 'flex-start' : 'center'}`} p={{ sm: `${!auth.collapseSidebar ? '0 15px' : '0'}`, xxl: `${!auth.collapseSidebar ? '0 15px' : '0'}` }} color='white.primary' textTransform='uppercase'>
              <Image mr={`${!auth.collapseSidebar ? '10px' : '0'}`} w='24px' h='24px' src={addNewReportIcon} />
              {!auth.collapseSidebar && <Text fontSize='lg' lineHeight='20px' as='span' whiteSpace='nowrap'>Add New Report</Text>}
            </Box>
          </Link>}
          <Link mb='18px' aria-disabled={(auth?.user?.user_Type === "company_admin" || auth?.user?.user_Type === "home_inspector") ? !!subscription?.isSubscriptionExpire : false} _disabled={{ pointerEvents: 'none' }} w='100%' _hover={{ textDecoration: 'none' }} _focus={{ boxShadow: 'none' }} as={NavLink} to='/template'>
            <Box w='100%' h='44px' d='inline-flex' alignItems='center' justifyContent={`${!auth.collapseSidebar ? 'flex-start' : 'center'}`} p={{ sm: `${!auth.collapseSidebar ? '0 15px' : '0'}`, xxl: `${!auth.collapseSidebar ? '0 15px' : '0'}` }} color='white.primary' textTransform='uppercase'>
              <Image mr={`${!auth.collapseSidebar ? '10px' : '0'}`} w='20px' h='20px' src={templateIcon} />
              {!auth.collapseSidebar && <Text fontSize='lg' lineHeight='20px' as='span' whiteSpace='nowrap'>Template</Text>}
            </Box>
          </Link>
          <Link mb='18px' aria-disabled={(auth?.user?.user_Type === "company_admin" || auth?.user?.user_Type === "home_inspector") ? !!subscription?.isSubscriptionExpire : false} _disabled={{ pointerEvents: 'none' }} w='100%' _hover={{ textDecoration: 'none' }} _focus={{ boxShadow: 'none' }} as={NavLink} to={auth.user?.user_Type === 'super_admin' ? '/company-listing' : '/reports'}>
            <Box w='100%' h='48px' d='inline-flex' alignItems='center' justifyContent={`${!auth.collapseSidebar ? 'flex-start' : 'center'}`} p={{ sm: `${!auth.collapseSidebar ? '0 15px' : '0'}`, xxl: `${!auth.collapseSidebar ? '0 15px' : '0'}` }} color='white.primary' textTransform='uppercase'>
              <Image mr={`${!auth.collapseSidebar ? '10px' : '0'}`} maxH='20px' w='26px' h='26px' src={reportsIcon} />
              {!auth.collapseSidebar && <Text fontSize='lg' lineHeight='20px' as='span' whiteSpace='nowrap'>Reports</Text>}
            </Box>
          </Link>
          {(auth?.user?.user_Type === "company_admin" || auth?.user?.user_Type === "super_admin") && <Link mb='18px' aria-disabled={(auth?.user?.user_Type === "company_admin" || auth?.user?.user_Type === "home_inspector") ? !!subscription?.isSubscriptionExpire : false} _disabled={{ pointerEvents: 'none' }} w='100%' _hover={{ textDecoration: 'none' }} _focus={{ boxShadow: 'none' }} as={NavLink} to='/staff-management'>
            <Box w='100%' h='48px' d='inline-flex' alignItems='center' justifyContent={`${!auth.collapseSidebar ? 'flex-start' : 'center'}`} p={{ sm: `${!auth.collapseSidebar ? '0 15px' : '0'}`, xxl: `${!auth.collapseSidebar ? '0 15px' : '0'}` }} color='white.primary' textTransform='uppercase'>
              <Image mr={`${!auth.collapseSidebar ? '10px' : '0'}`} w='26px' h='26px' src={staffManagementIcon} />
              {!auth.collapseSidebar && <Text fontSize='lg' lineHeight='20px' as='span' whiteSpace='nowrap'>{auth.user?.user_Type === "company_admin" ? 'Inspector' : 'Staff'} Management</Text>}
            </Box>
          </Link>}
          <Link mb='18px' aria-disabled={(auth?.user?.user_Type === "company_admin" || auth?.user?.user_Type === "home_inspector") ? !!subscription?.isSubscriptionExpire : false} w='100%' _disabled={{ pointerEvents: 'none' }} _hover={{ textDecoration: 'none' }} _focus={{ boxShadow: 'none' }} as={NavLink} to='/settings'>
            <Box w='100%' h='44px' d='inline-flex' alignItems='center' justifyContent={`${!auth.collapseSidebar ? 'flex-start' : 'center'}`} p={{ sm: `${!auth.collapseSidebar ? '0 15px' : '0'}`, xxl: `${!auth.collapseSidebar ? '0 15px' : '0'}` }} color='white.primary' textTransform='uppercase'>
              <Image mr={`${!auth.collapseSidebar ? '10px' : '0'}`} w='20px' h='20px' src={settingsIcon} />
              {!auth.collapseSidebar && <Text fontSize='lg' lineHeight='20px' as='span' whiteSpace='nowrap'>Settings</Text>}
            </Box>
          </Link>
        </Flex>
      </Box>
      {!auth.collapseSidebar && <Box textAlign='center' m='32px 0'>
        {(auth?.user?.user_Type === "company_admin" || (auth?.user?.user_Type === "home_inspector" && auth.user.AdminID === null)) && <Link as={ReactLink} to='/subscription' fontSize='xxl' lineHeight='22px' mb='10px' color='white.primary' d='inline-block'>Subscription Plan</Link>}
        {auth?.user?.user_Type !== "super_admin" && <Link as={ReactLink} to='/help-center' textDecoration='none'><Button variant='primarySmall'>Go to help center</Button></Link>}
      </Box>}
    </Box>
  )
}