import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../api";
import { userDetailById } from "./auth";

// Template interface (this)
export interface TemplateState {
  error: any;
  loading: boolean[];
  template: any;
  companyTemplate: any[];
  sections: any[];
  category: any[];
  narrative: any[];
  myTemplates: any[];
  superAdminTemplates: any[];
  inspectionMethod: any[];
  sectionComponents: any[];
  randomNarrativeKey: any[];
  activeSectionTab: number;
  activeCategoryTab: number;
  activeComponentTab: number;
  activeNarrativeId: number;
  saveNarrative: boolean;
  cloneTemplate: boolean;
  cloneTemplateId: number;
  categoryTabChanged: boolean;
  getAllTemplateDetails: any;
  futureTemplate: boolean;
  narrativesList: any;
  categoriesList: any;
  previewTemplate: any;
}

// Template initial state (this)
const initialState: TemplateState = {
  error: null,
  loading: [],
  template: {},
  companyTemplate: [],
  sections: [],
  category: [],
  narrative: [],
  myTemplates: [],
  superAdminTemplates: [],
  inspectionMethod: [],
  sectionComponents: [],
  randomNarrativeKey: [],
  activeSectionTab: 0,
  activeCategoryTab: 0,
  activeComponentTab: 0,
  activeNarrativeId: 0,
  saveNarrative: false,
  cloneTemplateId: null,
  categoryTabChanged: false,
  getAllTemplateDetails: {},
  cloneTemplate: false,
  futureTemplate: true,
  narrativesList: null,
  categoriesList: null,
  previewTemplate: null,
};

export const getTemplates = createAsyncThunk(
  "template/getTemplates",
  async () => {
    const response = await api.get(`template/get`);
    return response.data;
  }
);
export const getTemplateofsuperadmin = createAsyncThunk(
  "superAdmin/get/superAdmintemplate",
  async () => {
    const response = await api.get(`superAdmin/get/superAdmintemplate`);
    return response.data;
  }
);

export const getTemplateById = createAsyncThunk(
  "template/getTemplateById",
  async (data: { templateId: number }) => {
    const { templateId } = data;
    const response = await api.get(`template/get/byid/${templateId}`);
    return response.data;
  }
);
export const getCompanyTemplates = createAsyncThunk(
  "template/getCompanyTemplates",
  async (data: { payload: object }) => {
    const { payload } = data;
    const response = await api.post(`admin/get/templates`, { data: payload });
    return response.data;
  }
);
export const getAllTemplateDetails = createAsyncThunk(
  "template/getAllTemplateDetails",
  async (data: { payload: any }) => {
    const { payload } = data;
    const response = await api.post("template/getdetails", { data: payload });
    return response.data;
  }
);

export const createTemplate = createAsyncThunk(
  "template/createTemplate",
  async (data: { payload: object }, { dispatch }) => {
    const { payload } = data;
    const response = await api.post("template/create", { data: payload });

    dispatch(getTemplateById({ templateId: response.data.id }));
    return response.data;
  }
);

export const deleteTemplate = createAsyncThunk(
  "template/deleteTemplate",
  async (data: { templateId: number }, { dispatch }) => {
    const { templateId } = data;
    const response = await api.del(`template/delete/${templateId}`);
    return response.data;
  }
);

export const updateTemplate = createAsyncThunk(
  "template/updateTemplate",
  async (data: { templateId: number; payload: object }, { dispatch }) => {
    const { payload, templateId } = data;
    const response = await api.put(`template/update/${templateId}`, {
      data: payload,
    });

    dispatch(getTemplateById({ templateId: templateId }));
    return response.data;
  }
);

export const updateUser = createAsyncThunk(
  "template/updateUser",
  async (data: { payload: object }, { dispatch, getState }) => {
    const { payload } = data;
    const state: any = getState();
    const response = await api.post(`template/update/user`, { data: payload });

    dispatch(userDetailById({ userId: state.auth.user.id }));
    return response.data;
  }
);

export const getTemplateSection = createAsyncThunk(
  "template/getTemplateSection",
  async (data: { payload: object }) => {
    const { payload } = data;
    const response = await api.post(`template/get/section`, { data: payload });
    return response.data;
  }
);

export const createTemplateSection = createAsyncThunk(
  "template/createTemplateSection",
  async (data: { payload: object }, { dispatch }) => {
    const { payload } = data;
    const response = await api.post("template/create/section", {
      data: payload,
    });

    return response.data;
  }
);

export const deleteTemplateSection = createAsyncThunk(
  "template/deleteTemplateSection",
  async (data: { sectionId: number }, { dispatch, getState }) => {
    const { sectionId } = data;
    const state: any = getState();
    const response = await api.del(`template/delete/section/${sectionId}`);

    dispatch(
      getTemplateSection({
        payload: { templateId: state.template.template.id },
      })
    );
    return response.data;
  }
);

export const updateTemplateSection = createAsyncThunk(
  "template/updateTemplateSection",
  async (
    data: { paramId: number; payload: object },
    { dispatch, getState }
  ) => {
    const state: any = getState();
    const { paramId, payload } = data;
    const response = await api.put(`template/update/section/${paramId}`, {
      data: payload,
    });

    dispatch(
      getTemplateSection({
        payload: { templateId: state.template.template.id },
      })
    );
    return response.data;
  }
);

export const getSectionComponents = createAsyncThunk(
  "template/getSectionComponents",
  async (data: { payload: object }) => {
    const { payload } = data;
    const response = await api.post(`template/get/component`, {
      data: payload,
    });
    return response.data;
  }
);

export const createSectionComponent = createAsyncThunk(
  "template/createSectionComponent",
  async (data: { payload: object }, { dispatch, getState }) => {
    const { payload } = data;
    const state: any = getState();
    const response = await api.post("template/create/component", {
      data: payload,
    });

    dispatch(
      getSectionComponents({
        payload: {
          sectionId:
            state.template.sections[state.template.activeSectionTab].id,
        },
      })
    );
    return response.data;
  }
);

export const deleteSectionComponent = createAsyncThunk(
  "template/deleteSectionComponent",
  async (data: { componentId: number }, { dispatch, getState }) => {
    const { componentId } = data;
    const state: any = getState();
    const response = await api.del(`template/delete/component/${componentId}`);

    dispatch(
      getSectionComponents({
        payload: {
          sectionId:
            state.template.sections[state.template.activeSectionTab].id,
        },
      })
    );
    return response.data;
  }
);

export const updateSectionComponent = createAsyncThunk(
  "template/updateSectionComponent",
  async (
    data: { paramId: number; payload: object },
    { dispatch, getState }
  ) => {
    const state: any = getState();
    const { paramId, payload } = data;
    const response = await api.put(`template/update/component/${paramId}`, {
      data: payload,
    });

    dispatch(
      getSectionComponents({
        payload: {
          sectionId:
            state.template.sections[state.template.activeSectionTab].id,
        },
      })
    );
    return response.data;
  }
);

export const getCategory = createAsyncThunk(
  "template/getCategory",
  async (data: { payload: object }) => {
    const { payload } = data;
    const response = await api.post(`template/get/category`, { data: payload });
    return response.data;
  }
);

export const createCategory = createAsyncThunk(
  "template/createCategory",
  async (data: { payload: object }, { dispatch, getState }) => {
    const { payload } = data;
    const state: any = getState();
    const response = await api.post("template/create/category", {
      data: payload,
    });

    dispatch(
      getCategory({
        payload: {
          componentId:
            state.template.sectionComponents[state.template.activeComponentTab]
              .id,
        },
      })
    );
    return response.data;
  }
);

export const deleteCategory = createAsyncThunk(
  "template/deleteCategory",
  async (data: { categoryId: number }, { dispatch, getState }) => {
    const { categoryId } = data;
    const state: any = getState();
    const response = await api.del(`template/delete/category/${categoryId}`);

    dispatch(
      getCategory({
        payload: {
          componentId:
            state.template.sectionComponents[state.template.activeComponentTab]
              .id,
        },
      })
    );
    return response.data;
  }
);

export const updateCategory = createAsyncThunk(
  "template/updateCategory",
  async (
    data: { paramId: number; payload: object },
    { dispatch, getState }
  ) => {
    const state: any = getState();
    const { paramId, payload } = data;
    const response = await api.put(`template/update/category/${paramId}`, {
      data: payload,
    });

    dispatch(
      getCategory({
        payload: {
          componentId:
            state.template.sectionComponents[state.template.activeComponentTab]
              .id,
        },
      })
    );
    return response.data;
  }
);

export const getNarratives = createAsyncThunk(
  "template/getNarratives",
  async (data: { payload: object }) => {
    const { payload } = data;
    const response = await api.post(`template/get/narrative`, {
      data: payload,
    });
    return response.data;
  }
);

export const createNarrative = createAsyncThunk(
  "template/createNarrative",
  async (data: { payload: object }, { dispatch, getState }) => {
    const { payload } = data;
    const state: any = getState();
    const response = await api.post("template/create/narrative", {
      data: payload,
    });

    dispatch(
      getNarratives({
        payload: {
          categoryId:
            state.template.category[state.template.activeCategoryTab].id,
        },
      })
    );
    return response.data;
  }
);

export const deleteNarrative = createAsyncThunk(
  "template/deleteNarrative",
  async (data: { narrativeId: number }, { dispatch, getState }) => {
    const { narrativeId } = data;
    const state: any = getState();
    const response = await api.del(`template/delete/narrative/${narrativeId}`);

    dispatch(
      getNarratives({
        payload: {
          categoryId:
            state.template.category[state.template.activeCategoryTab].id,
        },
      })
    );
    return response.data;
  }
);

export const updateNarrative = createAsyncThunk(
  "template/updateNarrative",
  async (
    data: { paramId: number; payload: object },
    { dispatch, getState }
  ) => {
    const state: any = getState();
    const { paramId, payload } = data;
    const response = await api.put(`template/update/narrative/${paramId}`, {
      data: payload,
    });

    dispatch(
      getNarratives({
        payload: {
          categoryId:
            state.template.category[state.template.activeCategoryTab].id,
        },
      })
    );
    return response.data;
  }
);

export const cloneTemplate = createAsyncThunk(
  "template/cloneTemplate",
  async (data: { payload: object }) => {
    const { payload } = data;
    const response = await api.post("template/cloneTemplate", {
      data: payload,
    });
    return response.data;
  }
);

export const getInspectionMethod = createAsyncThunk(
  "template/getInspectionMethod",
  async (data: { payload: object }) => {
    const { payload } = data;
    const response = await api.post("template/get/inspectionMethod", {
      data: payload,
    });
    return response.data;
  }
);

export const createInspectionMethod = createAsyncThunk(
  "template/createInspectionMethod",
  async (data: { payload: object }, { dispatch, getState }) => {
    const { payload } = data;
    const state: any = getState();
    const response = await api.post("template/create/inspectionMethod", {
      data: payload,
    });

    dispatch(
      getInspectionMethod({
        payload: {
          templateSectionId:
            state.template.sections[state.template.activeSectionTab].id,
        },
      })
    );
    return response.data;
  }
);

export const updateInspectionMethod = createAsyncThunk(
  "template/updateInspectionMethod",
  async (
    data: { paramId: string; payload: object },
    { dispatch, getState }
  ) => {
    const { paramId, payload } = data;
    const state: any = getState();
    const response = await api.put(
      `template/update/inspectionMethod/${paramId}`,
      { data: payload }
    );

    dispatch(
      getInspectionMethod({
        payload: {
          templateSectionId:
            state.template.sections[state.template.activeSectionTab].id,
        },
      })
    );
    return response.data;
  }
);

export const deleteInspectionMethod = createAsyncThunk(
  "template/deleteInspectionMethod",
  async (data: { inspectionMethodId: string }, { dispatch, getState }) => {
    const { inspectionMethodId } = data;
    const state: any = getState();
    const response = await api.del(
      `template/delete/inspectionMethod/${inspectionMethodId}`
    );

    dispatch(
      getInspectionMethod({
        payload: {
          templateSectionId:
            state.template.sections[state.template.activeSectionTab].id,
        },
      })
    );
    return response.data;
  }
);

export const deleteReportInspectionMethod = createAsyncThunk(
  "template/deleteReportInspectionMethod",
  async (data: { inspectionMethodId: string }) => {
    const { inspectionMethodId } = data;
    const response = await api.post(
      `report/delete/reportInspectionMethod/${inspectionMethodId}`
    );
    return response.data;
  }
);

export const checkUpdateTemplateSection = createAsyncThunk(
  "template/checkUpdateTemplateSection",
  async (data: { sectionId: string }) => {
    const { sectionId } = data;
    const response = await api.put(`template/update/checksection/${sectionId}`);
    return response.data;
  }
);

export const cloneReportTemplate = createAsyncThunk(
  "template/cloneReportTemplate",
  async (data: { templateId: any; reportId: any; selectedFrom: any }) => {
    const { templateId, reportId, selectedFrom } = data;
    const response = await api.post(`template/cloneData`, {
      data: {
        templateId: templateId,
        reportId: reportId,
        selectedFrom: selectedFrom,
      },
    });
    return response.data;
  }
);

export const TemplateSlice = createSlice({
  name: "template",
  initialState,
  reducers: {
    setTemplateError: (state, action) => {
      state.error = action.payload;
    },
    setActiveSectionTab: (state, action) => {
      state.activeSectionTab = action.payload;
    },
    setActiveComponentTab: (state, action) => {
      state.activeComponentTab = action.payload;
    },
    setActiveCategoryTab: (state, action) => {
      state.activeCategoryTab = action.payload;
    },
    resetTemplate: (state, action) => {
      if (action.payload === "all") {
        state.template = {};
        state.sections = [];
        state.category = [];
        state.narrative = [];
        state.inspectionMethod = [];
        state.sectionComponents = [];
      } else {
        action.payload.forEach((x) => (state[x] = x === "template" ? {} : []));
      }
    },
    resetTemplateTabs: (state, action) => {
      if (action.payload === "all") {
        state.activeSectionTab = 0;
        state.activeCategoryTab = 0;
        state.activeComponentTab = 0;
      } else {
        action.payload.forEach((x) => (state[x] = 0));
      }
    },
    setRandomNarrativeKey: (state, action) => {
      state.randomNarrativeKey = action.payload;
    },
    setActiveNarrativeId: (state, action) => {
      state.activeNarrativeId = action.payload;
    },
    setSaveNarrative: (state, action) => {
      state.saveNarrative = action.payload;
    },
    setFutureTemplate: (state, action) => {
      state.futureTemplate = action.payload;
    },
    setCloneTemplate: (state, action) => {
      state.cloneTemplate = action.payload;
    },
    setNarrativesList: (state, action) => {
      state.narrativesList = action.payload;
    },
    setCategoriesList: (state, action) => {
      state.categoriesList = action.payload;
    },
    setPreviewTemplate: (state, action) => {
      state.previewTemplate = action.payload;
    },
    setNarratives: (state, action) => {
      state.narrative = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTemplates.pending, (state) => {
        state.error = null;
        state.loading.push(true);
      })
      .addCase(getTemplates.rejected, (state, action) => {
        state.loading.pop();
        state.error = action.error;
      })
      .addCase(getTemplates.fulfilled, (state, action) => {
        state.error = null;
        state.loading.pop();
        state.myTemplates = action.payload;
      })
      .addCase(getTemplateofsuperadmin.pending, (state) => {
        state.error = null;
        state.loading.push(true);
      })
      .addCase(getTemplateofsuperadmin.rejected, (state, action) => {
        state.loading.pop();
        state.error = action.error;
      })
      .addCase(getTemplateofsuperadmin.fulfilled, (state, action) => {
        state.error = null;
        state.loading.pop();
        state.superAdminTemplates = action.payload;
      })
      .addCase(getTemplateById.pending, (state) => {
        state.error = null;
        state.loading.push(true);
      })
      .addCase(getTemplateById.rejected, (state, action) => {
        state.loading.pop();
        state.error = action.error;
      })
      .addCase(getTemplateById.fulfilled, (state, action) => {
        state.error = null;
        state.loading.pop();
        state.template = action.payload;
      })
      .addCase(getCompanyTemplates.pending, (state) => {
        state.error = null;
        state.loading.push(true);
      })
      .addCase(getCompanyTemplates.rejected, (state, action) => {
        state.loading.pop();
        state.error = action.error;
      })
      .addCase(getCompanyTemplates.fulfilled, (state, action) => {
        state.error = null;
        state.loading.pop();
        state.companyTemplate = action.payload;
      })
      .addCase(createTemplate.pending, (state) => {
        state.error = null;
        state.loading.push(true);
      })
      .addCase(createTemplate.rejected, (state, action) => {
        state.loading.pop();
        state.error = action.error;
      })
      .addCase(createTemplate.fulfilled, (state) => {
        state.error = null;
        state.loading.pop();
      })
      .addCase(updateUser.pending, (state) => {
        state.error = null;
        state.loading.push(true);
      })
      .addCase(updateUser.rejected, (state, action) => {
        state.loading.pop();
        state.error = action.error;
      })
      .addCase(updateUser.fulfilled, (state) => {
        state.error = null;
        state.loading.pop();
      })
      .addCase(getTemplateSection.pending, (state) => {
        state.error = null;
        state.loading.push(true);
      })
      .addCase(getTemplateSection.rejected, (state, action) => {
        state.loading.pop();
        state.error = action.error;
      })
      .addCase(getTemplateSection.fulfilled, (state, action) => {
        state.error = null;
        state.loading.pop();
        state.sections = action.payload;
      })
      .addCase(createTemplateSection.pending, (state) => {
        state.error = null;
        state.loading.push(true);
      })
      .addCase(createTemplateSection.rejected, (state, action) => {
        state.loading.pop();
        state.error = action.error;
      })
      .addCase(createTemplateSection.fulfilled, (state) => {
        state.error = null;
        state.loading.pop();
      })
      .addCase(deleteTemplateSection.pending, (state) => {
        state.error = null;
        state.loading.push(true);
      })
      .addCase(deleteTemplateSection.rejected, (state, action) => {
        state.loading.pop();
        state.error = action.error;
      })
      .addCase(deleteTemplateSection.fulfilled, (state) => {
        state.error = null;
        state.loading.pop();
      })
      .addCase(updateTemplateSection.pending, (state) => {
        state.error = null;
        state.loading.push(true);
      })
      .addCase(updateTemplateSection.rejected, (state, action) => {
        state.loading.pop();
        state.error = action.error;
      })
      .addCase(updateTemplateSection.fulfilled, (state) => {
        state.error = null;
        state.loading.pop();
      })
      .addCase(getSectionComponents.pending, (state) => {
        state.error = null;
        state.loading.push(true);
      })
      .addCase(getSectionComponents.rejected, (state, action) => {
        state.loading.pop();
        state.error = action.error;
      })
      .addCase(getSectionComponents.fulfilled, (state, action) => {
        state.error = null;
        state.loading.pop();
        state.sectionComponents = action.payload;
      })
      .addCase(createSectionComponent.pending, (state) => {
        state.error = null;
        state.loading.push(true);
      })
      .addCase(createSectionComponent.rejected, (state, action) => {
        state.loading.pop();
        state.error = action.error;
      })
      .addCase(createSectionComponent.fulfilled, (state) => {
        state.error = null;
        state.loading.pop();
      })
      .addCase(deleteSectionComponent.pending, (state) => {
        state.error = null;
        state.loading.push(true);
      })
      .addCase(deleteSectionComponent.rejected, (state, action) => {
        state.loading.pop();
        state.error = action.error;
      })
      .addCase(deleteSectionComponent.fulfilled, (state) => {
        state.error = null;
        state.loading.pop();
      })
      .addCase(updateSectionComponent.pending, (state) => {
        state.error = null;
        state.loading.push(true);
      })
      .addCase(updateSectionComponent.rejected, (state, action) => {
        state.loading.pop();
        state.error = action.error;
      })
      .addCase(updateSectionComponent.fulfilled, (state) => {
        state.error = null;
        state.loading.pop();
      })
      .addCase(getNarratives.pending, (state) => {
        state.error = null;
        state.loading.push(true);
      })
      .addCase(getNarratives.rejected, (state, action) => {
        state.loading.pop();
        // state.error = action.error;
      })
      .addCase(getNarratives.fulfilled, (state, action) => {
        state.error = null;
        state.loading.pop();
        state.narrative = action.payload;
      })
      .addCase(createNarrative.pending, (state) => {
        state.error = null;
        state.loading.push(true);
      })
      .addCase(createNarrative.rejected, (state, action) => {
        state.loading.pop();
        state.error = action.error;
      })
      .addCase(createNarrative.fulfilled, (state) => {
        state.error = null;
        state.loading.pop();
      })
      .addCase(deleteNarrative.pending, (state) => {
        state.error = null;
        state.loading.push(true);
      })
      .addCase(deleteNarrative.rejected, (state, action) => {
        state.loading.pop();
        state.error = action.error;
      })
      .addCase(deleteNarrative.fulfilled, (state) => {
        state.error = null;
        state.loading.pop();
      })
      .addCase(updateNarrative.pending, (state) => {
        state.error = null;
        state.loading.push(true);
      })
      .addCase(updateNarrative.rejected, (state, action) => {
        state.loading.pop();
        state.error = action.error;
      })
      .addCase(updateNarrative.fulfilled, (state) => {
        state.error = null;
        state.loading.pop();
      })
      .addCase(getCategory.pending, (state) => {
        state.error = null;
        state.loading.push(true);
      })
      .addCase(getCategory.rejected, (state, action) => {
        state.loading.pop();
        state.error = action.error;
      })
      .addCase(getCategory.fulfilled, (state, action) => {
        state.error = null;
        state.loading.pop();
        state.category = action.payload;
      })
      .addCase(createCategory.pending, (state) => {
        state.error = null;
        state.loading.push(true);
      })
      .addCase(createCategory.rejected, (state, action) => {
        state.loading.pop();
        state.error = action.error;
      })
      .addCase(createCategory.fulfilled, (state) => {
        state.error = null;
        state.loading.pop();
      })
      .addCase(deleteCategory.pending, (state) => {
        state.error = null;
        state.loading.push(true);
      })
      .addCase(deleteCategory.rejected, (state, action) => {
        state.loading.pop();
        state.error = action.error;
      })
      .addCase(deleteCategory.fulfilled, (state) => {
        state.error = null;
        state.loading.pop();
      })
      .addCase(updateCategory.pending, (state) => {
        state.error = null;
        state.loading.push(true);
      })
      .addCase(updateCategory.rejected, (state, action) => {
        state.loading.pop();
        state.error = action.error;
      })
      .addCase(updateCategory.fulfilled, (state) => {
        state.error = null;
        state.loading.pop();
      })
      .addCase(cloneTemplate.pending, (state) => {
        state.error = null;
        state.loading.push(true);
      })
      .addCase(cloneTemplate.rejected, (state, action) => {
        state.loading.pop();
        state.error = action.error;
      })
      .addCase(cloneTemplate.fulfilled, (state) => {
        state.error = null;
        state.loading.pop();
      })
      .addCase(getInspectionMethod.pending, (state) => {
        state.error = null;
        state.loading.push(true);
      })
      .addCase(getInspectionMethod.rejected, (state, action) => {
        state.loading.pop();
        state.error = action.error;
      })
      .addCase(getInspectionMethod.fulfilled, (state, action) => {
        state.error = null;
        state.loading.pop();
        state.inspectionMethod = action.payload;
      })
      .addCase(createInspectionMethod.pending, (state) => {
        state.error = null;
        state.loading.push(true);
      })
      .addCase(createInspectionMethod.rejected, (state, action) => {
        state.loading.pop();
        state.error = action.error;
      })
      .addCase(createInspectionMethod.fulfilled, (state) => {
        state.error = null;
        state.loading.pop();
      })
      .addCase(updateInspectionMethod.pending, (state) => {
        state.error = null;
        state.loading.push(true);
      })
      .addCase(updateInspectionMethod.rejected, (state, action) => {
        state.loading.pop();
        state.error = action.error;
      })
      .addCase(updateInspectionMethod.fulfilled, (state) => {
        state.error = null;
        state.loading.pop();
      })
      .addCase(deleteInspectionMethod.pending, (state) => {
        state.error = null;
        state.loading.push(true);
      })
      .addCase(deleteInspectionMethod.rejected, (state, action) => {
        state.loading.pop();
        state.error = action.error;
      })
      .addCase(deleteInspectionMethod.fulfilled, (state) => {
        state.error = null;
        state.loading.pop();
      })
      .addCase(deleteReportInspectionMethod.pending, (state) => {
        state.error = null;
        state.loading.push(true);
      })
      .addCase(deleteReportInspectionMethod.rejected, (state, action) => {
        state.loading.pop();
        state.error = action.error;
      })
      .addCase(deleteReportInspectionMethod.fulfilled, (state) => {
        state.error = null;
        state.loading.pop();
      })
      .addCase(checkUpdateTemplateSection.pending, (state) => {
        state.error = null;
        state.loading.push(true);
      })
      .addCase(checkUpdateTemplateSection.rejected, (state, action) => {
        state.loading.pop();
        state.error = action.error;
      })
      .addCase(checkUpdateTemplateSection.fulfilled, (state) => {
        state.error = null;
        state.loading.pop();
      })
      .addCase(deleteTemplate.pending, (state) => {
        state.error = null;
        state.loading.push(true);
      })
      .addCase(deleteTemplate.rejected, (state, action) => {
        state.loading.pop();
        state.error = action.error;
      })
      .addCase(deleteTemplate.fulfilled, (state) => {
        state.error = null;
        state.loading.pop();
      })
      .addCase(cloneReportTemplate.pending, (state) => {
        state.error = null;
        state.loading.push(true);
      })
      .addCase(cloneReportTemplate.rejected, (state, action) => {
        state.loading.pop();
        state.error = action.error;
      })
      .addCase(cloneReportTemplate.fulfilled, (state, action) => {
        state.error = null;
        state.loading.pop();
        state.cloneTemplateId = action.payload.id;
      })
      .addCase(getAllTemplateDetails.pending, (state) => {
        state.error = null;
        state.loading.push(true);
      })
      .addCase(getAllTemplateDetails.rejected, (state, action) => {
        state.loading.pop();
        state.error = action.error;
      })
      .addCase(getAllTemplateDetails.fulfilled, (state, action) => {
        state.error = null;
        state.loading.pop();
        state.getAllTemplateDetails = action.payload;
      });
  },
});

export const {
  setTemplateError,
  setActiveSectionTab,
  setCloneTemplate,
  setActiveComponentTab,
  setActiveCategoryTab,
  resetTemplate,
  resetTemplateTabs,
  setRandomNarrativeKey,
  setActiveNarrativeId,
  setSaveNarrative,
  setFutureTemplate,
  setNarrativesList,
  setCategoriesList,
  setPreviewTemplate,
  setNarratives,
} = TemplateSlice.actions;
export default TemplateSlice.reducer;
