import { ComponentMultiStyleConfig } from "@chakra-ui/react";

const tab: ComponentMultiStyleConfig = {
  parts: ['root', 'tab', 'tablist', 'tabpanels', 'tabpanel'],
  variants: {
    primary: {
      root: {
      },
      tab:{
        color:'orange.light',
        fontSize:'21px',
        fontWeight:'600',
        
        _selected:{
            borderBottom:'4px solid red',
            borderColor:'orange.light',
            _focus: {
                outline:'none',
                boxShadow:'none'
            }
        }
      }
    }
  },
}

export default tab