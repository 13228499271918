import type { ComponentSingleStyleConfig } from "@chakra-ui/react";


const Textarea: ComponentSingleStyleConfig = {

  variants: {
    Bordered: {
      border: '1px',
      borderRadius: '4px',
      width: '100%',
      height: '83px',
      fontSize: 'xxl',
      borderColor: 'gray.light4',
      color: 'black.primary',
      resize: 'none',
      overflow: 'auto',

      _placeholder: {
        fontSize: 'xxl',
        fontWeight: 'normal',
        color: 'gray.light5',
      },
    },
  }

}
export default Textarea