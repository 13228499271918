import { Avatar, Box, Button, Flex, Text } from '@chakra-ui/react'
import { css } from '@emotion/react';
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getReportById, ReportState } from '../../../redux/modules/report';
import { RootState } from '../../../redux/store';
export default function InspectionReport() {
    const params = useParams();
    const dispatch = useDispatch();
    const report: ReportState = useSelector((state: RootState) => state.report);
    useEffect(() => {
        dispatch(getReportById({ reportId: params.id }));
    }, [dispatch, params.id]);
    const handleReportPDF = () => {
        const finalUrl = report?.inspectionReportDetails?.reportLink;
        const a = document.createElement('a')
        // @ts-ignore
        a.href = finalUrl;
        a.download = finalUrl;
        window.open(finalUrl, '_blank');
    }
    const handleInvoicePDF = () => {
        const finalUrl = report?.inspectionReportDetails?.paymentLink;
        const a = document.createElement('a');
        // @ts-ignore
        a.href = finalUrl;
        a.download = finalUrl;
        window.open(finalUrl, '_blank');
    }
    const handleAgreementPDF = () => {
        const finalUrl = report?.inspectionReportDetails?.aggrementLink;
        const a = document.createElement('a');
        // @ts-ignore
        a.href = finalUrl;
        a.download = finalUrl;
        window.open(finalUrl, '_blank');
    }
    return (
        <>
            <Box bg='white.lighten3' p='29px 20px 20px' h='100vh' overflow='auto' overflowX='hidden' w='100%'>
                <Flex maxW='1200px' w='100%' m='auto' alignItems='center' gap={{ sm: '10px', lg: '35px' }} flexDirection={{ sm: 'column', lg: 'row' }}>
                    <Box w={{ sm: '75%', lg: '50%' }}>
                        <Box>
                            <Text fontSize='20px' mb='5px' color='gray.light12' ml='15px'>Your Report</Text>
                            <Flex alignItems='center' justifyContent='space-between' bg='white.primary' borderRadius='5px' padding='20px 30px'>
                                <Text fontSize='24px' color='black.lighten'>Report PDF</Text>
                                <Button variant='primary2' onClick={handleReportPDF}>View</Button>
                            </Flex>
                        </Box>
                        <Box mt='20px'>
                            <Text fontSize='20px' mb='5px' color='gray.light12' ml='15px'>Additional Documents</Text>
                            <Box padding='20px 30px' borderRadius='5px' bg='white.primary'>
                                <Flex alignItems='center' justifyContent='space-between'>
                                    <Text fontSize='24px' color='black.lighten'>Invoice</Text>
                                    <Button variant='primary2' onClick={handleInvoicePDF}>View</Button>
                                </Flex>
                                <Flex alignItems='center' justifyContent='space-between' mt='20px '>
                                    <Text fontSize='24px' color='black.lighten'>Agreement</Text>
                                    <Button variant='primary2' onClick={handleAgreementPDF}>View</Button>
                                </Flex>
                            </Box>
                        </Box>
                    </Box>
                    <Box w={{ sm: '75%', lg: '50%' }}>
                        <Box bg='white.primary' borderRadius='5px' mb='9px' p='15px'>
                            <Flex alignItems='center' p='15px' borderRadius='12px' bg='white.lighten3'>
                                <Box w='100%' display='flex' flexDirection='column'>
                                    <Box mb={{ sm: '4px', lg: '6px' }} w='fit-content'>
                                        <Text as='h3' color='blue.dark4' w='100%' fontSize='20px' fontWeight='600' mb='6px'>Client Info</Text>
                                        <Text as='span' fontSize='18px' color='gray.primary' mr='4px'>Name :</Text>
                                        <Text as='span' fontSize='18px' color='black.lighten'>{report?.inspectionReportDetails?.clientinformation?.firstName} {report?.inspectionReportDetails?.clientinformation?.lastName}</Text>
                                    </Box>
                                    <Box mb={{ sm: '4px', lg: '6px' }} w='fit-content'>
                                        <Text as='span' fontSize='18px' color='gray.primary' mr='4px'>Phone No :</Text>
                                        <Text as='span' fontSize='18px' color='black.lighten'>{report?.inspectionReportDetails?.clientinformation?.phone}</Text>
                                    </Box>
                                    <Box mb={{ sm: '4px', lg: '6px' }} w='fit-content'>
                                        <Text as='span' fontSize='18px' color='gray.primary' mr='4px'>Email :</Text>
                                        <Text as='span' fontSize='18px' color='black.lighten'>{report?.inspectionReportDetails?.clientinformation?.email}</Text>
                                    </Box>
                                </Box>
                            </Flex>
                            <Flex alignItems='center' p={{ sm: '15px 20px', lg: '15px' }} borderRadius='12px' bg='white.lighten3' mt='20px' flexWrap='wrap'>
                                <Text as='h3' color='blue.dark4' w='100%' fontSize='20px' fontWeight='600' mb='8px'>Inspector Info</Text>
                                <Box>
                                    <Box borderRadius='50%' minW={{ sm: '112px', lg: '80px' }} w={{ sm: '112px', lg: '80px' }} h={{ sm: '112px', lg: '80px' }} overflow='hidden' bg='black.lighten'>
                                        <Avatar name={report?.inspectionReportDetails?.inspectorDetail?.inspectorName} src={report?.inspectionReportDetails?.inspectorDetail?.inspectorAccessImageUrl} fontSize='18px' h='100%' w='100%' bg='transparent' css={css({
                                            '.chakra-avatar__initials': {
                                                fontSize: '38px',
                                                color: 'white'
                                            },
                                            '.chakra-avatar__img': {
                                                borderRadius: '0',
                                                width: 'auto',
                                                height: 'auto',
                                                maxHeight: '100%'
                                            }
                                        })} />
                                    </Box>
                                </Box>
                                <Box w={{ sm: 'calc(100% - 132px)', lg: 'fit-content' }} ml={{ sm: '20px', lg: '25px' }} justifyContent='flex-end' flexWrap='wrap' alignItems='center'>
                                    <Box mb={{ sm: '4px', lg: '6px' }} w='fit-content'>
                                        <Text as='span' fontSize='18px' color='gray.primary' mr='4px'>Name :</Text>
                                        <Text as='span' fontSize='18px' color='black.lighten'>{report?.inspectionReportDetails?.inspectorDetail?.inspectorName}</Text>
                                    </Box>
                                    <Box mb={{ sm: '4px', lg: '6px' }} w='fit-content'>
                                        <Text as='span' fontSize='18px' color='gray.primary' mr='4px'>Report Id :</Text>
                                        <Text as='span' fontSize='18px' color='black.lighten'>{report?.inspectionReportDetails?.inspectorDetail?.reportId}</Text>
                                    </Box>
                                </Box>
                            </Flex>
                        </Box>
                    </Box>
                </Flex>
            </Box>
        </>
    )
}