import { createAsyncThunk, createSlice, Action } from '@reduxjs/toolkit';
import api from '../../api';

export interface inspectorState {
  error: any;
  loading: boolean[];
  inspectors: any[];
}

const initialState: inspectorState = {
  error: null,
  loading: [],
  inspectors: []
};

export const createInspector = createAsyncThunk(
  'inspector/createInspector',
  async (data: { payload: object }, { dispatch }) => {
    const { payload } = data;
    const response = await api.post('admin/create/homeInspector', { data: payload });
    dispatch(getInspectors());
    return response.data;
  }
);

export const getInspectors = createAsyncThunk(
  'inspector/getInspectors',
  async () => {
    const response = await api.post('admin/get/homeinspector');
    return response.data;
  }
);

export const deleteInspector = createAsyncThunk(
  'inspector/deleteInspector',
  async (data: any, { dispatch }) => {
    const { paramId } = data;
    const response = await api.post(`admin/delete/inspector/${paramId}`);
    return response.data;
  }
);

export const updateInspector = createAsyncThunk(
  'inspector/updateInspector',
  async (data: { staffId: string, payload: object }, { dispatch }) => {
    const { staffId, payload } = data;
    const response = await api.put(`admin/update/homeinspector/${staffId}`, { data: payload });
    return response.data;
  }
)

export const blockInspector = createAsyncThunk(
  'inspector/blockInspector',
  async (data: { staffId: string, payload: object }, { dispatch }) => {
    const { staffId, payload } = data;
    const response = await api.put(`admin/block/homeinspector/${staffId}`, { data: payload });
    return response.data;
  }
)
export const blockFunnel = createAsyncThunk(
  'inspector/blockFunnel',
  async (data: { staffId: string, payload: object }, { dispatch }) => {
    console.log("Heyyyylo")
    const { staffId, payload } = data;
    const response = await api.put(`superadmin/block/funnel/company/admin/${staffId}`, { data: payload });
    return response.data;
  }
)
export const blockCRM = createAsyncThunk(
  'inspector/blockCRM',
  async (data: { staffId: string, payload: object }, { dispatch }) => {
  
    const { staffId, payload } = data;
    const response = await api.put(`superadmin/block/crm/company/admin/${staffId}`, { data: payload });
    return response.data;
  }
)
export const subscribeUser = createAsyncThunk(
  'inspector/subscribe/subscribeUser',
  async (data: { staffId: string, payload: object }, { dispatch }) => {
    const { staffId, payload } = data;
    const response = await api.put(`superadmin/subscribe/user/${staffId}`, { data: payload });
    return response.data;
  }
)

export const unsubscribeUser = createAsyncThunk(
  'subscription/create/custom/subscription',
  async (data: any, { dispatch }) => {
    const { payload } = data;
    const response = await api.post(`subscription/create/custom/subscription`,  { data: payload });
    return response.data;
  }
);



export const inspectorSlice = createSlice({
  name: 'inspector',
  initialState,
  reducers: {
    setInspectorError: (state, action) => {
      state.error = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createInspector.pending, (state) => {
        state.error = null;
        state.loading.push(true);
      })
      .addCase(createInspector.rejected, (state, action) => {
        state.loading.pop();
        state.error = action.error;
      })
      .addCase(createInspector.fulfilled, (state) => {
        state.error = null;
        state.loading.pop();
      })
      .addCase(getInspectors.pending, (state) => {
        state.error = null;
        state.loading.push(true);
      })
      .addCase(getInspectors.rejected, (state, action) => {
        state.loading.pop();
        state.error = action.error;
      })
      .addCase(getInspectors.fulfilled, (state, action) => {
        state.error = null;
        state.loading.pop();
        state.inspectors = action.payload
      })
      .addCase(deleteInspector.pending, (state) => {
        state.error = null;
        state.loading.push(true);
      })
      .addCase(deleteInspector.rejected, (state, action) => {
        state.loading.pop();
        state.error = action.error;
      })
      .addCase(deleteInspector.fulfilled, (state) => {
        state.error = null;
        state.loading.pop();
      })
      .addCase(updateInspector.pending, (state) => {
        state.error = null;
        state.loading.push(true);
      })
      .addCase(updateInspector.rejected, (state, action) => {
        state.loading.pop();
        state.error = action.error;
      })
      .addCase(updateInspector.fulfilled, (state) => {
        state.error = null;
        state.loading.pop();
      })
      .addCase(blockInspector.pending, (state) => {
        state.error = null;
        state.loading.push(true);
      })
      .addCase(blockInspector.rejected, (state, action) => {
        state.loading.pop();
        state.error = action.error;
      })
      .addCase(blockInspector.fulfilled, (state) => {
        state.error = null;
        state.loading.pop();
      })
      .addCase(blockFunnel.pending,(state)=>{
        state.error = null;
        state.loading.push(true);
      })
      .addCase(blockFunnel.rejected,(state,action)=>{
        state.loading.pop();
        state.error = action.error;
      })
      .addCase(blockFunnel.fulfilled,(state)=>{
        state.error = null;
        state.loading.pop();
      })
      .addCase(blockCRM.pending,(state)=>{
        state.error = null;
        state.loading.push(true);
      })
      .addCase(blockCRM.rejected,(state,action)=>{
        state.loading.pop();
        state.error = action.error;
      })
      .addCase(blockCRM.fulfilled,(state)=>{
        state.error = null;
        state.loading.pop();
      })

      .addCase(subscribeUser.pending,(state)=>{
        state.error = null;
        state.loading.push(true);
      })
      .addCase(subscribeUser.rejected,(state,action)=>{
        state.loading.pop();
        state.error = action.error;
      })
      .addCase(subscribeUser.fulfilled,(state)=>{
        state.error = null;
        state.loading.pop();
      })


      .addCase(unsubscribeUser.pending,(state)=>{
        state.error = null;
        state.loading.push(true);
      })
      .addCase(unsubscribeUser.rejected,(state,action)=>{
        state.loading.pop();
        state.error = action.error;
      })
      .addCase(unsubscribeUser.fulfilled,(state)=>{
        state.error = null;
        state.loading.pop();
      })

      
  },
});

export const { setInspectorError } = inspectorSlice.actions;
export default inspectorSlice.reducer;