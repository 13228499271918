import { Box, Flex, Icon, Image, Text, Button, Heading, Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalFooter, useDisclosure } from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';
import { IoMdClose } from 'react-icons/io';
import { useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import { authState, getSelectedAggrements, updateSaveAgreement } from '../../../redux/modules/auth';
import SignaturePad from 'signature_pad';
import doc from '../../../assets/images/doc.svg';
import { jsPDF } from 'jspdf';
import AWS from 'aws-sdk';
import { toastr } from 'react-redux-toastr';
import Loader from '../../../components/common/loader';
import { getReportDetail, ReportState } from '../../../redux/modules/report';

export default function Signature() {
  const location = useLocation();
  const dispatch = useDispatch();
  const params = useParams();
  const report: ReportState = useSelector((state: RootState) => state.report);
  const auth: authState = useSelector((state: RootState) => state.auth);
  const { isOpen: addSignature, onOpen: openAddSignature, onClose: closeAddSignature } = useDisclosure();

  const signaturePad = useRef(null);
  const [signature, setSignature] = useState();
  const query = location.search.split('?').at(-1)?.split('&');

  useEffect(() => {
    if (params?.id) {
      dispatch(getReportDetail({ paramId: params.id }));
    }
  }, [dispatch, params]);
  useEffect(() => {
    const ids = query.map((x: any) => x.split('agrId=').at(-1));
    dispatch(getSelectedAggrements({ payload: { ids: [...ids] } }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    if (addSignature === true) {
      setTimeout(() => {
        const canvas = document.querySelector("canvas");
        signaturePad.current = new SignaturePad(canvas, { backgroundColor: '#fff' });
      }, 100);
    }
  }, [addSignature]);

  const convertSignatureToImage = () => {
    if (signaturePad.current.isEmpty()) {
      alert("Please provide a signature first");
    } else {
      const dataURL = signaturePad.current.toDataURL("image/jpeg");
      setSignature(dataURL);
      closeAddSignature();
    }
  }

  const handlePdfDownload = async () => {
    if (!signature) {
      toastr.warning("", "Please provide a signature first");
      return;
    }
    if (report?.reportDetails?.aggrementLink) {
      toastr.warning("", "aggrement already saved");
      return;
    }

    const aggrementData = auth.selectedAggrements;
    let arr = aggrementData.map((ele: any, idx) => {
      return ({
        title: idx + 1 + '. ' + ele.title + ':',
        description: ele.description.split('<br>')
      })
    })

    let tempArr = arr.map((ele) => {
      return ({
        title: ele.title,
        description: ele.description.map((elem: string) => {
          return elem.replace(/<(.|\n)*?>/g, '').replace(/\&nbsp;/g, ' ');
        })
      })
    })

    const docs = new jsPDF('p', 'in', 'a4')
    docs.setFontSize(40);
    docs.text('Agreements', 2.5, 0.5)

    //break the long text
    let verticalOffset = 0.50;
    let maximumVerticalOffset = 11;
    let finalVerticalOffset: any;
    tempArr.forEach((agreement: any) => {

      verticalOffset = (verticalOffset + 0.50);
      if (verticalOffset > 11) {
        docs.addPage()
        verticalOffset = 0.5
      }
      docs.setFontSize(16);
      docs.setFont(undefined, undefined, 'bold')
      docs.text(agreement.title, 0.5, verticalOffset);
      const textLines = docs.setFont('Arial', undefined, 'normal')
        .setFontSize(12)
        .splitTextToSize(agreement.description, 7.25)

      verticalOffset = verticalOffset + 0.15;

      textLines.forEach((ele: any) => {
        if (verticalOffset > maximumVerticalOffset) {
          docs.addPage()
          verticalOffset = 0.5
        } else {
          verticalOffset = verticalOffset + 0.25;
          docs.text(ele, 0.5, verticalOffset)
        }
      })
      finalVerticalOffset = verticalOffset;
    })

    finalVerticalOffset = finalVerticalOffset + 0.25;
    signature && docs.addImage(signature, 'JPEG', 0.5, finalVerticalOffset, 4, 2)

    docs.save('agreement.pdf');

    const fileName = docs.output('arraybuffer');

    const name = `agreements/${Date.now()}.pdf`;

    const accessKey = "AKIAVAVVUYCZP466P7B5";
    const secretAccessId = "e0KyU0i6fqP35Nxu5bwlK5ymoJYNKVc6Qwv1gawv";
    const bucket = "home-inspection-test";

    const s3 = new AWS.S3({
      accessKeyId: accessKey,
      secretAccessKey: secretAccessId,
    });

    const AggrementUpload = async (file) => {
      try {
        return await new Promise((resolve, reject) => {
          const parameters = {
            Bucket: bucket,
            Key: name,
            Body: file,
            ContentType: 'application/pdf'
          };
          s3.upload(parameters, function (err, data) {
            if (err) {
              throw err;
            } else {
              new Promise(() => {
                const data2: any = dispatch(updateSaveAgreement({ reportId: params.id, payload: { aggrementLink: data?.Location } }));
                data2.then(function (value) {
                  if (value.type === 'auth/save/agreement/fulfilled') {
                    toastr.success('', 'Agreement Saved Succesfully');
                  }
                })
              })
              resolve(data?.Location);
            }
          });

        });
      } catch (err) { }
    }
    AggrementUpload(fileName);

  }

  useEffect(() => {
    if (auth?.error) {
      const { message } = auth.error;
      toastr.error("", message ? message : "");
    }
  }, [auth.error, dispatch]);

  return (
    <>
      {(auth.loginLoading.length !== 0) && <Loader />}
      <Box>
        <Box p='29px 30px 20px 20px' overflow='auto' w='100%'>
          <Box>
            <Box id="print">
              <Heading as='h2' fontSize='24px' fontWeight='600' color='black.lighten' mb='20px'>Agreements</Heading>
              <Box d='grid' gridTemplateColumns='1fr'>
                {auth.selectedAggrements?.map((x: any, index: number) => {
                  return (
                    <>
                      <Text fontSize='xxxl' fontWeight='600' mt='18px' mb='5px'>Agreement {index + 1}</Text>
                      <Box bg='white.primary' borderRadius='10px' boxShadow='0px 0px 12px rgba(136, 136, 136, 0.1)' d='inline-block' w='100%' p='20px 25px'>
                        <Flex key={x.id}>
                          <Box mr='35px' w='70px'>
                            <Image src={doc} alt='doc' />
                          </Box>
                          <Box d='inline-flex' flexDir='column'>
                            <Heading fontSize='18px' fontWeight='600' color='black.lighten' mb='2px' dangerouslySetInnerHTML={{ __html: x.title }}></Heading>
                            <Text fontSize='16px' dangerouslySetInnerHTML={{ __html: x.description }}></Text>
                          </Box>
                        </Flex>
                      </Box>
                    </>
                  )
                })}
              </Box>
              {signature && <Box mt='20px' w='250px' h='100px' borderRadius='10px' border='1px solid' borderColor='black.primary' d='inline-flex' alignItems='center' justifyContent='center' overflow='hidden' p='10px'><img id="dd" src={signature} alt=''></img></Box>}
            </Box>
            <Flex justifyContent='flex-start' mt='25px'>
              <Button w='100px' variant='bordered' mr='15px' onClick={openAddSignature}>Sign</Button>
              <Button w='100px' variant='primary2' onClick={handlePdfDownload}>Print & Save</Button>
            </Flex>
          </Box>
        </Box>
        <Modal isOpen={addSignature} onClose={closeAddSignature} size='xl' scrollBehavior='inside'>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader p='30px' fontSize='21px' lineHeight='26px' color='black.lighten' fontWeight='600'>Add Signature</ModalHeader>
            <Icon color='black.primary' fontSize='24px' as={IoMdClose} onClick={closeAddSignature} cursor='pointer' position='absolute' top='29px' right='29px'></Icon>
            <ModalBody p='8px 30px'>
              <Box borderRadius='4px' d='inline-flex' alignItems='center' mb='30px'>
                <Box w='100px' h='93px' borderRadius='4px'>
                  <Box border='1px solid' borderColor='black.lighten' borderRadius='10px' d='inline-block' overflow='hidden'>
                    <canvas id='canvas' ref={signaturePad} width="510" style={{ touchAction: 'none', userSelect: 'none' }} height="150"></canvas>
                  </Box>
                </Box>
              </Box>
            </ModalBody>
            <ModalFooter justifyContent='flex-end' p='30px'>
              <Button w='120px' variant='primary2' mr='15px' onClick={convertSignatureToImage}>Save</Button>
              <Button w='120px' variant='bordered' onClick={() => signaturePad?.current && signaturePad.current.clear()}>Clear</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Box>
    </>
  )
}