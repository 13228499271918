import type { ComponentMultiStyleConfig } from "@chakra-ui/react";


const Input : ComponentMultiStyleConfig = {
    parts : ['field' , 'element'],

    variants : {
        Simple : {
            field : {
                border : '1px',
                borderRadius : '5px',
                width:'100%',
                height:'52px',
                fontSize: '20px',
                _placeholder : {
                    fontSize : 'xxl',
                    fontWeight : 'normal',
                    color : '#C3C3C3',
                  
                },
                _invalid : {
                    borderColor: '#ff5252',
                    boxShadow: '0px 0px 6px #fec1c1'
                }
            },
        },
        Bordered: {
            field : {
                border : '1px',
                borderRadius : '4px',
                width:'100%',
                height:'48px',
                fontSize: 'xxl',
                borderColor: '#C5C7D0',
                color: 'black.primary',
                bg: 'transparent',
                _placeholder : {
                    fontSize : 'xxl',
                    fontWeight : 'normal',
                    color: 'gray.light5',                  
                },
                _invalid : {
                    borderColor: '#ff5252',
                    boxShadow: '0px 0px 6px #fec1c1'
                }
            }
        },
        Search : {
            field : {
                bg: 'gray.light',
                borderRadius: '5px',
                fontSize: '16px',
                color: 'black.primary',
                h: '36px',
                p: '0 18px 0 70px',
                width:'100%',
                _placeholder : {
                    fontSize : 'xl',
                    fontWeight : 'normal',
                    color : 'gray.primary',
                  
                },
                _invalid : {
                    borderColor: '#ff5252',
                    boxShadow: '0px 0px 6px #fec1c1'
                }
            }
        },
        Underlined : {
            field : {
                borderBottom : '1px',
                borderRadius : '0px',
                width:'100%',
                height:'40px',
                fontSize: 'xxl',
                borderColor: '#C5C7D0',
                color: 'black.primary',
                bg: 'transparent',
                padding:'0px',
                _placeholder : {
                    fontSize : 'xxl',
                    fontWeight : 'normal',
                    color: 'gray.light5',                  
                },
                _invalid : {
                    borderColor: '#ff5252',
                    boxShadow: '0px 0px 6px #fec1c1'
                }
            }
        },
    }

}
export default Input