import { Box, Flex, Heading, Icon, Input, Select, SimpleGrid, Text } from '@chakra-ui/react'
import { FiArrowLeft } from 'react-icons/fi'
import Header from '../Header'
import Sidebar from '../Sidebar'

export function InvoicePayment() {
  return (
    <>
      <Flex bg='white.lighten3'>
        <Sidebar />
        <Box  w={{ lg: 'calc(100% - 250px)', xxl: 'calc(100% - 266px)' }}>
          <Header />
          <Box p='29px 30px 20px 20px'>
            <Box d='flex' alignItems='center' mb='20px'>
              <Icon fontSize='22px' color='gray.primary' as={FiArrowLeft}></Icon>
              <Heading fontWeight='600' fontSize='24px' lineHeight='30px' color='black.lighten' ml='22px' as='h2'>Make Payment</Heading>
            </Box>
            <Box borderRadius='12px' bg='white.primary' overflow='hidden' p='30px'>
              <Heading fontSize='21px' fontWeight='400' lineHeight='26px' color='black.lighten' mb='30px' as='h3'>Payment</Heading>
              <SimpleGrid columns={3} spacing={10}>
                <Box d='inline-flex' justifyContent='space-between' alignItems='center'>
                  <Text fontSize='xxl' lineHeight='22px' color='black.ligthen' as='label'>Name</Text>
                  <Select variant='Simple' w='340px' name='name'>
                    <option value='Online Payment'>Online Payment</option>
                    <option value='Offline Payment'>Offline Payment</option>
                  </Select>
                </Box>
                <Box d='inline-flex' justifyContent='space-between' alignItems='center'>
                  <Text fontSize='xxl' lineHeight='22px' color='black.ligthen' as='label'>Comapny Name</Text>
                  <Input variant='Simple' name='companyname' placeholder='Test Agent' />
                </Box>
                <Box d='inline-flex' justifyContent='space-between' alignItems='center'>
                  <Text fontSize='xxl' lineHeight='22px' color='black.ligthen' as='label'>Admin</Text>
                  <Input variant='Simple' name='admin' placeholder='Test Agent' />
                </Box>
                <Box d='inline-flex' justifyContent='space-between' alignItems='center'>
                  <Text fontSize='xxl' lineHeight='22px' color='black.ligthen' as='label'>Inspector</Text>
                  <Select variant='Simple' w='340px' name='country' placeholder='Choose inspector'>
                    <option value='01'>01</option>
                    <option value='02'>02</option>
                    <option value='03'>03</option>
                    <option value='04'>04</option>
                    <option value='05'>05</option>
                    <option value='06'>06</option>
                  </Select>
                </Box>
                <Box d='inline-flex' justifyContent='space-between' alignItems='center'>
                  <Text fontSize='xxl' lineHeight='22px' color='black.ligthen' as='label'>Plan</Text>
                  <Select variant='Simple' w='340px' name='plan'>
                    <option value='Silver'>Silver</option>
                    <option value='Gold' selected={true}>Gold</option>
                    <option value='Platinum'>Platinum</option>
                  </Select>
                </Box>
                <Box d='inline-flex' justifyContent='space-between' alignItems='center'>
                  <Text fontSize='xxl' lineHeight='22px' color='black.ligthen' as='label'>Duration</Text>
                  <Select variant='Simple' w='340px' name='country'>
                    <option value='Online Payment'>Online Payment</option>
                    <option value='Offline Payment'>Offline Payment</option>
                  </Select>
                </Box>
                <Box d='inline-flex' justifyContent='space-between' alignItems='center'>
                  <Text fontSize='xxl' lineHeight='22px' color='black.ligthen' as='label'>Duration</Text>
                  <Select variant='Simple' w='340px' name='country'>
                    <option value='Silver'>Silver</option>
                    <option value='Gold' selected={true}>Gold</option>
                    <option value='Platinum'>Platinum</option>
                  </Select>
                </Box>
                <Box d='inline-flex' justifyContent='space-between' alignItems='center'>
                  <Text fontSize='xxl' lineHeight='22px' color='black.ligthen' as='label'>Name</Text>
                  <Select variant='Simple' w='340px' name='country'>
                    <option value='Online Payment'>Online Payment</option>
                    <option value='Offline Payment'>Offline Payment</option>
                  </Select>
                </Box>
                <Box d='inline-flex' justifyContent='space-between' alignItems='center'>
                  <Text fontSize='xxl' lineHeight='22px' color='black.ligthen' as='label'>Name</Text>
                  <Select variant='Simple' w='340px' name='country'>
                    <option value='Online Payment'>Online Payment</option>
                    <option value='Offline Payment'>Offline Payment</option>
                  </Select>
                </Box>
              </SimpleGrid>
            </Box>
          </Box>
        </Box>
      </Flex>
    </>
  )
}

export default InvoicePayment