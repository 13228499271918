import {
  Box,
  Button,
  Checkbox,
  FormControl,
  Grid,
  GridItem,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  SimpleGrid,
  Stack,
  Switch,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Textarea,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { ImSearch } from "react-icons/im";
import moment from "moment";
import { MdDelete, MdEdit } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import {
  getAdminAccountDetail,
  superAdminState,
  getHomeInspectorAccountsDetail,
  deleteAdminDetail,
  blockCompanyAdmin,
  setSuperAdminError,
  getProSubscriber,
  getProPlusSubscriber,
  getUnsubscribeUsers,
} from "../../redux/modules/superAdmin/superAdmin";
import { RootState } from "../../redux/store";
import { IoMdClose } from "react-icons/io";
import { useForm } from "../../utils/hooks";
import { toastr } from "react-redux-toastr";
import {
  authState,
  companyProfileUpdate,
  profileUpdate,
  setAuthError,
} from "../../redux/modules/auth";
import Loader from "../common/loader";
import {
  blockCRM,
  blockFunnel,
  blockInspector,
  subscribeUser,
  unsubscribeUser,
} from "../../redux/modules/admin/inspector";

export default function Users() {
  const USER_UPDATE_FORM = {
    default: {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      companyName: "",
      website: "",
    },
    field: {
      required: true,
      inputs: [
        "firstName",
        "lastName",
        "email",
        "phone",
        "companyName",
        "website",
      ],
    },
  };
  const superAdmin: superAdminState = useSelector(
    (state: RootState) => state.superAdmin
  );
  const auth: authState = useSelector((state: RootState) => state.auth);
  const dispatch = useDispatch();
  const [usersForAdmin, setUsersForAdmin] = useState([]);
  const [usersForHomeInspector, setUsersForHomeInspector] = useState([]);
  const [usersForGrowthPlus, setUsersForGrowthPlus] = useState([]);
  const [usersForProPlus, setUsersForProPlus] = useState([]);
  const [usersUnsubscribe, setUsersUnsubscribe] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const [userId, setUserId] = useState("");
  const [adminId, setAdminId] = useState(null);
  const [companyid, setCompanyId] = useState();
  const [searchKeyword, setSearchKeyword] = useState("");
  const [staffValue, setStaffValue] = useState<any>({});
  // const [staffValue, setStaffValue] = useState<any>({});
  const [noteValue, setNoteValue] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const {
    isOpen: userUpdateDetails,
    onOpen: openUserUpdateDetails,
    onClose: closeUserUpdateDetails,
  } = useDisclosure();
  const {
    isOpen: noteAdd,
    onOpen: openNote,
    onClose: closeNote,
  } = useDisclosure();
  const {
    isOpen: deleteConfirmation,
    onOpen: openDeleteConfirmation,
    onClose: closeDeleteConfirmation,
  } = useDisclosure();
  const {
    values: userUpdate,
    change: userUpdateChange,
    errors: userUpdateErrors,
    setValues: userUpdateValues,
    touched: userUpdateTouched,
  } = useForm(USER_UPDATE_FORM.default, USER_UPDATE_FORM.field);

  useEffect(() => {
    dispatch(getHomeInspectorAccountsDetail());
    dispatch(getProSubscriber());
    dispatch(getProPlusSubscriber());
    dispatch(getUnsubscribeUsers());
  }, [auth.user?.user_Type, dispatch]);

  useEffect(() => {
    if (activeTab == 0) {
    if (searchKeyword.match(/^[a-zA-Z0-9!//@. ]+$/g)) {
      const filteredUsers = superAdmin.companyAdminUsers.filter((user) =>       
        ((user?.firstName && `${user?.firstName.toLowerCase().trim()} ${user?.lastName.toLowerCase().trim()}`.match(searchKeyword.toLowerCase().trim()))
        ||
        (user?.email && user?.email.match(searchKeyword.trim()))
        ||
        (user?.phone && user?.phone.match(searchKeyword.trim()))
      )
      );
      setUsersForAdmin(filteredUsers);
    } else {
      setUsersForAdmin(superAdmin.companyAdminUsers);
    }
  }
  else if (activeTab == 1) {
    if (searchKeyword.match(/^[a-zA-Z0-9!//@. ]+$/g)) {
      const filteredUsers = superAdmin.homeInspectorUsers.filter((user) =>       
        ((user?.firstName && `${user?.firstName.toLowerCase().trim()} ${user?.lastName.toLowerCase().trim()}`.match(searchKeyword.toLowerCase().trim()))
        ||
        (user?.email && user?.email.match(searchKeyword.trim()))
        ||
        (user?.phone && user?.phone.match(searchKeyword.trim()))
      )
      );
      setUsersForHomeInspector(filteredUsers);
    } else {
      setUsersForHomeInspector(superAdmin.homeInspectorUsers);
    }
  }
  else if (activeTab == 2) {
    if (searchKeyword.match(/^[a-zA-Z0-9!//@. ]+$/g)) {
      const filteredUsers = superAdmin.growthPlusUsers.filter((user) =>       
        ((user?.firstName && `${user?.firstName.toLowerCase().trim()} ${user?.lastName.toLowerCase().trim()}`.match(searchKeyword.toLowerCase().trim()))
        ||
        (user?.email && user?.email.match(searchKeyword.trim()))
        ||
        (user?.phone && user?.phone.match(searchKeyword.trim()))
      )
      );
      setUsersForGrowthPlus(filteredUsers);
    } else {
      setUsersForGrowthPlus(superAdmin.growthPlusUsers);
    }
  }
  else if (activeTab == 3) {
    if (searchKeyword.match(/^[a-zA-Z0-9!//@. ]+$/g)) {
      const filteredUsers = superAdmin.proPlusUsers.filter((user) =>       
        ((user?.firstName && `${user?.firstName.toLowerCase().trim()} ${user?.lastName.toLowerCase().trim()}`.match(searchKeyword.toLowerCase().trim()))
        ||
        (user?.email && user?.email.match(searchKeyword.trim()))
        ||
        (user?.phone && user?.phone.match(searchKeyword.trim()))
      )
      );
      setUsersForProPlus(filteredUsers);
    } else {
      setUsersForProPlus(superAdmin.proPlusUsers);
    }
  }
  else{
    if (searchKeyword.match(/^[a-zA-Z0-9!//@. ]+$/g)) {
      const filteredUsers = superAdmin.unsubscribeUsers.filter((user) =>       
        ((user?.firstName && `${user?.firstName.toLowerCase().trim()} ${user?.lastName.toLowerCase().trim()}`.match(searchKeyword.toLowerCase().trim()))
        ||
        (user?.email && user?.email.match(searchKeyword.trim()))
        ||
        (user?.phone && user?.phone.match(searchKeyword.trim()))
      )
      );
      setUsersUnsubscribe(filteredUsers);
    } else {
      setUsersUnsubscribe(superAdmin.unsubscribeUsers);
    }

  }

  }, [searchKeyword, superAdmin.companyAdminUsers,superAdmin.homeInspectorUsers,superAdmin.growthPlusUsers,superAdmin.proPlusUsers,superAdmin.unsubscribeUsers]);
  

  useEffect(() => {
    if (activeTab == 0) {
      dispatch(getAdminAccountDetail());
    } else if (activeTab == 1) {
      dispatch(getHomeInspectorAccountsDetail());
    } else if (activeTab == 2) {
      dispatch(getProSubscriber());
    } else if (activeTab == 3) {
      dispatch(getProPlusSubscriber());
    } else {
      dispatch(getUnsubscribeUsers());
    }
  }, [activeTab, dispatch]);
  useEffect(() => {
    setUsersForAdmin(superAdmin?.companyAdminUsers);
    setUsersForHomeInspector(superAdmin?.homeInspectorUsers);
    setUsersForGrowthPlus(superAdmin?.growthPlusUsers);
    setUsersForProPlus(superAdmin?.proPlusUsers);
    setUsersUnsubscribe(superAdmin?.unsubscribeUsers);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    superAdmin?.companyAdminUsers,
    superAdmin?.homeInspectorUsers,
    superAdmin?.growthPlusUsers,
    superAdmin?.proPlusUsers,
    superAdmin?.unsubscribeUsers,
  ]);

  // useEffect(() => {
  //   if (searchKeyword.match(/^[a-zA-Z0-9!//@. ]+$/g)) {
  //     if (activeTab == 1) {
  //       let data: any[] = superAdmin.companyAdminUsers.filter(
  //         (users) =>
  //           users.expireTime &&
  //           moment(users.expireTime)
  //             .format("MM-DD-YYYY, HH:MM A")
  //             .match(searchKeyword)
  //       );
  //       data.push(
  //         ...superAdmin.companyAdminUsers.filter(
  //           (users) =>
  //             (users?.firstName || users?.lastName) &&
  //             `${users?.firstName} ${users?.lastName}`.match(searchKeyword)
  //         )
  //       );
  //       data.push(
  //         ...superAdmin.companyAdminUsers.filter(
  //           (users) => users?.email && users?.email.match(searchKeyword)
  //         )
  //       );
  //       data.push(
  //         ...superAdmin.companyAdminUsers.filter(
  //           (users) => users?.phone && users?.phone.match(searchKeyword)
  //         )
  //       );
  //       data.push(
  //         ...superAdmin.companyAdminUsers.filter(
  //           (users) =>
  //             users?.Companyname && users?.Companyname.match(searchKeyword)
  //         )
  //       );
  //       data.push(
  //         ...superAdmin.companyAdminUsers.filter(
  //           (users) => users?.website && users?.website.match(searchKeyword)
  //         )
  //       );
  //       data = data.filter(
  //         (value, index, self) =>
  //           index === self.findIndex((t) => t.id === value.id)
  //       );
  //       setUsersForAdmin(data);
  //     } else if (activeTab == 2) {
  //       let data: any[] = superAdmin.homeInspectorUsers.filter(
  //         (users) =>
  //           users.expireTime &&
  //           moment(users.expireTime)
  //             .format("MM-DD-YYYY, HH:MM A")
  //             .match(searchKeyword)
  //       );
  //       data.push(
  //         ...superAdmin.homeInspectorUsers.filter(
  //           (users) =>
  //             users?.firstName &&
  //             `${users?.firstName} ${users?.lastName}`.match(searchKeyword)
  //         )
  //       );
  //       data.push(
  //         ...superAdmin.homeInspectorUsers.filter(
  //           (users) => users?.email && users?.email.match(searchKeyword)
  //         )
  //       );
  //       data.push(
  //         ...superAdmin.homeInspectorUsers.filter(
  //           (users) => users?.phone && users?.phone.match(searchKeyword)
  //         )
  //       );
  //       data.push(
  //         ...superAdmin.homeInspectorUsers.filter(
  //           (users) =>
  //             users?.Companyname && users?.Companyname.match(searchKeyword)
  //         )
  //       );
  //       data.push(
  //         ...superAdmin.homeInspectorUsers.filter(
  //           (users) => users?.website && users?.website.match(searchKeyword)
  //         )
  //       );
  //       data = data.filter(
  //         (value, index, self) =>
  //           index === self.findIndex((t) => t.id === value.id)
  //       );
  //       setUsersForHomeInspector(data);
  //     }
  //   } else {
  //     let data: any[] = superAdmin.growthPlusUsers.filter(
  //       (users) =>
  //         users.expireTime &&
  //         moment(users.expireTime)
  //           .format("MM-DD-YYYY, HH:MM A")
  //           .match(searchKeyword)
  //     );
  //     data.push(
  //       ...superAdmin.growthPlusUsers.filter(
  //         (users) =>
  //           (users?.firstName || users?.lastName) &&
  //           `${users?.firstName} ${users?.lastName}`.match(searchKeyword)
  //       )
  //     );
  //     data.push(
  //       ...superAdmin.growthPlusUsers.filter(
  //         (users) => users?.email && users?.email.match(searchKeyword)
  //       )
  //     );
  //     data.push(
  //       ...superAdmin.growthPlusUsers.filter(
  //         (users) => users?.phone && users?.phone.match(searchKeyword)
  //       )
  //     );
  //     data.push(
  //       ...superAdmin.growthPlusUsers.filter(
  //         (users) =>
  //           users?.Companyname && users?.Companyname.match(searchKeyword)
  //       )
  //     );
  //     data.push(
  //       ...superAdmin.growthPlusUsers.filter(
  //         (users) => users?.website && users?.website.match(searchKeyword)
  //       )
  //     );
  //     data = data.filter(
  //       (value, index, self) =>
  //         index === self.findIndex((t) => t.id === value.id)
  //     );
  //     setUsersForAdmin(data);
  //     // setUsersForHomeInspector(data);
  //     setUsersForHomeInspector(superAdmin?.homeInspectorUsers);
  //     setUsersForAdmin(superAdmin?.companyAdminUsers);
  //     setUsersForGrowthPlus(superAdmin?.growthPlusUsers);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [searchKeyword]);

  const handleBlockStaff = (e: boolean, staffId: string) => {
    if (e === true) {
      openNote();
      setStaffValue({ e, staffId });
    } else {
      if (activeTab === 0 || activeTab === 2 || activeTab === 3) {
        new Promise(() => {
          const data1: any = dispatch(
            blockCompanyAdmin({ staffId: staffId, payload: { blockValue: e } })
          );
          data1.then(function (value) {
            if (value.type === "superadmin/block/company/admin/fulfilled") {
              toastr.success("", "Staff unblocked successfully");
              dispatch(getAdminAccountDetail());
              dispatch(getProSubscriber());
              dispatch(getProPlusSubscriber());
            }
          });
        });
      } else {
        new Promise(() => {
          const data2: any = dispatch(
            blockInspector({ staffId: staffId, payload: { blockValue: e } })
          );
          data2.then(function (value) {
            if (value.type === "inspector/blockInspector/fulfilled") {
              toastr.success("", "Staff unblocked successfully");
              dispatch(getHomeInspectorAccountsDetail());
            }
          });
        });
      }
    }
  };
  const handleBlockFunnel = (e: boolean, staffId: string, type: string) => {
    if (e === true) {
      openNote();
      setStaffValue({ e, staffId, type });
    } else {
      new Promise(() => {
        const data2: any = dispatch(
          blockFunnel({ staffId: staffId, payload: { blockValue: e } })
        );
        data2.then(function (value) {
          if (value.type === "inspector/blockFunnel/fulfilled") {
            toastr.success("", "Funnel unblocked successfully");
            dispatch(getProSubscriber());
          }
        });
      });
    }
  };

  ///**Subscribe**///

  const [openSubscribe, setOpenSubscribe] = useState(false);
  const [subscribeUserId, setSubscribeUserId] = useState("");
  const [isSubscribe, setSubscribe] = useState(false);
  const [openSubscribtion, setOpenSubscribtion] = useState(false);

  const openSubscribeModal = () => {
    setOpenSubscribe(true);
  };
  const closeSubscribeModal = () => {
    setOpenSubscribe(false);
  };

  const openSubscribTionModal = () => {
    setOpenSubscribtion(true);
    setInspectorSubPlan(false);
  };
  const closeSubscribtionModal = () => {
    setOpenSubscribtion(false);
  };

  const handleSubscribed = (e: boolean, staffId: string) => {
    setSubscribeUserId(staffId);
    setSubscribe(e);
    openSubscribeModal();
  };
  const subscribe = () => {
    new Promise(() => {
      const data2: any = dispatch(
        subscribeUser({
          staffId: subscribeUserId,
          payload: { subscribeValue: isSubscribe },
        })
      );
      data2.then(function (value) {
        if (value.type === "inspector/subscribe/subscribeUser/fulfilled") {
          toastr.success(
            "",
            `Subscription ${
              isSubscribe == false ? "disabled" : "enabled"
            } successfully`
          );
          dispatch(getAdminAccountDetail());
          dispatch(getHomeInspectorAccountsDetail());
          dispatch(getProSubscriber());
          dispatch(getProPlusSubscriber());
          dispatch(getUnsubscribeUsers());
          setOpenSubscribe(false);
          // if (activeTab == 0) {
          //   dispatch(getAdminAccountDetail());
          //   setOpenSubscribe(false);
          // } else if (activeTab == 1) {
          //   dispatch(getHomeInspectorAccountsDetail());
          //   setOpenSubscribe(false);
          // } else if (activeTab == 2) {
          //   dispatch(getProSubscriber());
          //   setOpenSubscribe(false);
          // } else if (activeTab == 3) {
          //   dispatch(getProPlusSubscriber());
          //   setOpenSubscribe(false);
          // } else {
          //   dispatch(getUnsubscribeUsers());
          //   setOpenSubscribe(false);
          // }
        }
      });
    });
  };

  ///**Subscribe**///

  const handleSubscribeUser = (staffId: string) => {
    setSubscribeUserId(staffId);
    openSubscribTionModal();
  };

  const subscriptionSubmit = () => {
    new Promise(() => {
      const data2: any = dispatch(
        unsubscribeUser({
          payload: {
            userId: subscribeUserId,
            plan: goupVaue,
            planType: inspectorSubPlan ? "year" : "month",
          },
        })
      );
      data2.then(function (value) {
        if (
          value.type === "subscription/create/custom/subscription/fulfilled"
        ) {
          toastr.success("", "Subscription enabled successfully");

          dispatch(getAdminAccountDetail());
          dispatch(getHomeInspectorAccountsDetail());
          dispatch(getProSubscriber());
          dispatch(getProPlusSubscriber());
          dispatch(getUnsubscribeUsers());
          closeSubscribtionModal();

          // if (activeTab == 0) {
          //   dispatch(getAdminAccountDetail());
          //   closeSubscribtionModal();
          // } else if (activeTab == 1) {
          //   dispatch(getHomeInspectorAccountsDetail());
          //   closeSubscribtionModal();
          // } else if (activeTab == 2) {
          //   dispatch(getProSubscriber());
          //   closeSubscribtionModal();
          // } else if (activeTab == 3) {
          //   dispatch(getProPlusSubscriber());
          //   closeSubscribtionModal();
          // } else {
          //   dispatch(getUnsubscribeUsers());
          //   closeSubscribtionModal();
          // }
        }
      });
    });
  };

  const handleBlockCRM = (e: boolean, staffId: string, type: string) => {
    if (e === true) {
      openNote();
      setStaffValue({ e, staffId, type });
      // console.log("Stafff", staffValue);
    } else {
      new Promise(() => {
        const data2: any = dispatch(
          blockCRM({ staffId: staffId, payload: { blockValue: e } })
        );
        data2.then(function (value) {
          if (value.type === "inspector/blockCRM/fulfilled") {
            toastr.success("", "CRM unblocked successfully");
            dispatch(getProSubscriber());
          }
        });
      });
    }
  };

  const blockStaff = (e) => {
    console.log(activeTab, staffValue.type, "activeTab");
    e.preventDefault();
    if (!noteValue || noteValue === "") {
      toastr.warning("", "Note is required for Block");
      return;
    }
    if (
      (activeTab === 0 || activeTab === 2 || activeTab === 3) &&
      staffValue.type !== "CRM" &&
      staffValue.type !== "Funnel"
    ) {
      new Promise(() => {
        const data3: any = dispatch(
          blockCompanyAdmin({
            staffId: staffValue.staffId,
            payload: { blockValue: staffValue.e, block_description: noteValue },
          })
        );
        data3.then(function (value) {
          if (
            value.type === "superadmin/block/company/admin/fulfilled" &&
            staffValue.e === true
          ) {
            setNoteValue("");
            closeNote();
            toastr.success("", "Staff blocked successfully");
            dispatch(getAdminAccountDetail());
            dispatch(getProSubscriber());
            dispatch(getProPlusSubscriber());
          }
        });
      });
    } else if (activeTab == 1) {
      new Promise(() => {
        const data4: any = dispatch(
          blockInspector({
            staffId: staffValue.staffId,
            payload: { blockValue: staffValue.e, block_description: noteValue },
          })
        );
        data4.then(function (value) {
          if (
            value.type === "inspector/blockInspector/fulfilled" &&
            staffValue.e === true
          ) {
            setNoteValue("");
            closeNote();
            toastr.success("", "Staff blocked successfully");
            dispatch(getHomeInspectorAccountsDetail());
          }
        });
      });
    } else if (
      staffValue.type == "CRM" &&
      (activeTab === 2 || activeTab === 3)
    ) {
      new Promise(() => {
        const data5: any = dispatch(
          blockCRM({
            staffId: staffValue.staffId,
            payload: {
              blockValue: staffValue.e,
              crm_block_description: noteValue,
            },
          })
        );
        data5.then(function (value) {
          if (
            value.type === "inspector/blockCRM/fulfilled" &&
            staffValue.e === true
          ) {
            setNoteValue("");
            closeNote();
            toastr.success("", "CRM blocked successfully");
            dispatch(getProSubscriber());
            dispatch(getProPlusSubscriber());
          }
        });
      });
    } else if (
      staffValue.type == "Funnel" &&
      (activeTab === 2 || activeTab === 3)
    ) {
      new Promise(() => {
        const data5: any = dispatch(
          blockFunnel({
            staffId: staffValue.staffId,
            payload: {
              blockValue: staffValue.e,
              funnel_block_description: noteValue,
            },
          })
        );
        data5.then(function (value) {
          if (
            value.type === "inspector/blockFunnel/fulfilled" &&
            staffValue.e === true
          ) {
            setNoteValue("");
            closeNote();
            toastr.success("", "Funnel blocked successfully");
            dispatch(getProSubscriber());
            dispatch(getProPlusSubscriber());
          }
        });
      });
    }
  };

  const handleBlockProFunnel = (e: boolean, staffId: string, type: string) => {
    if (e === true) {
      openNote();
      setStaffValue({ e, staffId, type });
    } else {
      new Promise(() => {
        const data2: any = dispatch(
          blockFunnel({ staffId: staffId, payload: { blockValue: e } })
        );
        data2.then(function (value) {
          if (value.type === "inspector/blockFunnel/fulfilled") {
            toastr.success("", "Funnel unblocked successfully");
            dispatch(getProPlusSubscriber());
          }
        });
      });
    }
  };

  const handleBlockProCRM = (e: boolean, staffId: string, type: string) => {
    if (e === true) {
      openNote();
      setStaffValue({ e, staffId, type });
      // console.log("Stafff", staffValue);
    } else {
      new Promise(() => {
        const data2: any = dispatch(
          blockCRM({ staffId: staffId, payload: { blockValue: e } })
        );
        data2.then(function (value) {
          if (value.type === "inspector/blockCRM/fulfilled") {
            toastr.success("", "CRM unblocked successfully");
            dispatch(getProPlusSubscriber());
          }
        });
      });
    }
  };
  const handleDeleteAdmin = () => {
    new Promise(() => {
      const data5: any = dispatch(deleteAdminDetail({ paramId: adminId }));
      data5.then(function (value) {
        if (value.type === "superadmin/delete/admin/fulfilled") {
          closeDeleteConfirmation(); // Close Confirmation Modal
          toastr.success("", "Admin deleted successfully");
        }
      });
    });
  };
  const handleDeleteAdminModal = (deleteAdminId: string) => {
    setAdminId(deleteAdminId);
    openDeleteConfirmation(); // Open Confirmation Modal
  };

  const tableColumnsAdmin = [
    {
      name: "User Name",
      selector: (row: any) => row.user_name,
      sortable: true,
      minWidth: "250px",
    },
    {
      name: "Email",
      selector: (row: any) => row.email,
      minWidth: "280px",
      allowOverflow: true,
    },

    {
      name: "Contact No",
      selector: (row: any) => row.contact_no,
      minWidth: "150px",
      allowOverflow: true,
    },
    {
      name: "Status",
      selector: (row: any) => row.status,
      sortable: true,
      minWidth: "160px",
    },
    {
      name: "Plan",
      selector: (row: any) => row.plan,
      sortable: true,
      minWidth: "160px",
    },
    {
      name: "Block User",
      selector: (row: any) => row.homeInspector_block,
      minWidth: "150px",
    },
    {
      name: "Subscribed",
      selector: (row: any) => row.is_subscribed,
      minWidth: "150px",
    },
    {
      name: "Company Name",
      selector: (row: any) => row.company_name,
      minWidth: "170px",
    },
    {
      name: "Website",
      selector: (row: any) => row.website,
      minWidth: "170px",
    },
    {
      name: "Manage",
      selector: (row: any) => row.manage,
      minWidth: "120px",
    },
  ];
  const tableColumnsGrowth = [
    {
      name: "User Name",
      selector: (row: any) => row.user_name,
      sortable: true,
      minWidth: "250px",
    },
    {
      name: "Email",
      selector: (row: any) => row.email,
      minWidth: "280px",
      allowOverflow: true,
    },
    {
      name: "Contact No",
      selector: (row: any) => row.contact_no,
      minWidth: "150px",
      allowOverflow: true,
    },
    {
      name: "Status",
      selector: (row: any) => row.status,
      sortable: true,
      minWidth: "160px",
    },
    {
      name: "Plan",
      selector: (row: any) => row.plan,
      sortable: true,
      minWidth: "160px",
    },
    {
      name: "Block User",
      selector: (row: any) => row.homeInspector_block,
      minWidth: "150px",
    },
    {
      name: "Subscribed",
      selector: (row: any) => row.is_subscribed,
      minWidth: "150px",
    },
    {
      name: "Block Funnel",
      selector: (row: any) => row.block_funnel,
      minWidth: "150px",
    },
    {
      name: "Block CRM",
      selector: (row: any) => row.block_crm,
      minWidth: "150px",
    },
    {
      name: "Company Name",
      selector: (row: any) => row.company_name,
      minWidth: "170px",
    },
    {
      name: "Website",
      selector: (row: any) => row.website,
      minWidth: "170px",
    },
    {
      name: "Manage",
      selector: (row: any) => row.manage,
      minWidth: "120px",
    },
  ];
  const tableColumnsPro = [
    {
      name: "User Name",
      selector: (row: any) => row.user_name,
      sortable: true,
      minWidth: "250px",
    },
    {
      name: "Email",
      selector: (row: any) => row.email,
      minWidth: "280px",
      allowOverflow: true,
    },

    {
      name: "Contact No",
      selector: (row: any) => row.contact_no,
      minWidth: "150px",
      allowOverflow: true,
    },
    {
      name: "Status",
      selector: (row: any) => row.status,
      sortable: true,
      minWidth: "160px",
    },
    {
      name: "Plan",
      selector: (row: any) => row.plan,
      sortable: true,
      minWidth: "160px",
    },
    {
      name: "Block User",
      selector: (row: any) => row.homeInspector_block,
      minWidth: "150px",
    },
    {
      name: "Subscribed",
      selector: (row: any) => row.is_subscribed,
      minWidth: "150px",
    },
    {
      name: "Block Funnel",
      selector: (row: any) => row.block_funnel,
      minWidth: "150px",
    },
    {
      name: "Block CRM",
      selector: (row: any) => row.block_crm,
      minWidth: "150px",
    },

    {
      name: "Company Name",
      selector: (row: any) => row.company_name,
      minWidth: "170px",
    },
    {
      name: "Website",
      selector: (row: any) => row.website,
      minWidth: "170px",
    },
    {
      name: "Manage",
      selector: (row: any) => row.manage,
      minWidth: "120px",
    },
  ];
  const tableColumnsHome = [
    {
      name: "User Name",
      selector: (row: any) => row.user_name,
      sortable: true,
      minWidth: "250px",
    },
    {
      name: "Email",
      selector: (row: any) => row.email,
      minWidth: "280px",
      allowOverflow: true,
    },
    {
      name: "Contact No",
      selector: (row: any) => row.contact_no,
      minWidth: "150px",
      allowOverflow: true,
    },
    {
      name: "Status",
      selector: (row: any) => row.status,
      sortable: true,
      minWidth: "160px",
    },
    {
      name: "Block User",
      selector: (row: any) => row.homeInspector_block,
      minWidth: "150px",
    },
    {
      name: "Subscribed",
      selector: (row: any) => row.is_subscribed,
      minWidth: "150px",
    },
    {
      name: "Company Name",
      selector: (row: any) => row.company_name,
      minWidth: "170px",
    },
    {
      name: "Website",
      selector: (row: any) => row.website,
      minWidth: "170px",
    },
    {
      name: "Manage",
      selector: (row: any) => row.manage,
      minWidth: "120px",
    },
  ];
  const tableColumnsUnsubscribed = [
    {
      name: "User Name",
      selector: (row: any) => row.user_name,
      sortable: true,
      minWidth: "250px",
    },
    {
      name: "Email",
      selector: (row: any) => row.email,
      minWidth: "280px",
      allowOverflow: true,
    },

    {
      name: "Contact No",
      selector: (row: any) => row.contact_no,
      minWidth: "150px",
      allowOverflow: true,
    },
    {
      name: "Subscribed",
      selector: (row: any) => row.is_subscribed,
      minWidth: "150px",
    },
  ];

  const handleUserUpdateForm = (user: any) => {
    userUpdateValues({
      firstName: `${user.firstName}`,
      lastName: `${user.lastName}`,
      email: user.email,
      phone: user.phone,
      companyName: user.Companyname,
      website: user.website,
    });
    setUserId(user.id);
    setUserEmail(user.email);
    setCompanyId(user.companyDetailId);
    openUserUpdateDetails();
  };

  const updateUserFormValues = () => {
    if (Object.keys(userUpdateTouched).length <= 0) {
      toastr.warning("", "Please edit the form to update");
      return;
    }
    if (Object.keys(userUpdateErrors).length > 0) {
      toastr.warning("", userUpdateErrors[Object.keys(userUpdateErrors)[0]]);
      return;
    }

    let updateEmail = {};
    if (userUpdate.email !== userEmail) {
      updateEmail = { email: userUpdate.email };
    }

    new Promise(() => {
      const data6: any = dispatch(
        profileUpdate({
          userId: userId,
          payload: {
            firstName: userUpdate.firstName,
            lastName: userUpdate.lastName,
            ...updateEmail,
            phone: userUpdate.phone,
          },
        })
      );
      // Call profileUpdate api
      dispatch(
        companyProfileUpdate({
          companyId: companyid,
          payload: {
            companyName: userUpdate.companyName,
            website: userUpdate.website,
          },
        })
      );

      data6.then(function (value) {
        if (value.type === "auth/profileUpdate/fulfilled") {
          closeUserUpdateDetails();
          toastr.success("", "User details updated successfully");
          if (activeTab === 0) {
            dispatch(getAdminAccountDetail());
          } else {
            dispatch(getHomeInspectorAccountsDetail());
          }
        }
      });
    });
  };

  const capitalizeFirstLetter = (str: string): string => {
    return str?.charAt(0)?.toUpperCase() + str?.slice(1);
  };
  // console.log("Userfor", usersForAdmin);
  const companyAdminTable = usersForAdmin?.map((users: any) => {
    return {
      user_name: (
        <>
          <Box d="inline-flex" alignItems="center">
            <Text
              as="span"
              textOverflow="ellipsis"
              whiteSpace="nowrap"
              overflow="hidden"
              maxW="280px"
              display="inline-block"
            >
              {users.firstName} {users.lastName}
            </Text>
          </Box>
        </>
      ),
      email: (
        <Text
          as="span"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
          overflow="hidden"
          maxW="280px"
          display="inline-block"
        >
          {users.email}
        </Text>
      ),
      contact_no: <Text as="span">{users.phone}</Text>,
      status: (
        <>
          <Box
            bg={users.homeInspector_block ? "red.lighten" : "green.lighten2"}
            p="6px 24px"
            borderRadius="20px"
          >
            <Text
              color={users.homeInspector_block ? "red.dark" : "green.lighten"}
            >
              {users.homeInspector_block ? "Inactive" : "Active"}
            </Text>
          </Box>
        </>
      ),
      plan: (
        <>
          {users.planType && (
            <Box
              w="82px"
              justifyContent="center"
              bg={users.planType === "year" ? "red.ligthen4" : "purple.light"}
              color={users.planType === "year" ? "red.darken2" : "purple.dark"}
              d="inline-flex"
              alignItems="center"
              h="26px"
              p="0 8px"
              borderRadius="30px"
            >
              {capitalizeFirstLetter(users?.planType)}
            </Box>
          )}
        </>
      ),
      homeInspector_block: (
        <Checkbox
          className="switch"
          mr="15px"
          variant="transparent"
          colorScheme="transparent"
          isChecked={users.homeInspector_block}
          onChange={(e) => {
            handleBlockStaff(e.target.checked, users.id);
          }}
        ></Checkbox>
      ),
      is_subscribed: (
        <Checkbox
          className="switch"
          mr="15px"
          variant="transparent"
          colorScheme="transparent"
          isChecked={users.is_subscribed}
          onChange={(e) => {
            handleSubscribed(e.target.checked, users.id);
          }}
        ></Checkbox>
      ),
      company_name: (
        <Text
          textOverflow="ellipsis"
          whiteSpace="nowrap"
          overflow="hidden"
          maxW="270px"
          display="inline-block"
        >
          {users.Companyname}
        </Text>
      ),
      website: (
        <Text
          textOverflow="ellipsis"
          whiteSpace="nowrap"
          overflow="hidden"
          maxW="270px"
          display="inline-block"
        >
          {users.website}
        </Text>
      ),
      manage: (
        <>
          <Tooltip
            hasArrow
            label="Edit Admin"
            fontSize="14px"
            bg="gray.light12"
            color="white.primary"
            placement="top"
          >
            <Button
              variant="iconBtn"
              minW="unset"
              p="0"
              color="gray.primary"
              onClick={() => handleUserUpdateForm(users)}
            >
              <Icon mr="10px" fontSize="18px" as={MdEdit}></Icon>
            </Button>
          </Tooltip>
          <Tooltip
            hasArrow
            label={"Delete Admin"}
            fontSize="14px"
            bg="gray.light12"
            color="white.primary"
            placement="top"
          >
            <Button
              fontSize="18px"
              variant="iconBtn"
              minW="unset"
              p="0"
              color="gray.primary"
              mr="15px"
              onClick={() => handleDeleteAdminModal(users.id)}
            >
              <Icon as={MdDelete}></Icon>
            </Button>
          </Tooltip>
        </>
      ),
    };
  });
  const growthPlusTable = usersForGrowthPlus?.map((users: any) => {
    return {
      user_name: (
        <>
          <Box d="inline-flex" alignItems="center">
            <Text
              as="span"
              textOverflow="ellipsis"
              whiteSpace="nowrap"
              overflow="hidden"
              maxW="280px"
              display="inline-block"
            >
              {users.firstName} {users.lastName}
            </Text>
          </Box>
        </>
      ),
      email: (
        <Text
          as="span"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
          overflow="hidden"
          maxW="280px"
          display="inline-block"
        >
          {users.email}
        </Text>
      ),
      contact_no: <Text as="span">{users.phone}</Text>,
      status: (
        <>
          <Box
            bg={users.homeInspector_block ? "red.lighten" : "green.lighten2"}
            p="6px 24px"
            borderRadius="20px"
          >
            <Text
              color={users.homeInspector_block ? "red.dark" : "green.lighten"}
            >
              {users.homeInspector_block ? "Inactive" : "Active"}
            </Text>
          </Box>
        </>
      ),
      plan: (
        <>
          {users.planType && (
            <Box
              w="82px"
              justifyContent="center"
              bg={users.planType === "year" ? "red.ligthen4" : "purple.light"}
              color={users.planType === "year" ? "red.darken2" : "purple.dark"}
              d="inline-flex"
              alignItems="center"
              h="26px"
              p="0 8px"
              borderRadius="30px"
            >
              {capitalizeFirstLetter(users?.planType)}
            </Box>
          )}
        </>
      ),
      homeInspector_block: (
        <Checkbox
          className="switch"
          mr="15px"
          variant="transparent"
          colorScheme="transparent"
          isChecked={users.homeInspector_block}
          onChange={(e) => {
            handleBlockStaff(e.target.checked, users.id);
          }}
        ></Checkbox>
      ),
      is_subscribed: (
        <Checkbox
          className="switch"
          mr="15px"
          variant="transparent"
          colorScheme="transparent"
          isChecked={users.is_subscribed}
          onChange={(e) => {
            handleSubscribed(e.target.checked, users.id);
          }}
        ></Checkbox>
      ),
      block_funnel: (
        <Checkbox
          className="switch"
          mr="15px"
          variant="transparent"
          colorScheme="transparent"
          isChecked={users.funnel_block}
          onChange={(e) => {
            handleBlockFunnel(e.target.checked, users.id, "Funnel");
          }}
        ></Checkbox>
      ),
      block_crm: (
        <Checkbox
          className="switch"
          mr="15px"
          variant="transparent"
          colorScheme="transparent"
          isChecked={users.crm_block}
          onChange={(e) => {
            handleBlockCRM(e.target.checked, users.id, "CRM");
          }}
        ></Checkbox>
      ),
      company_name: (
        <Text
          textOverflow="ellipsis"
          whiteSpace="nowrap"
          overflow="hidden"
          maxW="270px"
          display="inline-block"
        >
          {users.Companyname}
        </Text>
      ),
      website: (
        <Text
          textOverflow="ellipsis"
          whiteSpace="nowrap"
          overflow="hidden"
          maxW="270px"
          display="inline-block"
        >
          {users.website}
        </Text>
      ),
      manage: (
        <>
          <Tooltip
            hasArrow
            label="Edit Admin"
            fontSize="14px"
            bg="gray.light12"
            color="white.primary"
            placement="top"
          >
            <Button
              variant="iconBtn"
              minW="unset"
              p="0"
              color="gray.primary"
              onClick={() => handleUserUpdateForm(users)}
            >
              <Icon mr="10px" fontSize="18px" as={MdEdit}></Icon>
            </Button>
          </Tooltip>
          <Tooltip
            hasArrow
            label={"Delete Admin"}
            fontSize="14px"
            bg="gray.light12"
            color="white.primary"
            placement="top"
          >
            <Button
              fontSize="18px"
              variant="iconBtn"
              minW="unset"
              p="0"
              color="gray.primary"
              mr="15px"
              onClick={() => handleDeleteAdminModal(users.id)}
            >
              <Icon as={MdDelete}></Icon>
            </Button>
          </Tooltip>
        </>
      ),
    };
  });

  const proPlusTable = usersForProPlus?.map((users: any) => {
    return {
      user_name: (
        <>
          <Box d="inline-flex" alignItems="center">
            <Text
              as="span"
              textOverflow="ellipsis"
              whiteSpace="nowrap"
              overflow="hidden"
              maxW="280px"
              display="inline-block"
            >
              {users.firstName} {users.lastName}
            </Text>
          </Box>
        </>
      ),
      email: (
        <Text
          as="span"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
          overflow="hidden"
          maxW="280px"
          display="inline-block"
        >
          {users.email}
        </Text>
      ),
      contact_no: <Text as="span">{users.phone}</Text>,
      status: (
        <>
          <Box
            bg={users.homeInspector_block ? "red.lighten" : "green.lighten2"}
            p="6px 24px"
            borderRadius="20px"
          >
            <Text
              color={users.homeInspector_block ? "red.dark" : "green.lighten"}
            >
              {users.homeInspector_block ? "Inactive" : "Active"}
            </Text>
          </Box>
        </>
      ),
      plan: (
        <>
          {users.planType && (
            <Box
              w="82px"
              justifyContent="center"
              bg={users.planType === "year" ? "red.ligthen4" : "purple.light"}
              color={users.planType === "year" ? "red.darken2" : "purple.dark"}
              d="inline-flex"
              alignItems="center"
              h="26px"
              p="0 8px"
              borderRadius="30px"
            >
              {capitalizeFirstLetter(users?.planType)}
            </Box>
          )}
        </>
      ),
      homeInspector_block: (
        <Checkbox
          className="switch"
          mr="15px"
          variant="transparent"
          colorScheme="transparent"
          isChecked={users.homeInspector_block}
          onChange={(e) => {
            handleBlockStaff(e.target.checked, users.id);
          }}
        ></Checkbox>
      ),
      block_funnel: (
        <Checkbox
          className="switch"
          mr="15px"
          variant="transparent"
          colorScheme="transparent"
          isChecked={users.funnel_block}
          onChange={(e) => {
            handleBlockProFunnel(e.target.checked, users.id, "Funnel");
          }}
        ></Checkbox>
      ),
      block_crm: (
        <Checkbox
          className="switch"
          mr="15px"
          variant="transparent"
          colorScheme="transparent"
          isChecked={users.crm_block}
          onChange={(e) => {
            handleBlockProCRM(e.target.checked, users.id, "CRM");
          }}
        ></Checkbox>
      ),
      is_subscribed: (
        <Checkbox
          className="switch"
          mr="15px"
          variant="transparent"
          colorScheme="transparent"
          isChecked={users.is_subscribed}
          onChange={(e) => {
            handleSubscribed(e.target.checked, users.id);
          }}
        ></Checkbox>
      ),
      company_name: (
        <Text
          textOverflow="ellipsis"
          whiteSpace="nowrap"
          overflow="hidden"
          maxW="270px"
          display="inline-block"
        >
          {users.Companyname}
        </Text>
      ),
      website: (
        <Text
          textOverflow="ellipsis"
          whiteSpace="nowrap"
          overflow="hidden"
          maxW="270px"
          display="inline-block"
        >
          {users.website}
        </Text>
      ),
      manage: (
        <>
          <Tooltip
            hasArrow
            label="Edit Admin"
            fontSize="14px"
            bg="gray.light12"
            color="white.primary"
            placement="top"
          >
            <Button
              variant="iconBtn"
              minW="unset"
              p="0"
              color="gray.primary"
              onClick={() => handleUserUpdateForm(users)}
            >
              <Icon mr="10px" fontSize="18px" as={MdEdit}></Icon>
            </Button>
          </Tooltip>
          <Tooltip
            hasArrow
            label={"Delete Admin"}
            fontSize="14px"
            bg="gray.light12"
            color="white.primary"
            placement="top"
          >
            <Button
              fontSize="18px"
              variant="iconBtn"
              minW="unset"
              p="0"
              color="gray.primary"
              mr="15px"
              onClick={() => handleDeleteAdminModal(users.id)}
            >
              <Icon as={MdDelete}></Icon>
            </Button>
          </Tooltip>
        </>
      ),
    };
  });

  const unsubscribedTable = usersUnsubscribe?.map((users: any) => {
    return {
      user_name: (
        <>
          <Box d="inline-flex" alignItems="center">
            <Text
              as="span"
              textOverflow="ellipsis"
              whiteSpace="nowrap"
              overflow="hidden"
              maxW="280px"
              display="inline-block"
            >
              {users.firstName} {users.lastName}
            </Text>
          </Box>
        </>
      ),
      email: (
        <Text
          as="span"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
          overflow="hidden"
          maxW="280px"
          display="inline-block"
        >
          {users.email}
        </Text>
      ),
      contact_no: <Text as="span">{users.phone}</Text>,

      is_subscribed: (
        <Checkbox
          className="switch"
          mr="15px"
          variant="transparent"
          colorScheme="transparent"
          isChecked={users.is_subscribed}
          onChange={(e) => {
            handleSubscribeUser(users.id);
          }}
        ></Checkbox>
      ),
    };
  });
  const homeInspectorTable = usersForHomeInspector?.map((users: any) => {
    return {
      user_name: (
        <>
          <Box d="inline-flex" alignItems="center">
            <Text
              as="span"
              textOverflow="ellipsis"
              whiteSpace="nowrap"
              overflow="hidden"
              maxW="280px"
              display="inline-block"
            >
              {users.firstName} {users.lastName}
            </Text>
          </Box>
        </>
      ),
      email: (
        <Text
          as="span"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
          overflow="hidden"
          maxW="280px"
          display="inline-block"
        >
          {users.email}
        </Text>
      ),
      contact_no: <Text as="span">{users.phone}</Text>,
      status: (
        <>
          <Box
            bg={users.homeInspector_block ? "red.lighten" : "green.lighten2"}
            p="6px 24px"
            borderRadius="20px"
          >
            <Text
              color={users.homeInspector_block ? "red.dark" : "green.lighten"}
            >
              {users.homeInspector_block ? "Inactive" : "Active"}
            </Text>
          </Box>
        </>
      ),
      funnel_block: (
        <>
          <Box
            bg={users.funnel_block ? "red.lighten" : "green.lighten2"}
            p="6px 24px"
            borderRadius="20px"
          >
            <Text color={users.funnel_block ? "red.dark" : "green.lighten"}>
              {users.funnel_block ? "Inactive" : "Active"}
            </Text>
          </Box>
        </>
      ),
      homeInspector_block: (
        <Checkbox
          className="switch"
          mr="15px"
          variant="transparent"
          colorScheme="transparent"
          isChecked={users.homeInspector_block}
          onChange={(e) => {
            handleBlockStaff(e.target.checked, users.id);
          }}
        ></Checkbox>
      ),
      is_subscribed: (
        <Checkbox
          className="switch"
          mr="15px"
          variant="transparent"
          colorScheme="transparent"
          isChecked={users.is_subscribed}
          onChange={(e) => {
            handleSubscribed(e.target.checked, users.id);
          }}
        ></Checkbox>
      ),
      company_name: (
        <Text
          textOverflow="ellipsis"
          whiteSpace="nowrap"
          overflow="hidden"
          maxW="270px"
          display="inline-block"
        >
          {users.Companyname}
        </Text>
      ),
      website: (
        <Text
          textOverflow="ellipsis"
          whiteSpace="nowrap"
          overflow="hidden"
          maxW="270px"
          display="inline-block"
        >
          {users.website}
        </Text>
      ),
      manage: (
        <Tooltip
          hasArrow
          label="Edit Inspector"
          fontSize="14px"
          bg="gray.light12"
          color="white.primary"
          placement="top"
        >
          <Button
            variant="iconBtn"
            minW="unset"
            p="0"
            color="gray.primary"
            onClick={() => handleUserUpdateForm(users)}
          >
            <Icon mr="10px" fontSize="18px" as={MdEdit}></Icon> Edit
          </Button>
        </Tooltip>
      ),
    };
  });

  useEffect(() => {
    if (auth?.error) {
      const { message } = auth.error;
      toastr.error("", message ? message : "");
    } else if (superAdmin?.error) {
      const { message } = superAdmin.error;
      toastr.error("", message ? message : "");
    }

    return () => {
      dispatch(setAuthError(null));
      dispatch(setSuperAdminError(null));
    };
  }, [auth.error, dispatch, superAdmin.error]);
  const [goupVaue, setGroupValue] = React.useState("Growth");
  const [inspectorSubPlan, setInspectorSubPlan] = useState(false);

  return (
    <>
      {superAdmin.loading.length !== 0 && <Loader />}
      <Box p="29px 30px 20px 20px" minH="calc(100vh - 70px)">
        <Box display="flex" justifyContent="space-between" w="100%">
          <Text
            as="h1"
            p="0 10px"
            mb={{ sm: "16px", xl: "26px" }}
            color="black.lighten"
            fontSize="24px"
            lineHeight="30px"
            fontWeight="600"
          >
            Users
          </Text>
          <Box display="flex" justifyContent="flex-end" w="100%" mb="20px">
            <InputGroup w="282px" mr="20px">
              <InputLeftElement
                left="12px"
                top="0"
                _before={{
                  content: '""',
                  position: "absolute",
                  right: "-8px",
                  height: "23px",
                  width: "1px",
                  background: "blue.light2",
                }}
                children={
                  <Icon fontSize="17px" color="blue.dark3" as={ImSearch} />
                }
              />
              <Input
                variant="Search"
                placeholder="Search Keyword"
                h="40px"
                value={searchKeyword}
                onChange={(e) => setSearchKeyword(e.target.value)}
              />
            </InputGroup>
          </Box>
        </Box>
        <Box bg="white.primary" borderRadius="4px">
          <Tabs
            index={activeTab}
            onChange={(index: number) => setActiveTab(index)}
            className="tab-with-icons"
          >
            <TabList borderColor="gray.light3" borderBottomWidth="1px">
              <Tab
                _selected={{ color: "black.lighten" }}
                border="none"
                p="19px 17px"
              >
                <Text
                  fontSize={{ sm: "18px", xxl: "19px" }}
                  lineHeight={{ sm: "24px", xxl: "26px" }}
                  color="black.lighten"
                >
                  Growth Users
                </Text>
                <Box
                  bg="orange.primary"
                  ml="5px"
                  p="0 8px"
                  h="18px"
                  borderRadius="5px"
                >
                  <Text fontSize="12px" color="white.primary">
                    {superAdmin?.companyAdminUsers?.length}
                  </Text>
                </Box>
              </Tab>
              <Tab
                _selected={{ color: "black.lighten" }}
                border="none"
                p="19px 17px"
              >
                <Text
                  fontSize={{ sm: "18px", xxl: "19px" }}
                  lineHeight={{ sm: "24px", xxl: "26px" }}
                  color="black.lighten"
                >
                  Pro Users
                </Text>
                <Box
                  bg="orange.primary"
                  ml="5px"
                  p="0 8px"
                  h="18px"
                  borderRadius="5px"
                >
                  <Text fontSize="12px" color="white.primary">
                    {superAdmin?.homeInspectorUsers?.length}
                  </Text>
                </Box>
              </Tab>
              <Tab
                _selected={{ color: "black.lighten" }}
                border="none"
                p="19px 17px"
              >
                <Text
                  fontSize={{ sm: "18px", xxl: "19px" }}
                  lineHeight={{ sm: "24px", xxl: "26px" }}
                  color="black.lighten"
                >
                  Growth plus Users
                </Text>
                <Box
                  bg="orange.primary"
                  ml="5px"
                  p="0 8px"
                  h="18px"
                  borderRadius="5px"
                >
                  <Text fontSize="12px" color="white.primary">
                    {superAdmin?.growthPlusUsers?.length}
                  </Text>
                </Box>
              </Tab>
              <Tab
                _selected={{ color: "black.lighten" }}
                border="none"
                p="19px 17px"
              >
                <Text
                  fontSize={{ sm: "18px", xxl: "19px" }}
                  lineHeight={{ sm: "24px", xxl: "26px" }}
                  color="black.lighten"
                >
                  Pro plus Users
                </Text>
                <Box
                  bg="orange.primary"
                  ml="5px"
                  p="0 8px"
                  h="18px"
                  borderRadius="5px"
                >
                  <Text fontSize="12px" color="white.primary">
                    {superAdmin?.proPlusUsers?.length}
                  </Text>
                </Box>
              </Tab>
              <Tab
                _selected={{ color: "black.lighten" }}
                border="none"
                p="19px 17px"
              >
                <Text
                  fontSize={{ sm: "18px", xxl: "19px" }}
                  lineHeight={{ sm: "24px", xxl: "26px" }}
                  color="black.lighten"
                >
                  Unsubscribed Users
                </Text>
                <Box
                  bg="orange.primary"
                  ml="5px"
                  p="0 8px"
                  h="18px"
                  borderRadius="5px"
                >
                  <Text fontSize="12px" color="white.primary">
                    {superAdmin?.unsubscribeUsers?.length}
                  </Text>
                </Box>
              </Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                <Box p="0px 30px 20px 20px" minH="calc(100vh - 275px)">
                  <Box
                    borderRadius="12px"
                    overflow="hidden"
                    boxShadow="0px 0px 16px #f1f1f1c7"
                    pt="10px"
                  >
                    <DataTable
                      className="hi-datatable-component"
                      data={companyAdminTable}
                      columns={tableColumnsAdmin}
                      pagination
                      highlightOnHover
                      fixedHeader
                      fixedHeaderScrollHeight="calc(100vh - 370px)"
                    />
                  </Box>
                </Box>
              </TabPanel>

              <TabPanel>
                <Box p="0px 30px 20px 20px" minH="calc(100vh - 275px)">
                  <Box
                    borderRadius="12px"
                    overflow="hidden"
                    boxShadow="0px 0px 16px #f1f1f1c7"
                    pt="10px"
                  >
                    <DataTable
                      className="hi-datatable-component"
                      data={homeInspectorTable}
                      columns={tableColumnsHome}
                      pagination
                      highlightOnHover
                      fixedHeader
                      fixedHeaderScrollHeight="calc(100vh - 370px)"
                    />
                  </Box>
                </Box>
              </TabPanel>
              <TabPanel>
                <Box p="0px 30px 20px 20px" minH="calc(100vh - 275px)">
                  <Box
                    borderRadius="12px"
                    overflow="hidden"
                    boxShadow="0px 0px 16px #f1f1f1c7"
                    pt="10px"
                  >
                    <DataTable
                      className="hi-datatable-component"
                      data={growthPlusTable}
                      columns={tableColumnsGrowth}
                      pagination
                      highlightOnHover
                      fixedHeader
                      fixedHeaderScrollHeight="calc(100vh - 370px)"
                    />
                  </Box>
                </Box>
              </TabPanel>

              <TabPanel>
                <Box p="0px 30px 20px 20px" minH="calc(100vh - 275px)">
                  <Box
                    borderRadius="12px"
                    overflow="hidden"
                    boxShadow="0px 0px 16px #f1f1f1c7"
                    pt="10px"
                  >
                    <DataTable
                      className="hi-datatable-component"
                      data={proPlusTable}
                      columns={tableColumnsPro}
                      pagination
                      highlightOnHover
                      fixedHeader
                      fixedHeaderScrollHeight="calc(100vh - 370px)"
                    />
                  </Box>
                </Box>
              </TabPanel>

              <TabPanel>
                <Box p="0px 30px 20px 20px" minH="calc(100vh - 275px)">
                  <Box
                    borderRadius="12px"
                    overflow="hidden"
                    boxShadow="0px 0px 16px #f1f1f1c7"
                    pt="10px"
                  >
                    <DataTable
                      className="hi-datatable-component"
                      data={unsubscribedTable}
                      columns={tableColumnsUnsubscribed}
                      pagination
                      highlightOnHover
                      fixedHeader
                      fixedHeaderScrollHeight="calc(100vh - 370px)"
                    />
                  </Box>
                </Box>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Box>
        <Modal
          isOpen={userUpdateDetails}
          onClose={closeUserUpdateDetails}
          size="lg"
          scrollBehavior="inside"
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader
              p="25px 30px"
              fontSize="21px"
              lineHeight="26px"
              color="black.lighten"
              fontWeight="600"
            >
              Edit user details
            </ModalHeader>
            <Icon
              color="black.primary"
              fontSize="24px"
              as={IoMdClose}
              onClick={closeUserUpdateDetails}
              cursor="pointer"
              position="absolute"
              top="29px"
              right="29px"
            ></Icon>
            <ModalBody p="0px 30px 30px">
              <FormControl mb="10px">
                <Text fontSize="18px">First Name</Text>
                <Input
                  type="text"
                  maxW="unset"
                  name="firstName"
                  value={userUpdate.firstName}
                  onChange={userUpdateChange}
                  variant="Bordered"
                  height="50px"
                  fontSize="xl"
                />
              </FormControl>
              <FormControl mb="10px">
                <Text fontSize="18px">Last Name</Text>
                <Input
                  type="text"
                  maxW="unset"
                  name="lastName"
                  value={userUpdate.lastName}
                  onChange={userUpdateChange}
                  variant="Bordered"
                  height="50px"
                  fontSize="xl"
                />
              </FormControl>
              <FormControl mb="10px">
                <Text fontSize="18px">Email</Text>
                <Input
                  maxW="unset"
                  variant="Bordered"
                  name="email"
                  isInvalid={
                    userUpdateTouched["email"] && userUpdateErrors["email"]
                  }
                  onChange={userUpdateChange}
                  value={userUpdate.email}
                  height="50px"
                  fontSize="xl"
                />
              </FormControl>
              <FormControl mb="10px">
                <Text fontSize="18px">Contact No</Text>
                <Input
                  maxW="unset"
                  variant="Bordered"
                  name="phone"
                  isInvalid={
                    userUpdateTouched["phone"] && userUpdateErrors["phone"]
                  }
                  onChange={userUpdateChange}
                  value={userUpdate.phone}
                  height="50px"
                  fontSize="xl"
                />
              </FormControl>
              <FormControl>
                <Text fontSize="18px">Company Name</Text>
                <Input
                  maxW="unset"
                  variant="Bordered"
                  name="companyName"
                  value={userUpdate.companyName}
                  onChange={userUpdateChange}
                  height="50px"
                  fontSize="xl"
                />
              </FormControl>
              <FormControl>
                <Text fontSize="18px">Website</Text>
                <Input
                  maxW="unset"
                  variant="Bordered"
                  name="website"
                  value={userUpdate.website}
                  onChange={userUpdateChange}
                  height="50px"
                  fontSize="xl"
                />
              </FormControl>
              <Button
                variant="primary2"
                mt="15px"
                float="right"
                onClick={updateUserFormValues}
              >
                Update
              </Button>
            </ModalBody>
          </ModalContent>
        </Modal>
        <Modal
          isOpen={noteAdd}
          onClose={closeNote}
          size="lg"
          closeOnOverlayClick={false}
          scrollBehavior="inside"
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader
              p="25px 30px"
              fontSize="21px"
              lineHeight="26px"
              color="black.lighten"
              fontWeight="600"
            >
              Note
            </ModalHeader>
            <Icon
              color="black.primary"
              fontSize="24px"
              as={IoMdClose}
              onClick={closeNote}
              cursor="pointer"
              position="absolute"
              top="29px"
              right="29px"
            ></Icon>
            <ModalBody p="0px 30px 30px">
              <FormControl mb="10px">
                <Textarea
                  w="100%"
                  variant="Bordered"
                  name="note"
                  value={noteValue}
                  onChange={(e) => setNoteValue(e.target.value)}
                ></Textarea>
              </FormControl>
              <Button
                variant="primary2"
                mt="15px"
                float="right"
                onClick={blockStaff}
              >
                Block
              </Button>
            </ModalBody>
          </ModalContent>
        </Modal>
        <Modal
          isOpen={deleteConfirmation}
          onClose={closeDeleteConfirmation}
          size="xl"
          scrollBehavior="inside"
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader
              p="30px 30px 35px"
              fontSize="21px"
              lineHeight="26px"
              color="black.lighten"
              fontWeight="600"
            >
              Delete Admin
            </ModalHeader>
            <Icon
              color="black.primary"
              fontSize="24px"
              as={IoMdClose}
              onClick={closeDeleteConfirmation}
              cursor="pointer"
              position="absolute"
              top="29px"
              right="29px"
            ></Icon>
            <ModalBody p="0 30px 25px">
              <Text fontSize="18px" color="black.lighten">
                Are you sure you want to delete?
              </Text>
            </ModalBody>
            <ModalFooter>
              <Button
                variant="primary2"
                minW="120px"
                onClick={handleDeleteAdmin}
              >
                Yes
              </Button>
              <Button
                variant="grey"
                ml="15px"
                minW="120px"
                onClick={closeDeleteConfirmation}
              >
                No
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>

        <Modal
          isOpen={openSubscribe}
          onClose={closeSubscribeModal}
          size="xl"
          scrollBehavior="inside"
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader
              p="30px 30px 35px"
              fontSize="21px"
              lineHeight="26px"
              color="black.lighten"
              fontWeight="600"
            >
              Subscribtion
            </ModalHeader>
            <Icon
              color="black.primary"
              fontSize="24px"
              as={IoMdClose}
              onClick={closeSubscribeModal}
              cursor="pointer"
              position="absolute"
              top="29px"
              right="29px"
            ></Icon>
            <ModalBody p="0 30px 25px">
              <Text fontSize="18px" color="black.lighten">
                Are you sure?
                {/* as this user doesn't purchase subscription? */}
              </Text>
            </ModalBody>
            <ModalFooter>
              <Button
                variant="primary2"
                minW="120px"
                onClick={() => subscribe()}
              >
                Yes
              </Button>
              <Button
                variant="grey"
                ml="15px"
                minW="120px"
                onClick={() => closeSubscribeModal()}
              >
                No
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>

        <Modal
          isOpen={openSubscribtion}
          onClose={closeSubscribtionModal}
          size="xl"
          scrollBehavior="inside"
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader
              p="30px 30px 35px"
              fontSize="21px"
              lineHeight="26px"
              color="black.lighten"
              fontWeight="600"
            >
              Subscribtion
            </ModalHeader>
            <Icon
              color="black.primary"
              fontSize="24px"
              as={IoMdClose}
              onClick={closeSubscribtionModal}
              cursor="pointer"
              position="absolute"
              top="29px"
              right="29px"
            ></Icon>
            <ModalBody p="0 30px 25px">
              {/* <Text fontSize="18px" color="black.lighten">
              sdfdsf
              </Text> */}
              <Box className="modal_bodySection">
                <Stack
                  direction="row"
                  alignItems="center"
                  className="toggle_section cmn_section"
                >
                  <Text fontSize="18px" color="black.lighten">
                    Monthly
                  </Text>
                  {/* <Checkbox
                    className="switch switch_section"
                    variant="transparent"
                    colorScheme="transparent"
                    isChecked={false}
                  
                  ></Checkbox> */}

                  <Switch
                    className="toggle_moth_yearswitch"
                    variant={"primary"}
                    size="md"
                    isChecked={inspectorSubPlan}
                    onChange={(e) =>
                      setInspectorSubPlan(e.target.checked ? true : false)
                    }
                    m="0 15px"
                  />
                  <Text fontSize="18px" color="black.lighten">
                    Yearly
                  </Text>
                </Stack>
                <Box
                  className="cmn_section cmn_modal_radioSection"
                  maxWidth="
                300px"
                  marginRight="auto"
                >
                  <RadioGroup
                    onChange={setGroupValue}
                    value={goupVaue}
                    padding="40px 0 20px"
                    fontSize="18px"
                  >
                    <Stack
                      direction="row"
                      alignItems="center"
                      marginBottom="20px"
                      flexWrap="wrap"
                    >
                      <Box width="50%" flexBasis="50%">
                        <Radio colorScheme="orange" value="Growth">
                          <Text fontSize="18px" color="black.lighten">
                            Growth
                          </Text>
                        </Radio>
                      </Box>
                      <Box width="50%" flexBasis="50%" margin={0}>
                        <Radio colorScheme="orange" marginLeft={0} value="Pro">
                          <Text fontSize="18px" color="black.lighten">
                            Pro
                          </Text>
                        </Radio>
                      </Box>
                    </Stack>
                    <Stack direction="row" alignItems="center" flexWrap="wrap">
                      <Box width="50%" flexBasis="50%">
                        <Radio colorScheme="orange" value="Growth Plus">
                          <Text fontSize="18px" color="black.lighten">
                            Growth Plus
                          </Text>
                        </Radio>
                      </Box>
                      <Box width="50%" flexBasis="50%" margin={0}>
                        <Radio
                          colorScheme="orange"
                          marginLeft={0}
                          value="Pro Plus"
                        >
                          <Text fontSize="18px" color="black.lighten">
                            {" "}
                            Pro Plus
                          </Text>
                        </Radio>
                      </Box>
                    </Stack>
                  </RadioGroup>
                </Box>
              </Box>
            </ModalBody>
            <ModalFooter>
              <Button
                variant="primary2"
                minW="120px"
                onClick={() => subscriptionSubmit()}
              >
                Submit
              </Button>
              <Button
                variant="grey"
                ml="15px"
                minW="120px"
                onClick={() => closeSubscribtionModal()}
              >
                Cancel
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Box>
    </>
  );
}
