import React, { useState, useEffect } from "react";
import {
  Box,
  Image,
  Heading,
  Input,
  Button,
  Text,
  InputGroup,
  InputRightElement,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  useDisclosure,
  FormControl,
  FormLabel,
  Flex,
  Icon,
} from "@chakra-ui/react";
import { Link, useNavigate } from "react-router-dom";
import loginBanner from "../../../assets/images/login-banner.svg";
import logo from "../../../assets/images/logo.png";
import visibilityOff from "../../../assets/images/visibility_off.svg";
import visibilityOn from "../../../assets/images/visibility_on.svg";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import {
  login,
  sendForgotPasswordEmail,
  authState,
  setAuthError,
} from "../../../redux/modules/auth";
import { toastr } from "react-redux-toastr";
import { useForm } from "../../../utils/hooks";
import signupIllustration from "../../../assets/images/signup-banner.svg";
import Loader from "../../../components/common/loader";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import defaultAvatar from "../../../assets/images/pdf-default-profile.png";
import { FaQuoteLeft } from "react-icons/fa";
import whitePattern from "../../../assets/images/white-pattern.jpg";

export default function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const auth: authState = useSelector((state: RootState) => state.auth);
  const {
    isOpen: openVerificationModal,
    onOpen: openEmailVerification,
    onClose: closeVerificationModal,
  } = useDisclosure();

  const LOGIN_FORM = {
    default: {
      email: "",
      password: "",
    },
    field: {
      required: true,
      inputs: ["email", "password"],
    },
  };

  const VERIFY_EMAIL_FORM = {
    default: {
      email: "",
    },
    field: {
      required: true,
      inputs: ["email"],
    },
  };

  const [showPassword, setShowPassword] = useState(false);
  const [isLoginScreen, setLoginScreen] = useState(true);
  const {
    values: loginValues,
    change: loginChange,
    touched: loginTouched,
    errors: loginErrors,
  } = useForm(LOGIN_FORM.default, LOGIN_FORM.field);
  const {
    values: mailValues,
    change: mailChange,
    touched: mailTouched,
    errors: mailErrors,
    reset: mailReset,
  } = useForm(VERIFY_EMAIL_FORM.default, VERIFY_EMAIL_FORM.field);

  const isForgotPasswordScreen = () => setLoginScreen(!isLoginScreen);

  const loginSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();

    if (Object.keys(loginTouched).length <= 0) {
      toastr.warning("", "Please first fill the form");
      return;
    }

    if (Object.keys(loginErrors).length > 0) {
      toastr.warning("", loginErrors[Object.keys(loginErrors)[0]]);
      return;
    }
    // Call login user api
    new Promise(() => {
      const data1: any = dispatch(login({ payload: loginValues }));

      data1.then(function (value) {
        console.log("Login", value);
        if (value.type === "auth/login/fulfilled") {
          if (
            value.payload.message ===
            "Otp send to your email Id .Its valid for 10 minutes only"
          ) {
            navigate("/otp");
          }
        }
      });
    });
  };

  const verifyEmailSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();

    if (Object.keys(mailTouched).length <= 0) {
      toastr.warning("", "Please first fill the form");
      return;
    }

    if (Object.keys(mailErrors).length > 0) {
      toastr.warning("", mailErrors[Object.keys(mailErrors)[0]]);
      return;
    }

    // Call sendForgotPasswordEmail api
    new Promise(() => {
      const data2: any = dispatch(
        sendForgotPasswordEmail({ payload: mailValues })
      );
      data2.then(function (value) {
        if (value.type === "auth/sendForgotPasswordEmail/fulfilled") {
          mailReset(); // Reset mail form
          isForgotPasswordScreen();
          toastr.success(
            "",
            "We have sent an email, please click on the link to change your password"
          );
        }
      });
    });
  };

  useEffect(() => {
    if (auth.error) {
      const { message } = auth.error;
      if (auth.error?.message?.includes("please verify your email id")) {
        openEmailVerification();
      } else if (
        auth.error?.message?.includes(
          "We already sent a email to your account. Please check your Email Id"
        )
      ) {
        toastr.error("", message ? message : "");
        isForgotPasswordScreen();
      } else {
        toastr.error("", message ? message : "");
      }
    }

    return () => {
      dispatch(setAuthError(null));
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.error, dispatch]);

  return (
    <>
      {auth.loginLoading.length !== 0 && <Loader />}
      <Box display="flex" minH="100vh">
        <Box
          minW={{ sm: "50%", lg: "45%", xxl: "40%" }}
          w={{ sm: "50%", lg: "45%", xxl: "40%" }}
          py="20px"
          px="20px"
          display="inline-flex"
          alignItems="center"
          flexDirection="column"
          justifyContent="center"
          pos="relative"
          _before={{
            content: '""',
            bgImg: whitePattern,
            pos: "absolute",
            w: "100%",
            h: "100%",
            top: "0",
            left: "0",
            right: "0",
            bottom: "0",
            bgSize: "cover",
            opacity: "0.2",
          }}
        >
          <Carousel
            interval={4500}
            infiniteLoop={true}
            stopOnHover={false}
            showArrows={false}
            showStatus={false}
          >
            <Box
              display="inline-flex"
              alignItems="center"
              justifyContent="center"
              flexDirection="column"
              pos="relative"
            >
              <Icon
                color="#456db5"
                as={FaQuoteLeft}
                top="205px"
                fontSize="70px"
                left="15px"
                opacity="0.09"
                pos="absolute"
              ></Icon>
              <Icon
                color="#456db5"
                as={FaQuoteLeft}
                bottom="110px"
                fontSize="55px"
                right="15px"
                opacity="0.09"
                pos="absolute"
                transform="rotate(180deg)"
              ></Icon>
              <Image
                maxH={{ sm: "82px", xxl: "98px" }}
                src={logo}
                alt="Sign Up Logo"
                mb={{ sm: "80px", xxl: "90px" }}
              />
              <Box
                overflow="hidden"
                w="85px"
                h="85px"
                mb="20px"
                borderRadius="50%"
              >
                <Image
                  src={defaultAvatar}
                  w="100%"
                  h="100%"
                  objectFit="cover"
                ></Image>
              </Box>
              <Flex
                minHeight="220px"
                alignItems="center"
                flexDir="column"
                justifyContent="flex-start"
                mb="40px"
              >
                <Text fontSize={{ sm: "24px", xxl: "26px" }} textAlign="center">
                  "This inspection software is a BIG improvement from the one I
                  had before! It takes me much less time to complete my
                  inspection reports! Thank you."
                </Text>
                <Text
                  as="h3"
                  mt="10px"
                  fontSize={{ sm: "24px", xxl: "28px" }}
                  fontWeight="600"
                  textAlign="center"
                >
                  F.Menesis
                </Text>
              </Flex>
            </Box>
            <Box
              display="inline-flex"
              alignItems="center"
              justifyContent="center"
              flexDirection="column"
              pos="relative"
            >
              <Icon
                color="#456db5"
                as={FaQuoteLeft}
                top="205px"
                fontSize="70px"
                left="15px"
                opacity="0.09"
                pos="absolute"
              ></Icon>
              <Icon
                color="#456db5"
                as={FaQuoteLeft}
                bottom="110px"
                fontSize="55px"
                right="15px"
                opacity="0.09"
                pos="absolute"
                transform="rotate(180deg)"
              ></Icon>
              <Image
                maxH={{ sm: "82px", xxl: "98px" }}
                src={logo}
                alt="Sign Up Logo"
                mb={{ sm: "80px", xxl: "90px" }}
              />
              <Box
                overflow="hidden"
                w="85px"
                h="85px"
                mb="20px"
                borderRadius="50%"
              >
                <Image
                  src={defaultAvatar}
                  w="100%"
                  h="100%"
                  objectFit="cover"
                ></Image>
              </Box>
              <Flex
                minHeight="220px"
                alignItems="center"
                flexDir="column"
                justifyContent="flex-start"
                mb="40px"
              >
                <Text fontSize={{ sm: "24px", xxl: "26px" }} textAlign="center">
                  “I really like the fact that I can go to the Property without
                  my clipboard and complete the whole inspection with Aurora.”
                </Text>
                <Text
                  as="h3"
                  mt="10px"
                  fontSize={{ sm: "24px", xxl: "28px" }}
                  fontWeight="600"
                  textAlign="center"
                >
                  R. Dunn
                </Text>
              </Flex>
            </Box>
          </Carousel>
        </Box>
        <Box
          minW={{ sm: "50%", lg: "55%", xxl: "60%" }}
          w={{ sm: "50%", lg: "55%", xxl: "60%" }}
          pos="relative"
          bg="#0A1F3E"
          overflow="auto"
          bgImg={loginBanner}
          bgRepeat="no-repeat"
          bgSize="cover"
          d="flex"
          alignItems="center"
          justifyContent="center"
          p="40px 0"
        >
          {isLoginScreen ? (
            <Box w="100%" maxW={{ sm: "300px", xxl: "340px" }}>
              <Heading as="h1" color="#fff" fontSize="25px" mb="50px">
                Log In
              </Heading>
              <Box mb="30px">
                <FormControl isRequired>
                  <FormLabel
                    as="h4"
                    fontSize="xxl"
                    fontWeight="normal"
                    color="#fff"
                    mb="10px"
                  >
                    Email
                  </FormLabel>
                  <Input
                    isInvalid={loginTouched["email"] && loginErrors["email"]}
                    variant="Simple"
                    placeholder="Email"
                    type="email"
                    name="email"
                    value={loginValues.email}
                    onChange={loginChange}
                  />
                </FormControl>
              </Box>
              <Box>
                <FormControl isRequired>
                  <FormLabel
                    as="h4"
                    fontSize="xxl"
                    fontWeight="normal"
                    color="#fff"
                    mb="10px"
                  >
                    Password
                  </FormLabel>
                  <InputGroup>
                    <Input
                      isInvalid={
                        loginTouched["password"] && loginErrors["password"]
                      }
                      pr="45px"
                      type={showPassword ? "text" : "password"}
                      variant="Simple"
                      name="password"
                      value={loginValues.password}
                      placeholder="*****************"
                      onChange={loginChange}
                      onKeyDown={(e) => e.key === "Enter" && loginSubmit(e)}
                    />
                    <InputRightElement top="8px" right="12px">
                      <Button
                        p="0"
                        minWidth="unset"
                        bg="transparent"
                        _hover={{ bg: "transparent" }}
                        _focus={{ outline: "none" }}
                        _active={{ bg: "transparent" }}
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? (
                          <Image
                            w="18px"
                            src={visibilityOff}
                            alt="visibility off"
                          />
                        ) : (
                          <Image
                            w="18px"
                            src={visibilityOn}
                            alt="visibility on"
                          />
                        )}
                      </Button>
                    </InputRightElement>
                  </InputGroup>
                </FormControl>
              </Box>
              <Text
                fontSize="xl"
                color="#00AEEF"
                mt="10px"
                mb="50px"
                textAlign="right"
                cursor="pointer"
                onClick={isForgotPasswordScreen}
                transition="0.4s all ease"
                _hover={{ color: "#51d0ff" }}
              >
                Forgot Password ?
              </Text>
              <Button variant="primary" w="100%" onClick={loginSubmit}>
                Log In
              </Button>
              <Box
                d="flex"
                justifyContent="center"
                mt="12px"
                alignItems="center"
                fontSize="xl"
                lineHeight="19px"
                flexWrap="wrap"
                position="relative"
              >
                <Text textAlign="right" color="#fff" mr="15px">
                  New User ?
                </Text>
                <Link to={"/signup"}>
                  <Text
                    as="span"
                    color="#00AEEF"
                    transition="0.4s all ease"
                    _hover={{ color: "#51d0ff" }}
                  >
                    Create Account
                  </Text>
                </Link>
              </Box>
            </Box>
          ) : (
            <Box w="100%" maxW={{ sm: "300px", xxl: "340px" }}>
              <Heading as="h1" color="#fff" fontSize="25px" mb="35px">
                Forgot Password
              </Heading>
              <Box mb="35px">
                <FormControl isRequired>
                  <FormLabel
                    as="h4"
                    fontSize="xxl"
                    fontWeight="normal"
                    color="#fff"
                    mb="10px"
                  >
                    Enter your email id
                  </FormLabel>
                  <Input
                    isInvalid={mailTouched["email"] && mailErrors["email"]}
                    type="email"
                    variant="Simple"
                    placeholder="Email"
                    name="email"
                    value={mailValues.email}
                    onChange={mailChange}
                  />
                </FormControl>
              </Box>
              <Button
                variant="primary"
                w="100%"
                mb="20px"
                onClick={verifyEmailSubmit}
              >
                Submit
              </Button>
              <Text
                as="a"
                color="#ffffff"
                textAlign="center"
                fontSize="xl"
                d="block"
                cursor="pointer"
                transition="0.4s all ease"
                textDecoration="underline"
                _hover={{ color: "#d1d1d1", textDecoration: "underline" }}
                onClick={isForgotPasswordScreen}
              >
                Back To Login
              </Text>
            </Box>
          )}
        </Box>
      </Box>
      <Modal
        isOpen={openVerificationModal}
        onClose={closeVerificationModal}
        size="4xl"
        scrollBehavior="inside"
      >
        <ModalOverlay />
        <ModalContent
          boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
          textAlign="center"
        >
          <ModalHeader p={{ sm: "35px 50px 20px", xxl: "45px 50px 30px" }}>
            <Text
              as="h2"
              fontSize="24px"
              lineHeight="26px"
              color="black.lighten"
              fontWeight="600"
              mb="10px"
            >
              Verify your email
            </Text>
            <Text
              fontSize="18px"
              color="gray.darken"
              fontWeight="normal"
              as="p"
            >
              You need to verify your email to login
            </Text>
          </ModalHeader>
          <ModalBody
            p="8px 50px"
            textAlign="center"
            d="flex"
            alignItems="center"
            flexDirection="column"
          >
            <Image
              maxW={{ sm: "260px", xxl: "300px" }}
              src={signupIllustration}
              alt="verification-illustration"
            ></Image>
            <Text
              maxW="450px"
              as="p"
              fontSize="17px"
              lineHeight="24px"
              color="gray.primary"
              mt={{ sm: "5px", xxl: "25px" }}
            >
              An email has been sent at{" "}
              <Text as="span" color="black.lighten">
                {loginValues.email}
              </Text>{" "}
              If you have not recieved the email please check the spam folder{" "}
            </Text>
          </ModalBody>
          <ModalFooter
            justifyContent="center"
            p={{ sm: "30px 50px 25px", xxl: "40px 50px 35px" }}
          >
            <Button
              variant="primary"
              fontSize="16px"
              color="white.primary"
              fontWeight="normal"
              mr="20px"
              height="45px"
              minW="120px"
              onClick={closeVerificationModal}
            >
              Ok
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
