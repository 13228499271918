import store from "store2";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { RootState } from "../../../redux/store";
import {
  authState,
  loadUser,
  logout,
  userDetailById,
} from "../../../redux/modules/auth";
import { setSubscriptionExpiration } from "../../../redux/modules/subscription";
import { toastr } from "react-redux-toastr";

export default function AuthGaurd({ children }) {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [isUserFatched, setUserFatched] = useState(false);
  const auth: authState = useSelector((state: RootState) => state.auth);

  // Initial value loder
  // ... render page resources
  useEffect(() => {
    if (!auth.userLoaded) {
      dispatch(loadUser());
    } else if (auth.userLoaded && !isUserFatched && auth?.user?.id) {
      setUserFatched(true);
      dispatch(userDetailById({ userId: auth.user.id }));
    }
  }, [auth.user, auth.userLoaded, dispatch, isUserFatched]);

  useEffect(() => {
    if (auth?.user?.id) {
      dispatch(userDetailById({ userId: auth?.user?.id }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, navigate]);

  useEffect(() => {
    const routes =
      /\/signup|\/login|\/forgot-password\/|\/generate-password\/|\/otp\/|\/useractivate\/|\/agreement-signature/gi;

    if (
      auth.userLoaded &&
      auth.user &&
      (location.pathname === "/" ||
        location.pathname === "/login" ||
        location.pathname === "/signup" ||
        location.pathname === "/otp") &&
      !auth.user.message &&
      auth.error?.message !== "user not found"
    ) {
      navigate("/dashboard");
    } else if (
      auth.userLoaded &&
      !auth.user &&
      !routes.test(location.pathname)
    ) {
      navigate("/login");
    }
  }, [auth.user, navigate, location, auth.userLoaded, auth.error?.message]);

  useEffect(() => {
    if (auth?.user) {
      const isTokenExpired = (isToken: string) =>
        Date.now() >= JSON.parse(atob(isToken.split(".")[1])).exp * 1000;
      const token = store.get("authToken");
      dispatch(
        setSubscriptionExpiration(
          auth.user?.is_subscription_cancelled ? true : false
        )
      );

      if (auth.user?.is_subscription_cancelled) {
        if (
          (auth?.user?.user_Type === "company_admin" ||
            auth?.user?.user_Type === "home_inspector") &&
          !auth.user.AdminID
        ) {
          if (
            auth.user.is_payment_failed === true ||
            auth.user.is_subscription_cancelled === true
          ) {
            navigate("/subscription");
          }
        }
      }

      if (
        (auth?.user?.user_Type === "company_admin" ||
          auth?.user?.user_Type === "home_inspector") &&
        !auth.user?.AdminID &&
        auth?.user?.expireTime === null
      ) {
        navigate("/subscription");
      }
      if (
        auth?.user?.user_Type === "home_inspector" &&
        auth?.user?.AdminID &&
        auth?.user?.expireTime === null
      ) {
        if (
          auth.user?.is_subscription_cancelled === null ||
          auth.user?.is_subscribed === null
        ) {
          dispatch(logout()); // Logout User
          toastr.error("", `Contact Admin to select the subscription plan`);
        }
      }

      if (
        (token && isTokenExpired(token)) ||
        auth.user?.homeInspector_block === true ||
        auth.user?.isDeleted === true
      ) {
        dispatch(logout()); // Logout User
        if (auth.user?.homeInspector_block === true) {
          toastr.error(
            "",
            `Super Admin is blocked your account because ${auth.user?.block_description}`
          );
        }
      }
    }
  }, [auth.user, dispatch, navigate]);

  return children;
}
