import App from './App';
import { createRoot } from 'react-dom/client';
import { store } from './redux/store';
import { Provider } from 'react-redux';
import ReduxToastr from 'react-redux-toastr';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import AuthGaurd from './components/common/AuthGaurd';

import './index.scss';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <Provider store={store}>
    <BrowserRouter>
      <AuthGaurd>
        <App />
        <ReduxToastr
          position="top-center"
          transitionIn="fadeIn"
          transitionOut="fadeOut"
        />
      </AuthGaurd>
    </BrowserRouter>
  </Provider>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
reportWebVitals();
