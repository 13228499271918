import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getCompanyDetailsSuperAdmin, superAdminState} from '../../redux/modules/superAdmin/superAdmin';
import { RootState } from '../../redux/store';
import { Box, Icon, Input, InputGroup, InputLeftElement, Text } from '@chakra-ui/react'
import DataTable from 'react-data-table-component'
import Loader from '../common/loader';
import { useNavigate } from 'react-router-dom';
import { ImSearch } from 'react-icons/im';
import moment from 'moment';


function CompanyListing() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const superAdmin: superAdminState = useSelector((state: RootState) => state.superAdmin)

  const [companyDetailsList, setCompanyDetailsList] = useState(null)
  const [searchKeyword, setSearchKeyword] = useState('');

  useEffect(() => {
    if (searchKeyword.match(/^[a-zA-Z0-9!//@. ]+$/g)) {
      const data: any[] = superAdmin.companyDatailSuperAdmin.filter((users) => users.expireTime && moment(users.expireTime).format("MM-DD-YYYY, HH:MM A").match(searchKeyword));
      data.push(...superAdmin.companyDatailSuperAdmin.filter((users) => users?.companyName && users?.companyName.match(searchKeyword)));
      data.push(...superAdmin.companyDatailSuperAdmin.filter((users) => users?.email && users?.email.match(searchKeyword)));
      data.push(...superAdmin.companyDatailSuperAdmin.filter((users) => users?.companyAddress && users?.companyAddress.match(searchKeyword)));
      data.push(...superAdmin.companyDatailSuperAdmin.filter((users) => users?.website && users?.website.match(searchKeyword)));
      setCompanyDetailsList(data);
    }
    else {
      setCompanyDetailsList(superAdmin.companyDatailSuperAdmin)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchKeyword]);

  useEffect(() => {
    dispatch(getCompanyDetailsSuperAdmin());
  }, [dispatch]);

  useEffect(() => {
    setCompanyDetailsList(superAdmin.companyDatailSuperAdmin);
  }, [superAdmin.companyDatailSuperAdmin]);

  const tableColumns = [
    {
      name: 'Company Name',
      selector: (row: any) => row.companyName,
      sortable: true,
      minWidth: '25%'
    },
    {
      name: 'Company Address',
      selector: (row: any) => row.companyAddress,
      sortable: true,
      minWidth: '25%'
    },
    {
      name: 'Email',
      selector: (row: any) => row.email,
      sortable: true,
      minWidth: '25%'
    },
    {
      name: 'Website',
      selector: (row: any) => row.website,
      sortable: true,
      minWidth: '25%'
    },
  ];

  const tableDataItems = companyDetailsList?.map((companyDetails: any) => {
    return {
      "reportId": companyDetails.id,
      "companyName": companyDetails.companyName,
      "companyAddress": companyDetails.companyAddress,
      "email": companyDetails.email,
      "website": companyDetails.website
    }
  })

  return (
    <>
      {(superAdmin.loading.length !== 0) && <Loader />}
      <Box p='29px 30px 20px 20px' minH='calc(100vh - 70px)'>
        <Box display='flex' justifyContent='space-between' alignItems='center' mb='18px'>
          <Box>
            <Box display='inline-flex' alignItems='center'>
              <Text as='h1' mr='20px' color='black.lighten' fontSize='30px' fontWeight='700'>Reports</Text>
              <Box bg='orange.primary' p='0 8px' h='18px' borderRadius='5px'>
                <Text fontSize='12px' color='white.primary'>{companyDetailsList?.length}</Text>
              </Box>
            </Box>
            <Text d='block' as='span' color='black.lighten'>Reports are listed company wise</Text>
          </Box>
          <Box display='inline-flex'>
            <InputGroup w='282px' mr='20px'>
              <InputLeftElement left='12px' top='0' _before={{ content: '""', position: 'absolute', right: '-8px', height: '23px', width: '1px', background: 'blue.light2' }} children={<Icon fontSize='17px' color='blue.dark3' as={ImSearch} />} />
              <Input variant='Search' placeholder='Search Keyword' h='40px' value={searchKeyword} onChange={(e) => setSearchKeyword(e.target.value)} />
            </InputGroup>
          </Box>
        </Box>
        <Box borderRadius='12px' overflow='hidden' boxShadow='0px 0px 16px #f1f1f1c7' pt='10px'>
          <DataTable
            className='hi-datatable-component customize-scrollbar'
            data={tableDataItems}
            columns={tableColumns}
            pagination
            highlightOnHover
            fixedHeader
            fixedHeaderScrollHeight='calc(100vh - 276px)'
            onRowClicked={(report) => navigate(`/reports/${report.reportId}`)}
          />
        </Box>
      </Box>
    </>
  )
}

export default CompanyListing