import {
  Avatar,
  Box,
  Button,
  Flex,
  Icon,
  Input,
  SimpleGrid,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Textarea,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  FormControl,
  FormLabel,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { MdDelete, MdModeEdit } from "react-icons/md";
import { IoMdClose, IoMdEye } from "react-icons/io";
import "react-quill/dist/quill.snow.css";
import { Cropper } from "react-cropper";
import "cropperjs/dist/cropper.css";
import { toastr } from "react-redux-toastr";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import {
  authState,
  companyProfileUpdate,
  createAggrement,
  getAggrements,
  setAuthError,
  updateAggrement,
  updatePassword,
  updateUser,
  uploadCompanyImage,
  uploadUserImage,
  userDetailById,
} from "../../redux/modules/auth";
import { useForm } from "../../utils/hooks";
import {
  fillFormValues,
  googleMapAutocomplete,
  loadGoogleMapScript,
} from "../../utils/common";
import { css } from "@emotion/react";
import Loader from "../../components/common/loader";
import TextEditor from "../../components/common/TextEditor";
import AgreementModal from "../../components/common/AgreementModal";
import { ReportState, setReportAgreement } from "../../redux/modules/report";
import { useLocation, useNavigate } from "react-router-dom";
import {
  createCompanyPolicy,
  deletePolicy,
  getCompanyPolicy,
  superAdminState,
  updateCompanyPolicy,
} from "../../redux/modules/superAdmin/superAdmin";

export function SuperAdminSettings() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const auth: authState = useSelector((state: RootState) => state.auth);
  const superAdmin: superAdminState = useSelector(
    (state: RootState) => state.superAdmin
  );
  const {
    isOpen: isOpenReset,
    onOpen: onOpenReset,
    onClose: onCloseReset,
  } = useDisclosure();
  const {
    isOpen: isOpenCropper,
    onOpen: onOpenCropper,
    onClose: onCloseCropper,
  } = useDisclosure();
  const {
    isOpen: companyPolicyDetails,
    onOpen: openCompanyPolicyDetails,
    onClose: closeCompanyPolicyDetails,
  } = useDisclosure();
  const {
    isOpen: deleteConfirmation,
    onOpen: openDeleteConfirmation,
    onClose: closeDeleteConfirmation,
  } = useDisclosure();

  const MANAGE_PASSWORD_FORM = {
    default: {
      oldPassword: "",
      password: "",
      confirmPassword: "",
    },
    field: {
      required: true,
      inputs: ["oldPassword", "password", "confirmPassword"],
    },
  };

  const USER_UPDATE_FORM = {
    default: {
      firstName: "",
      lastName: "",
      phone: "",
      address: "",
      state: "",
      email: "",
      user_Certificate: "",
      user_LicenceNumber: "",
    },
    field: {
      required: true,
      inputs: [],
    },
  };

  const COMPANY_UPDATE_FORM = {
    default: {
      companyName: "",
      companyAddress: "",
      email: "",
      state: "",
      city: "",
      zipcode: "",
      website: "",
    },
    field: {
      required: true,
      inputs: [],
    },
  };

  const COMPANY_POLICY_FORM = {
    default: {
      title: "",
      description: "",
    },
    field: {
      required: true,
      inputs: ["title", "description"],
    },
  };

  const {
    values: managePassValues,
    change: managePassChange,
    touched: managePassTouched,
    errors: managePassErrors,
    reset: managePassReset,
  } = useForm(MANAGE_PASSWORD_FORM.default, MANAGE_PASSWORD_FORM.field);
  const {
    values: companyValues,
    change: companyChange,
    touched: companyTouched,
    errors: companyErrors,
    setValues: companySetValues,
    reset: companyReset,
  } = useForm(COMPANY_UPDATE_FORM.default, COMPANY_UPDATE_FORM.field);
  const {
    values: userUpdateValues,
    change: userUpdateChange,
    touched: userUpdateTouched,
    errors: userUpdateErrors,
    setValues: userUpdateSetValues,
    reset: userUpdateReset,
  } = useForm(USER_UPDATE_FORM.default, USER_UPDATE_FORM.field);
  //company policy use form
  const {
    values: policyValues,
    change: policyChange,
    errors: policyErrors,
    setValues: setPolicyValues,
    touched: policyTouched,
  } = useForm(COMPANY_POLICY_FORM.default, COMPANY_POLICY_FORM.field);

  const report: ReportState = useSelector((state: RootState) => state.report);
  const [agreementDesc, setAgreementDesc] = useState("");
  const [agreementTitle, setAgreementTitle] = useState("");
  const [showAgreementForm, setShowAgreementForm] = useState(false);
  const [isAgreementUpdate, setIsAgreementUpdate] = useState({
    status: false,
    id: null,
  });

  const [selectFor, setSelectFor] = useState(null);
  const [cropImage, setCropImage] = useState(null);
  const [croppedImage, setCroppedImage] = useState<any>();
  const [currentFileName, setCurrentFileName] = useState(null);

  // Set agreement modal states
  const [selectedAgreement, setSelectedAgreement] = useState(null);
  const [openAgreementModel, setOpenAgreementModel] = useState(false);
  const [agreementUpdated, setAgreementUpdated] = useState(null);

  //set company policy
  const [companyPolicyData, setCompanyPolicyData] = useState(null);
  const [editPolicy, setEditPolicy] = useState(null);
  const [policyId, setPolicyId] = useState(null);
  const [loadMap, setLoadMap] = useState(false);

  useEffect(() => {
    loadGoogleMapScript(() => {
      setLoadMap(true);
    });
  }, []);

  useEffect(() => {
    if (loadMap) {
      let autocomplete: google.maps.places.Autocomplete;
      const autocompleteInput = window.document.getElementById(
        "autocomplete"
      ) as HTMLInputElement;
      autocomplete = new google.maps.places.Autocomplete(autocompleteInput);

      // When a place is selected from the autocomplete dropdown
      autocomplete.addListener("place_changed", () => {
        const addressValues = googleMapAutocomplete(autocomplete);
        if (addressValues) {
          companySetValues({
            companyName: companyValues.companyName,
            companyAddress: addressValues.streetNumber,
            email: companyValues.email,
            state: addressValues.addressState,
            city: addressValues.addressCity,
            zipcode: addressValues.addressZipcode || "",
            website: companyValues.website,
          });
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyValues.companyAddress]);

  // Initial value loder
  // ... render page resources
  useEffect(() => {
    if (auth.user?.id) {
      dispatch(getAggrements({ payload: { userId: auth.user.id } }));
    }
  }, [auth.user?.id, dispatch]);

  const fileChange = (event: any) => {
    event.preventDefault();
    setSelectFor(event.target.name);

    let files: any;
    if (event.dataTransfer) {
      files = event.dataTransfer.files;
      onOpenCropper();
    } else if (event.target) {
      files = event.target.files;
      onOpenCropper();
    }

    const reader = new FileReader();
    reader.onload = () => {
      setCropImage(reader.result as any);
    };

    reader.readAsDataURL(files[0]);
    setCurrentFileName(files[0].name);
  };

  const getCropData = () => {
    if (typeof croppedImage !== "undefined") {
      const dataURLtoFile = (dataurl: string, filename: string) => {
        const arr = dataurl.split(",");
        const mime = arr[0].match(/:(.*?);/)[1];
        const bstr = atob(arr[1]);
        let n = bstr.length;
        const u8arr = new Uint8Array(n);
        while (n) {
          u8arr[n - 1] = bstr.charCodeAt(n - 1);
          n -= 1; // to make eslint happy
        }
        return new File([u8arr], filename, { type: mime });
      };

      // put file into form data
      const data = new FormData();
      data.append(
        "image",
        dataURLtoFile(
          croppedImage.getCroppedCanvas().toDataURL(),
          currentFileName
        )
      );
      if (selectFor === "company") {
        // Call uploadCompanyImage api
        new Promise(() => {
          const data1: any = dispatch(
            uploadCompanyImage({
              companyId: auth.company.id,
              payload: data,
            })
          );
          data1.then(function (value) {
            if (value.type === "auth/uploadCompanyImage/fulfilled") {
              toastr.success("", "Image uploaded successfully");
              getUserDetails();
            }
          });
        });
      } else if (selectFor === "profile") {
        // Call uploadUserImage api
        new Promise(() => {
          const data2: any = dispatch(
            uploadUserImage({
              userId: auth.user.id,
              payload: data,
            })
          );
          data2.then(function (value) {
            if (value.type === "auth/uploadUserImage/fulfilled") {
              toastr.success("", "Image uploaded successfully");
              getUserDetails();
            }
          });
        });
      }
      onCloseCropper();
    }
  };

  // Call userDetailbyId api
  function getUserDetails() {
    dispatch(userDetailById({ userId: auth.user.id }));
  }

  useEffect(() => {
    if (auth.user && auth.company) {
      userUpdateSetValues({
        ...userUpdateValues,
        ...fillFormValues(USER_UPDATE_FORM.default, auth.user, ["id"]),
      });
      companySetValues({
        ...companyValues,
        ...fillFormValues(COMPANY_UPDATE_FORM.default, auth.company, ["id"]),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.company, auth.user]);

  const managePasswordSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();

    if (Object.keys(managePassTouched).length <= 0) {
      toastr.warning("", "Please first fill the form");
      return;
    }

    if (Object.keys(managePassErrors).length > 0) {
      toastr.warning("", managePassErrors[Object.keys(managePassErrors)[0]]);
      return;
    }

    // Call updatePassword api
    new Promise(() => {
      const data3: any = dispatch(
        updatePassword({
          userId: auth.user.id,
          payload: {
            newpassword: managePassValues.password,
            currentpassword: managePassValues.oldPassword,
          },
        })
      );
      data3.then(function (value) {
        if (value.type === "auth/updatePassword/fulfilled") {
          onCloseReset();
          managePassReset();
          toastr.success("", "Password updated successfully");
        }
      });
    });
  };

  const userUpdatedSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();

    if (Object.keys(userUpdateTouched).length <= 0) {
      toastr.warning("", "Please first fill the form");
      return;
    }

    if (Object.keys(userUpdateErrors).length > 0) {
      toastr.warning("", userUpdateErrors[Object.keys(userUpdateErrors)[0]]);
      return;
    }

    // Call profileUpdate api
    new Promise(() => {
      const data4: any = dispatch(
        updateUser({
          userId: auth.user.id,
          payload: Object.fromEntries(
            Object.entries(userUpdateValues).filter(([_, v]) => v !== "")
          ),
        })
      );
      data4.then(function (value) {
        if (value.type === "auth/updateUser/fulfilled") {
          getUserDetails();
          userUpdateReset();
          toastr.success("", "Profile updated successfully");
        }
      });
    });
  };

  const editorUpdated = (
    _content: any,
    _delta: any,
    _source: any,
    editor: any
  ) => {
    const html = editor.getHTML();
    setAgreementDesc(html);
    setAgreementUpdated(true);
  };

  function checkSpace(fieldName: string) {
    const strippedFieldName = fieldName
      .replace(/<\/?[^>]+(>|$)/g, "")
      .replace(/&nbsp;/g, " ")
      .trim();

    if (strippedFieldName === "") {
      return "";
    }

    return fieldName;
  }

  const submitAgreement = () => {
    if (isAgreementUpdate.status === true) {
      if (!agreementUpdated) {
        toastr.warning("", "Change the values to Update the agreement");
        return;
      }
      if (checkSpace(agreementTitle) === "") {
        toastr.warning("", "Agreement title cannot be empty");
        return;
      }
      if (checkSpace(agreementDesc) === "") {
        toastr.warning("", "Agreement description cannot be empty");
        return;
      }
      // Call updateAggrement api
      new Promise(() => {
        const data5: any = dispatch(
          updateAggrement({
            aggrementId: isAgreementUpdate.id,
            payload: {
              title: agreementTitle,
              description: agreementDesc,
            },
          })
        );
        data5.then(function (value) {
          if (value.type === "auth/updateAggrement/fulfilled") {
            setAgreementDesc("");
            setAgreementTitle("");
            setShowAgreementForm(!showAgreementForm);
            setIsAgreementUpdate({ status: false, id: null });
          }
        });
      });
    } else {
      if (!(agreementTitle !== "" && agreementDesc !== "")) {
        toastr.warning("", "Add all the values to save the agreement");
        return;
      }
      if (checkSpace(agreementTitle) === "") {
        toastr.warning("", "Agreement title cannot be empty");
        return;
      }
      if (checkSpace(agreementDesc) === "") {
        toastr.warning("", "Agreement description cannot be empty");
        return;
      }
      // Call createAggrement api
      new Promise(() => {
        const data6: any = dispatch(
          createAggrement({
            payload: {
              userId: auth.user.id,
              title: agreementTitle,
              description: agreementDesc,
            },
          })
        );
        data6.then(function (value) {
          if (value.type === "auth/createAggrement/fulfilled") {
            setAgreementDesc("");
            setAgreementTitle("");
            setShowAgreementForm(!showAgreementForm);
          }
        });
      });
    }
    setAgreementUpdated(false);
    if (report?.reportAgreement) {
      navigate(`/edit-report/${report?.editReport?.report?.id}`);
      dispatch(setReportAgreement(0));
    }
  };

  const closeAgreementForm = () => {
    setAgreementDesc("");
    setAgreementTitle("");
    setShowAgreementForm(!showAgreementForm);
    setIsAgreementUpdate({ status: false, id: null });
  };

  const handleUpdateAggrement = (agreement: any) => {
    setAgreementTitle(agreement.title);
    setAgreementDesc(agreement.description);
    setShowAgreementForm(!showAgreementForm);
    setIsAgreementUpdate({ status: true, id: agreement.id });
  };

  const handleViewAggrement = (agreement: any) => {
    setSelectedAgreement(agreement);
    setOpenAgreementModel(true);
  };

  const submitPolicy = () => {
    if (
      Object.keys(policyTouched).length <= 0 ||
      !policyValues.title ||
      !policyValues.description
    ) {
      toastr.warning("", "Please first fill the form");
      return;
    }
    if (Object.keys(policyErrors).length > 0) {
      toastr.warning("", policyErrors[Object.keys(policyErrors)[0]]);
      return;
    }
    setEditPolicy(false);
    new Promise(() => {
      const data7: any = dispatch(
        createCompanyPolicy({
          payload: {
            title: policyValues.title,
            description: policyValues.description,
          },
        })
      );
      data7.then(function (value) {
        if (value.type === "superadmin/create/termsCondition/fulfilled") {
          closeCompanyPolicyDetails();
          toastr.success("", "Policy added successfully");
        }
      });
    });
  };

  const updatePolicy = () => {
    new Promise(() => {
      const data8: any = dispatch(
        updateCompanyPolicy({
          policyId: policyId,
          payload: { ...policyValues },
        })
      );
      data8.then(function (value) {
        if (value.type === "superadmin/update/termsCondition/fulfilled") {
          closeCompanyPolicyDetails();
          setEditPolicy(false);
          toastr.success("", "Policy updated successfully");
        }
      });
    });
  };

  const companyUpdatedSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();

    if (Object.keys(companyTouched).length <= 0) {
      toastr.warning("", "Please first update the form");
      return;
    }

    if (Object.keys(companyErrors).length > 0) {
      toastr.warning("", companyErrors[Object.keys(companyErrors)[0]]);
      return;
    }
    if (
      !companyValues.companyAddress ||
      !companyValues.city ||
      !companyValues.state ||
      !companyValues.zipcode
    ) {
      toastr.warning("", "Please first fill the Address");
      return;
    }
    const address =
      companyValues.companyAddress.toLowerCase() +
      " " +
      companyValues.city.toLowerCase() +
      " " +
      companyValues.state.toLowerCase() +
      " " +
      companyValues.zipcode;
    var geocoder = new google.maps.Geocoder();
    geocoder.geocode({ address: address }, (results, status) => {
      if (status === "OK") {
        // Call profileUpdate api
        new Promise(() => {
          const data9: any = dispatch(
            companyProfileUpdate({
              companyId: auth.user.companyDetailId,
              payload: Object.fromEntries(
                Object.entries(companyValues).filter(([_, v]) => v !== "")
              ),
            })
          );
          data9.then(function (value) {
            if (value.type === "auth/companyProfileUpdate/fulfilled") {
              companyReset();
              getUserDetails();
              toastr.success("", "Company profile saved successfully");
            }
          });
        });
      } else {
        toastr.error("", "Please enter a valid address");
        return;
      }
    });
  };

  const handleEditPolicyModal = (policy: any, policyDetailsId: string) => {
    setEditPolicy(true);
    setPolicyId(policyDetailsId);
    setPolicyValues({ title: policy.title, description: policy.description });
    openCompanyPolicyDetails();
  };

  const addPolicyModal = () => {
    setEditPolicy(false);
    setPolicyValues(
      fillFormValues(COMPANY_POLICY_FORM.default, COMPANY_UPDATE_FORM.default, [
        "id",
      ])
    );
    openCompanyPolicyDetails();
  };

  const handleDeletePolicy = () => {
    new Promise(() => {
      const data10: any = dispatch(deletePolicy({ paramId: policyId }));
      data10.then(function (value) {
        if (value.type === "superadmin/delete/termsCondition/fulfilled") {
          closeDeleteConfirmation(); // Close Confirmation Modal
          toastr.success("", "Policy deleted successfully");
        }
      });
    });
  };
  const handleDeletePolicyModal = (policyDetailsId: string) => {
    setPolicyId(policyDetailsId);
    openDeleteConfirmation();
  };

  useEffect(() => {
    if (auth?.error) {
      const { message } = auth.error;
      toastr.error("", message ? message : "");
    }
    return () => {
      dispatch(setAuthError(null));
    };
  }, [auth.error, dispatch]);
  useEffect(() => {
    if (location.pathname !== "/settings") {
      dispatch(setReportAgreement(0));
    }
  });

  useEffect(() => {
    dispatch(getCompanyPolicy());
  }, [dispatch]);

  useEffect(() => {
    setCompanyPolicyData(superAdmin?.companyPolicy);
  }, [superAdmin?.companyPolicy, companyPolicyData]);

  return (
    <>
      {(auth.loginLoading.length !== 0 || superAdmin.loading.length !== 0) && (
        <Loader />
      )}
      <Box
        p="29px 30px 20px 20px"
        h="calc(100vh - 70px)"
        overflow="auto"
        w="100%"
      >
        <Text
          as="h1"
          p="0 10px"
          mb={{ sm: "16px", xl: "26px" }}
          color="black.lighten"
          fontSize="24px"
          lineHeight="30px"
          fontWeight="600"
        >
          Settings
        </Text>
        <Box
          bg="white"
          borderRadius="4px"
          p={{ sm: "19px 0", xxl: "29px 0" }}
          minH="calc(100vh - 175px)"
        >
          <Tabs
            defaultIndex={report?.reportAgreement}
            className="tab-with-icons"
          >
            <TabList borderColor="gray.light3" borderBottomWidth="1px">
              <Tab
                _selected={{ color: "black.lighten" }}
                border="none"
                p="19px 17px"
              >
                <Text
                  fontSize={{ sm: "18px", xxl: "21px" }}
                  lineHeight={{ sm: "24px", xxl: "26px" }}
                  color="black.lighten"
                >
                  Company Policy
                </Text>
              </Tab>
              <Tab
                _selected={{ color: "black.lighten" }}
                border="none"
                p="19px 17px"
              >
                <Text
                  fontSize={{ sm: "18px", xxl: "21px" }}
                  lineHeight={{ sm: "24px", xxl: "26px" }}
                  color="black.lighten"
                >
                  Agreements
                </Text>
              </Tab>
              <Tab
                _selected={{ color: "black.lighten" }}
                border="none"
                p="19px 17px"
              >
                <Text
                  fontSize={{ sm: "18px", xxl: "21px" }}
                  lineHeight={{ sm: "24px", xxl: "26px" }}
                  color="black.lighten"
                >
                  Profile Settings
                </Text>
              </Tab>
            </TabList>

            <TabPanels>
              <TabPanel p="13px 21px">
                <Flex
                  alignItems="center"
                  mb="20px"
                  justifyContent="space-between"
                >
                  <Text fontSize="18px" color="gray.primary" mb="10px">
                    Aurora takes your privacy seriously. Please read the
                    following to learn more about our privacy policy.
                  </Text>
                  <Button
                    variant="primarySmall"
                    cursor="pointer"
                    onClick={() => addPolicyModal()}
                  >
                    Add Policy
                  </Button>
                </Flex>
                {companyPolicyData?.map((policy, index) => {
                  return (
                    <Box key={index}>
                      <Flex
                        justifyContent="space-between"
                        cursor="pointer"
                        color="gray.primary"
                        alignItems="center"
                      >
                        <Text
                          fontSize="21px"
                          textAlign="justify"
                          color="black.primary"
                        >
                          {policy.title}
                        </Text>
                        <Box ml="20px">
                          <Icon
                            as={MdModeEdit}
                            cursor="pointer"
                            mr="10px"
                            onClick={() =>
                              handleEditPolicyModal(policy, policy.id)
                            }
                          ></Icon>
                          <Icon
                            as={MdDelete}
                            cursor="pointer"
                            onClick={() => handleDeletePolicyModal(policy.id)}
                          ></Icon>
                        </Box>
                      </Flex>
                      <Text
                        fontSize="18px"
                        textAlign="justify"
                        color="gray.primary"
                        mb="10px"
                      >
                        {policy.description}
                      </Text>
                    </Box>
                  );
                })}
              </TabPanel>
              <TabPanel p="13px 21px">
                {showAgreementForm ? (
                  <Box maxW="1162px">
                    <Box d="inline-flex">
                      <Text
                        as="h2"
                        mb={{ sm: "22px", lg: "32px" }}
                        color="black.lighten"
                        fontSize={{ sm: "22px", xxl: "24px" }}
                        lineHeight="30px"
                        fontWeight="600"
                      >
                        Add Agreements
                      </Text>
                    </Box>
                    <Box>
                      <Box
                        d="flex"
                        flexDirection="row"
                        flexWrap="wrap"
                        mb="29px"
                      >
                        <Text
                          as="label"
                          fontSize={{ sm: "16px", xxl: "xxl" }}
                          pr="10px"
                          mb={{ sm: "4px", xxl: "0" }}
                          lineHeight="22px"
                          color="black.lighten"
                          w="100%"
                        >
                          Title
                        </Text>
                        <Input
                          variant="Bordered"
                          type="text"
                          placeholder="Add title"
                          name="title"
                          value={agreementTitle}
                          onChange={(e) => {
                            setAgreementTitle(e.target.value);
                            setAgreementUpdated(true);
                          }}
                        />
                      </Box>
                      <Box d="flex" flexDirection="row" flexWrap="wrap">
                        <Text
                          as="label"
                          fontSize={{ sm: "16px", xxl: "xxl" }}
                          pr="10px"
                          mb={{ sm: "6px !important", xxl: "0" }}
                          lineHeight="22px"
                          color="black.lighten"
                          w="100%"
                        >
                          Agreement
                        </Text>
                        <TextEditor
                          value={agreementDesc}
                          onChange={editorUpdated}
                        />
                      </Box>
                    </Box>
                    <Box mt={{ sm: "37px", xxl: "47px" }}>
                      <Button
                        w="90px"
                        mr="15px"
                        variant="primary2"
                        onClick={submitAgreement}
                      >
                        {isAgreementUpdate.status ? "Update" : "Add"}
                      </Button>
                      <Button
                        w="90px"
                        variant="outlined"
                        onClick={closeAgreementForm}
                      >
                        Back
                      </Button>
                    </Box>
                  </Box>
                ) : (
                  <>
                    <Flex
                      alignItems="center"
                      mb="30px"
                      justifyContent="space-between"
                    >
                      <Text
                        as="h2"
                        color="black.lighten"
                        fontSize="24px"
                        lineHeight="30px"
                        fontWeight="600"
                      >
                        Agreements
                      </Text>
                      <Button
                        variant="primary2"
                        ml="20px"
                        onClick={() => setShowAgreementForm(!showAgreementForm)}
                      >
                        Create Agreement
                      </Button>
                    </Flex>
                    <Box display="flex" flexDirection="column">
                      {auth?.agreements?.map((x: any) => {
                        return (
                          <Box
                            key={x.id}
                            display="inline-flex"
                            mb="20px"
                            alignItems="center"
                          >
                            <Box mr="14px">
                              <Text
                                fontSize="xxl"
                                lineHeight="22px"
                                color="black.lighten"
                                mr="20px"
                                w="100%"
                                whiteSpace="pre-wrap"
                              >
                                {x.title}
                              </Text>
                            </Box>
                            <Box ml="auto" display="inline-flex">
                              <Icon
                                cursor="pointer"
                                fontSize="22px"
                                mr="15px"
                                color="gray.primary"
                                as={IoMdEye}
                                onClick={() => handleViewAggrement(x)}
                              ></Icon>
                              <Icon
                                cursor="pointer"
                                color="gray.primary"
                                fontSize="18px"
                                as={MdModeEdit}
                                onClick={() => handleUpdateAggrement(x)}
                              ></Icon>
                            </Box>
                          </Box>
                        );
                      })}
                    </Box>
                  </>
                )}
              </TabPanel>
              <TabPanel p="13px 21px">
                <Box
                  border="1px solid"
                  borderColor="white.lighten2"
                  h="calc(100% - 50px)"
                >
                  <Tabs className="tab-with-icons">
                    <TabList borderColor="gray.light3" borderBottomWidth="1px">
                      <Tab
                        _selected={{ color: "black.lighten" }}
                        border="none"
                        p="19px 17px"
                      >
                        <Text
                          fontSize={{ sm: "16px", xxl: "xxl" }}
                          lineHeight={{ sm: "20px", xxl: "22px" }}
                          color="black.lighten"
                        >
                          Company Profile
                        </Text>
                      </Tab>
                      <Tab
                        _selected={{ color: "black.lighten" }}
                        border="none"
                        p="19px 17px"
                      >
                        <Text
                          fontSize={{ sm: "16px", xxl: "xxl" }}
                          lineHeight={{ sm: "20px", xxl: "22px" }}
                          color="black.lighten"
                        >
                          User Profile
                        </Text>
                      </Tab>
                    </TabList>
                    <TabPanels>
                      <TabPanel p={{ sm: "20px", xxl: "30px" }}>
                        <Box w="100%" maxW="991px">
                          <SimpleGrid spacing="20px 0">
                            <SimpleGrid
                              columns={2}
                              spacing={{ sm: "0 30px", xxl: "0 60px" }}
                            >
                              <Box
                                d="inline-flex"
                                alignItems="center"
                                justifyContent="space-between"
                                flexWrap="wrap"
                                alignContent="flex-start"
                              >
                                <Text
                                  as="label"
                                  fontSize={{ sm: "16px", xxl: "xxl" }}
                                  pr="10px"
                                  mb={{ sm: "4px", xxl: "0" }}
                                  lineHeight="22px"
                                  w={{ sm: "100%", xxl: "auto" }}
                                  color="black.lighten"
                                >
                                  Company Logo
                                </Text>
                                <Box maxW="300px" w="100%">
                                  <Box
                                    position="relative"
                                    display="inline-flex"
                                  >
                                    <Box
                                      w="147px"
                                      h="147px"
                                      borderRadius="50%"
                                      overflow="hidden"
                                      display="inline-flex"
                                      alignItems="center"
                                      justifyContent="center"
                                      bg="black.lighten"
                                      border="3px solid"
                                      borderColor="blue.dark"
                                    >
                                      <Avatar
                                        name={companyValues.companyName}
                                        src={auth?.company?.accessProfileUrl}
                                        h="100%"
                                        w="100%"
                                        bg="transparent"
                                        css={css({
                                          ".chakra-avatar__initials": {
                                            fontSize: "38px",
                                            color: "white",
                                          },
                                          ".chakra-avatar__img": {
                                            borderRadius: "0",
                                            width: "auto",
                                            height: "auto",
                                            maxHeight: "100%",
                                          },
                                        })}
                                      />
                                    </Box>
                                    <Box
                                      top="15px"
                                      right="4px"
                                      position="absolute"
                                      width="25px"
                                      height="25px"
                                      borderRadius="50%"
                                      display="inline-flex"
                                      alignItems="center"
                                      justifyContent="center"
                                      bg="blue.dark"
                                      cursor="pointer"
                                    >
                                      <label>
                                        <Icon
                                          cursor="pointer"
                                          fontSize="15px"
                                          color="white"
                                          as={MdModeEdit}
                                        ></Icon>
                                        <Input
                                          type="file"
                                          accept=".jpeg, .jpg, .png"
                                          onChange={fileChange}
                                          name="company"
                                          w="0"
                                          h="0"
                                          border="none"
                                          outline="none"
                                          p="0"
                                          _focus={{ outline: "none" }}
                                        />
                                      </label>
                                    </Box>
                                  </Box>
                                </Box>
                              </Box>
                              <Box>
                                <Box
                                  justifyContent="space-between"
                                  d="flex"
                                  flexWrap="wrap"
                                  mb="20px"
                                >
                                  <Text
                                    as="label"
                                    fontSize={{ sm: "16px", xxl: "xxl" }}
                                    pr="10px"
                                    mb={{ sm: "4px", xxl: "0" }}
                                    lineHeight="22px"
                                    w={{ sm: "100%", xxl: "auto" }}
                                    color="black.lighten"
                                  >
                                    Name
                                  </Text>
                                  <Input
                                    variant="Bordered"
                                    type="text"
                                    maxW={{ sm: "unset", xxl: "300px" }}
                                    placeholder="Inspection Company Name"
                                    name="companyName"
                                    value={companyValues.companyName}
                                    onChange={companyChange}
                                  />
                                </Box>
                                <Box
                                  justifyContent="space-between"
                                  d="flex"
                                  flexWrap="wrap"
                                  mb="20px"
                                >
                                  <Text
                                    as="label"
                                    fontSize={{ sm: "16px", xxl: "xxl" }}
                                    pr="10px"
                                    mb={{ sm: "4px", xxl: "0" }}
                                    lineHeight="22px"
                                    w={{ sm: "100%", xxl: "auto" }}
                                    color="black.lighten"
                                    data-disabled
                                  >
                                    Email
                                  </Text>
                                  <Input
                                    maxW={{ sm: "unset", xxl: "300px" }}
                                    variant="Bordered"
                                    type="text"
                                    placeholder="inspectaurora@gmail.com"
                                    name="email"
                                    value={companyValues.email}
                                    onChange={companyChange}
                                  />
                                </Box>
                                <Box
                                  justifyContent="space-between"
                                  d="flex"
                                  flexWrap="wrap"
                                >
                                  <Text
                                    as="label"
                                    fontSize={{ sm: "16px", xxl: "xxl" }}
                                    pr="10px"
                                    mb={{ sm: "4px", xxl: "0" }}
                                    lineHeight="22px"
                                    w={{ sm: "100%", xxl: "auto" }}
                                    color="black.lighten"
                                  >
                                    Website
                                  </Text>
                                  <Input
                                    variant="Bordered"
                                    type="text"
                                    maxW={{ sm: "unset", xxl: "300px" }}
                                    placeholder="www.inspectwithaurora.com"
                                    name="website"
                                    value={companyValues.website}
                                    onChange={companyChange}
                                  />
                                </Box>
                              </Box>
                            </SimpleGrid>
                            <SimpleGrid
                              columns={2}
                              spacing={{ sm: "0 30px", xxl: "0 60px" }}
                            >
                              <FormControl
                                isRequired
                                display="flex"
                                alignItems="center"
                                justifyContent="space-between"
                                flexWrap={{ sm: "wrap", xxl: "nowrap" }}
                              >
                                <FormLabel
                                  as="label"
                                  fontSize={{ sm: "16px", xxl: "xxl" }}
                                  pr="10px"
                                  mb={{ sm: "4px", xxl: "0" }}
                                  lineHeight="22px"
                                  w={{ sm: "100%", xxl: "auto" }}
                                  color="black.lighten"
                                >
                                  Address
                                </FormLabel>
                                <Input
                                  id="autocomplete"
                                  autoComplete="off"
                                  variant="Bordered"
                                  maxW={{ sm: "unset", xxl: "300px" }}
                                  placeholder="1016 White Dove Cir Stockbridge, Georgia(GA), 30281"
                                  name="companyAddress"
                                  value={companyValues.companyAddress}
                                  onChange={companyChange}
                                />
                              </FormControl>
                              <FormControl
                                isRequired
                                display="inline-flex"
                                alignItems="center"
                                justifyContent="space-between"
                                flexWrap={{ sm: "wrap", xxl: "nowrap" }}
                              >
                                <FormLabel
                                  as="label"
                                  fontSize={{ sm: "16px", xxl: "xxl" }}
                                  pr="10px"
                                  mb={{ sm: "4px", xxl: "0" }}
                                  lineHeight="22px"
                                  w={{ sm: "100%", xxl: "auto" }}
                                  color="black.lighten"
                                >
                                  State
                                </FormLabel>
                                <Input
                                  variant="Bordered"
                                  type="text"
                                  maxW={{ sm: "unset", xxl: "300px" }}
                                  placeholder="Type State Name"
                                  name="state"
                                  value={companyValues.state}
                                  onChange={companyChange}
                                />
                              </FormControl>
                            </SimpleGrid>
                            <SimpleGrid
                              columns={2}
                              spacing={{ sm: "0 30px", xxl: "0 60px" }}
                            >
                              <FormControl
                                isRequired
                                display="flex"
                                alignItems="center"
                                justifyContent="space-between"
                                flexWrap={{ sm: "wrap", xxl: "nowrap" }}
                              >
                                <FormLabel
                                  as="label"
                                  fontSize={{ sm: "16px", xxl: "xxl" }}
                                  pr="10px"
                                  mb={{ sm: "4px", xxl: "0" }}
                                  lineHeight="22px"
                                  w={{ sm: "100%", xxl: "auto" }}
                                  color="black.lighten"
                                >
                                  City
                                </FormLabel>
                                <Input
                                  variant="Bordered"
                                  type="text"
                                  maxW={{ sm: "unset", xxl: "300px" }}
                                  placeholder="Type City Name"
                                  name="city"
                                  value={companyValues.city}
                                  onChange={companyChange}
                                />
                              </FormControl>
                              <FormControl
                                isRequired
                                d="inline-flex"
                                alignItems="center"
                                justifyContent="space-between"
                                flexWrap={{ sm: "wrap", xxl: "nowrap" }}
                              >
                                <FormLabel
                                  as="label"
                                  fontSize={{ sm: "16px", xxl: "xxl" }}
                                  pr="10px"
                                  mb={{ sm: "4px", xxl: "0" }}
                                  lineHeight="22px"
                                  w={{ sm: "100%", xxl: "auto" }}
                                  color="black.lighten"
                                >
                                  Zip
                                </FormLabel>
                                <Input
                                  variant="Bordered"
                                  type="text"
                                  maxW={{ sm: "unset", xxl: "300px" }}
                                  placeholder="Type Zip Code"
                                  name="zipcode"
                                  value={companyValues.zipcode}
                                  onChange={companyChange}
                                />
                              </FormControl>
                            </SimpleGrid>
                          </SimpleGrid>
                          <Box d="flex" justifyContent="flex-end" mt="38px">
                            {!!Object.keys(companyTouched).length && (
                              <Button
                                variant="primary2"
                                onClick={companyUpdatedSubmit}
                              >
                                Save Profile
                              </Button>
                            )}
                          </Box>
                        </Box>
                      </TabPanel>
                      <TabPanel p={{ sm: "20px", xxl: "30px" }}>
                        <Box w="100%" maxW="991px">
                          <SimpleGrid spacing="20px 0">
                            <SimpleGrid
                              columns={2}
                              spacing={{ sm: "0 30px", xxl: "0 60px" }}
                            >
                              <Box
                                justifyContent="space-between"
                                d="flex"
                                flexWrap="wrap"
                                alignItems="center"
                              >
                                <Text
                                  as="label"
                                  fontSize={{ sm: "16px", xxl: "xxl" }}
                                  pr="10px"
                                  mb={{ sm: "4px", xxl: "0" }}
                                  lineHeight="22px"
                                  w={{ sm: "100%", xxl: "auto" }}
                                  color="black.lighten"
                                >
                                  User Image
                                </Text>
                                <Box w="300px">
                                  <Box position="relative" d="inline-flex">
                                    <Box
                                      w="147px"
                                      h="147px"
                                      borderRadius="50%"
                                      overflow="hidden"
                                      d="inline-flex"
                                      alignItems="center"
                                      justifyContent="center"
                                      bg="black.lighten"
                                      border="3px solid"
                                      borderColor="blue.dark"
                                    >
                                      <Avatar
                                        name={`${auth?.user?.firstName} ${auth?.user?.lastName}`}
                                        src={auth?.user?.AccessProfileUrl}
                                        h="100%"
                                        w="100%"
                                        bg="transparent"
                                        css={css({
                                          ".chakra-avatar__initials": {
                                            fontSize: "38px",
                                            color: "white",
                                          },
                                          ".chakra-avatar__img": {
                                            borderRadius: "0",
                                            width: "auto",
                                            height: "auto",
                                            maxHeight: "100%",
                                          },
                                        })}
                                      />
                                    </Box>
                                    <Box
                                      top="15px"
                                      right="4px"
                                      position="absolute"
                                      width="25px"
                                      height="25px"
                                      borderRadius="50%"
                                      display="inline-flex"
                                      alignItems="center"
                                      justifyContent="center"
                                      bg="blue.dark"
                                      cursor="pointer"
                                    >
                                      <label>
                                        <Icon
                                          cursor="pointer"
                                          fontSize="15px"
                                          color="white"
                                          as={MdModeEdit}
                                        ></Icon>
                                        <Input
                                          type="file"
                                          accept=".jpeg, .jpg, .png"
                                          onChange={fileChange}
                                          name="profile"
                                          w="0"
                                          h="0"
                                          border="none"
                                          outline="none"
                                          p="0"
                                          _focus={{ outline: "none" }}
                                        />
                                      </label>
                                    </Box>
                                  </Box>
                                </Box>
                              </Box>
                            </SimpleGrid>
                            <SimpleGrid spacing="20px 0">
                              <SimpleGrid
                                columns={2}
                                spacing={{ sm: "0 30px", xxl: "0 60px" }}
                              >
                                <Box
                                  justifyContent="space-between"
                                  d="flex"
                                  flexWrap="wrap"
                                >
                                  <Text
                                    as="label"
                                    fontSize={{ sm: "16px", xxl: "xxl" }}
                                    pr="10px"
                                    mb={{ sm: "4px", xxl: "0" }}
                                    lineHeight="22px"
                                    w={{ sm: "100%", xxl: "auto" }}
                                    color="black.lighten"
                                    data-disabled
                                  >
                                    First Name
                                  </Text>
                                  <Input
                                    maxW={{ sm: "unset", xxl: "300px" }}
                                    isDisabled
                                    variant="Bordered"
                                    type="text"
                                    placeholder="Denwik"
                                    name="firstName"
                                    value={userUpdateValues.firstName}
                                    onChange={userUpdateChange}
                                  />
                                </Box>
                                <Box
                                  justifyContent="space-between"
                                  d="flex"
                                  flexWrap="wrap"
                                >
                                  <Text
                                    as="label"
                                    fontSize={{ sm: "16px", xxl: "xxl" }}
                                    pr="10px"
                                    mb={{ sm: "4px", xxl: "0" }}
                                    lineHeight="22px"
                                    w={{ sm: "100%", xxl: "auto" }}
                                    color="black.lighten"
                                    data-disabled
                                  >
                                    Last Name
                                  </Text>
                                  <Input
                                    maxW={{ sm: "unset", xxl: "300px" }}
                                    isDisabled
                                    variant="Bordered"
                                    type="text"
                                    placeholder="M"
                                    name="lastName"
                                    value={userUpdateValues.lastName}
                                    onChange={userUpdateChange}
                                  />
                                </Box>
                              </SimpleGrid>
                              <SimpleGrid
                                columns={2}
                                spacing={{ sm: "0 30px", xxl: "0 60px" }}
                              >
                                <Box
                                  justifyContent="space-between"
                                  d="flex"
                                  flexWrap="wrap"
                                >
                                  <Text
                                    as="label"
                                    fontSize={{ sm: "16px", xxl: "xxl" }}
                                    pr="10px"
                                    mb={{ sm: "4px", xxl: "0" }}
                                    lineHeight="22px"
                                    w={{ sm: "100%", xxl: "auto" }}
                                    color="black.lighten"
                                    data-disabled
                                  >
                                    Phone
                                  </Text>
                                  <Input
                                    maxW={{ sm: "unset", xxl: "300px" }}
                                    isDisabled
                                    variant="Bordered"
                                    type="text"
                                    placeholder="+91 25875 62456"
                                    name="phone"
                                    value={userUpdateValues.phone}
                                    onChange={userUpdateChange}
                                  />
                                </Box>
                                <Box
                                  justifyContent="space-between"
                                  d="flex"
                                  flexWrap="wrap"
                                >
                                  <Text
                                    as="label"
                                    fontSize={{ sm: "16px", xxl: "xxl" }}
                                    pr="10px"
                                    mb={{ sm: "4px", xxl: "0" }}
                                    lineHeight="22px"
                                    w={{ sm: "100%", xxl: "auto" }}
                                    color="black.lighten"
                                    data-disabled
                                  >
                                    Email
                                  </Text>
                                  <Input
                                    maxW={{ sm: "unset", xxl: "300px" }}
                                    isDisabled
                                    variant="Bordered"
                                    type="text"
                                    placeholder="inspectaurora@gmail.com"
                                    name="email"
                                    value={userUpdateValues.email}
                                    onChange={userUpdateChange}
                                  />
                                </Box>
                              </SimpleGrid>
                              <SimpleGrid
                                columns={2}
                                spacing={{ sm: "0 30px", xxl: "0 60px" }}
                              >
                                <Box
                                  justifyContent="space-between"
                                  d="flex"
                                  flexWrap="wrap"
                                >
                                  <Text
                                    as="label"
                                    fontSize={{ sm: "16px", xxl: "xxl" }}
                                    pr="10px"
                                    mb={{ sm: "4px", xxl: "0" }}
                                    lineHeight="22px"
                                    w={{ sm: "100%", xxl: "auto" }}
                                    color="black.lighten"
                                  >
                                    Address
                                  </Text>
                                  <Textarea
                                    maxW={{ sm: "unset", xxl: "300px" }}
                                    variant="Bordered"
                                    placeholder="1016 White Dove Cir Stockbridge, Georgia(GA), 30281"
                                    name="address"
                                    value={userUpdateValues.address}
                                    onChange={userUpdateChange}
                                  ></Textarea>
                                </Box>
                                <Box
                                  justifyContent="space-between"
                                  d="flex"
                                  flexWrap="wrap"
                                  alignContent="flex-start"
                                >
                                  <Text
                                    as="label"
                                    fontSize={{ sm: "16px", xxl: "xxl" }}
                                    pr="10px"
                                    mb={{ sm: "4px", xxl: "0" }}
                                    lineHeight="22px"
                                    w={{ sm: "100%", xxl: "auto" }}
                                    color="black.lighten"
                                  >
                                    State
                                  </Text>
                                  <Input
                                    maxW={{ sm: "unset", xxl: "300px" }}
                                    variant="Bordered"
                                    type="text"
                                    placeholder="Type State Name"
                                    name="state"
                                    value={userUpdateValues.state}
                                    onChange={userUpdateChange}
                                  />
                                </Box>
                              </SimpleGrid>
                              <SimpleGrid
                                columns={2}
                                spacing={{ sm: "0 30px", xxl: "0 60px" }}
                              >
                                <Box
                                  justifyContent="space-between"
                                  d="flex"
                                  flexWrap="wrap"
                                  alignContent="flex-start"
                                >
                                  <Text
                                    as="label"
                                    fontSize={{ sm: "16px", xxl: "xxl" }}
                                    pr="10px"
                                    mb={{ sm: "4px", xxl: "0" }}
                                    lineHeight="22px"
                                    w={{ sm: "100%", xxl: "auto" }}
                                    color="black.lighten"
                                  >
                                    Certifications
                                  </Text>
                                  <Input
                                    maxW={{ sm: "unset", xxl: "300px" }}
                                    variant="Bordered"
                                    type="text"
                                    placeholder="Type Certificates Name"
                                    name="user_Certificate"
                                    value={userUpdateValues.user_Certificate}
                                    onChange={userUpdateChange}
                                  />
                                </Box>
                                <Box
                                  justifyContent="space-between"
                                  d="flex"
                                  flexWrap="wrap"
                                  alignContent="flex-start"
                                >
                                  <Text
                                    as="label"
                                    fontSize={{ sm: "16px", xxl: "xxl" }}
                                    pr="10px"
                                    mb={{ sm: "4px", xxl: "0" }}
                                    lineHeight="22px"
                                    w={{ sm: "100%", xxl: "auto" }}
                                    color="black.lighten"
                                  >
                                    Licenses
                                  </Text>
                                  <Input
                                    maxW={{ sm: "unset", xxl: "300px" }}
                                    variant="Bordered"
                                    type="text"
                                    placeholder="Type License Name"
                                    name="user_LicenceNumber"
                                    value={userUpdateValues.user_LicenceNumber}
                                    onChange={userUpdateChange}
                                  />
                                </Box>
                              </SimpleGrid>
                            </SimpleGrid>
                            <Box
                              d="flex"
                              justifyContent="space-between"
                              mt="38px"
                              alignItems="center"
                            >
                              <Text
                                fontSize={{ sm: "16px", xxl: "xxl" }}
                                lineHeight="22px"
                                cursor="pointer"
                                textDecoration="underline"
                                color="blue.light4"
                                as="span"
                                onClick={onOpenReset}
                              >
                                Change Password
                              </Text>
                              {!!Object.keys(userUpdateTouched).length && (
                                <Button
                                  variant="primary2"
                                  onClick={userUpdatedSubmit}
                                >
                                  Save Profile
                                </Button>
                              )}
                            </Box>
                          </SimpleGrid>
                        </Box>
                      </TabPanel>
                    </TabPanels>
                  </Tabs>
                </Box>
                <Modal
                  isOpen={isOpenReset}
                  onClose={onCloseReset}
                  size={"lg"}
                  scrollBehavior="inside"
                >
                  <ModalOverlay />
                  <ModalContent>
                    <ModalHeader
                      p="25px 28px"
                      fontSize="21px"
                      lineHeight="26px"
                      color="black.lighten"
                      fontWeight="600"
                    >
                      Change Password
                    </ModalHeader>
                    <Icon
                      color="black.primary"
                      fontSize="24px"
                      as={IoMdClose}
                      onClick={onCloseReset}
                      cursor="pointer"
                      position="absolute"
                      top="29px"
                      right="29px"
                    ></Icon>
                    <ModalBody p="0 28px">
                      <Box
                        justifyContent="space-between"
                        d="flex"
                        flexWrap="wrap"
                        mb="15px"
                      >
                        <Text
                          as="label"
                          fontSize={{ sm: "16px", xxl: "xxl" }}
                          pr="10px"
                          mb={{ sm: "4px", xxl: "0" }}
                          lineHeight="22px"
                          w={{ sm: "100%", xxl: "auto" }}
                          color="black.lighten"
                        >
                          Old password
                        </Text>
                        <Input
                          variant="Bordered"
                          type="password"
                          placeholder="********"
                          name="oldPassword"
                          value={managePassValues.oldPassword}
                          onChange={managePassChange}
                        />
                      </Box>
                      <Box
                        justifyContent="space-between"
                        d="flex"
                        flexWrap="wrap"
                        mb="15px"
                      >
                        <Text
                          as="label"
                          fontSize={{ sm: "16px", xxl: "xxl" }}
                          pr="10px"
                          mb={{ sm: "4px", xxl: "0" }}
                          lineHeight="22px"
                          w={{ sm: "100%", xxl: "auto" }}
                          color="black.lighten"
                        >
                          New password
                        </Text>
                        <Input
                          variant="Bordered"
                          type="password"
                          placeholder="********"
                          name="password"
                          value={managePassValues.password}
                          onChange={managePassChange}
                        />
                      </Box>
                      <Box
                        justifyContent="space-between"
                        d="flex"
                        flexWrap="wrap"
                      >
                        <Text
                          as="label"
                          fontSize={{ sm: "16px", xxl: "xxl" }}
                          pr="10px"
                          mb={{ sm: "4px", xxl: "0" }}
                          lineHeight="22px"
                          w={{ sm: "100%", xxl: "auto" }}
                          color="black.lighten"
                        >
                          Confirm password
                        </Text>
                        <Input
                          variant="Bordered"
                          type="password"
                          placeholder="********"
                          name="confirmPassword"
                          value={managePassValues.confirmPassword}
                          onChange={managePassChange}
                        />
                      </Box>
                    </ModalBody>
                    <ModalFooter p="25px 28px">
                      <Button
                        variant="primary2"
                        w="117px"
                        onClick={managePasswordSubmit}
                      >
                        Submit
                      </Button>
                    </ModalFooter>
                  </ModalContent>
                </Modal>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Box>
      </Box>
      <Modal
        isOpen={isOpenCropper}
        onClose={onCloseCropper}
        size={"5xl"}
        scrollBehavior="inside"
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader
            p="25px 28px"
            fontSize="21px"
            lineHeight="26px"
            color="black.lighten"
            fontWeight="600"
          >
            Add Image
          </ModalHeader>
          <Icon
            color="black.primary"
            fontSize="24px"
            as={IoMdClose}
            onClick={onCloseCropper}
            cursor="pointer"
            position="absolute"
            top="29px"
            right="29px"
          ></Icon>
          <ModalBody p="0 28px 25px">
            <Box>
              <Box style={{ width: "100%" }}>
                <Cropper
                  className="img-cropper"
                  zoomTo={0}
                  initialAspectRatio={1}
                  preview=".img-preview"
                  src={cropImage}
                  viewMode={1}
                  minCropBoxHeight={10}
                  minCropBoxWidth={10}
                  background={false}
                  responsive={true}
                  autoCropArea={1}
                  checkOrientation={false}
                  onInitialized={(instance) => {
                    setCroppedImage(instance);
                  }}
                  guides={true}
                />
              </Box>
              <Box m="20px 0" overflow="hidden">
                <Box w="auto">
                  <Box
                    className="img-preview"
                    bg="gray.light3"
                    borderRadius="4px"
                  />
                </Box>
              </Box>
              <Button variant="primary2" onClick={getCropData}>
                Save
              </Button>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
      <AgreementModal
        setOpen={openAgreementModel}
        setClose={setOpenAgreementModel}
        title={selectedAgreement?.title}
        description={selectedAgreement?.description}
      />
      <Modal
        isOpen={companyPolicyDetails}
        onClose={closeCompanyPolicyDetails}
        size="lg"
        scrollBehavior="inside"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader
            p="25px 30px"
            fontSize="21px"
            lineHeight="26px"
            color="black.lighten"
            fontWeight="600"
          >
            {editPolicy ? "Edit Company Policy" : "Add Company Policy"}
          </ModalHeader>
          <Icon
            color="black.primary"
            fontSize="24px"
            as={IoMdClose}
            onClick={closeCompanyPolicyDetails}
            cursor="pointer"
            position="absolute"
            top="29px"
            right="29px"
          ></Icon>
          <ModalBody p="0px 30px 30px">
            <FormControl mb="10px">
              <Text fontSize="18px">Title</Text>
              <Input
                type="text"
                maxW="unset"
                name="title"
                isInvalid={policyTouched["title"] && policyErrors["title"]}
                value={policyValues.title}
                onChange={policyChange}
                variant="Bordered"
                height="50px"
                fontSize="xl"
              />
            </FormControl>
            <FormControl>
              <Text fontSize="18px">Description</Text>
              <Textarea
                maxW="unset"
                variant="Bordered"
                name="description"
                isInvalid={
                  policyTouched["description"] && policyErrors["description"]
                }
                onChange={policyChange}
                value={policyValues.description}
                height="50px"
                fontSize="xl"
              />
            </FormControl>
            {editPolicy ? (
              <Button
                variant="primary2"
                mt="15px"
                float="right"
                onClick={() => updatePolicy()}
              >
                Update
              </Button>
            ) : (
              <Button
                variant="primary2"
                mt="15px"
                float="right"
                onClick={() => submitPolicy()}
              >
                Save
              </Button>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
      <Modal
        isOpen={deleteConfirmation}
        onClose={closeDeleteConfirmation}
        size="xl"
        scrollBehavior="inside"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader
            p="30px 30px 35px"
            fontSize="21px"
            lineHeight="26px"
            color="black.lighten"
            fontWeight="600"
          >
            Delete Report
          </ModalHeader>
          <Icon
            color="black.primary"
            fontSize="24px"
            as={IoMdClose}
            onClick={closeDeleteConfirmation}
            cursor="pointer"
            position="absolute"
            top="29px"
            right="29px"
          ></Icon>
          <ModalBody p="0 30px 25px">
            <Text fontSize="18px" color="black.lighten">
              Are you sure you want to delete?
            </Text>
          </ModalBody>
          <ModalFooter>
            <Button
              variant="primary2"
              minW="120px"
              onClick={() => handleDeletePolicy()}
            >
              Yes
            </Button>
            <Button
              variant="grey"
              ml="15px"
              minW="120px"
              onClick={closeDeleteConfirmation}
            >
              No
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default SuperAdminSettings;
