import { Box, Button, Icon, Input, ListItem, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay, UnorderedList, useDisclosure } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { IoMdClose } from "react-icons/io";
import { MdDelete } from "react-icons/md";
import { useSelector } from "react-redux";
import { toastr } from "react-redux-toastr";
import { RootState } from "../../../redux/store";

export default function EditChoiceModal({ open, setClose, states }: editChoiceModal) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [editableFieldId, setEditableFieldId] = useState<any>(null);
  const template: any = useSelector((state: RootState) => state.template);

  useEffect(() => {
    if (open === true) {
      onOpen(); // Model
    }
  }, [open, onOpen]);

  const closeModel = () => {
    onClose(); // Model
    setClose(false);
  }

  const submitUpdateField = () => {
    const element: any = document.querySelector(`input[name="s-${editableFieldId}"]`);

    if (!element?.value?.length) {
      toastr.warning('', 'Please first fill the form');
      return
    }

    states.callback.update({
      paramId: editableFieldId,
      payload: { [states.keyName]: element.value }
    });

    setEditableFieldId(null);
    closeModel(); // Close Model
    element.blur(); // Blur current element
  }

  const submitDeleteField = (field: any) => {
    if (field?.id) {
      states.callback.delete(field.id);
    }
  };

  useEffect(() => {
    if (template[states.type]?.length === 0) {
      closeModel();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [states.type, template]);

  return (
    <Modal isOpen={isOpen} onClose={closeModel} size='lg' scrollBehavior='inside'>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader p='25px 28px' fontSize='21px' lineHeight='26px' color='black.lighten' fontWeight='600'>
          {states.fieldHeading ? `Edit or Delete ${states.fieldHeading}` : `Edit or Delete Items`}
        </ModalHeader>
        <Icon color='black.primary' fontSize='24px' as={IoMdClose} onClick={closeModel} cursor='pointer' position='absolute' top='29px' right='29px'></Icon>
        <ModalBody p='0 28px'>
          <UnorderedList listStyleType='none' m='0'>
            {template[states.type]?.map((field: any) => {
              return <ListItem d='flex' alignItems='center' mb='15px' key={field.id}>
                <Input w='calc(100% - 68px)' type='text' variant='Bordered' height='42px' name={`s-${field.id}`} defaultValue={field[states.keyName]} onKeyDown={(e) => e.key === 'Enter' && submitUpdateField()} _focus={{ borderColor: 'gray.primary' }} onFocus={() => setEditableFieldId(field?.id)} />
                <Box pl='10px' ml='auto'>
                  <Button variant='iconBtn' p='0' mr='30px' minW='unset' onClick={() => submitDeleteField(field)}>
                    <Icon fontSize='20px' color='gray.primary' as={MdDelete}></Icon>
                  </Button>
                </Box>
              </ListItem>
            })}
          </UnorderedList>
        </ModalBody>
        <ModalFooter p='25px 28px' flexWrap='wrap'>
          <Box d='flex' alignItems='center' w='100%'>
            <Button variant='primary2' ml='auto' w='117px' onClick={submitUpdateField}>
              Update
            </Button>
          </Box>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export interface editChoiceModalState {
  type: string;
  keyName: string;
  fieldHeading: string;
  dafaultValue: string;
  callback: { update: Function, delete: Function };
}

interface editChoiceModal {
  open: boolean;
  setClose: Function;
  states: editChoiceModalState
}