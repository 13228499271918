import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../api';

// Dashboard interface (this)
export interface DashboardState {
  error: any;
  loading: boolean[];
  clients: any[];
  payments: any[];
  dashboardDetails: any;
  inspectionDetails: any;
  revenueDetails: any;
  scheduledJobs: any[];
  activeJobId: string;
  travelDistance: string;
}

// Dashboard initial state (this)
const initialState: DashboardState = {
  error: null,
  loading: [],
  clients: [],
  payments: [],
  dashboardDetails: null,
  inspectionDetails: [],
  revenueDetails: [],
  scheduledJobs: [],
  activeJobId: '',
  travelDistance: ''
};

export const getClients = createAsyncThunk(
  'dashboard/getClients',
  async () => {
    const response = await api.get('dashboard/getclients/byuserid');
    return response.data;
  }
);

export const getPayments = createAsyncThunk(
  'dashboard/getPayments',
  async () => {
    const response = await api.get('dashboard/getpayments/byuserid');
    return response.data;
  }
);

export const dashboardDetails = createAsyncThunk(
  'dashboard/dashboardDetails',
  async (data: { type: string }) => {
    const { type: detailsType } = data;
    const response = await api.post('dashboard/dashboardDetail/byuserid', { data: { type: detailsType } });
    return response.data;
  }
);

export const inspectionDetails = createAsyncThunk(
  'dashboard/inspectionDetail/dashboard',
  async (data: { type: string }) => {
    const { type: inspectionType } = data;
    const response = await api.post('dashboard/inspectionDetail/dashboard', { data: { type: inspectionType } });
    const inspectionData: any = Object.entries(response.data.inspectionData);
    inspectionData.unshift(["Month", "Sales"]);
    return inspectionData;
  }
);

export const revenueDetails = createAsyncThunk(
  'dashboard/revenueDetails',
  async (data: { type: string }) => {
    const { type: detailsType } = data;
    const response = await api.post('dashboard/revenue/details', { data: { type: detailsType } });
    const revenueData: any = Object.entries(response.data.RevenueData);
    revenueData.unshift(["Month", "Sales"]);
    return revenueData;
  }
);

export const createScheduleJob = createAsyncThunk(
  'dashboard/create/schedulejob',
  async (data: { payload: object }, { dispatch }) => {
    const { payload } = data;
    const response = await api.post('dashboard/create/schedulejob', { data: payload });
    dispatch(getScheduleJobs());
    return response.data;
  }
);

export const getScheduleJobs = createAsyncThunk(
  'dashboard/getscheduleJob/byuserid',
  async () => {
    const response = await api.get('dashboard/getscheduleJob/byuserid');
    return response.data;
  }
);

export const deleteScheduleJob = createAsyncThunk(
  'dashboard/delete/schedulejob',
  async (data: { paramId: string }, { dispatch }) => {
    const { paramId } = data;
    const response = await api.post(`dashboard/delete/schedulejob/${paramId}`);
    dispatch(getScheduleJobs());
    return response.data;
  }
);

export const updateScheduledJob = createAsyncThunk(
  'dashboard/update/scheduleJob',
  async (data: { jobId: string, payload: object }, { dispatch }) => {
    const { jobId, payload } = data;
    const response = await api.put(`dashboard/update/scheduleJob/${jobId}`, { data: payload });
    dispatch(getScheduleJobs());
    return response.data;
  }
);

export const DashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    setDashboardError: (state, action) => {
      state.error = action.payload;
    },
    setAddJobValues: (state, action) => {
      state.activeJobId = action.payload;
    },
    setTravelDistance: (state, action) => {
      state.travelDistance = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getClients.pending, (state) => {
        state.error = null;
        state.loading.push(true);
      })
      .addCase(getClients.rejected, (state, action) => {
        state.loading.pop();
        state.error = action.error;
      })
      .addCase(getClients.fulfilled, (state, action) => {
        state.error = null;
        state.loading.pop();
        state.clients = action.payload;
      })
      .addCase(getPayments.pending, (state) => {
        state.error = null;
        state.loading.push(true);
      })
      .addCase(getPayments.rejected, (state, action) => {
        state.loading.pop();
        state.error = action.error;
      })
      .addCase(getPayments.fulfilled, (state, action) => {
        state.error = null;
        state.loading.pop();
        state.payments = action.payload;
      })
      .addCase(dashboardDetails.pending, (state) => {
        state.error = null;
        state.loading.push(true);
      })
      .addCase(dashboardDetails.rejected, (state, action) => {
        state.loading.pop();
        state.error = action.error;
      })
      .addCase(dashboardDetails.fulfilled, (state, action) => {
        state.error = null;
        state.loading.pop();
        state.dashboardDetails = action.payload;
      })
      .addCase(inspectionDetails.pending, (state) => {
        state.error = null;
        state.loading.push(true);
      })
      .addCase(inspectionDetails.rejected, (state, action) => {
        state.loading.pop();
        state.error = action.error;
      })
      .addCase(inspectionDetails.fulfilled, (state, action) => {
        state.error = null;
        state.loading.pop();
        state.inspectionDetails = action.payload;
      })
      .addCase(revenueDetails.pending, (state) => {
        state.error = null;
        state.loading.push(true);
      })
      .addCase(revenueDetails.rejected, (state, action) => {
        state.loading.pop();
        state.error = action.error;
      })
      .addCase(revenueDetails.fulfilled, (state, action) => {
        state.error = null;
        state.loading.pop();
        state.revenueDetails = action.payload;
      })
      .addCase(createScheduleJob.pending, (state) => {
        state.error = null;
        state.loading.push(true);
      })
      .addCase(createScheduleJob.rejected, (state, action) => {
        state.loading.pop();
        state.error = action.error;
      })
      .addCase(createScheduleJob.fulfilled, (state, action) => {
        state.error = null;
        state.loading.pop();
      })
      .addCase(updateScheduledJob.pending, (state) => {
        state.error = null;
        state.loading.push(true);
      })
      .addCase(updateScheduledJob.rejected, (state, action) => {
        state.loading.pop();
        state.error = action.error;
      })
      .addCase(updateScheduledJob.fulfilled, (state, action) => {
        state.error = null;
        state.loading.pop();
      })
      .addCase(getScheduleJobs.pending, (state) => {
        state.loading.push(true);
        state.error = null;
      })
      .addCase(getScheduleJobs.rejected, (state, action) => {
        state.loading.pop();
        state.error = action.error;
      })
      .addCase(getScheduleJobs.fulfilled, (state, action) => {
        state.loading.pop();
        state.error = null;
        state.scheduledJobs = action.payload;
      })
      .addCase(deleteScheduleJob.pending, (state) => {
        state.error = null;
        state.loading.push(true);
      })
      .addCase(deleteScheduleJob.rejected, (state, action) => {
        state.loading.pop();
        state.error = action.error;
      })
      .addCase(deleteScheduleJob.fulfilled, (state) => {
        state.error = null;
        state.loading.pop();
      })
  }
});

export const { setDashboardError, setAddJobValues, setTravelDistance } = DashboardSlice.actions;
export default DashboardSlice.reducer;