import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { dataURLtoFile } from "../../components/common/CropImage";
import api from "../api";
import { getInvoice } from "./auth";
import store from "store2";

export interface ReportChoice {
  property_type: object[];
  occupancy_type: object[];
  property_style: object[];
  orientation_type: object[];
  contruction_type: object[];
}

export interface EditReport {
  report: any;
  paymentDetails: any;
  narrativeImages: any;
  inspectorDetail: any;
  propertyDetails: any;
  propertyImages: any[];
  selectedAgents: any[];
  weatherCondition: any;
  narrativeDetails: any;
  clientInformation: any;
  selectedNarratives: any[];
  selectedInspectionMethod: any[];
  illustrativeImages: any[];
  imageName: string;
  newNarrativeImages: any[];
}

// Report interface (this)
export interface ReportState {
  error: any;
  loading: boolean[];
  agents: any[];
  allReports: any[];
  allCloudReports: any[];
  reportsForAdmin: any[];
  reportDetails: any;
  choice: ReportChoice;
  editReport: EditReport;
  inspectionDetails: any[];
  inspectionReportDetails: any;
  reportTemplate: boolean;
  reportAgreement: any;
}

const initialReportChoice: ReportChoice = {
  property_type: [],
  occupancy_type: [],
  property_style: [],
  contruction_type: [],
  orientation_type: [],
};

const initialEditReport: EditReport = {
  report: {},
  paymentDetails: {},
  propertyImages: [],
  selectedAgents: [],
  inspectorDetail: {},
  narrativeImages: {},
  propertyDetails: {},
  weatherCondition: {},
  narrativeDetails: {},
  clientInformation: {},
  selectedNarratives: [],
  selectedInspectionMethod: [],
  illustrativeImages: [],
  imageName: "",
  newNarrativeImages: [],
};

// Report initial state (this)
const initialState: ReportState = {
  error: null,
  loading: [],
  agents: [],
  allReports: [],
  allCloudReports: [],
  reportsForAdmin: [],
  reportDetails: [],
  inspectionDetails: [],
  choice: initialReportChoice,
  editReport: initialEditReport,
  inspectionReportDetails: {},
  reportTemplate: null,
  reportAgreement: 0,
};

export const getInspectorDetail = createAsyncThunk(
  "report/getInspectorDetail",
  async (data: { inspectorId: string }) => {
    const { inspectorId } = data;
    const response = await api.get(`report/get/inpsectordetail/${inspectorId}`);
    return response.data;
  }
);

export const createInspectorDetail = createAsyncThunk(
  "report/createInspectorDetail",
  async (data: { payload: object }, { dispatch }) => {
    const { payload } = data;
    const response = await api.post("report/create/inspectordetail", {
      data: payload,
    });
    dispatch(
      getInspectorDetail({
        inspectorId: response.data.report.inspectorDetailId,
      })
    );
    return response.data;
  }
);

export const updateInspectorDetail = createAsyncThunk(
  "report/updateInspectorDetail",
  async (
    data: { inspectorId: string; payload: object },
    { dispatch, getState }
  ) => {
    const state: any = getState();
    const { inspectorId, payload } = data;
    const response = await api.put(
      `report/update/inspectorDetail/${inspectorId}`,
      {
        data: payload,
      }
    );

    dispatch(
      getInspectorDetail({
        inspectorId: state.report.editReport.inspectorDetail.id,
      })
    );
    return response.data;
  }
);

export const getClientInformation = createAsyncThunk(
  "report/getClientInformation",
  async (data: { clientId: string }) => {
    const { clientId } = data;
    const response = await api.get(`report/get/clientinformation/${clientId}`);
    return response.data;
  }
);

export const createClientInformation = createAsyncThunk(
  "report/createClientInformation",
  async (data: { payload: object }, { dispatch }) => {
    const { payload } = data;
    const response = await api.post("report/create/clientinformation", {
      data: payload,
    });
    dispatch(
      getClientInformation({ clientId: response.data.clientInformation.id })
    );
    return response.data;
  }
);

export const updateClientInformation = createAsyncThunk(
  "report/updateClientInformation",
  async (
    data: { clientId: string; payload: object },
    { dispatch, getState }
  ) => {
    const state: any = getState();
    const { clientId, payload } = data;
    const response = await api.put(
      `report/update/clientinformation/${clientId}`,
      {
        data: payload,
      }
    );

    dispatch(
      getClientInformation({
        clientId: state.report.editReport.clientInformation.id,
      })
    );
    return response.data;
  }
);

export const uploadAgentImage = createAsyncThunk(
  "report/uploadAgentImage",
  async (data: { agentId: string; payload: object }, { dispatch }) => {
    const { agentId, payload } = data;
    const response = await api.post(`report/uploadAgentimage/${agentId}`, {
      data: payload,
    });

    dispatch(getAgents());
    return response.data;
  }
);

export const uploadInspectorImage = createAsyncThunk(
  "report/uploadInspectorImage",
  async (
    data: { inspectorId: string; payload: object },
    { dispatch, getState }
  ) => {
    const state: any = getState();
    const { inspectorId, payload } = data;
    const response = await api.post(
      `report/uploadinspectorimage/${inspectorId}`,
      {
        data: payload,
      }
    );

    dispatch(
      getInspectorDetail({
        inspectorId: state.report.editReport.report.inspectorDetailId,
      })
    );
    return response.data;
  }
);

export const uploadFrontsideImage = createAsyncThunk(
  "report/uploadFrontsideImage",
  async (
    data: { inspectorId: string; payload: object },
    { dispatch, getState }
  ) => {
    const state: any = getState();
    const { inspectorId, payload } = data;
    const response = await api.post(
      `report/uploadfrontsideimage/${inspectorId}`,
      {
        data: payload,
      }
    );

    dispatch(
      getInspectorDetail({
        inspectorId: state.report.editReport.report.inspectorDetailId,
      })
    );
    return response.data;
  }
);

export const uploadinspectorimage = createAsyncThunk(
  "report/uploadInspectorImage",
  async (data: { inspectorId: string; payload: object }) => {
    const { inspectorId, payload } = data;
    const response = await api.post(
      `report/uploadinspectorimage/${inspectorId}`,
      {
        data: payload,
      }
    );
    return response.data;
  }
);

export const uploadBacksideImage = createAsyncThunk(
  "report/uploadBacksideImage",
  async (
    data: { inspectorId: string; payload: object },
    { dispatch, getState }
  ) => {
    const state: any = getState();
    const { inspectorId, payload } = data;
    const response = await api.post(
      `report/uploadbacksideimage/${inspectorId}`,
      {
        data: payload,
      }
    );

    dispatch(
      getInspectorDetail({
        inspectorId: state.report.editReport.report.inspectorDetailId,
      })
    );
    return response.data;
  }
);

export const getPropertyImages = createAsyncThunk(
  "report/getPropertyImages",
  async (data: { payload: object }) => {
    const { payload } = data;
    const response = await api.post(`report/get/PropertyImages`, {
      data: payload,
    });
    return response.data;
  }
);

export const uploadPropertyImage = createAsyncThunk(
  "report/uploadPropertyImage",
  async (data: { reportId: string; payload: any }, { dispatch, getState }) => {
    let { payload, reportId } = data;
    const state: any = getState();
    payload = Array.isArray(payload) ? payload : [payload];

    const response: object[] = await Promise.all(
      payload.map((image: any) => {
        return api.post(`report/uplodad/propertyimage/${reportId}`, {
          data: image,
        });
      })
    );

    dispatch(
      getPropertyImages({
        payload: { reportId: state.report.editReport.report.id },
      })
    );
    return response;
  }
);

export const getPropertyDetail = createAsyncThunk(
  "report/getPropertyDetail",
  async (propertyId: string) => {
    const response = await api.get(
      `report/get/propertydetailbyid/${propertyId}`
    );
    return response.data;
  }
);

export const createPropertyDetail = createAsyncThunk(
  "report/createPropertyDetail",
  async (data: { payload: object }, { dispatch }) => {
    const { payload } = data;
    const response = await api.post("report/create/propertydetail", {
      data: payload,
    });
    dispatch(getPropertyDetail(response.data.propertydetail.id));
    return response.data;
  }
);

export const updatePropertyDetail = createAsyncThunk(
  "report/updatePropertyDetail",
  async (
    data: { propertyId: string; payload: object },
    { dispatch, getState }
  ) => {
    const state: any = getState();
    const { propertyId, payload } = data;
    const response = await api.put(
      `report/update/propertydetail/${propertyId}`,
      {
        data: payload,
      }
    );

    dispatch(getPropertyDetail(state.report.editReport.propertyDetails.id));
    return response.data;
  }
);

export const getweatheReport = createAsyncThunk(
  "report/getweatheReport",
  async (weatherId: string) => {
    const response = await api.get(`report/get/weathereport/${weatherId}`);
    return response.data;
  }
);

export const createWeatheReport = createAsyncThunk(
  "report/createWeatheReport",
  async (data: { payload: object }, { dispatch }) => {
    const { payload } = data;
    const response = await api.post("report/create/weathereport", {
      data: payload,
    });
    dispatch(getweatheReport(response.data.weatherdetail.id));
    return response.data;
  }
);

export const updateWeatheReport = createAsyncThunk(
  "report/updateWeatheReport",
  async (
    data: { weatherId: string; payload: object },
    { dispatch, getState }
  ) => {
    const state: any = getState();
    const { weatherId, payload } = data;
    const response = await api.put(`report/update/weathereport/${weatherId}`, {
      data: payload,
    });

    dispatch(getweatheReport(state.report.editReport.weatherCondition.id));
    return response.data;
  }
);

export const getAgents = createAsyncThunk("report/getAgents", async () => {
  const response = await api.get(`report/get/agents`);
  return response.data;
});

export const createAgents = createAsyncThunk(
  "report/createAgents",
  async (data: { payload: object }, { dispatch }) => {
    const { payload } = data;
    const response = await api.post("report/create/agents", { data: payload });

    dispatch(getAgents());
    return response.data;
  }
);

export const updateAgent = createAsyncThunk(
  "report/updateAgent",
  async (data: { agentId: string; payload: object }, { dispatch }) => {
    const { agentId, payload } = data;
    const response = await api.put(`report/update/agent/${agentId}`, {
      data: payload,
    });

    dispatch(getAgents());
    return response.data;
  }
);

export const updateReportAgent = createAsyncThunk(
  "report/updateReportAgent",
  async (data: { reportId: string; payload: object }) => {
    const { reportId, payload } = data;
    const response = await api.put(`report/update/agentId/${reportId}`, {
      data: payload,
    });
    return response.data;
  }
);

export const getPaymentDetail = createAsyncThunk(
  "report/getPaymentDetail",
  async (paymentId: string) => {
    const response = await api.get(`report/get/paymentdetail/${paymentId}`);
    return response.data;
  }
);

export const createPaymentMode = createAsyncThunk(
  "report/createPaymentMode",
  async (data: { payload: object }, { dispatch }) => {
    const { payload } = data;
    const response = await api.post(`report/create/paymentmode`, {
      data: payload,
    });
    dispatch(
      getPaymentDetail(response.data.paymentdetail?.id || response.data?.id)
    );
    return response.data;
  }
);

export const updatePaymentMode = createAsyncThunk(
  "report/updatePaymentMode",
  async (
    data: { paymentId: string; payload: object },
    { dispatch, getState }
  ) => {
    const { paymentId, payload } = data;
    const state: any = getState();
    const response = await api.put(`report/update/paymentmode/${paymentId}`, {
      data: payload,
    });

    dispatch(getPaymentDetail(state.report.editReport.paymentDetails.id));
    return response.data;
  }
);

export const getPropertyStyle = createAsyncThunk(
  "report/getPropertyStyle",
  async () => {
    const response = await api.get(`get/propertystyle`);
    return response.data;
  }
);

export const getPropertyType = createAsyncThunk(
  "report/getPropertyType",
  async () => {
    const response = await api.get(`get/propertytype`);
    return response.data;
  }
);

export const getOccupancyType = createAsyncThunk(
  "report/getOccupancyType",
  async () => {
    const response = await api.get(`get/occupancytype`);
    return response.data;
  }
);

export const getOrienationType = createAsyncThunk(
  "report/getOrienationType",
  async () => {
    const response = await api.get(`get/orienationtype`);
    return response.data;
  }
);

export const getConstructionType = createAsyncThunk(
  "report/getConstructionType",
  async () => {
    const response = await api.get(`get/constructiontype`);
    return response.data;
  }
);

export const createPropertyStyle = createAsyncThunk(
  "report/createPropertyStyle",
  async (data: { payload: object }, { dispatch }) => {
    const { payload } = data;
    const response = await api.post(`create/propertystyle`, { data: payload });
    dispatch(getPropertyStyle()); // Update getPropertyStyle state
    return response.data;
  }
);

export const deletePropertyStyle = createAsyncThunk(
  "report/deletePropertyStyle",
  async (data: any, { dispatch }) => {
    const { paramId } = data;
    const response = await api.del(`delete/propertystyle/${paramId}`);
    dispatch(getPropertyStyle()); // Update getPropertyStyle state
    return response.data;
  }
);

export const createPropertyType = createAsyncThunk(
  "report/createPropertyType",
  async (data: { payload: object }, { dispatch }) => {
    const { payload } = data;
    const response = await api.post(`create/propertytype`, { data: payload });
    dispatch(getPropertyType()); // Update getPropertyType state
    return response.data;
  }
);

export const deletePropertyType = createAsyncThunk(
  "report/deletePropertyType",
  async (data: any, { dispatch }) => {
    const { paramId } = data;
    const response = await api.del(`/delete/propertytype/${paramId}`);
    dispatch(getPropertyType()); // Update getPropertyType state
    return response.data;
  }
);

export const createOccupancyType = createAsyncThunk(
  "report/createOccupancyType",
  async (data: { payload: object }, { dispatch }) => {
    const { payload } = data;
    const response = await api.post(`create/occupancytype`, { data: payload });
    dispatch(getOccupancyType()); // Update getOccupancyType state
    return response.data;
  }
);

export const createOrienationType = createAsyncThunk(
  "report/createOrienationType",
  async (data: { payload: object }, { dispatch }) => {
    const { payload } = data;
    const response = await api.post(`create/orienationtype`, { data: payload });
    dispatch(getOrienationType()); // Update getOrienationType state
    return response.data;
  }
);

export const createConstructionType = createAsyncThunk(
  "report/createConstructionType",
  async (data: { payload: object }, { dispatch }) => {
    const { payload } = data;
    const response = await api.post(`create/constructiontype`, {
      data: payload,
    });
    dispatch(getConstructionType()); // Update getConstructionType state
    return response.data;
  }
);

export const deleteConstructionType = createAsyncThunk(
  "report/deleteConstructionType",
  async (data: any, { dispatch }) => {
    const { paramId } = data;
    const response = await api.del(`delete/constructionType/${paramId}`);
    dispatch(getConstructionType()); // Update getConstructionType state
    return response.data;
  }
);

export const createInspectionDetail = createAsyncThunk(
  "report/createInspectionDetail",
  async (data: any, { dispatch, getState }) => {
    const response: object[] = await Promise.all(
      data
        .filter((x) => x.editMode === false)
        .map((x: any) => {
          return api.post(`report/create/inspectionDetail`, { data: x });
        })
    );
    return response;
  }
);

export const uploadNarrativeImage = createAsyncThunk(
  "report/uploadNarrativeImage",
  async (data: any, { getState }) => {
    const { payload } = data;
    const images = [];
    const narrativeImageData = [];

    if (payload) {
      payload?.images?.forEach((img: any) => {
        if (!/http:\/\/|https:\/\//gi.test(img)) {
          images.push({ img: img, key: payload.key });
        }
      });
    }

    console.log(images, "imagessss");

    const token = store.get("authToken");
    console.log(token);

    const response: object[] = await Promise.all(
      images.map((x: any, index: number) => {
        narrativeImageData[index] = new FormData();
        narrativeImageData[index].append(
          "image",
          dataURLtoFile(
            x.img.accessImageUrl,
            x?.img?.image_name || x?.img?.imageName
          )
        );

        return api.post(
          `report/upload/narrtaiveImage/${x.key}`,
          { data: narrativeImageData[index] },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
      })
    );
    return response;
  }
);

export const getNarrativeImage = createAsyncThunk(
  "report/get/narrativeImages",
  async (data: { narrativeKey: string }) => {

    console.log("narrativeKey1", data)
    const { narrativeKey } = data;
    const response = await api.post(`report/get/narrativeImages`, {
      data: { narrativeKey: narrativeKey },
    });
    return response.data;
  }
);
export const getNarrativeImageFunction = createAsyncThunk(
  "report/get/narrativeImages",
  async (data: { narrativeKey: string }) => {

    const { narrativeKey } = data;
    const response = await api.post(`report/get/narrativeImages`, {
      data: { narrativeKey: narrativeKey },
    });
    return response.data;
  }
);

export const updateNarrativeImage = createAsyncThunk(
  "report/update/narrativeImages",
  async (data: { id: string; name: string }, { dispatch, getState }) => {
    const state: any = getState();
    const { id, name } = data;
    console.log(data, "update data");

    const response = await api.put(`report/update/narrativeImages/${id}`, {
      data: { image_name: name },
    });

    dispatch(
      getNarrativeImage({ narrativeKey: state.report.inspectionDetails.id })
    );
    return response.data;
  }
);

export const updateNarrativeImageFunction = createAsyncThunk(
  "report/update/narrativeImages",
  async (
    data: { id: string; name: string; reportId: any },
    { dispatch, getState }
  ) => {
    const state: any = getState();
    const { id, name, reportId } = data;
    console.log(data, "update data");

    const response = await api.put(`report/update/narrativeImages/${id}`, {
      data: { image_name: name },
    });


    dispatch(getNarrativeImage({ narrativeKey: `${reportId}` }));
    return response.data;
  }
);

export const deleteNarrativeImage = createAsyncThunk(
  "report/deleteNarrativeImage",
  async (data: { imageId: string }) => {
    const { imageId } = data;
    const response = await api.del(`report/delete/narrativeImages/${imageId}`);
    return response.data;
  }
);

export const deleteNarrativeImageFunction = createAsyncThunk(
  "report/deleteNarrativeImage",
  async (data: { imageId: string, narrativeKey:string  }, { dispatch }) => {
    const { imageId, narrativeKey } = data;
    const response = await api.del(`report/delete/narrativeImages/${imageId}`);
    dispatch(getNarrativeImage({ narrativeKey: `${narrativeKey}` }));
    return response.data;
    
  }
);

export const uploadIllustrativeImage = createAsyncThunk(
  "report/upload/illustrativeImage",
  async (
    data: { sectionId: string; fileData: any },
    { dispatch, getState }
  ) => {
    const state: any = getState();
    const { sectionId, fileData } = data;
    const response = await api.post(
      `report/upload/illustrativeImage/${sectionId}`,
      { data: fileData }
    );

    dispatch(
      updateIllustrativeImage({
        id: response.data.id,
        reportId: state.report.editReport.report.id,
      })
    );
    return response.data;
  }
);

export const getIllustrativeImage = createAsyncThunk(
  "report/get/illustrativeImages",
  async (data: { reportId: string }) => {
    const { reportId } = data;
    const response = await api.post(`report/get/IllustrativeImages`, {
      data: { reportId: reportId },
    });
    return response.data;
  }
);

export const updateIllustrativeImage = createAsyncThunk(
  "report/update/IllustrativeImages",
  async (
    data: { id: string; reportId: string; name?: any },
    { dispatch, getState }
  ) => {
    const state: any = getState();
    const { id, reportId, name } = data;
    const response = await api.put(`report/update/IllustrativeImages/${id}`, {
      data: { randomKey: reportId, image_name: name },
    });

    dispatch(
      getIllustrativeImage({ reportId: state.report.editReport.report.id })
    );
    return response.data;
  }
);

export const deleteIllustrativeImage = createAsyncThunk(
  "report/delete/illustrativeImage",
  async (imageId: any, { dispatch, getState }) => {
    const state: any = getState();
    const response = await api.del(
      `report/delete/IllustrativeImages/${imageId}`
    );

    dispatch(
      getIllustrativeImage({ reportId: state.report.editReport.report.id })
    );
    return response.data;
  }
);

export const createReportInspectionMethod = createAsyncThunk(
  "report/createReportInspectionMethod",
  async (data: { payload: any[] }) => {
    const { payload } = data;
    const response: object[] = await Promise.all(
      payload
        .filter((x) => x.editMode === false)
        .map((inspctionItem: any) => {
          return api.post(`report/create/inspectionMethod`, {
            data: inspctionItem,
          });
        })
    );
    return response;
  }
);

export const generateInvoice = createAsyncThunk(
  "report/generate/invoice",
  async (data: {
    reportId: any;
    companyId: any;
    clientId: any;
    paymentId: any;
  }) => {
    const { reportId, companyId, clientId, paymentId } = data;
    const response = await api.post("report/generate/invoice", {
      data: { reportId, companyId, clientId, paymentId },
    });
    return response.data;
  }
);

export const getInvoiceReport = createAsyncThunk(
  "report/get/invoiceofReport",
  async (data: { reportId: any }, { dispatch }) => {
    const { reportId } = data;
    const response = await api.post("report/get/invoiceofReport", {
      data: { reportId },
    });

    dispatch(getInvoice({ invoiceId: response.data[0].id }));
    return response.data;
  }
);

export const createPDF = createAsyncThunk(
  "report/createPDF",
  async (data: { paramId: string; payload: object }) => {
    const { paramId, payload } = data;
    const response = await api.post(`report/createPDF/${paramId}`, {
      data: payload,
    });
    return response.data;
  }
);

export const getReportDetail = createAsyncThunk(
  "report/getReportDetail",
  async (data: { paramId: string }) => {
    const { paramId } = data;
    const response = await api.get(`report/get/report/${paramId}`);

    console.log("getReportDetail->", response.data);

    const { inspectionDetails } = response.data;
    console.log(inspectionDetails, "<===inspectionDetails");

    const obj: any = {
      sections: [],
      components: [],
      catagory: [],
      narratives: [],
    };

    inspectionDetails.forEach((narrative: any) => {
      const catagory = narrative["category-Narrative"]["Component-Category"];
      const component =
        narrative["category-Narrative"]["Component-Category"][
          "SectionComponent"
        ];
      const section =
        narrative["category-Narrative"]["Component-Category"][
          "SectionComponent"
        ]["templateSection"];

      if (!obj.sections.find((x: any) => x.id === section.id)) {
        section.components = [];
        obj.sections.push(section);
      }

      if (!obj.components.find((x: any) => x.id === component.id)) {
        delete component.templateSection;
        component.catagory = [];
        obj.components.push(component);
      }
      if (!obj.catagory.find((x: any) => x.id === catagory.id)) {
        delete catagory.SectionComponent;
        catagory.narratives = [];
        obj.catagory.push(catagory);
      }
      if (!obj.narratives.find((x: any) => x.id === narrative.id)) {
        delete narrative["category-Narrative"]["Component-Category"];
        obj.narratives.push(narrative);
      }
    });

    obj.narratives.forEach((narrative: any) => {
      const categoryId = narrative["category-Narrative"].ComponentCategoryId;
      const catagory = obj.catagory.find((x) => x.id === categoryId);

      if (categoryId && catagory) {
        catagory.narratives.push(narrative);
      }
    });

    obj.catagory.forEach((catagory: any) => {
      const componentId = catagory.SectionComponentId;
      const component = obj.components.find((x) => x.id === componentId);

      if (componentId && component) {
        component.catagory.push(catagory);
      }
    });

    obj.components.forEach((component: any) => {
      const sectionId = component.templateSectionId;
      const section = obj.sections.find((x) => x.id === sectionId);

      if (sectionId && section) {
        section.components.push(component);
      }
    });

    delete obj.catagory;
    delete obj.components;
    console.log({
      data: response.data,
      inspectionDetails: obj,
      narrativeImages: response.narrativeImages,
    });

    return {
      data: response.data,
      inspectionDetails: obj,
      narrativeImages: response.narrativeImages,
    };
  }
);

export const getAllReports = createAsyncThunk(
  "report/getAllReports",
  async () => {
    const response = await api.get(`report/getReportbyuserId`);
    return response.data;
  }
);
export const getAllReportsCloudStorage = createAsyncThunk(
  "report/getAllReportsCloudStorage",
  async () => {
    const response = await api.get(`report/getcloudStorageReport`);
    return response.data;
  }
);

export const getReportForAdmin = createAsyncThunk(
  "admin/get/reports/admin",
  async () => {
    const response = await api.post(`admin/get/reports/admin`);
    return response.data;
  }
);

export const deleteReportById = createAsyncThunk(
  "report/deleteReportById",
  async (data: any, { dispatch }) => {
    const { paramId } = data;
    const response = await api.post(`report/delete/${paramId}`);
    return response.data;
  }
);

export const getReportById = createAsyncThunk(
  "report/get/report",
  async (data: { reportId: string }) => {
    const { reportId } = data;
    const response = await api.get(`report/get/report/${reportId}`);
    return response;
  }
);

export const getURLData = createAsyncThunk(
  "report/get/UrlData",
  async (data: { payload: any }) => {
    const { payload } = data;
    const response = await api.post(`report/get/UrlData`, { data: payload });
    return response;
  }
);

export const createInspectorDetailScheduleJob = createAsyncThunk(
  "dashboard/create/inspectordetail/scheduleJob",
  async (data: { payload: object }, { dispatch }) => {
    const { payload } = data;
    const response = await api.post(
      "report/create/inspectordetail/scheduleJob",
      { data: payload }
    );
    dispatch(
      getInspectorDetail({
        inspectorId: response.data.report.inspectorDetailId,
      })
    );
    return response.data;
  }
);

export const saveReportToCloud = createAsyncThunk(
  "report/savetocloud",
  async (data: { reportId: string }) => {
    const { reportId } = data;
    const response = await api.put(`report/savetocloud/report/${reportId}`);
    return response;
  }
);

export const UserSlice = createSlice({
  name: "report",
  initialState,
  reducers: {
    setReportError: (state, action) => {
      state.error = action.payload;
    },
    setSelectedNarratives: (state, action) => {
      let initialPayload = state.editReport.selectedNarratives;
      const payload = initialPayload.find((x) => x.id === action.payload.id);

      if (payload && payload !== null) {
        // initialPayload = [...initialPayload, action.payload];
        payload.checked = !payload.checked;
      } else {
        if (Array.isArray(action.payload)) {
          initialPayload = [...action.payload];
        } else {
          initialPayload = [...initialPayload, action.payload];
        }
      }

      state.editReport.selectedNarratives = initialPayload;
    },
    setSelectedInspectionMethod: (state, action) => {
      let initialPayload = state.editReport.selectedInspectionMethod;
      const payload = initialPayload.find((x) => x.id === action.payload.id);

      if (action.payload.length === 0) {
        const newInpsectionMethod = initialPayload.filter(
          (x) => !x.reportInspectionId
        );
        initialPayload = newInpsectionMethod;
      } else {
        if (payload && payload !== null) {
          payload.checked = !payload.checked;
        } else {
          initialPayload = [...initialPayload, action.payload];
        }
      }
      state.editReport.selectedInspectionMethod = initialPayload;
    },
    resetAddReport: (state) => {
      state.editReport = initialEditReport;
    },
    resetSelectedNarratives: (state) => {
      state.editReport.selectedNarratives.forEach((x: any) => {
        if (x.checked) {
          x.checked = false;
        }
      });
    },
    setNarrativeImages: (state, action) => {
      const { narrativeImages } = state.editReport;
      const temp = narrativeImages;
      const img = [];
      console.log(action.payload, "PAYLOAD");
      action.payload.img.forEach((x: any) => {
        img.push({
          accessImageUrl: x.accessImageUrl,
          image_name: x.image_name || x.imageName,
          imageId: x.imageId,
          narrativeId: action.payload.narrativeId,
        });
      });

      state.editReport.narrativeImages = {
        ...temp,
        [action.payload.id]: img,
      };
    },

    setEditedNarrativeImages: (state, action) => {
      state.editReport.narrativeImages = action.payload;
    },
    setNarrativesDetails: (state, action) => {
      const { narrativeDetails } = state.editReport;
      const initial = narrativeDetails[action.payload.id];
      const data = {
        ...(action.payload.note && { note: action.payload.note }),
        ...(action.payload.description && {
          description: action.payload.description,
        }),
        ...(action.payload.editMode && { editMode: action.payload.editMode }),
        ...(action.payload.reportNarrativeId && {
          reportNarrativeId: action.payload.reportNarrativeId,
        }),
      };
      state.editReport.narrativeDetails = {
        ...narrativeDetails,
        [action.payload.id]: { ...initial, ...data },
      };
    },
    setEditReportDetails: (state, action) => {
      state.editReport[action.payload.key] = action.payload.data;
    },
    setImageName: (state, action) => {
      state.editReport.imageName = action.payload.name;
    },
    setReportTemplateState: (state, action) => {
      state.reportTemplate = action.payload.value;
    },
    setReportAgreement: (state, action) => {
      state.reportAgreement = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getInspectorDetail.pending, (state) => {
        state.error = null;
        state.loading.push(true);
      })
      .addCase(getInspectorDetail.rejected, (state, action) => {
        state.loading.pop();
        state.error = action.error;
      })
      .addCase(getInspectorDetail.fulfilled, (state, action) => {
        const propertyImages = state.editReport.propertyImages;
        const { frontendAccessUrl, backendAccessUrl } = action.payload;

        const backside = propertyImages.find((x) => x.type === "backsideImage");
        const frontside = propertyImages.find(
          (x) => x.type === "frontsideImage"
        );

        backside
          ? (backside.imageUrl = backendAccessUrl)
          : propertyImages.push({
              type: "backsideImage",
              accessImageUrl: backendAccessUrl,
            });
        frontside
          ? (frontside.imageUrl = frontendAccessUrl)
          : propertyImages.push({
              type: "frontsideImage",
              accessImageUrl: frontendAccessUrl,
            });

        state.error = null;
        state.loading.pop();
        state.editReport.inspectorDetail = action.payload;
        state.editReport.propertyImages = propertyImages.filter(
          (x) => x.accessImageUrl !== null
        );
      })
      .addCase(createInspectorDetail.pending, (state) => {
        state.error = null;
        state.loading.push(true);
      })
      .addCase(createInspectorDetail.rejected, (state, action) => {
        state.loading.pop();
        state.error = action.error;
      })
      .addCase(createInspectorDetail.fulfilled, (state, action) => {
        state.error = null;
        state.loading.pop();
        state.editReport.report = action.payload.report;
      })
      .addCase(updateInspectorDetail.pending, (state) => {
        state.error = null;
        state.loading.push(true);
      })
      .addCase(updateInspectorDetail.rejected, (state, action) => {
        state.loading.pop();
        state.error = action.error;
      })
      .addCase(updateInspectorDetail.fulfilled, (state, action) => {
        state.error = null;
        state.loading.pop();
      })
      .addCase(updateIllustrativeImage.pending, (state) => {
        state.error = null;
        state.loading.push(true);
      })
      .addCase(updateIllustrativeImage.rejected, (state, action) => {
        state.loading.pop();
        state.error = action.error;
      })
      .addCase(updateIllustrativeImage.fulfilled, (state, action) => {
        state.error = null;
        state.loading.pop();
      })
      .addCase(updateNarrativeImage.pending, (state) => {
        state.error = null;
        state.loading.push(true);
      })
      .addCase(updateNarrativeImage.rejected, (state, action) => {
        state.loading.pop();
        state.error = action.error;
      })
      .addCase(updateNarrativeImage.fulfilled, (state, action) => {
        state.error = null;
        state.loading.pop();
      })
      .addCase(getClientInformation.pending, (state) => {
        state.error = null;
        state.loading.push(true);
      })
      .addCase(getClientInformation.rejected, (state, action) => {
        state.loading.pop();
        state.error = action.error;
      })
      .addCase(getClientInformation.fulfilled, (state, action) => {
        state.error = null;
        state.loading.pop();
        state.editReport.clientInformation = action.payload;
      })
      .addCase(createClientInformation.pending, (state) => {
        state.error = null;
        state.loading.push(true);
      })
      .addCase(createClientInformation.rejected, (state, action) => {
        state.loading.pop();
        state.error = action.error;
      })
      .addCase(createClientInformation.fulfilled, (state) => {
        state.error = null;
        state.loading.pop();
      })
      .addCase(updateClientInformation.pending, (state) => {
        state.error = null;
        state.loading.push(true);
      })
      .addCase(updateClientInformation.rejected, (state, action) => {
        state.loading.pop();
        state.error = action.error;
      })
      .addCase(updateClientInformation.fulfilled, (state) => {
        state.error = null;
        state.loading.pop();
      })
      .addCase(uploadAgentImage.pending, (state) => {
        state.error = null;
        state.loading.push(true);
      })
      .addCase(uploadAgentImage.rejected, (state, action) => {
        state.loading.pop();
        state.error = action.error;
      })
      .addCase(uploadAgentImage.fulfilled, (state) => {
        state.error = null;
        state.loading.pop();
      })
      .addCase(uploadInspectorImage.pending, (state) => {
        state.error = null;
        state.loading.push(true);
      })
      .addCase(uploadInspectorImage.rejected, (state, action) => {
        state.loading.pop();
        state.error = action.error;
      })
      .addCase(uploadInspectorImage.fulfilled, (state) => {
        state.error = null;
        state.loading.pop();
      })
      .addCase(uploadFrontsideImage.pending, (state) => {
        state.error = null;
        state.loading.push(true);
      })
      .addCase(uploadFrontsideImage.rejected, (state, action) => {
        state.loading.pop();
        state.error = action.error;
      })
      .addCase(uploadFrontsideImage.fulfilled, (state) => {
        state.error = null;
        state.loading.pop();
      })
      .addCase(uploadBacksideImage.pending, (state) => {
        state.error = null;
        state.loading.push(true);
      })
      .addCase(uploadBacksideImage.rejected, (state, action) => {
        state.loading.pop();
        state.error = action.error;
      })
      .addCase(uploadBacksideImage.fulfilled, (state) => {
        state.error = null;
        state.loading.pop();
      })
      .addCase(getPropertyImages.pending, (state) => {
        state.error = null;
        state.loading.push(true);
      })
      .addCase(getPropertyImages.rejected, (state, action) => {
        state.loading.pop();
        state.error = action.error;
      })
      .addCase(getPropertyImages.fulfilled, (state, action) => {
        const propertyImages = action.payload;
        const { frontendAccessUrl, backendAccessUrl } =
          state.editReport.inspectorDetail;

        const backside = propertyImages.find((x) => x.type === "backsideImage");
        const frontside = propertyImages.find(
          (x) => x.type === "frontsideImage"
        );

        backside
          ? (backside.imageUrl = backendAccessUrl)
          : propertyImages.push({
              type: "backsideImage",
              accessImageUrl: backendAccessUrl,
            });
        frontside
          ? (frontside.imageUrl = frontendAccessUrl)
          : propertyImages.push({
              type: "frontsideImage",
              accessImageUrl: frontendAccessUrl,
            });

        state.error = null;
        state.loading.pop();
        state.editReport.propertyImages = propertyImages.filter(
          (x) => x.accessImageUrl !== null
        );
      })
      .addCase(uploadPropertyImage.pending, (state) => {
        state.error = null;
        state.loading.push(true);
      })
      .addCase(uploadPropertyImage.rejected, (state, action) => {
        state.loading.pop();
        state.error = action.error;
      })
      .addCase(uploadPropertyImage.fulfilled, (state) => {
        state.error = null;
        state.loading.pop();
      })
      .addCase(getPropertyDetail.pending, (state) => {
        state.error = null;
        state.loading.push(true);
      })
      .addCase(getPropertyDetail.rejected, (state, action) => {
        state.loading.pop();
        state.error = action.error;
      })
      .addCase(getPropertyDetail.fulfilled, (state, action) => {
        state.error = null;
        state.loading.pop();
        state.editReport.propertyDetails = action.payload;
      })
      .addCase(createPropertyDetail.pending, (state) => {
        state.error = null;
        state.loading.push(true);
      })
      .addCase(createPropertyDetail.rejected, (state, action) => {
        state.loading.pop();
        state.error = action.error;
      })
      .addCase(createPropertyDetail.fulfilled, (state) => {
        state.error = null;
        state.loading.pop();
      })
      .addCase(updatePropertyDetail.pending, (state) => {
        state.error = null;
        state.loading.push(true);
      })
      .addCase(updatePropertyDetail.rejected, (state, action) => {
        state.loading.pop();
        state.error = action.error;
      })
      .addCase(updatePropertyDetail.fulfilled, (state) => {
        state.error = null;
        state.loading.pop();
      })
      .addCase(getweatheReport.pending, (state) => {
        state.error = null;
        state.loading.push(true);
      })
      .addCase(getweatheReport.rejected, (state, action) => {
        state.loading.pop();
        state.error = action.error;
      })
      .addCase(getweatheReport.fulfilled, (state, action) => {
        state.error = null;
        state.loading.pop();
        state.editReport.weatherCondition = action.payload;
      })
      .addCase(createWeatheReport.pending, (state) => {
        state.error = null;
        state.loading.push(true);
      })
      .addCase(createWeatheReport.rejected, (state, action) => {
        state.loading.pop();
        state.error = action.error;
      })
      .addCase(createWeatheReport.fulfilled, (state) => {
        state.error = null;
        state.loading.pop();
      })
      .addCase(updateWeatheReport.pending, (state) => {
        state.error = null;
        state.loading.push(true);
      })
      .addCase(updateWeatheReport.rejected, (state, action) => {
        state.loading.pop();
        state.error = action.error;
      })
      .addCase(updateWeatheReport.fulfilled, (state) => {
        state.error = null;
        state.loading.pop();
      })
      .addCase(createAgents.pending, (state) => {
        state.error = null;
        state.loading.push(true);
      })
      .addCase(createAgents.rejected, (state, action) => {
        state.loading.pop();
        state.error = action.error;
      })
      .addCase(createAgents.fulfilled, (state) => {
        state.error = null;
        state.loading.pop();
      })
      .addCase(updateAgent.pending, (state) => {
        state.error = null;
        state.loading.push(true);
      })
      .addCase(updateAgent.rejected, (state, action) => {
        state.loading.pop();
        state.error = action.error;
      })
      .addCase(updateAgent.fulfilled, (state) => {
        state.error = null;
        state.loading.pop();
      })
      .addCase(getAgents.pending, (state) => {
        state.error = null;
        state.loading.push(true);
      })
      .addCase(getAgents.rejected, (state, action) => {
        state.loading.pop();
        state.error = action.error;
      })
      .addCase(getAgents.fulfilled, (state, action) => {
        state.error = null;
        state.loading.pop();
        state.agents = action.payload;
      })
      .addCase(getPaymentDetail.pending, (state) => {
        state.error = null;
        state.loading.push(true);
      })
      .addCase(getPaymentDetail.rejected, (state, action) => {
        state.loading.pop();
        state.error = action.error;
      })
      .addCase(getPaymentDetail.fulfilled, (state, action) => {
        state.error = null;
        state.loading.pop();
        state.editReport.paymentDetails = action.payload;
      })
      .addCase(createPaymentMode.pending, (state) => {
        state.error = null;
        state.loading.push(true);
      })
      .addCase(createPaymentMode.rejected, (state, action) => {
        state.loading.pop();
        state.error = action.error;
      })
      .addCase(createPaymentMode.fulfilled, (state) => {
        state.error = null;
        state.loading.pop();
      })
      .addCase(updatePaymentMode.pending, (state) => {
        state.error = null;
        state.loading.push(true);
      })
      .addCase(updatePaymentMode.rejected, (state, action) => {
        state.loading.pop();
        state.error = action.error;
      })
      .addCase(updatePaymentMode.fulfilled, (state) => {
        state.error = null;
        state.loading.pop();
      })
      .addCase(getPropertyStyle.pending, (state) => {
        state.error = null;
        state.loading.push(true);
      })
      .addCase(getPropertyStyle.rejected, (state, action) => {
        state.loading.pop();
        state.error = action.error;
      })
      .addCase(getPropertyStyle.fulfilled, (state, action) => {
        state.error = null;
        state.loading.pop();
        state.choice.property_style = action.payload;
      })
      .addCase(getPropertyType.pending, (state) => {
        state.error = null;
        state.loading.push(true);
      })
      .addCase(getPropertyType.rejected, (state, action) => {
        state.loading.pop();
        state.error = action.error;
      })
      .addCase(getPropertyType.fulfilled, (state, action) => {
        state.error = null;
        state.loading.pop();
        state.choice.property_type = action.payload;
      })
      .addCase(getOccupancyType.pending, (state) => {
        state.error = null;
        state.loading.push(true);
      })
      .addCase(getOccupancyType.rejected, (state, action) => {
        state.loading.pop();
        state.error = action.error;
      })
      .addCase(getOccupancyType.fulfilled, (state, action) => {
        state.error = null;
        state.loading.pop();
        state.choice.occupancy_type = action.payload;
      })
      .addCase(getOrienationType.pending, (state) => {
        state.error = null;
        state.loading.push(true);
      })
      .addCase(getOrienationType.rejected, (state, action) => {
        state.loading.pop();
        state.error = action.error;
      })
      .addCase(getOrienationType.fulfilled, (state, action) => {
        state.error = null;
        state.loading.pop();
        state.choice.orientation_type = action.payload;
      })
      .addCase(getConstructionType.pending, (state) => {
        state.error = null;
        state.loading.push(true);
      })
      .addCase(getConstructionType.rejected, (state, action) => {
        state.loading.pop();
        state.error = action.error;
      })
      .addCase(getConstructionType.fulfilled, (state, action) => {
        state.error = null;
        state.loading.pop();
        state.choice.contruction_type = action.payload;
      })
      .addCase(createPropertyStyle.pending, (state) => {
        state.error = null;
        state.loading.push(true);
      })
      .addCase(createPropertyStyle.rejected, (state, action) => {
        state.loading.pop();
        state.error = action.error;
      })
      .addCase(createPropertyStyle.fulfilled, (state) => {
        state.error = null;
        state.loading.pop();
      })
      .addCase(deletePropertyStyle.pending, (state) => {
        state.error = null;
        state.loading.push(true);
      })
      .addCase(deletePropertyStyle.rejected, (state, action) => {
        state.loading.pop();
        state.error = action.error;
      })
      .addCase(deletePropertyStyle.fulfilled, (state) => {
        state.error = null;
        state.loading.pop();
      })
      .addCase(createPropertyType.pending, (state) => {
        state.error = null;
        state.loading.push(true);
      })
      .addCase(createPropertyType.rejected, (state, action) => {
        state.loading.pop();
        state.error = action.error;
      })
      .addCase(createPropertyType.fulfilled, (state) => {
        state.error = null;
        state.loading.pop();
      })
      .addCase(deletePropertyType.pending, (state) => {
        state.error = null;
        state.loading.push(true);
      })
      .addCase(deletePropertyType.rejected, (state, action) => {
        state.loading.pop();
        state.error = action.error;
      })
      .addCase(deletePropertyType.fulfilled, (state) => {
        state.error = null;
        state.loading.pop();
      })
      .addCase(createOccupancyType.pending, (state) => {
        state.error = null;
        state.loading.push(true);
      })
      .addCase(createOccupancyType.rejected, (state, action) => {
        state.loading.pop();
        state.error = action.error;
      })
      .addCase(createOccupancyType.fulfilled, (state) => {
        state.error = null;
        state.loading.pop();
      })
      .addCase(createOrienationType.pending, (state) => {
        state.error = null;
        state.loading.push(true);
      })
      .addCase(createOrienationType.rejected, (state, action) => {
        state.loading.pop();
        state.error = action.error;
      })
      .addCase(createOrienationType.fulfilled, (state) => {
        state.error = null;
        state.loading.pop();
      })
      .addCase(createConstructionType.pending, (state) => {
        state.error = null;
        state.loading.push(true);
      })
      .addCase(createConstructionType.rejected, (state, action) => {
        state.loading.pop();
        state.error = action.error;
      })
      .addCase(createConstructionType.fulfilled, (state) => {
        state.error = null;
        state.loading.pop();
      })
      .addCase(deleteConstructionType.pending, (state) => {
        state.error = null;
        state.loading.push(true);
      })
      .addCase(deleteConstructionType.rejected, (state, action) => {
        state.loading.pop();
        state.error = action.error;
      })
      .addCase(deleteConstructionType.fulfilled, (state) => {
        state.error = null;
        state.loading.pop();
      })
      .addCase(createInspectionDetail.pending, (state) => {
        state.error = null;
        state.loading.push(true);
      })
      .addCase(createInspectionDetail.rejected, (state, action) => {
        state.loading.pop();
        state.error = action.error;
      })
      .addCase(createInspectionDetail.fulfilled, (state) => {
        state.error = null;
        state.loading.pop();
      })
      .addCase(uploadNarrativeImage.pending, (state) => {
        state.error = null;
        state.loading.push(true);
      })
      .addCase(uploadNarrativeImage.rejected, (state, action) => {
        state.loading.pop();
        state.error = action.error;
      })
      .addCase(uploadNarrativeImage.fulfilled, (state) => {
        state.error = null;
        state.loading.pop();
      })
      .addCase(getNarrativeImage.pending, (state) => {
        state.error = null;
        state.loading.push(true);
      })
      .addCase(getNarrativeImage.rejected, (state, action) => {
        state.loading.pop();
        state.error = action.error;
      })
      .addCase(getNarrativeImage.fulfilled, (state, action) => {
        state.error = null;
        state.loading.pop();
        state.editReport.illustrativeImages = action.payload;
        state.editReport.newNarrativeImages = action.payload;
      })
      .addCase(uploadIllustrativeImage.pending, (state) => {
        state.error = null;
        state.loading.push(true);
      })
      .addCase(uploadIllustrativeImage.rejected, (state, action) => {
        state.loading.pop();
        state.error = action.error;
      })
      .addCase(uploadIllustrativeImage.fulfilled, (state) => {
        state.error = null;
        state.loading.pop();
      })
      .addCase(getIllustrativeImage.pending, (state) => {
        state.error = null;
        state.loading.push(true);
      })
      .addCase(getIllustrativeImage.rejected, (state, action) => {
        state.loading.pop();
        state.error = action.error;
      })
      .addCase(getIllustrativeImage.fulfilled, (state, action) => {
        state.error = null;
        state.loading.pop();
        state.editReport.illustrativeImages = action.payload;
      })
      .addCase(deleteIllustrativeImage.pending, (state) => {
        state.error = null;
        state.loading.push(true);
      })
      .addCase(deleteIllustrativeImage.rejected, (state, action) => {
        state.loading.pop();
        state.error = action.error;
      })
      .addCase(deleteIllustrativeImage.fulfilled, (state) => {
        state.error = null;
        state.loading.pop();
      })
      .addCase(createReportInspectionMethod.pending, (state) => {
        state.error = null;
        state.loading.push(true);
      })
      .addCase(createReportInspectionMethod.rejected, (state, action) => {
        state.loading.pop();
        state.error = action.error;
      })
      .addCase(createReportInspectionMethod.fulfilled, (state) => {
        state.error = null;
        state.loading.pop();
      })
      .addCase(getReportDetail.pending, (state) => {
        state.error = null;
        state.loading.push(true);
      })
      .addCase(getReportDetail.rejected, (state, action) => {
        state.loading.pop();
        state.error = action.error;
      })
      .addCase(getReportDetail.fulfilled, (state, action) => {
        state.error = null;
        state.loading.pop();
        state.reportDetails = action.payload.data;
        state.editReport.narrativeImages = action.payload.narrativeImages;
        state.inspectionDetails = action.payload.inspectionDetails.sections;
      })
      .addCase(getAllReports.pending, (state) => {
        state.error = null;
        state.loading.push(true);
      })
      .addCase(getAllReports.rejected, (state, action) => {
        state.loading.pop();
        state.error = action.error;
      })
      .addCase(getAllReports.fulfilled, (state, action) => {
        state.error = null;
        state.loading.pop();
        state.allReports = action.payload;
      })
      .addCase(getAllReportsCloudStorage.pending, (state) => {
        state.error = null;
        state.loading.push(true);
      })
      .addCase(getAllReportsCloudStorage.rejected, (state, action) => {
        state.loading.pop();
        state.error = action.error;
      })
      .addCase(getAllReportsCloudStorage.fulfilled, (state, action) => {
        state.error = null;
        state.loading.pop();
        state.allCloudReports = action.payload;
      })
      .addCase(getReportForAdmin.pending, (state) => {
        state.error = null;
        state.loading.push(true);
      })
      .addCase(getReportForAdmin.rejected, (state, action) => {
        state.loading.pop();
        state.error = action.error;
      })
      .addCase(getReportForAdmin.fulfilled, (state, action) => {
        state.error = null;
        state.loading.pop();
        state.reportsForAdmin = action.payload;
      })
      .addCase(deleteReportById.pending, (state) => {
        state.error = null;
        state.loading.push(true);
      })
      .addCase(deleteReportById.rejected, (state, action) => {
        state.loading.pop();
        state.error = action.error;
      })
      .addCase(deleteReportById.fulfilled, (state) => {
        state.error = null;
        state.loading.pop();
      })
      .addCase(updateReportAgent.pending, (state) => {
        state.error = null;
        state.loading.push(true);
      })
      .addCase(updateReportAgent.rejected, (state, action) => {
        state.loading.pop();
        state.error = action.error;
      })
      .addCase(updateReportAgent.fulfilled, (state) => {
        state.error = null;
        state.loading.pop();
      })
      .addCase(generateInvoice.pending, (state) => {
        state.error = null;
        state.loading.push(true);
      })
      .addCase(generateInvoice.rejected, (state, action) => {
        state.loading.pop();
        state.error = action.error;
      })
      .addCase(generateInvoice.fulfilled, (state) => {
        state.error = null;
        state.loading.pop();
      })
      .addCase(getInvoiceReport.pending, (state) => {
        state.error = null;
        state.loading.push(true);
      })
      .addCase(getInvoiceReport.rejected, (state, action) => {
        state.loading.pop();
        state.error = action.error;
      })
      .addCase(getInvoiceReport.fulfilled, (state, action) => {
        state.error = null;
        state.loading.pop();
      })
      .addCase(createPDF.pending, (state) => {
        state.error = null;
        state.loading.push(true);
      })
      .addCase(createPDF.rejected, (state, action) => {
        state.loading.pop();
        state.error = action.error;
      })
      .addCase(createPDF.fulfilled, (state, action) => {
        state.error = null;
        state.loading.pop();
      })
      .addCase(deleteNarrativeImage.pending, (state) => {
        state.error = null;
        state.loading.push(true);
      })
      .addCase(deleteNarrativeImage.rejected, (state, action) => {
        state.loading.pop();
        state.error = action.error;
      })
      .addCase(deleteNarrativeImage.fulfilled, (state) => {
        state.error = null;
        state.loading.pop();
      })
      .addCase(getReportById.pending, (state) => {
        state.error = null;
        state.loading.push(true);
      })
      .addCase(getReportById.rejected, (state, action) => {
        state.loading.pop();
        state.error = action.error;
      })
      .addCase(getReportById.fulfilled, (state, action) => {
        state.error = null;
        state.loading.pop();
        state.inspectionReportDetails = action.payload.data;
      })
      .addCase(getURLData.pending, (state) => {
        state.error = null;
        state.loading.push(true);
      })
      .addCase(getURLData.rejected, (state, action) => {
        state.loading.pop();
        state.error = action.error;
      })
      .addCase(getURLData.fulfilled, (state) => {
        state.error = null;
        state.loading.pop();
      })
      .addCase(createInspectorDetailScheduleJob.pending, (state) => {
        state.error = null;
        state.loading.push(true);
      })
      .addCase(createInspectorDetailScheduleJob.rejected, (state, action) => {
        state.loading.pop();
        state.error = action.error;
      })
      .addCase(createInspectorDetailScheduleJob.fulfilled, (state, action) => {
        state.error = null;
        state.loading.pop();
        state.editReport.report = action.payload.report;
      })
      .addCase(saveReportToCloud.pending, (state) => {
        state.error = null;
        state.loading.push(true);
      })
      .addCase(saveReportToCloud.rejected, (state, action) => {
        state.loading.pop();
        state.error = action.error;
      })
      .addCase(saveReportToCloud.fulfilled, (state, action) => {
        state.error = null;
        state.loading.pop();
      });
  },
});

export const {
  resetAddReport,
  setReportError,
  setNarrativeImages,
  setNarrativesDetails,
  setSelectedNarratives,
  setSelectedInspectionMethod,
  setEditReportDetails,
  setImageName,
  setEditedNarrativeImages,
  setReportTemplateState,
  setReportAgreement,
  resetSelectedNarratives,
} = UserSlice.actions;
export default UserSlice.reducer;
