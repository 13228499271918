import { ComponentMultiStyleConfig } from "@chakra-ui/react";

const Table: ComponentMultiStyleConfig = {
    parts : ['th','td', 'tr'],
    variants : {
        SimpleTable : {
            tr :{
                td: {
                    color:'#2a2a2a',
                    height: '64px',
                    fontSize: '20px',
                    fontWeight: '600',
                    border:'1px solid #2a2a2a33',
                    textAlign: 'center',
                }
            },
            th: {
                bg: '#dbdbdb33',
                color: '#2a2a2a',
                height: '64px',
                fontSize: '20px',
                textTransform: 'none',
                fontWeight: '600',
                border:'1px solid #2a2a2a33',
                textAlign: 'center',
            },
        },
    },
}
export default Table