import {
  Box,
  Button,
  Flex,
  Icon,
  Image,
  List,
  ListItem,
  Text,
  Heading,
  Avatar,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import propertyImage from "../../../../assets/images/property-img-1.svg";
import { MdDownload, MdModeEdit } from "react-icons/md";
import { AiOutlineMinus } from "react-icons/ai";
import { IoIosWarning } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import {
  createPDF,
  getAgents,
  getAllReports,
  getIllustrativeImage,
  getReportDetail,
  getReportForAdmin,
  getURLData,
  ReportState,
} from "../../../../redux/modules/report";
import { RootState } from "../../../../redux/store";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { FiArrowLeft } from "react-icons/fi";
import { authState } from "../../../../redux/modules/auth";
import { BsFillHouseFill } from "react-icons/bs";
import like from "../../../../assets/images/like.png";
import information from "../../../../assets/images/information.png";
import caution from "../../../../assets/images/caution.png";
import needService from "../../../../assets/images/needService.png";
import { toastr } from "react-redux-toastr";
import Loader from "../../../../components/common/loader";
import { css } from "@emotion/react";

export default function ReportDetails() {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const report: ReportState = useSelector((state: RootState) => state.report);
  const auth: authState = useSelector((state: RootState) => state.auth);
  const [frontImage, setFrontImage] = useState("");
  const [rearImage, setRearImage] = useState("");
  const [needServiceCount, setNeedServiceCount] = useState<any>([]);
  const [safetyHazardCount, setSafetyHazardCount] = useState<any>([]);
  console.log("report---", report);

  // Initial value loder
  // ... render page resources
  useEffect(() => {
    if (params?.id) {
      dispatch(getAgents());
      dispatch(getReportDetail({ paramId: params.id }));
      dispatch(getIllustrativeImage({ reportId: params.id }));
    }
  }, [
    dispatch,
    params,
    report.editReport.report.inspectorDetailId,
    report.reportDetails?.inspectorDetail?.frontendAccessUrl,
  ]);

  useEffect(() => {
    if (params?.id) {
      dispatch(getAgents());
      dispatch(getReportDetail({ paramId: params.id }));
    }
    setFrontImage(report.reportDetails?.inspectorDetail?.frontendAccessUrl);
    setRearImage(report.reportDetails?.inspectorDetail?.backendAccessUrl);
  }, [
    dispatch,
    params,
    report.reportDetails?.inspectorDetail?.frontendAccessUrl,
    report.editReport.report.inspectorDetailId,
    report.reportDetails?.inspectorDetail?.backendAccessUrl,
  ]);

  const handleDownloadPdf = () => {
    new Promise(() => {
      console.log('inspectionDetails==========>',report.inspectionDetails);
      
      const data2: any = dispatch(
        createPDF({
          paramId: params.id,
          payload: { sections: report.inspectionDetails },
        })
      );
      data2.then(function (value) {
        if (value.type === "report/createPDF/fulfilled") {
          toastr.success("", "Pdf Downloaded successfully");
          const finalUrl = value?.payload;
          const a = document.createElement("a");
          // @ts-ignore
          a.href = finalUrl;
          a.download = finalUrl;
          window.open(finalUrl, "_blank");
        }
      });
    });
  };
  const handleSendReport = () => {
    new Promise(() => {
      const data3: any = dispatch(getReportDetail({ paramId: params.id }));
      data3.then(function (value) {
        if (value.type === "report/getReportDetail/fulfilled") {
          if (!value.payload.data.aggrementLink) {
            toastr.warning("", "Please sign and save the agreement first");
            return;
          }
          if (!value.payload.data.paymentLink) {
            toastr.warning("", "Please complete the payment first");
            return;
          }
          const reportUrl = `${window.location.origin}/inspection-report/${params.id}`;
          new Promise(() => {
            const data4: any = dispatch(
              createPDF({
                paramId: params.id,
                payload: { sections: report.inspectionDetails },
              })
            );
            data4.then(function (value1) {
              if (value1.type === "report/createPDF/fulfilled") {
                new Promise(() => {
                  const data5: any = dispatch(
                    getURLData({
                      payload: {
                        email: `${report?.reportDetails?.clientinformation.email}`,
                        urlData: reportUrl,
                        reportId: params.id,
                      },
                    })
                  );
                  data5.then(function (value2) {
                    if (value2.type === "report/get/UrlData/fulfilled") {
                      toastr.success("", "Report sent successfully");
                    }
                  });
                });
              }
            });
          });
        }
      });
    });
  };

  const narrDesc = [
    { name: "Compliant", color: "#000", image: like },
    { name: "Informational", color: "#00CA72", image: information },
    { name: "Need Service", color: "#FFDE1F", image: needService },
    { name: "Safety Hazard", color: "#D64000", image: caution },
  ];

  useEffect(() => {
    if (report.inspectionDetails) {
      return report?.inspectionDetails.forEach((section: any) => {
        return section?.components.forEach((component: any) => {
          return component?.catagory.forEach((catagory: any) => {
            return catagory?.narratives.forEach((narr: any) => {
              if (
                narr["category-Narrative"].description_color === "#D64000" &&
                safetyHazardCount?.includes(narr.id) === false
              ) {
                setSafetyHazardCount([...safetyHazardCount, narr.id]);
              }
              if (
                narr["category-Narrative"].description_color === "#FFDE1F" &&
                needServiceCount?.includes(narr.id) === false
              ) {
                setNeedServiceCount([...needServiceCount, narr.id]);
              }
            });
          });
        });
      });
    } else {
      setSafetyHazardCount([]);
      setNeedServiceCount([]);
    }
  }, [needServiceCount, report.inspectionDetails, safetyHazardCount]);

  useEffect(() => {
    if (auth?.error) {
      const { message } = auth.error;
      toastr.error("", message ? message : "");
    } else if (report?.error) {
      const { message } = report.error;
      toastr.error("", message ? message : "");
    }
  }, [auth.error, dispatch, report.error]);

  useEffect(() => {
    if (auth.user?.user_Type !== "super_admin") {
      dispatch(getAllReports());
    }
    if (auth.user?.user_Type === "company_admin") {
      dispatch(getReportForAdmin());
    }
  }, [dispatch, auth.user?.user_Type, location.pathname]);

  // check report in report listing
  useEffect(() => {
    if (
      report.reportDetails?.id &&
      (report?.allReports.length || report?.reportsForAdmin.length)
    ) {
      if (
        auth.user?.user_Type === "home_inspector" &&
        (report.allReports.filter((x) => x.id === report.reportDetails?.id)
          .length ||
          report.allCloudReports.filter(
            (x) => x.id === report.reportDetails?.id
          ).length) === 0
      ) {
        navigate(-1);
      }

      if (
        auth.user?.user_Type === "super_admin_home_inspector" &&
        (report.allReports.filter((x) => x.id === report.reportDetails?.id)
          .length ||
          report.allCloudReports.filter(
            (x) => x.id === report.reportDetails?.id
          ).length) === 0
      ) {
        navigate(-1);
      }
      if (
        auth.user?.user_Type === "company_admin" &&
        (report.allReports.filter((x) => x.id === report.reportDetails?.id)
          .length ||
          report.allCloudReports.filter(
            (x) => x.id === report.reportDetails?.id
          ).length) === 0 &&
        report.reportsForAdmin.filter((x) => x.id === report.reportDetails?.id)
          .length === 0
      ) {
        navigate(-1);
      }
    }
  }, [
    auth.user?.user_Type,
    navigate,
    params?.id,
    report.allCloudReports,
    report.allReports,
    report.reportDetails?.id,
    report.reportsForAdmin,
  ]);

  const setPreviousRoute = () => {
    navigate(-1);
  };

  const findInspectionMethod = (sectionId: number) => {
    const inspectionMethod = report.reportDetails.reportInspectionMethods
      .map((y: any) => sectionId === y.inspectionMethod.templateSectionId)
      .filter((x: boolean) => x === true);
    return inspectionMethod;
  };

  return (
    <>
      {(auth.loginLoading.length !== 0 || report.loading.length !== 0) && (
        <Loader />
      )}
      <Box
        p="29px 20px 20px"
        h="calc(100vh - 70px)"
        overflow="auto"
        overflowX="hidden"
        w="100%"
      >
        <Button variant="primary2" mb="15px" onClick={setPreviousRoute}>
          <Icon fontSize="21px" mr="10px" as={FiArrowLeft}></Icon>
          Back to Reports
        </Button>
        <Flex justifyContent="space-between">
          <Box w="100%" h={{ sm: "415px", xxl: "485px" }} overflow="hidden">
            <Flex
              position="relative"
              justifyContent="space-between"
              h={{ sm: "415px", xxl: "485px" }}
            >
              <Box
                w="49%"
                position="relative"
                h={{ sm: "415px", xxl: "485px" }}
                bg="#383d4e1c"
                display="flex"
                justifyContent="center"
              >
                {frontImage ? (
                  <Image
                    h="100%"
                    src={frontImage ? frontImage : propertyImage}
                  ></Image>
                ) : (
                  <Box
                    h="100%"
                    w="100%"
                    border="1px solid"
                    borderColor="#cccccc"
                    d="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                    cursor="pointer"
                    bg="#383d4e1c"
                  >
                    <Icon
                      fontSize="36px"
                      color="gray.primary"
                      as={BsFillHouseFill}
                    ></Icon>
                    <Text
                      w="100%"
                      textAlign="center"
                      fontSize="xxl"
                      color="gray.primary"
                      mt="8px"
                    >
                      Front image
                    </Text>
                  </Box>
                )}
                <Flex
                  bg="rgba(0, 0, 0, 0.8)"
                  justifyContent="space-between"
                  position="absolute"
                  bottom="0"
                  w="100%"
                  alignItems={{ sm: "flex-start", xxl: "center" }}
                  p="10px 18px"
                  flexDirection={{ sm: "column", xxl: "row" }}
                >
                  <Text color="white.primary">Front Image</Text>
                  <Box>
                    <Flex flexDirection={{ sm: "column", md: "row" }}>
                      <Flex>
                        <Text color="white.primary" fontWeight="600">
                          Inspection Date:{" "}
                        </Text>
                        <Text color="white.primary" ml="5px">
                          {moment(
                            report.reportDetails.inspectorDetail?.inspectionDate
                          ).format("DD-MM-YYYY")}
                        </Text>
                      </Flex>
                      <Flex>
                        <Text
                          color="white.primary"
                          fontWeight="600"
                          ml={{ sm: "0", md: "10px" }}
                        >
                          Inspection Start:{" "}
                        </Text>
                        <Text color="white.primary" ml="5px">
                          {moment(
                            report.reportDetails.inspectorDetail
                              ?.inspectionEndDate
                          ).format("hh:mm a")}
                        </Text>
                      </Flex>
                    </Flex>
                    <Flex>
                      <Text color="white.primary" fontWeight="600">
                        Inspection End:{" "}
                      </Text>
                      <Text color="white.primary" ml="5px">
                        {moment(
                          report.reportDetails.inspectorDetail
                            ?.inspectionEndTime
                        ).format("hh:mm a")}
                      </Text>
                    </Flex>
                  </Box>
                </Flex>
              </Box>
              <Box
                w="49%"
                position="relative"
                h={{ sm: "415px", xxl: "485px" }}
                bg="#383d4e1c"
                display="flex"
                justifyContent="center"
              >
                {rearImage ? (
                  <Image
                    h="100%"
                    src={rearImage ? rearImage : propertyImage}
                  ></Image>
                ) : (
                  <Box
                    h="100%"
                    w="100%"
                    border="1px solid"
                    borderColor="#cccccc"
                    d="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                    cursor="pointer"
                    bg="#383d4e1c"
                  >
                    <Icon
                      fontSize="36px"
                      color="gray.primary"
                      as={BsFillHouseFill}
                    ></Icon>
                    <Text
                      w="100%"
                      textAlign="center"
                      fontSize="xxl"
                      color="gray.primary"
                      mt="8px"
                    >
                      Back image
                    </Text>
                  </Box>
                )}
                <Flex
                  bg="rgba(0, 0, 0, 0.8)"
                  position="absolute"
                  bottom="0"
                  w="100%"
                  justifyContent="space-between"
                  p="10px 18px"
                  flexDirection={{ sm: "column", xxl: "row" }}
                  alignItems={{ sm: "flex-start", xxl: "center" }}
                >
                  <Text color="white.primary">Rear Image</Text>
                  <Box>
                    <Flex flexDirection={{ sm: "column", md: "row" }}>
                      <Flex>
                        <Text color="white.primary" fontWeight="600">
                          Client's Name:{" "}
                        </Text>
                        <Text color="white.primary" ml="5px">
                          {report?.reportDetails?.clientinformation?.firstName}{" "}
                          {report?.reportDetails?.clientinformation?.lastName}
                        </Text>
                      </Flex>
                      <Flex>
                        <Text
                          color="white.primary"
                          fontWeight="600"
                          ml={{ sm: "0", md: "10px" }}
                        >
                          Client's Email:{" "}
                        </Text>
                        <Text color="white.primary" ml="5px">
                          {report?.reportDetails?.clientinformation?.email}
                        </Text>
                      </Flex>
                    </Flex>
                    <Flex>
                      <Text color="white.primary" fontWeight="600">
                        Client's Address:{" "}
                      </Text>
                      <Text color="white.primary" ml="5px">
                        {report?.reportDetails?.clientinformation?.address}{" "}
                        {report?.reportDetails?.clientinformation?.city}{" "}
                        {report?.reportDetails?.clientinformation?.state}{" "}
                        {report?.reportDetails?.clientinformation?.country}
                      </Text>
                    </Flex>
                  </Box>
                </Flex>
              </Box>
            </Flex>
          </Box>
        </Flex>
        <Box
          justifyContent="space-between"
          mt={{ sm: "24px", xxl: "34px" }}
          flexWrap="wrap"
        >
          <Box
            w="100%"
            d="flex"
            flexWrap="wrap"
            justifyContent="space-between"
            alignContent="flex-start"
          >
            <Flex justifyContent="space-between" flexWrap="wrap" w="100%">
              <Box
                w="100%"
                display="flex"
                flexWrap="nowrap"
                columnGap="10px"
                overflowX="auto"
                alignContent="flex-start"
              >
                <Flex
                  alignItems="center"
                  p={{ sm: "15px 20px 10px", xxl: "20px 25px 15px" }}
                  borderRadius="12px"
                  minW="340px"
                  bg="white.primary"
                  mb="20px"
                  w="33%"
                >
                  <Box>
                    <Box
                      borderRadius="50%"
                      minW={{ sm: "112px", xxl: "122px" }}
                      w={{ sm: "112px", xxl: "122px" }}
                      h={{ sm: "112px", xxl: "122px" }}
                      overflow="hidden"
                      bg="#383d4e1c"
                    >
                      <Avatar
                        src={
                          report.reportDetails.inspectorDetail
                            ?.inspectorAccessImageUrl
                        }
                        h="100%"
                        w="100%"
                        overflow="hidden"
                        css={css({
                          ".chakra-avatar__img": {
                            borderRadius: "0",
                            width: "auto",
                            height: "auto",
                            maxHeight: "100%",
                          },
                        })}
                      />
                    </Box>
                    <Text
                      as="h3"
                      textAlign="center"
                      color="blue.dark4"
                      w="100%"
                      fontSize={{ sm: "16px", xxl: "20px" }}
                      fontWeight="600"
                      mt="6px"
                    >
                      Inspector
                    </Text>
                  </Box>
                  <Box
                    w={{ sm: "calc(100% - 132px)", xxl: "fit-content" }}
                    ml={{ sm: "20px", xxl: "25px" }}
                    justifyContent="flex-end"
                    flexWrap="wrap"
                    alignItems="center"
                  >
                    <Box mb={{ sm: "4px", xxl: "6px" }} w="fit-content">
                      <Text
                        as="span"
                        fontSize={{ sm: "15px", lg: "16px", xxl: "18px" }}
                        color="gray.primary"
                        mr="4px"
                      >
                        Inspector Name :
                      </Text>
                      <Text
                        as="span"
                        fontSize={{ sm: "15px", lg: "16px", xxl: "18px" }}
                        color="black.lighten"
                      >
                        {report.reportDetails.inspectorDetail?.inspectorName ||
                          "--"}
                      </Text>
                    </Box>
                    <Box mb={{ sm: "4px", xxl: "6px" }} w="fit-content">
                      <Text
                        as="span"
                        fontSize={{ sm: "15px", lg: "16px", xxl: "18px" }}
                        color="gray.primary"
                        mr="4px"
                      >
                        Phone No :
                      </Text>
                      <Text
                        as="span"
                        fontSize={{ sm: "15px", lg: "16px", xxl: "18px" }}
                        color="black.lighten"
                      >
                        {auth.user?.phone || "--"}
                      </Text>
                    </Box>
                    <Box mb={{ sm: "4px", xxl: "6px" }} w="fit-content">
                      <Text
                        as="span"
                        fontSize={{ sm: "15px", lg: "16px", xxl: "18px" }}
                        color="gray.primary"
                        mr="4px"
                      >
                        Report ID :
                      </Text>
                      <Text
                        as="span"
                        fontSize={{ sm: "15px", lg: "16px", xxl: "18px" }}
                        color="black.lighten"
                      >
                        {report.reportDetails.inspectorDetail?.reportId || "--"}
                      </Text>
                    </Box>
                  </Box>
                </Flex>
                {report.agents.length > 0 &&
                  [
                    report.agents.find(
                      (x) => x.id === Number(report.reportDetails.buyerId)
                    ),
                    report.agents.find(
                      (x) => x.id === Number(report.reportDetails.sellerId)
                    ),
                  ]?.map((agent: any) => {
                    if (!agent?.id) return null; // Prevent shown blank agents if not present
                    return (
                      <Flex
                        key={agent?.id}
                        alignItems="center"
                        p={{ sm: "15px 20px 10px", xxl: "20px 25px 15px" }}
                        borderRadius="12px"
                        minW="340px"
                        bg="white.primary"
                        mb="20px"
                        w="33%"
                      >
                        <Box>
                          <Box
                            borderRadius="50%"
                            minW={{ sm: "112px", xxl: "122px" }}
                            w={{ sm: "112px", xxl: "122px" }}
                            h={{ sm: "112px", xxl: "122px" }}
                            overflow="hidden"
                            bg="#383d4e1c"
                          >
                            <Avatar
                              src={agent?.accessImageUrl}
                              h="100%"
                              w="100%"
                              overflow="hidden"
                              css={css({
                                ".chakra-avatar__img": {
                                  borderRadius: "0",
                                  width: "auto",
                                  height: "auto",
                                  maxHeight: "100%",
                                },
                              })}
                            />
                          </Box>
                          <Text
                            as="h3"
                            textAlign="center"
                            color="blue.dark4"
                            w="100%"
                            fontSize={{ sm: "16px", xxl: "20px" }}
                            fontWeight="600"
                            mt="6px"
                          >
                            Agent
                          </Text>
                        </Box>
                        <Box
                          w={{ sm: "calc(100% - 132px)", xxl: "fit-content" }}
                          ml={{ sm: "20px", xxl: "25px" }}
                          justifyContent="flex-end"
                          flexWrap="wrap"
                          alignItems="center"
                        >
                          <Box mb={{ sm: "4px", xxl: "6px" }} w="fit-content">
                            <Text
                              as="span"
                              fontSize={{ sm: "15px", lg: "16px", xxl: "18px" }}
                              color="gray.primary"
                              mr="4px"
                            >
                              Agent Name :
                            </Text>
                            <Text
                              as="span"
                              fontSize={{ sm: "15px", lg: "16px", xxl: "18px" }}
                              color="black.lighten"
                            >
                              {agent?.firstName} {agent?.lastName}
                            </Text>
                          </Box>
                          <Box mb={{ sm: "4px", xxl: "6px" }} w="fit-content">
                            <Text
                              as="span"
                              fontSize={{ sm: "15px", lg: "16px", xxl: "18px" }}
                              color="gray.primary"
                              mr="4px"
                            >
                              Phone No :
                            </Text>
                            <Text
                              as="span"
                              fontSize={{ sm: "15px", lg: "16px", xxl: "18px" }}
                              color="black.lighten"
                            >
                              {agent?.phone}
                            </Text>
                          </Box>
                          <Box mb={{ sm: "4px", xxl: "6px" }} w="fit-content">
                            <Text
                              as="span"
                              fontSize={{ sm: "15px", lg: "16px", xxl: "18px" }}
                              color="gray.primary"
                              mr="4px"
                            >
                              Agency No :
                            </Text>
                            <Text
                              as="span"
                              fontSize={{ sm: "15px", lg: "16px", xxl: "18px" }}
                              color="black.lighten"
                            >
                              {agent?.agencyNumber}
                            </Text>
                          </Box>
                        </Box>
                      </Flex>
                    );
                  })}
              </Box>
            </Flex>
          </Box>
          <Box
            w="100%"
            bg="white.primary"
            p={{ sm: "20px 15px", xxl: "25px 20px" }}
            borderRadius="12px"
          >
            <Box>
              <Box mt="20px">
                <Flex
                  alignItems="center"
                  justifyContent="space-between"
                  w="100%"
                  mb="5px"
                >
                  <Text
                    as="h3"
                    fontSize={{ sm: "21px", xxl: "26px" }}
                    fontWeight="600"
                    color="black.lighten"
                  >
                    Summary
                  </Text>
                  {auth?.user?.user_Type !== "super_admin" && (
                    <Box>
                      {location.pathname ===
                        `/report-detail/${report.reportDetails.id}` && (
                        <Button
                          variant="bordered"
                          mr="15px"
                          onClick={() => navigate(`/edit-report/${params.id}`)}
                        >
                          <Icon
                            fontSize="21px"
                            mr="10px"
                            as={MdModeEdit}
                          ></Icon>
                          Edit
                        </Button>
                      )}
                      <Button
                        variant="bordered"
                        mr="15px"
                        onClick={handleDownloadPdf}
                      >
                        <Icon fontSize="21px" mr="10px" as={MdDownload}></Icon>
                        Download
                      </Button>
                      <Button variant="bordered" onClick={handleSendReport}>
                        Send Report
                      </Button>
                    </Box>
                  )}
                </Flex>
                <Flex mt="20px">
                  <Box mr="32px">
                    <Flex
                      bg="yellow.lighten4"
                      minW="42px"
                      fontSize="20px"
                      color="white.primary"
                      h="42px"
                      w="42px"
                      alignItems="center"
                      justifyContent="center"
                      borderRadius="50%"
                      m="auto"
                    >
                      {needServiceCount.length}
                    </Flex>
                    <Text fontWeight="600" mt="10px" color="black.lighten">
                      Needs Service
                    </Text>
                  </Box>
                  <Box mr="32px">
                    <Flex
                      bg="orange.dark"
                      fontSize="20px"
                      color="white.primary"
                      minW="42px"
                      h="42px"
                      w="42px"
                      alignItems="center"
                      justifyContent="center"
                      borderRadius="50%"
                      m="auto"
                    >
                      {safetyHazardCount.length}
                    </Flex>
                    <Text fontWeight="600" mt="10px" color="black.lighten">
                      Safety Hazard
                    </Text>
                  </Box>
                </Flex>

                <List spacing={2} mt="20px">
                  {report.inspectionDetails.map((response: any) => {
                    return response.components.map((res: any) => {
                      return res.catagory.map((cat: any) => {
                        return cat.narratives.map((nar: any) => {
                          if (
                            nar["category-Narrative"].description_color ===
                            "#D64000"
                          ) {
                            return (
                              <ListItem
                                alignItems="center"
                                display="flex"
                                fontSize="18px"
                              >
                                <Flex
                                  mr="5px"
                                  minW="20px"
                                  h="20px"
                                  w="20px"
                                  bg="orange.dark"
                                  borderRadius="50%"
                                  alignItems="center"
                                  justifyContent="center"
                                >
                                  <Icon
                                    as={IoIosWarning}
                                    color="white.primary"
                                    fontSize="11px"
                                  />
                                </Flex>
                                <Text
                                  textOverflow="ellipsis"
                                  whiteSpace="nowrap"
                                  overflow="hidden"
                                  w="calc(100% - 25px)"
                                >
                                  {`${response?.section_name}, ${res?.component_name}, ${cat?.category_name}, ${nar?.["category-Narrative"]?.narrative_name}`}
                                </Text>
                              </ListItem>
                            );
                          }
                          if (
                            nar["category-Narrative"].description_color ===
                            "#FFDE1F"
                          ) {
                            return (
                              <ListItem
                                alignItems="center"
                                display="flex"
                                fontSize="18px"
                              >
                                <Flex
                                  mr="5px"
                                  minW="20px"
                                  h="20px"
                                  w="20px"
                                  bg="#FFDE1F"
                                  borderRadius="50%"
                                  alignItems="center"
                                  justifyContent="center"
                                >
                                  <Icon
                                    as={AiOutlineMinus}
                                    color="white.primary"
                                    fontSize="11px"
                                  />
                                </Flex>
                                <Text
                                  textOverflow="ellipsis"
                                  whiteSpace="nowrap"
                                  overflow="hidden"
                                  w="calc(100% - 25px)"
                                >
                                  {`${response?.section_name}, ${res?.component_name}, ${cat?.category_name}, ${nar?.["category-Narrative"]?.narrative_name}`}
                                </Text>
                              </ListItem>
                            );
                          }
                        });
                      });
                    });
                  })}
                  {needServiceCount.length === 0 &&
                    safetyHazardCount.length === 0 && <Text>No Records</Text>}
                </List>
              </Box>
              <Box mb="20px">
                <Box>
                  <Text
                    as="h3"
                    fontSize={{ sm: "21px", xxl: "26px" }}
                    fontWeight="600"
                    color="black.lighten"
                    mt="20px"
                  >
                    Inspection Details
                  </Text>
                </Box>
                <Box>
                  <Box d="flex" mb="15px" pb="15px" flexWrap="wrap">
                    {report.inspectionDetails?.map(
                      (section: any, sectionIndex) => {
                        return (
                          <React.Fragment key={sectionIndex + Math.random()}>
                            <Text
                              w="100%"
                              as="h3"
                              mt="10px"
                              fontSize={{ sm: "19px", xxl: "21px" }}
                              color="black.lighten"
                              fontWeight="600"
                              mb="12px"
                            >
                              {section.section_name}
                            </Text>
                            {report.editReport.illustrativeImages.length !==
                              0 && (
                              <Flex justifyContent="center" w="100%">
                                <Box
                                  mt="15px"
                                  display="flex"
                                  flexWrap="wrap"
                                  gap="10px"
                                >
                                  <>
                                    {report.editReport.illustrativeImages
                                      ?.filter(
                                        (x) =>
                                          x.templateSectionId === section?.id
                                      )
                                      .map((res) => {
                                        return (
                                          <>
                                            <Box w="248px" h="86px">
                                              <Image
                                                key={res.id}
                                                order={{ sm: 1, xxl: "unset" }}
                                                w="100%"
                                                h="100%"
                                                mr="10px"
                                                mb="10px"
                                                src={res.accessImageUrl}
                                                alt=""
                                              />
                                            </Box>
                                          </>
                                        );
                                      })}
                                  </>
                                </Box>
                              </Flex>
                            )}
                            {findInspectionMethod(section?.id).length >= 1 && (
                              <Text
                                w="100%"
                                as="h3"
                                m="10px 0 6px"
                                fontSize={{ sm: "19px", xxl: "21px" }}
                                color="black.lighten"
                                fontWeight="600"
                              >
                                Inspection Method-{" "}
                              </Text>
                            )}
                            <Box mb="15px">
                              {report.reportDetails.reportInspectionMethods.map(
                                (x: any, index: number) => {
                                  return (
                                    <>
                                      {section?.id ===
                                        x.inspectionMethod
                                          .templateSectionId && (
                                        <Text
                                          as="span"
                                          fontSize={{ sm: "18px", xxl: "19px" }}
                                          lineHeight="23px"
                                          color="black.lighten"
                                        >
                                          {x.inspectionMethod.name}
                                          {index <
                                            findInspectionMethod(section?.id)
                                              .length -
                                              1 &&
                                            report.reportDetails.reportInspectionMethods
                                              .map(
                                                (y: any) =>
                                                  section?.id ===
                                                  y.inspectionMethod
                                                    .templateSectionId
                                              )
                                              .filter((z) => z === true)
                                              .length > 1 &&
                                            ", "}
                                        </Text>
                                      )}
                                    </>
                                  );
                                }
                              )}
                            </Box>
                            <Box p="0 6px" w="100%">
                              {section.components?.map(
                                (component: any, componentIndex: number) => {
                                  return (
                                    <Box
                                      w="100%"
                                      key={componentIndex + Math.random()}
                                    >
                                      <Text
                                        display="flex"
                                        alignItems="center"
                                        color="yellow.lighten2"
                                        as="h4"
                                        fontSize={{ sm: "16px", xxl: "18px" }}
                                        mb={{ sm: "8px", xxl: "10px" }}
                                      >
                                        <Text
                                          as="span"
                                          fontSize="18px"
                                          fontWeight="600"
                                          mr="6px"
                                        >
                                          {sectionIndex + 1}.
                                          {componentIndex + 1}
                                        </Text>
                                        {component.component_name}
                                      </Text>
                                      <Box
                                        mb="15px"
                                        borderBottom="1px solid"
                                        borderColor="gray.light3"
                                      >
                                        {component.catagory?.map(
                                          (
                                            catagory: any,
                                            catagoryIndex: number
                                          ) => {
                                            return (
                                              <Box
                                                mb="15px"
                                                key={
                                                  catagoryIndex + Math.random()
                                                }
                                              >
                                                <Text
                                                  as="h4"
                                                  display="flex"
                                                  alignItems="center"
                                                  fontSize={{
                                                    sm: "16px",
                                                    xxl: "18px",
                                                  }}
                                                  mb={{
                                                    sm: "8px",
                                                    xxl: "10px",
                                                  }}
                                                  color="blue.light3"
                                                >
                                                  <Text
                                                    as="span"
                                                    fontSize="18px"
                                                    fontWeight="600"
                                                    mr="6px"
                                                    color="#009ee199"
                                                  >
                                                    {sectionIndex + 1}.
                                                    {componentIndex + 1}.
                                                    {catagoryIndex + 1}
                                                  </Text>
                                                  {catagory.category_name}
                                                </Text>
                                                {catagory.narratives?.map(
                                                  (
                                                    narrative: any,
                                                    narrativeIndex: number
                                                  ) => {
                                                    return (
                                                      <Box
                                                        w="100%"
                                                        key={
                                                          narrativeIndex +
                                                          Math.random()
                                                        }
                                                      >
                                                        <Box>
                                                          <Box
                                                            w="100%"
                                                            mb="10px"
                                                          >
                                                            <Flex justifyContent="space-between">
                                                              <Box
                                                                display="flex"
                                                                alignItems="flex-start"
                                                                mb={{
                                                                  sm: "8px",
                                                                  xxl: "10px",
                                                                }}
                                                                w="calc(100% - 112px)"
                                                              >
                                                                <Text
                                                                  as="span"
                                                                  fontSize="18px"
                                                                  fontWeight="600"
                                                                  mr="6px"
                                                                  color="gray.light10"
                                                                >
                                                                  {sectionIndex +
                                                                    1}
                                                                  .
                                                                  {componentIndex +
                                                                    1}
                                                                  .
                                                                  {catagoryIndex +
                                                                    1}
                                                                  .
                                                                  {narrativeIndex +
                                                                    1}
                                                                </Text>
                                                                <Text
                                                                  as="p"
                                                                  fontSize={{
                                                                    sm: "16px",
                                                                    xxl: "18px",
                                                                  }}
                                                                  color="black.lighten"
                                                                  fontWeight="500"
                                                                >
                                                                  {narrative[
                                                                    "category-Narrative"
                                                                  ]?.description
                                                                    ?.replaceAll(
                                                                      /<.>/g,
                                                                      ""
                                                                    )
                                                                    ?.replaceAll(
                                                                      /<\/.>/g,
                                                                      ""
                                                                    )
                                                                    ?.replaceAll(
                                                                      /(<([^>]+)>)/gi,
                                                                      ""
                                                                    )
                                                                    ?.replaceAll(
                                                                      /&nbsp;/g,
                                                                      ""
                                                                    )}
                                                                </Text>
                                                              </Box>
                                                              <Box>
                                                                {narrative?.[
                                                                  "category-Narrative"
                                                                ]
                                                                  ?.description_color ? (
                                                                  <Flex
                                                                    bg={
                                                                      narrDesc?.find(
                                                                        (x) =>
                                                                          x.color ===
                                                                          narrative?.[
                                                                            "category-Narrative"
                                                                          ]
                                                                            ?.description_color
                                                                      )?.color
                                                                    }
                                                                    h="26px"
                                                                    alignItems="center"
                                                                    borderRadius="30px"
                                                                    p="0 8px"
                                                                  >
                                                                    <Image
                                                                      w="12px"
                                                                      src={
                                                                        narrDesc?.find(
                                                                          (x) =>
                                                                            x.color ===
                                                                            narrative?.[
                                                                              "category-Narrative"
                                                                            ]
                                                                              ?.description_color
                                                                        )?.image
                                                                      }
                                                                    />
                                                                    <Text
                                                                      color="white.primary"
                                                                      fontSize="12px"
                                                                      ml="5px"
                                                                      whiteSpace="nowrap"
                                                                    >
                                                                      {
                                                                        narrDesc?.find(
                                                                          (x) =>
                                                                            x.color ===
                                                                            narrative?.[
                                                                              "category-Narrative"
                                                                            ]
                                                                              ?.description_color
                                                                        )?.name
                                                                      }
                                                                    </Text>
                                                                  </Flex>
                                                                ) : (
                                                                  <Flex
                                                                    bg="black"
                                                                    h="26px"
                                                                    alignItems="center"
                                                                    borderRadius="30px"
                                                                    p="0 8px"
                                                                  >
                                                                    <Image
                                                                      w="12px"
                                                                      src={like}
                                                                    />
                                                                    <Text
                                                                      color="white.primary"
                                                                      fontSize="12px"
                                                                      ml="5px"
                                                                    >
                                                                      Compliant
                                                                    </Text>
                                                                  </Flex>
                                                                )}
                                                              </Box>
                                                            </Flex>
                                                            <Box
                                                              mt="15px"
                                                              d="flex"
                                                              flexWrap="wrap"
                                                              mx="auto"
                                                            >
                                                              {narrative.narrativeImages?.map(
                                                                (
                                                                  image: any
                                                                ) => {
                                                                  return (
                                                                    <Image
                                                                      key={
                                                                        image.id
                                                                      }
                                                                      order={{
                                                                        sm: 1,
                                                                        xxl: "unset",
                                                                      }}
                                                                      maxW="142px"
                                                                      float="right"
                                                                      mr="10px"
                                                                      mb="10px"
                                                                      src={
                                                                        report.editReport?.narrativeImages[
                                                                          narrative
                                                                            .randomKey
                                                                        ]?.find(
                                                                          (
                                                                            x: any
                                                                          ) =>
                                                                            x.imageId ===
                                                                            image.id
                                                                        )
                                                                          ?.accessImageUrl
                                                                      }
                                                                      alt=""
                                                                    />
                                                                  );
                                                                }
                                                              )}
                                                            </Box>
                                                          </Box>
                                                        </Box>
                                                      </Box>
                                                    );
                                                  }
                                                )}
                                              </Box>
                                            );
                                          }
                                        )}
                                      </Box>
                                    </Box>
                                  );
                                }
                              )}
                            </Box>
                          </React.Fragment>
                        );
                      }
                    )}
                    {!report.inspectionDetails.length && (
                      <Text>No Templates found</Text>
                    )}
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}
