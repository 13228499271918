import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../../api';

export interface adminDashboardState {
    error: any;
    loading: boolean[];
    adminRevenueDetails: any;
    adminDashboardDetails: any;
    adminScheduledJobs: any[];
    adminClients: any[];
    adminInspectionDetails: any[];
}

const initialState: adminDashboardState = {
    error: null,
    loading: [],
    adminRevenueDetails: [],
    adminDashboardDetails: null,
    adminScheduledJobs: [],
    adminClients: [],
    adminInspectionDetails: [],
};


export const adminRevenueDetails = createAsyncThunk(
    'get/totalRevenuedetails',
    async (data: { type: string }) => {
        const { type: detailsType } = data;
        const response = await api.post('admin/get/totalRevenuedetails', { data: { type: detailsType } });
        const adminRevenueData: any = Object.entries(response.data.RevenueData);
        adminRevenueData.unshift(["Month", "Sales"]);
        return adminRevenueData;
    }
);

export const getAdminScheduleJobs = createAsyncThunk(
    'admin/get/scheduleJob',
    async () => {
        const response = await api.post('admin/get/scheduleJob');
        return response.data;
    }
);

export const adminDashboardDetails = createAsyncThunk(
    'admin/revenue/dashboardDetail',
    async (data: { type: string }) => {
        const { type: detailsType } = data;
        const response = await api.post('admin/revenue/dashboardDetail', { data: { type: detailsType } });
        return response.data;
    }
);

export const getAdminClients = createAsyncThunk(
    'admin/get/clientDetails',
    async () => {
        const response = await api.get('admin/get/clientDetails');
        return response.data;
    }
);
export const adminInspectionDetails = createAsyncThunk(
    'admin/inspectionDetail',
    async (data: { type: string }) => {
        const { type: inspectionType } = data;
        const response = await api.post('admin/inspectionDetail', { data: { type: inspectionType } });
        const inspectionData: any = Object.entries(response.data.inspectionData);
        inspectionData.unshift(["Month", "Sales"]);
        return inspectionData;
    }
);

export const adminDashboardSlice = createSlice({
    name: 'adminDashboard',
    initialState,
    reducers: {
        setAdminDashboardError: (state, action) => {
            state.error = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(adminRevenueDetails.pending, (state) => {
                state.error = null;
                state.loading.push(true);
            })
            .addCase(adminRevenueDetails.rejected, (state, action) => {
                state.loading.pop();
                state.error = action.error;
            })
            .addCase(adminRevenueDetails.fulfilled, (state, action) => {
                state.error = null;
                state.loading.pop();
                state.adminRevenueDetails = action.payload;
            })
            .addCase(adminDashboardDetails.pending, (state) => {
                state.error = null;
                state.loading.push(true);
            })
            .addCase(adminDashboardDetails.rejected, (state, action) => {
                state.loading.pop();
                state.error = action.error;
            })
            .addCase(adminDashboardDetails.fulfilled, (state, action) => {
                state.error = null;
                state.loading.pop();
                state.adminDashboardDetails = action.payload;
            })
            .addCase(getAdminScheduleJobs.pending, (state) => {
                state.loading.push(true);
                state.error = null;
            })
            .addCase(getAdminScheduleJobs.rejected, (state, action) => {
                state.loading.pop();
                state.error = action.error;
            })
            .addCase(getAdminScheduleJobs.fulfilled, (state, action) => {
                state.loading.pop();
                state.error = null;
                state.adminScheduledJobs = action.payload;
            })
            .addCase(getAdminClients.pending, (state) => {
                state.error = null;
                state.loading.push(true);
            })
            .addCase(getAdminClients.rejected, (state, action) => {
                state.loading.pop();
                state.error = action.error;
            })
            .addCase(getAdminClients.fulfilled, (state, action) => {
                state.error = null;
                state.loading.pop();
                state.adminClients = action.payload;
            })
            .addCase(adminInspectionDetails.pending, (state) => {
                state.error = null;
                state.loading.push(true);
            })
            .addCase(adminInspectionDetails.rejected, (state, action) => {
                state.loading.pop();
                state.error = action.error;
            })
            .addCase(adminInspectionDetails.fulfilled, (state, action) => {
                state.error = null;
                state.loading.pop();
                state.adminInspectionDetails = action.payload;
            })
    },
});

export const { setAdminDashboardError } = adminDashboardSlice.actions;
export default adminDashboardSlice.reducer;