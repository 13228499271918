import { ComponentSingleStyleConfig } from "@chakra-ui/react";

const Button: ComponentSingleStyleConfig = {

    variants : {
        primary : {
            bg: '#00AEEF',
            color : '#ffffff',
            borderRadius : '5px',
            fontWeight : 'bold',
            fontSize: 'xxl',
            height:'52px',
            _focus : {
                outline : '0px',
                boxShadow : '0px',
            },
            _disabled: {
                bg: 'blue.light5',
                opacity: '1'
            }
        },   
        primary2 : {
            bg: 'blue.light3',
            color : '#ffffff',
            borderRadius : '4px',
            fontWeight : 'normal',
            fontSize: 'xl',
            height:'40px',
            p: '8px 16px',
            _focus : {
                outline : '0px',
                boxShadow : '0px',
            }
        },  
        primary3 : {
            bg: 'orange.primary',
            color : '#ffffff',
            borderRadius : '4px',
            fontWeight : 'normal',
            fontSize: 'xl',
            height:'40px',
            p: '8px 16px',
            _focus : {
                outline : '0px',
                boxShadow : '0px',
            }
        },  
        primaryMedium : {
            bg: 'blue.primary',
            color : '#ffffff',
            borderRadius : '4px',
            fontWeight : 'normal',
            fontSize: 'xl',
            height:'40px',
            p: '8px 16px',
            _focus : {
                outline : '0px',
                boxShadow : '0px',
            }
        }, 
        primarySmall: {
            bg: '#00AEEF',
            color : '#ffffff',
            borderRadius : '4px',
            fontWeight : 'normal',
            fontSize: 'xxl',
            height:'38px',
            p: '0 22px',
            _focus : {
                outline : '0px',
                boxShadow : '0px',
            }
        },  
        primaryExtraSmall: {
            bg: '#00AEEF',
            color : '#ffffff',
            borderRadius : '4px',
            fontWeight : 'normal',
            fontSize: {sm: '14px', lg: 'lg'},
            height:'32px',
            p: '0 8px',
            _focus : {
                outline : '0px',
                boxShadow : '0px',
            }
        },    
        outlined: {
            bg: 'white.primary',
            color : 'black.lighten',
            borderRadius : '4px',
            fontWeight : 'normal',
            fontSize: 'xl',
            height:'40px',
            p: '0 22px',
            border: '1px solid',
            borderColor: 'black.lighten',
            _focus : {
                outline : '0px',
                boxShadow : '0px',
            }
        },  
        secondary: {
            bg: 'white.primary',
            color : 'black.lighten',
            borderRadius : '4px',
            fontWeight : 'normal',
            fontSize: 'xl',
            height:'40px',
            p: '0 22px',
            _focus : {
                outline : '0px',
                boxShadow : '0px',
            }
        },  
        secondary2: {
            bg: 'transparent',
            color : 'black.lighten',
            borderRadius : '4px',
            fontWeight : 'normal',
            fontSize: 'xl',
            height:'40px',
            p: '0 22px',
            _focus : {
                outline : '0px',
                boxShadow : '0px',
            }
        },
        transparent: {
            bg: 'transparent',
            color : 'gray.primary',
            borderRadius : '4px',
            fontWeight : 'normal',
            fontSize: 'xl',
            height:'40px',
            p: '0',
            _focus : {
                outline : '0px',
                boxShadow : '0px',
            }
        },
        grey: {
            bg: 'gray.light3',
            color : 'gray.primary',
            borderRadius : '4px',
            fontWeight : 'normal',
            fontSize: 'xl',
            height:'40px',
            _focus : {
                outline : '0px',
                boxShadow : '0px',
            }
        },
        iconBtn: {
            bg: 'transparent',
            color : 'transparent',
            fontWeight : 'normal',
            fontSize : 'xl',
            _focus : {
                outline : '0px',
                boxShadow : '0px',
            }
        },
        bordered: {
            border: '1px solid',
            borderColor: 'gray.primary',
            p: '0 13px',
            fontSize: 'xl',
            bg: 'transparent',
            color: 'gray.primary',
            fontWeight: 'normal',
            borderRadius: '4px',
            _focus : {
                outline : '0px',
                boxShadow : '0px',
            }
        },
        redBtn : {
            bg: 'red.dark',
            color : '#ffffff',
            borderRadius : '4px',
            fontWeight : 'normal',
            fontSize: 'xl',
            height:'40px',
            p: '8px 16px',
            _focus : {
                outline : '0px',
                boxShadow : '0px',
            }
        },
    },

}

export default Button