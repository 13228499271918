import { useEffect } from 'react'
import { MdModeEdit } from 'react-icons/md'
import { Box, Button, Flex, Icon, Modal, ModalBody, ModalContent, ModalHeader, ModalOverlay, Radio, RadioGroup, Text, useDisclosure } from '@chakra-ui/react'
import { IoMdAdd, IoMdClose, } from 'react-icons/io'
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../redux/store'
import { toastr } from 'react-redux-toastr'
import { getTemplateById, getTemplateSection, resetTemplate, resetTemplateTabs, setTemplateError, TemplateState } from '../../../../redux/modules/template'
import Loader from '../../../../components/common/loader'
import { authState, setAuthError } from '../../../../redux/modules/auth'
import { Section, useTemplateState } from '../CreateTemplate/CreateTemplate'
import stores from 'store2';

export default function TemplateView() {
  useTemplateState();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const auth: authState = useSelector((state: RootState) => state.auth);
  const template: TemplateState = useSelector((state: RootState) => state.template);
  const { isOpen: createTemplate, onOpen: openCreateTemplate, onClose: closeCreateTemplate } = useDisclosure()

  // Initial value loder
  // ... render page resources

  const newTemplateId = stores.get("templateId");

  useEffect(() => {
    if (newTemplateId) {
      dispatch(getTemplateById({ templateId: newTemplateId }));
      dispatch(getTemplateSection({ payload: { templateId: newTemplateId } }));
    }
  }, [dispatch, newTemplateId]);

  const handleCreateTemplate = () => {
    dispatch(resetTemplate('all'));
    dispatch(resetTemplateTabs('all'));
    navigate('/template/create-template');
  }

  const previewEditTemplate = async (action: string) => {
    dispatch(resetTemplate('all'));
    dispatch(resetTemplateTabs('all'));
    navigate(`/template/${action}-template/${newTemplateId}`);
  }

  useEffect(() => {
    if (auth.error) {
      const { message } = auth.error;
      toastr.error('', message ? message : '');
    } else if (template.error) {
      const { message } = template.error;
      toastr.error('', message ? message : '');
    }

    return () => {
      dispatch(setAuthError(null));
      dispatch(setTemplateError(null));
    }
  }, [auth.error, dispatch, template.error]);

  return (
    <>
      {(auth.loginLoading.length !== 0 || template.loading.length !== 0) && <Loader />}
      <Box p='29px 20px 20px' h='calc(100vh - 70px)' overflow='auto' w='100%'>
        <Box m='0'>
          <Box alignItems='center' flexWrap='wrap' display='flex' justifyContent='space-between'>
            <Text as='h1' d='inline-block' color='black.lighten' fontSize='24px' lineHeight='30px' fontWeight='600'>Template</Text>
            {auth?.user?.templateId && <Box ml='auto'>
              <Link to='/template/template-center'>
                <Button variant='bordered' mr='15px'>
                  <Box mr='10px'>
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M1 4C0.45 4 0 4.45 0 5V18C0 19.1 0.9 20 2 20H15C15.55 20 16 19.55 16 19C16 18.45 15.55 18 15 18H3C2.45 18 2 17.55 2 17V5C2 4.45 1.55 4 1 4ZM18 0H6C4.9 0 4 0.9 4 2V14C4 15.1 4.9 16 6 16H18C19.1 16 20 15.1 20 14V2C20 0.9 19.1 0 18 0ZM16 9H8C7.45 9 7 8.55 7 8C7 7.45 7.45 7 8 7H16C16.55 7 17 7.45 17 8C17 8.55 16.55 9 16 9ZM12 13H8C7.45 13 7 12.55 7 12C7 11.45 7.45 11 8 11H12C12.55 11 13 11.45 13 12C13 12.55 12.55 13 12 13ZM16 5H8C7.45 5 7 4.55 7 4C7 3.45 7.45 3 8 3H16C16.55 3 17 3.45 17 4C17 4.55 16.55 5 16 5Z" fill="#888888" />
                    </svg>
                  </Box>
                  Template Center
                </Button>
              </Link>
              <Button variant='bordered' mr='15px' onClick={openCreateTemplate}>
                <Icon fontSize='24px' mr='6px' as={IoMdAdd}></Icon>
                Create Template
              </Button>
              {auth?.user?.templateId && <Button variant='primary2' onClick={() => previewEditTemplate('edit')}>
                <Icon fontSize={{ sm: '19px', xl: '21px' }} mr='10px' as={MdModeEdit}></Icon>
                Edit
              </Button>}
            </Box>}
          </Box>
          {auth?.user?.templateId && template?.template?.template_name && template.sections.length >= 1 && <Text as='h1' display='inline-block' mt='25px' color='black.lighten' fontSize='24px' lineHeight='30px' fontWeight='600'>{template?.template?.template_name}</Text>}
          {(auth?.user?.templateId && template.sections.length >= 1) && <Section action='preview' />}
          {!auth?.user?.templateId && <Flex w='100%' alignItems='center' justifyContent='center' h='71vh'>
            <Link to='/template/template-center'>
              <Button variant='bordered' mr='15px' w='250px' h='250px' display='flex' flexDirection='column' >
                <Box mb='10px'>
                  <svg width="22" height="22" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 4C0.45 4 0 4.45 0 5V18C0 19.1 0.9 20 2 20H15C15.55 20 16 19.55 16 19C16 18.45 15.55 18 15 18H3C2.45 18 2 17.55 2 17V5C2 4.45 1.55 4 1 4ZM18 0H6C4.9 0 4 0.9 4 2V14C4 15.1 4.9 16 6 16H18C19.1 16 20 15.1 20 14V2C20 0.9 19.1 0 18 0ZM16 9H8C7.45 9 7 8.55 7 8C7 7.45 7.45 7 8 7H16C16.55 7 17 7.45 17 8C17 8.55 16.55 9 16 9ZM12 13H8C7.45 13 7 12.55 7 12C7 11.45 7.45 11 8 11H12C12.55 11 13 11.45 13 12C13 12.55 12.55 13 12 13ZM16 5H8C7.45 5 7 4.55 7 4C7 3.45 7.45 3 8 3H16C16.55 3 17 3.45 17 4C17 4.55 16.55 5 16 5Z" fill="#888888" />
                  </svg>
                </Box>
                <Text fontSize='xxxl'>Template Center</Text>
              </Button>
            </Link>
            <Button variant='bordered' mr='15px' onClick={openCreateTemplate} w='250px' h='250px'>
              <Flex flexDirection='column' justifyContent='center' alignItems='center'>
                <Icon fontSize='24px' mb='10px' as={IoMdAdd}></Icon>
                <Text fontSize='xxxl'>Create Template</Text>
              </Flex>
            </Button>
          </Flex>}
        </Box>
      </Box>
      <Modal isOpen={createTemplate} onClose={closeCreateTemplate} size='xl' scrollBehavior='inside'>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader p='30px 30px 35px' fontSize='21px' lineHeight='26px' color='black.lighten' fontWeight='600'>Create Template</ModalHeader>
          <Icon color='black.primary' fontSize='24px' as={IoMdClose} onClick={closeCreateTemplate} cursor='pointer' position='absolute' top='29px' right='29px'></Icon>
          <ModalBody p='0 30px 30px'>
            <RadioGroup d='flex' justifyContent='space-between'>
              <Box p='10px' border='1px solid' borderColor='gray.primary' w='48%' borderRadius='8px' onClick={handleCreateTemplate}>
                <Radio variant='primary' className='radio-black-label' value='scratch'>Create template from scratch</Radio>
              </Box>
              <Box p='10px' border='1px solid' borderColor='gray.primary' w='48%' borderRadius='8px'>
                <Link to='/template/template-center'>
                  <Radio variant='primary' className='radio-black-label' value='default'>Choose from default templates</Radio>
                </Link>
              </Box>
            </RadioGroup>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}