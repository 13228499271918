import { Route, Routes } from 'react-router-dom';

import Main from './pages/common/Main';
import Login from './pages/common/Login';
import Signup from './pages/common/Signup';
import Otp from './pages/common/Login/Otp';
import Dashboard from './components/Dashboard';
import InvoiceListing from './components/Invoice';
import Subscription from './components/Subscription';
import Settings from './pages/home-inspector/Settings';
import InvoicePayment from './components/Invoice/InvoicePayment';
import PaymentFailed from './pages/common/PaymentFailed';
import AddReport from './pages/home-inspector/Report/AddReport';
import EmailVerification from './pages/common/EmailVerification';
import TemplateView from './pages/common/Template/TemplateView';
import TemplateCenter from './pages/common/Template/templateCenter';
import CreateTemplate from './pages/common/Template/CreateTemplate';
import ReportDetails from './pages/home-inspector/Report/ReportDetails';
import ForgotPassword from './pages/common/ForgotPassword';
import ReportListing from './pages/home-inspector/Report/ReportListing';
import AgreementSignature from './pages/home-inspector/AgreementSignature';
import PaymentSuccess from './pages/common/PaymentSuccess';
import StaffManagement from './components/StaffManagement';
import Users from './components/Users';
import HelpCenter from './components/HelpCenter';
import InvoiceDetails from './pages/home-inspector/Report/InvoiceDetail';
import InspectionReport from './pages/common/InspectionReport';
import Invoice from './pages/home-inspector/Report/Invoice';
import GeneratePassword from './pages/common/GeneratePassword/GeneratePassword';
import AdminDashboard from './components/AdminDashboard';
import CompanyListing from './components/CompanyListing';
import SuperAdminDashboard from './components/SuperAdminDashboard';
import { useSelector } from 'react-redux';
import { authState } from './redux/modules/auth';
import { RootState } from './redux/store'
import SuperAdminSettings from './components/SuperAdminSettings';
import CloudReports from './pages/home-inspector/Report/CloudReports';


export default function AppRoutes() {
  const auth: authState = useSelector((state: RootState) => state.auth);

  const findDashboard = () => {
    if (auth.user?.user_Type === 'company_admin') {
      return <AdminDashboard />
    }
    else if (auth.user?.user_Type === 'super_admin') {
      return <SuperAdminDashboard />
    }
    else {
      return <Dashboard />
    }
  }

  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/signup" element={<Signup />} />
      <Route path="/otp" element={<Otp />} />
      <Route path="/payment-failed" element={<PaymentFailed />} />
      <Route path="/payment-success" element={<PaymentSuccess />} />
      <Route path="/invoice/payment" element={<InvoicePayment />} />
      <Route path="/useractivate/:token" element={<EmailVerification />} />
      <Route path="/forgot-password/:token" element={<ForgotPassword />} />
      <Route path="/agreement-signature/:id" element={<AgreementSignature />} />
      <Route path="/inspection-report/:id" element={<InspectionReport />} />
      <Route path="/invoice-details/:id" element={<InvoiceDetails />} />
      <Route path="/generate-password/:token" element={<GeneratePassword />} />

      <Route path="/" element={<Main />}>
        {auth.user?.user_Type && (auth?.user?.user_Type === "super_admin" ? <Route path="/settings" element={<SuperAdminSettings />} /> : <Route path="/settings" element={<Settings />} />)}
        <Route path="/subscription" element={<Subscription />} />
        <Route path="/invoices" element={<InvoiceListing />} />
        <Route path="/reports" element={<ReportListing />} />
        <Route path="/reports/:id" element={<ReportListing />} />
        <Route path="/cloud-reports" element={<CloudReports />} />
        <Route path="/add-report" element={<AddReport />} />
        <Route path="/edit-report/:id" element={<AddReport />} />
        <Route path="/report-detail/:id" element={<ReportDetails />} />
        <Route path="/admin-report-detail/:id" element={<ReportDetails />} />
        <Route path="/template" element={<TemplateView />} />
        <Route path="/template/template-center" element={<TemplateCenter />} />
        <Route path="/template/create-template" element={<CreateTemplate />} />
        <Route path="/template/edit-template/:id" element={<CreateTemplate />} />
        <Route path="/template/preview-template/:id" element={<CreateTemplate />} />
        {auth.user?.user_Type && <Route path="/dashboard" element={findDashboard()} />}
        <Route path="/staff-management" element={<StaffManagement />} />
        <Route path="/users" element={<Users />} />
        <Route path="/company-listing" element={<CompanyListing />} />
        <Route path="/help-center" element={<HelpCenter />} />
        <Route path="/invoice/:id" element={<Invoice />} />
      </Route>
    </Routes>
  );
}