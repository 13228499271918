import { ComponentMultiStyleConfig } from "@chakra-ui/react";

const radio: ComponentMultiStyleConfig = {
  parts: ['container', 'control', 'label'],
  variants: {
    primary: {
      control: {
        width: '24px',
        height: '24px',
        borderColor: 'gray.primary',
        border: '1px solid',
        _focus: {
          outline: '0px',
          boxShadow: '0px',
        },
        _checked: {
          bg: 'blue.dark',
          borderColor: 'blue.dark'
        }
      },
      label: {
        color: 'gray.primary',
        fontSize: '18px',
        lineHeight: '23px',
        marginLeft: '10px',
        textAlign: 'left'
      }
    },
    primarySmall: {
      control: {
        width: '18px',
        height: '18px',
        borderColor: 'gray.primary',
        border: '1px solid',
        _focus: {
          outline: '0px',
          boxShadow: '0px',
        },
        _checked: {
          bg: 'blue.primary',
          borderColor: 'blue.primary'
        }
      },
      label: {
        color: 'gray.primary',
        fontSize: '18px',
        lineHeight: '23px',
        marginLeft: '10px',
        textAlign: 'left'
      }
    },

    secondary: {
      control: {
        width: '24px',
        height: '24px',
        borderColor: 'black.lighten',
        border: '1px solid',
        _hover:{
          bg:'none'
        },
        _focus: {
          outline: '0px',
          boxShadow: '0px',
        },
        _checked: {
          bg: 'orange.primary',
          borderColor: 'orange.light',
          _hover:{
            bg: 'orange.primary',
          borderColor: 'orange.light',
          }
        }
        
      },
      label: {
        color: 'gray.primary',
        fontSize: '18px',
        lineHeight: '23px',
        marginLeft: '10px',
        textAlign: 'left'
      }
    }
  }
}

export default radio