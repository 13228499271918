import {
  Box,
  Button,
  Flex,
  Icon,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Switch,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { IoIosCheckmarkCircle, IoMdClose } from "react-icons/io";
import { toastr } from "react-redux-toastr";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { MdCheckCircleOutline, MdHighlightOff } from "react-icons/md";
import { Link } from "react-router-dom";
import {
  authState,
  setAuthError,
  setLoading,
  unsetLoading,
  userDetailById,
} from "../../redux/modules/auth";
import {
  subscriptionState,
  setSubscriptionError,
  cancelAdminSubscriptionPlan,
  createSubscription,
} from "../../redux/modules/subscription";
import Loader from "../common/loader";

export function Subscription() {
  const dispatch = useDispatch();
  const auth: authState = useSelector((state: RootState) => state.auth);
  const subscription: subscriptionState = useSelector(
    (state: RootState) => state.subscription
  );
  const {
    isOpen: showCancelSubscription,
    onOpen: openCancelSubscription,
    onClose: closeCancelSubscription,
  } = useDisclosure();
  const [expiryTime, setSxpiryTime] = useState(0);
  const [inspectorSubPlan, setInspectorSubPlan] = useState(false);

  // Initial value loder
  // ... render page resources
  useEffect(() => {
    if (auth?.user) {
      const expire = new Date(auth.user.expireTime?.split("T")[0]);
      const difference = expire.getTime() - new Date().getTime();
      setSxpiryTime(Math.ceil(difference / (1000 * 3600 * 24)));
      setInspectorSubPlan(auth?.user.planType === "year" ? true : false);
    }
  }, [auth.user]);

  const planSubmit = (action: string) => {
    new Promise(() => {
      const data: any = dispatch(
        createSubscription({
          payload: { userId: auth.user.id, planType: action },
        })
      );

      console.log("Data", data);
      data.then(function (value) {
        if (value.type === "subscription/create/fulfilled") {
          window.open(value.payload, "_self"); // Redirect for checkout
        }
      });
    });
  };

  const handleCancelSubscription = () => {
    new Promise(() => {
      const data2: any = dispatch(
        cancelAdminSubscriptionPlan({ payload: { userId: auth.user.id } })
      );
      data2.then(function (value) {
        if (
          value.type === "subscription/cancelAdminSubscriptionPlan/fulfilled"
        ) {
          toastr.success("", "Subscription cancel successfully");
          closeCancelSubscription();
          dispatch(setLoading());
          setTimeout(() => {
            dispatch(unsetLoading());
            dispatch(userDetailById({ userId: auth.user.id }));
          }, 10000);
        }
      });
    });
  };

  useEffect(() => {
    if (auth.error) {
      const { message } = auth.error;
      toastr.error("", message ? message : "");
    } else if (subscription.error) {
      const { message } = subscription.error;
      toastr.error("", message ? message : "");
    }

    return () => {
      dispatch(setAuthError(null));
      dispatch(setSubscriptionError(null));
    };
  }, [auth.error, dispatch, subscription.error]);

  return (
    <>
      {(auth.loginLoading.length !== 0 ||
        subscription.loading.length !== 0) && <Loader />}
      <Box
        p="29px 30px 20px 20px"
        h="calc(100vh - 70px)"
        overflow="auto"
        w="100%"
      >
        <Flex
          justifyContent="space-between"
          alignItems="center"
          mb={{ sm: "16px", xl: "26px" }}
        >
          <Text
            as="h1"
            color="black.lighten"
            fontSize="24px"
            lineHeight="30px"
            fontWeight="600"
          >
            Subscription
          </Text>
          <Link to="/invoices">
            <Text
              as="span"
              fontSize="16px"
              fontWeight="500"
              textDecoration="underline"
              color="blue.light3"
            >
              View your last transactions
            </Text>
          </Link>
        </Flex>
        <Flex alignItems="flex-start">
          <Box mr="auto" pr="15px">
            <Text
              as="h1"
              mb="12px"
              color="black.lighten"
              fontSize="20px"
              lineHeight="30px"
              fontWeight="600"
            >
              Pricing for subscription Plan
            </Text>
            {auth?.user?.is_trail_end === "false" && expiryTime >= 0 && (
              <Text
                mb="29px"
                fontSize="18px"
                lineHeight={{ sm: "22px", lg: "25px" }}
                as="p"
              >
                Your{" "}
                <Text color="blue.light3" as="span">
                  7 Days Free Plan
                </Text>{" "}
                is Expiring in {expiryTime} days. Click on to upgrade to new
                plan or change plan.
              </Text>
            )}
            {auth?.user?.is_trail_end === "true" && expiryTime >= 0 && (
              <Text
                mb="29px"
                fontSize="18px"
                lineHeight={{ sm: "22px", lg: "25px" }}
                as="p"
              >
                Your subscription is Expiring in {expiryTime} days.
              </Text>
            )}
          </Box>
          {auth.user?.role && (
            <Button
              variant="primary2"
              onClick={openCancelSubscription}
              mt="5px"
              minW="170px"
            >
              Cancel Subscription
            </Button>
          )}
        </Flex>
        <Flex alignItems="center" justifyContent="center" mb="18px">
          <Text fontSize="20px" color="black.lighten">
            Monthly
          </Text>
          <Switch
            variant="primary"
            size="md"
            isChecked={inspectorSubPlan}
            onChange={(e) =>
              setInspectorSubPlan(e.target.checked ? true : false)
            }
            m="0 15px"
          />
          <Text fontSize="20px" color="black.lighten">
            Annually
          </Text>
        </Flex>
        <Box bg="white">
          {inspectorSubPlan ? (
            <>
              <Flex
                w="100%"
                bg="white.primary"
                p={{ sm: "30px", lg: "40px" }}
                justifyContent="center"
              >
                <Box
                  d="inline-flex"
                  alignItems="flex-start"
                  justifyContent="flex-start"
                  flexDir="column"
                  minW="400px"
                  borderWidth="2px"
                  borderRadius="lg"
                  borderColor={`${
                    auth.user?.role === "Yearly Growth Subscriber"
                      ? "blue.primary"
                      : "black.lighten"
                  }`}
                  p={{ sm: "5", md: "6" }}
                  rounded="md"
                  bg="white"
                >
                  <Flex w="100%">
                    <Box textAlign="left">
                      <Text
                        fontSize="16px"
                        fontWeight="600"
                        lineHeight="22px"
                        color="blue.primary"
                      >
                        Growth
                      </Text>
                      <Flex m="10px 0" alignItems="flex-end">
                        <Text
                          fontSize="50px"
                          fontWeight="600"
                          lineHeight="50px"
                          color="black.primary"
                        >
                          $899
                        </Text>
                        <Text fontSize="20px" as="span" ml="4px">
                          /year
                        </Text>
                      </Flex>
                    </Box>
                    {auth.user?.role === "Yearly Growth Subscriber" && (
                      <Box ml="auto" pl="15px">
                        <Icon
                          fontSize="38px"
                          color="blue.primary"
                          as={IoIosCheckmarkCircle}
                        ></Icon>
                      </Box>
                    )}
                  </Flex>
                  <Text fontSize="16px" lineHeight="22px" color="gray.primary">
                    Get your business up and running
                  </Text>
                  <Box display="block" m="32px 0 15px" w="100%">
                    {auth.user?.role !== "Yearly Growth Subscriber" && (
                      <Button
                        variant="outlined"
                        width="100%"
                        height="45px"
                        m="10px 0"
                        onClick={() => planSubmit("yearly_Growth")}
                        borderWidth="2px"
                        fontWeight="600"
                      >
                        Subscribe Now
                      </Button>
                    )}
                  </Box>
                  <Text
                    fontSize="16px"
                    fontWeight="600"
                    lineHeight="22px"
                    color="black.primary"
                    mb="18px"
                  >
                    Features
                  </Text>
                  <Flex mb={{ sm: "15px", md: "20px" }}>
                    <Icon
                      as={MdCheckCircleOutline}
                      fontSize="25px"
                      color="blue.primary"
                      mr="7px"
                    ></Icon>
                    <Text
                      lineHeight="22px"
                      color="black.primary"
                      fontSize="18px"
                    >
                      Dynamic Report writer
                    </Text>
                  </Flex>

                  <Flex mb={{ sm: "15px", md: "20px" }}>
                    <Icon
                      as={MdHighlightOff}
                      fontSize="25px"
                      color="red.primary"
                      mr="7px"
                    ></Icon>
                    <Text
                      lineHeight="22px"
                      color="black.primary"
                      fontSize="18px"
                    >
                      Create Unlimited Inspectors
                    </Text>
                  </Flex>
                  <Flex mb={{ sm: "15px", md: "20px" }}>
                    <Icon
                      as={MdCheckCircleOutline}
                      fontSize="25px"
                      color="blue.primary"
                      mr="7px"
                    ></Icon>
                    <Text
                      lineHeight="22px"
                      color="black.primary"
                      fontSize="18px"
                    >
                      Template Editor
                    </Text>
                  </Flex>
                  <Flex mb={{ sm: "15px", md: "20px" }}>
                    <Icon
                      as={MdCheckCircleOutline}
                      fontSize="25px"
                      color="blue.primary"
                      mr="7px"
                    ></Icon>
                    <Text
                      lineHeight="22px"
                      color="black.primary"
                      fontSize="18px"
                    >
                      Edit “On the Fly”{" "}
                    </Text>
                  </Flex>
                  <Flex mb={{ sm: "15px", md: "20px" }}>
                    <Icon
                      as={MdCheckCircleOutline}
                      fontSize="25px"
                      color="blue.primary"
                      mr="7px"
                    ></Icon>
                    <Text
                      lineHeight="22px"
                      color="black.primary"
                      fontSize="18px"
                    >
                      Automation
                    </Text>
                  </Flex>
                  <Flex mb={{ sm: "15px", md: "20px" }}>
                    <Icon
                      as={MdCheckCircleOutline}
                      fontSize="25px"
                      color="blue.primary"
                      mr="7px"
                    ></Icon>
                    <Text
                      lineHeight="22px"
                      color="black.primary"
                      fontSize="18px"
                    >
                      Business
                    </Text>
                  </Flex>
                  <Flex mb={{ sm: "15px", md: "20px" }}>
                    <Icon
                      as={MdHighlightOff}
                      fontSize="25px"
                      color="red.primary"
                      mr="7px"
                    ></Icon>
                    <Text
                      lineHeight="22px"
                      color="black.primary"
                      fontSize="18px"
                    >
                      CRM
                    </Text>
                  </Flex>
                  <Flex mb={{ sm: "15px", md: "20px" }}>
                    <Icon
                      as={MdHighlightOff}
                      fontSize="25px"
                      color="red.primary"
                      mr="7px"
                    ></Icon>
                    <Text
                      lineHeight="22px"
                      color="black.primary"
                      fontSize="18px"
                    >
                      Funnel
                    </Text>
                  </Flex>
                </Box>
                <Box
                  display="inline-flex"
                  alignItems="flex-start"
                  justifyContent="flex-start"
                  flexDir="column"
                  minW="400px"
                  borderWidth="2px"
                  borderRadius="lg"
                  borderColor={`${
                    auth.user?.role === "Yearly Pro Subscriber"
                      ? "blue.primary"
                      : "black.lighten"
                  }`}
                  p={{ sm: "5", md: "6" }}
                  rounded="md"
                  bg="white"
                  ml="20px"
                >
                  <Flex w="100%">
                    <Box textAlign="left">
                      <Text
                        fontSize="16px"
                        fontWeight="600"
                        lineHeight="22px"
                        color="blue.primary"
                      >
                        Pro
                      </Text>
                      <Flex m="10px 0" alignItems="flex-end">
                        <Text
                          fontSize="50px"
                          fontWeight="600"
                          lineHeight="50px"
                          color="black.primary"
                        >
                          $990
                        </Text>
                        <Text fontSize="20px" as="span" ml="4px">
                          /year
                        </Text>
                      </Flex>
                    </Box>
                    {auth.user?.role === "Yearly Pro Subscriber" && (
                      <Box ml="auto" pl="15px">
                        <Icon
                          fontSize="38px"
                          color="blue.primary"
                          as={IoIosCheckmarkCircle}
                        ></Icon>
                      </Box>
                    )}
                  </Flex>
                  <Text fontSize="16px" lineHeight="22px" color="gray.primary">
                    Get your business up and running
                  </Text>
                  <Text
                    mt="6px"
                    as="span"
                    fontSize="18px"
                    display="block"
                    fontWeight="500"
                  >
                    Additional Inspectors:{" "}
                    {/* <Text fontWeight="600" as="span">
                      $99/month
                    </Text>{" "}
                    or{" "} */}
                    <Text fontWeight="600" as="span">
                      $99/year
                    </Text>
                  </Text>
                  <Box display="block" mb="15px" w="100%">
                    {auth.user?.role !== "Yearly Pro Subscriber" && (
                      <Button
                        variant="outlined"
                        width="100%"
                        height="45px"
                        m="10px 0"
                        onClick={() => planSubmit("yearly_Pro")}
                        borderWidth="2px"
                        fontWeight="600"
                      >
                        Subscribe Now
                      </Button>
                    )}
                  </Box>
                  <Text
                    fontSize="16px"
                    fontWeight="600"
                    lineHeight="22px"
                    color="black.primary"
                    mb="18px"
                  >
                    Features
                  </Text>
                  <Flex mb={{ sm: "15px", md: "20px" }}>
                    <Icon
                      as={MdCheckCircleOutline}
                      fontSize="25px"
                      color="blue.primary"
                      mr="7px"
                    ></Icon>
                    <Text
                      lineHeight="22px"
                      color="black.primary"
                      fontSize="18px"
                    >
                      Dynamic Report writer
                    </Text>
                  </Flex>

                  <Flex mb={{ sm: "15px", md: "20px" }}>
                    <Icon
                      as={MdCheckCircleOutline}
                      fontSize="25px"
                      color="blue.primary"
                      mr="7px"
                    ></Icon>
                    <Text
                      lineHeight="22px"
                      color="black.primary"
                      fontSize="18px"
                    >
                      Create Unlimited Inspectors
                    </Text>
                  </Flex>
                  <Flex mb={{ sm: "15px", md: "20px" }}>
                    <Icon
                      as={MdCheckCircleOutline}
                      fontSize="25px"
                      color="blue.primary"
                      mr="7px"
                    ></Icon>
                    <Text
                      lineHeight="22px"
                      color="black.primary"
                      fontSize="18px"
                    >
                      Template Editor
                    </Text>
                  </Flex>
                  <Flex mb={{ sm: "15px", md: "20px" }}>
                    <Icon
                      as={MdCheckCircleOutline}
                      fontSize="25px"
                      color="blue.primary"
                      mr="7px"
                    ></Icon>
                    <Text
                      lineHeight="22px"
                      color="black.primary"
                      fontSize="18px"
                    >
                      Edit “On the Fly”{" "}
                    </Text>
                  </Flex>
                  <Flex mb={{ sm: "15px", md: "20px" }}>
                    <Icon
                      as={MdCheckCircleOutline}
                      fontSize="25px"
                      color="blue.primary"
                      mr="7px"
                    ></Icon>
                    <Text
                      lineHeight="22px"
                      color="black.primary"
                      fontSize="18px"
                    >
                      Automation
                    </Text>
                  </Flex>
                  <Flex mb={{ sm: "15px", md: "20px" }}>
                    <Icon
                      as={MdCheckCircleOutline}
                      fontSize="25px"
                      color="blue.primary"
                      mr="7px"
                    ></Icon>
                    <Text
                      lineHeight="22px"
                      color="black.primary"
                      fontSize="18px"
                    >
                      Business
                    </Text>
                  </Flex>
                  <Flex mb={{ sm: "15px", md: "20px" }}>
                    <Icon
                      as={MdHighlightOff}
                      fontSize="25px"
                      color="red.primary"
                      mr="7px"
                    ></Icon>
                    <Text
                      lineHeight="22px"
                      color="black.primary"
                      fontSize="18px"
                    >
                      CRM
                    </Text>
                  </Flex>
                  <Flex mb={{ sm: "15px", md: "20px" }}>
                    <Icon
                      as={MdHighlightOff}
                      fontSize="25px"
                      color="red.primary"
                      mr="7px"
                    ></Icon>
                    <Text
                      lineHeight="22px"
                      color="black.primary"
                      fontSize="18px"
                    >
                      Funnel
                    </Text>
                  </Flex>
                </Box>
              </Flex>
              <Flex
                w="100%"
                bg="white.primary"
                // p={{ sm: "30px", lg: "40px" }}
                justifyContent="center"
              >
                <Box
                  d="inline-flex"
                  alignItems="flex-start"
                  justifyContent="flex-start"
                  flexDir="column"
                  minW="400px"
                  borderWidth="2px"
                  borderRadius="lg"
                  borderColor={`${
                    auth.user?.role === "Growth Subscriber"
                      ? "blue.primary"
                      : "black.lighten"
                  }`}
                  p={{ sm: "5", md: "6" }}
                  rounded="md"
                  bg="white"
                >
                  <Flex w="100%">
                    <Box textAlign="left">
                      <Text
                        fontSize="16px"
                        fontWeight="600"
                        lineHeight="22px"
                        color="blue.primary"
                      >
                        Growth Plus
                      </Text>
                      <Flex m="10px 0" alignItems="flex-end">
                        <Text
                          fontSize="50px"
                          fontWeight="600"
                          lineHeight="50px"
                          color="black.primary"
                        >
                          $1870
                        </Text>
                        <Text fontSize="20px" as="span" ml="4px">
                          /year
                        </Text>
                      </Flex>
                    </Box>
                    {auth.user?.role === "Yearly Growth Plus Subscriber" && (
                      <Box ml="auto" pl="15px">
                        <Icon
                          fontSize="38px"
                          color="blue.primary"
                          as={IoIosCheckmarkCircle}
                        ></Icon>
                      </Box>
                    )}
                  </Flex>
                  <Text fontSize="16px" lineHeight="22px" color="gray.primary">
                    Get your business up and running
                  </Text>
                  <Box display="block" m="32px 0 15px" w="100%">
                    {auth.user?.role !== "Yearly Growth Plus Subscriber" && (
                      <Button
                        variant="outlined"
                        width="100%"
                        height="45px"
                        m="10px 0"
                        onClick={() => planSubmit("yearly_Growth_Plus")}
                        borderWidth="2px"
                        fontWeight="600"
                      >
                        Subscribe Now
                      </Button>
                    )}
                  </Box>
                  <Text
                    fontSize="16px"
                    fontWeight="600"
                    lineHeight="22px"
                    color="black.primary"
                    mb="18px"
                  >
                    Features
                  </Text>
                  <Flex mb={{ sm: "15px", md: "20px" }}>
                    <Icon
                      as={MdCheckCircleOutline}
                      fontSize="25px"
                      color="blue.primary"
                      mr="7px"
                    ></Icon>
                    <Text
                      lineHeight="22px"
                      color="black.primary"
                      fontSize="18px"
                    >
                      Dynamic Report writer
                    </Text>
                  </Flex>
                  <Flex mb={{ sm: "15px", md: "20px" }}>
                    <Icon
                      as={MdHighlightOff}
                      fontSize="25px"
                      color="red.primary"
                      mr="7px"
                    ></Icon>
                    <Text
                      lineHeight="22px"
                      color="black.primary"
                      fontSize="18px"
                    >
                      Create Unlimited Inspectors
                    </Text>
                  </Flex>
                  <Flex mb={{ sm: "15px", md: "20px" }}>
                    <Icon
                      as={MdCheckCircleOutline}
                      fontSize="25px"
                      color="blue.primary"
                      mr="7px"
                    ></Icon>
                    <Text
                      lineHeight="22px"
                      color="black.primary"
                      fontSize="18px"
                    >
                      Template Editor
                    </Text>
                  </Flex>
                  <Flex mb={{ sm: "15px", md: "20px" }}>
                    <Icon
                      as={MdCheckCircleOutline}
                      fontSize="25px"
                      color="blue.primary"
                      mr="7px"
                    ></Icon>
                    <Text
                      lineHeight="22px"
                      color="black.primary"
                      fontSize="18px"
                    >
                      Edit “On the Fly”{" "}
                    </Text>
                  </Flex>
                  <Flex mb={{ sm: "15px", md: "20px" }}>
                    <Icon
                      as={MdCheckCircleOutline}
                      fontSize="25px"
                      color="blue.primary"
                      mr="7px"
                    ></Icon>
                    <Text
                      lineHeight="22px"
                      color="black.primary"
                      fontSize="18px"
                    >
                      Automation
                    </Text>
                  </Flex>
                  <Flex mb={{ sm: "15px", md: "20px" }}>
                    <Icon
                      as={MdCheckCircleOutline}
                      fontSize="25px"
                      color="blue.primary"
                      mr="7px"
                    ></Icon>
                    <Text
                      lineHeight="22px"
                      color="black.primary"
                      fontSize="18px"
                    >
                      Business
                    </Text>
                  </Flex>
                  <Flex mb={{ sm: "15px", md: "20px" }}>
                    <Icon
                      as={MdCheckCircleOutline}
                      fontSize="25px"
                      color="blue.primary"
                      mr="7px"
                    ></Icon>
                    <Text
                      lineHeight="22px"
                      color="black.primary"
                      fontSize="18px"
                    >
                      CRM
                    </Text>
                  </Flex>
                  <Flex mb={{ sm: "15px", md: "20px" }}>
                    <Icon
                      as={MdCheckCircleOutline}
                      fontSize="25px"
                      color="blue.primary"
                      mr="7px"
                    ></Icon>
                    <Text
                      lineHeight="22px"
                      color="black.primary"
                      fontSize="18px"
                    >
                      Funnel
                    </Text>
                  </Flex>
                </Box>
                <Box
                  d="inline-flex"
                  alignItems="flex-start"
                  justifyContent="flex-start"
                  flexDir="column"
                  minW="400px"
                  borderWidth="2px"
                  borderRadius="lg"
                  borderColor={`${
                    auth.user?.role === "Yearly Pro Plus Subscriber"
                      ? "blue.primary"
                      : "black.lighten"
                  }`}
                  p={{ sm: "5", md: "6" }}
                  rounded="md"
                  bg="white"
                  ml="20px"
                >
                  <Flex w="100%">
                    <Box textAlign="left">
                      <Text
                        fontSize="16px"
                        fontWeight="600"
                        lineHeight="22px"
                        color="blue.primary"
                      >
                        Pro Plus
                      </Text>
                      <Flex m="10px 0" alignItems="flex-end">
                        <Text
                          fontSize="50px"
                          fontWeight="600"
                          lineHeight="50px"
                          color="black.primary"
                        >
                          $2070
                        </Text>
                        <Text fontSize="20px" as="span" ml="4px">
                          /year
                        </Text>
                      </Flex>
                    </Box>
                    {auth.user?.role === "Yearly Pro Plus Subscriber" && (
                      <Box ml="auto" pl="15px">
                        <Icon
                          fontSize="38px"
                          color="blue.primary"
                          as={IoIosCheckmarkCircle}
                        ></Icon>
                      </Box>
                    )}
                  </Flex>
                  <Text fontSize="16px" lineHeight="22px" color="gray.primary">
                    Get your business up and running
                  </Text>
                  <Text
                    mt="6px"
                    as="span"
                    fontSize="18px"
                    display="block"
                    fontWeight="500"
                  >
                    Additional Inspectors:{" "}
                    {/* <Text fontWeight="600" as="span">
                      $99/month
                    </Text>{" "}
                    or{" "} */}
                    <Text fontWeight="600" as="span">
                      $99/year
                    </Text>
                  </Text>

                  <Box display="block" w="100%" mb="15px">
                    {/* {auth.user?.role !== "Yearly Pro Plus Subscriber" && (
                      <Button
                        variant="outlined"
                        width="100%"
                        height="45px"
                        m="10px 0"
                        onClick={() => planSubmit("yearly_Pro_Plus")}
                        borderWidth="2px"
                        fontWeight="600"
                      >
                        Subscribe Now
                      </Button>
                    )} */}
                    {auth.user?.role !== "Yearly Pro Plus Subscriber" && (
                      <Button
                        variant="outlined"
                        width="100%"
                        height="45px"
                        m="10px 0"
                        onClick={() => planSubmit("yearly_Pro_Plus")}
                        borderWidth="2px"
                        fontWeight="600"
                      >
                        Subscribe Now
                      </Button>
                    )}
                  </Box>
                  <Text
                    fontSize="16px"
                    fontWeight="600"
                    lineHeight="22px"
                    color="black.primary"
                    mb="18px"
                  >
                    Features
                  </Text>
                  <Flex mb={{ sm: "15px", md: "20px" }}>
                    <Icon
                      as={MdCheckCircleOutline}
                      fontSize="25px"
                      color="blue.primary"
                      mr="7px"
                    ></Icon>
                    <Text
                      lineHeight="22px"
                      color="black.primary"
                      fontSize="18px"
                    >
                      Dynamic Report writer
                    </Text>
                  </Flex>
                  <Flex mb={{ sm: "15px", md: "20px" }}>
                    <Icon
                      as={MdCheckCircleOutline}
                      fontSize="25px"
                      color="blue.primary"
                      mr="7px"
                    ></Icon>
                    <Text
                      lineHeight="22px"
                      color="black.primary"
                      fontSize="18px"
                    >
                      Create Unlimited Inspectors
                    </Text>
                  </Flex>
                  <Flex mb={{ sm: "15px", md: "20px" }}>
                    <Icon
                      as={MdCheckCircleOutline}
                      fontSize="25px"
                      color="blue.primary"
                      mr="7px"
                    ></Icon>
                    <Text
                      lineHeight="22px"
                      color="black.primary"
                      fontSize="18px"
                    >
                      Template Editor
                    </Text>
                  </Flex>
                  <Flex mb={{ sm: "15px", md: "20px" }}>
                    <Icon
                      as={MdCheckCircleOutline}
                      fontSize="25px"
                      color="blue.primary"
                      mr="7px"
                    ></Icon>
                    <Text
                      lineHeight="22px"
                      color="black.primary"
                      fontSize="18px"
                    >
                      Edit “On the Fly”{" "}
                    </Text>
                  </Flex>
                  <Flex mb={{ sm: "15px", md: "20px" }}>
                    <Icon
                      as={MdCheckCircleOutline}
                      fontSize="25px"
                      color="blue.primary"
                      mr="7px"
                    ></Icon>
                    <Text
                      lineHeight="22px"
                      color="black.primary"
                      fontSize="18px"
                    >
                      Automation
                    </Text>
                  </Flex>
                  <Flex mb={{ sm: "15px", md: "20px" }}>
                    <Icon
                      as={MdCheckCircleOutline}
                      fontSize="25px"
                      color="blue.primary"
                      mr="7px"
                    ></Icon>
                    <Text
                      lineHeight="22px"
                      color="black.primary"
                      fontSize="18px"
                    >
                      Business
                    </Text>
                  </Flex>
                  <Flex mb={{ sm: "15px", md: "20px" }}>
                    <Icon
                      as={MdCheckCircleOutline}
                      fontSize="25px"
                      color="blue.primary"
                      mr="7px"
                    ></Icon>
                    <Text
                      lineHeight="22px"
                      color="black.primary"
                      fontSize="18px"
                    >
                      CRM
                    </Text>
                  </Flex>
                  <Flex mb={{ sm: "15px", md: "20px" }}>
                    <Icon
                      as={MdCheckCircleOutline}
                      fontSize="25px"
                      color="blue.primary"
                      mr="7px"
                    ></Icon>
                    <Text
                      lineHeight="22px"
                      color="black.primary"
                      fontSize="18px"
                    >
                      Funnel
                    </Text>
                  </Flex>
                </Box>
              </Flex>
            </>
          ) : (
            <>
              <Flex
                w="100%"
                bg="white.primary"
                p={{ sm: "30px", lg: "40px" }}
                justifyContent="center"
              >
                <Box
                  d="inline-flex"
                  alignItems="flex-start"
                  justifyContent="flex-start"
                  flexDir="column"
                  minW="400px"
                  borderWidth="2px"
                  borderRadius="lg"
                  borderColor={`${
                    auth.user?.role === "Growth Subscriber"
                      ? "blue.primary"
                      : "black.lighten"
                  }`}
                  p={{ sm: "5", md: "6" }}
                  rounded="md"
                  bg="white"
                >
                  <Flex w="100%">
                    <Box textAlign="left">
                      <Text
                        fontSize="16px"
                        fontWeight="600"
                        lineHeight="22px"
                        color="blue.primary"
                      >
                        Growth
                      </Text>
                      <Flex m="10px 0" alignItems="flex-end">
                        <Text
                          fontSize="50px"
                          fontWeight="600"
                          lineHeight="50px"
                          color="black.primary"
                        >
                          $89
                        </Text>
                        <Text fontSize="20px" as="span" ml="4px">
                          /mon
                        </Text>
                      </Flex>
                    </Box>
                    {auth.user?.role === "Growth Subscriber" && (
                      <Box ml="auto" pl="15px">
                        <Icon
                          fontSize="38px"
                          color="blue.primary"
                          as={IoIosCheckmarkCircle}
                        ></Icon>
                      </Box>
                    )}
                  </Flex>
                  <Text fontSize="16px" lineHeight="22px" color="gray.primary">
                    Get your business up and running
                  </Text>
                  <Box display="block" m="32px 0 15px" w="100%">
                    {auth.user?.role !== "Growth Subscriber" && (
                      <Button
                        variant="outlined"
                        width="100%"
                        height="45px"
                        m="10px 0"
                        onClick={() => planSubmit("Growth")}
                        borderWidth="2px"
                        fontWeight="600"
                      >
                        Subscribe Now
                      </Button>
                    )}
                  </Box>
                  <Text
                    fontSize="16px"
                    fontWeight="600"
                    lineHeight="22px"
                    color="black.primary"
                    mb="18px"
                  >
                    Features
                  </Text>
                  <Flex mb={{ sm: "15px", md: "20px" }}>
                    <Icon
                      as={MdCheckCircleOutline}
                      fontSize="25px"
                      color="blue.primary"
                      mr="7px"
                    ></Icon>
                    <Text
                      lineHeight="22px"
                      color="black.primary"
                      fontSize="18px"
                    >
                      Dynamic Report writer
                    </Text>
                  </Flex>
                  <Flex mb={{ sm: "15px", md: "20px" }}>
                    <Icon
                      as={MdHighlightOff}
                      fontSize="25px"
                      color="red.primary"
                      mr="7px"
                    ></Icon>
                    <Text
                      lineHeight="22px"
                      color="black.primary"
                      fontSize="18px"
                    >
                      Create Unlimited Inspectors
                    </Text>
                  </Flex>
                  <Flex mb={{ sm: "15px", md: "20px" }}>
                    <Icon
                      as={MdCheckCircleOutline}
                      fontSize="25px"
                      color="blue.primary"
                      mr="7px"
                    ></Icon>
                    <Text
                      lineHeight="22px"
                      color="black.primary"
                      fontSize="18px"
                    >
                      Template Editor
                    </Text>
                  </Flex>
                  <Flex mb={{ sm: "15px", md: "20px" }}>
                    <Icon
                      as={MdCheckCircleOutline}
                      fontSize="25px"
                      color="blue.primary"
                      mr="7px"
                    ></Icon>
                    <Text
                      lineHeight="22px"
                      color="black.primary"
                      fontSize="18px"
                    >
                      Edit “On the Fly”{" "}
                    </Text>
                  </Flex>
                  <Flex mb={{ sm: "15px", md: "20px" }}>
                    <Icon
                      as={MdCheckCircleOutline}
                      fontSize="25px"
                      color="blue.primary"
                      mr="7px"
                    ></Icon>
                    <Text
                      lineHeight="22px"
                      color="black.primary"
                      fontSize="18px"
                    >
                      Automation
                    </Text>
                  </Flex>
                  <Flex mb={{ sm: "15px", md: "20px" }}>
                    <Icon
                      as={MdCheckCircleOutline}
                      fontSize="25px"
                      color="blue.primary"
                      mr="7px"
                    ></Icon>
                    <Text
                      lineHeight="22px"
                      color="black.primary"
                      fontSize="18px"
                    >
                      Business
                    </Text>
                  </Flex>
                  <Flex mb={{ sm: "15px", md: "20px" }}>
                    <Icon
                      as={MdHighlightOff}
                      fontSize="25px"
                      color="red.primary"
                      mr="7px"
                    ></Icon>
                    <Text
                      lineHeight="22px"
                      color="black.primary"
                      fontSize="18px"
                    >
                      CRM
                    </Text>
                  </Flex>
                  <Flex mb={{ sm: "15px", md: "20px" }}>
                    <Icon
                      as={MdHighlightOff}
                      fontSize="25px"
                      color="red.primary"
                      mr="7px"
                    ></Icon>
                    <Text
                      lineHeight="22px"
                      color="black.primary"
                      fontSize="18px"
                    >
                      Funnel
                    </Text>
                  </Flex>
                </Box>
                <Box
                  display="inline-flex"
                  alignItems="flex-start"
                  justifyContent="flex-start"
                  flexDir="column"
                  minW="400px"
                  borderWidth="2px"
                  borderRadius="lg"
                  borderColor={`${
                    auth.user?.role === "Pro Subscriber"
                      ? "blue.primary"
                      : "black.lighten"
                  }`}
                  p={{ sm: "5", md: "6" }}
                  rounded="md"
                  bg="white"
                  ml="20px"
                >
                  <Flex w="100%">
                    <Box textAlign="left">
                      <Text
                        fontSize="16px"
                        fontWeight="600"
                        lineHeight="22px"
                        color="blue.primary"
                      >
                        Pro
                      </Text>
                      <Flex m="10px 0" alignItems="flex-end">
                        <Text
                          fontSize="50px"
                          fontWeight="600"
                          lineHeight="50px"
                          color="black.primary"
                        >
                          $99
                        </Text>
                        <Text fontSize="20px" as="span" ml="4px">
                          /mon
                        </Text>
                      </Flex>
                    </Box>
                    {auth.user?.role === "Pro Subscriber" && (
                      <Box ml="auto" pl="15px">
                        <Icon
                          fontSize="38px"
                          color="blue.primary"
                          as={IoIosCheckmarkCircle}
                        ></Icon>
                      </Box>
                    )}
                  </Flex>
                  <Text fontSize="16px" lineHeight="22px" color="gray.primary">
                    Get your business up and running
                  </Text>
                  <Text
                    mt="6px"
                    as="span"
                    fontSize="18px"
                    display="block"
                    fontWeight="500"
                  >
                    Additional Inspectors:{" "}
                    <Text fontWeight="600" as="span">
                      $99/month
                    </Text>
                  </Text>
                  <Box display="block" mb="15px" w="100%">
                    {auth.user?.role !== "Pro Subscriber" && (
                      <Button
                        variant="outlined"
                        width="100%"
                        height="45px"
                        m="10px 0"
                        onClick={() => planSubmit("Pro")}
                        borderWidth="2px"
                        fontWeight="600"
                      >
                        Subscribe Now
                      </Button>
                    )}
                  </Box>
                  <Text
                    fontSize="16px"
                    fontWeight="600"
                    lineHeight="22px"
                    color="black.primary"
                    mb="18px"
                  >
                    Features
                  </Text>
                  <Flex mb={{ sm: "15px", md: "20px" }}>
                    <Icon
                      as={MdCheckCircleOutline}
                      fontSize="25px"
                      color="blue.primary"
                      mr="7px"
                    ></Icon>
                    <Text
                      lineHeight="22px"
                      color="black.primary"
                      fontSize="18px"
                    >
                      Dynamic Report writer
                    </Text>
                  </Flex>
                  <Flex mb={{ sm: "15px", md: "20px" }}>
                    <Icon
                      as={MdCheckCircleOutline}
                      fontSize="25px"
                      color="blue.primary"
                      mr="7px"
                    ></Icon>
                    <Text
                      lineHeight="22px"
                      color="black.primary"
                      fontSize="18px"
                    >
                      Create Unlimited Inspectors
                    </Text>
                  </Flex>
                  <Flex mb={{ sm: "15px", md: "20px" }}>
                    <Icon
                      as={MdCheckCircleOutline}
                      fontSize="25px"
                      color="blue.primary"
                      mr="7px"
                    ></Icon>
                    <Text
                      lineHeight="22px"
                      color="black.primary"
                      fontSize="18px"
                    >
                      Template Editor
                    </Text>
                  </Flex>
                  <Flex mb={{ sm: "15px", md: "20px" }}>
                    <Icon
                      as={MdCheckCircleOutline}
                      fontSize="25px"
                      color="blue.primary"
                      mr="7px"
                    ></Icon>
                    <Text
                      lineHeight="22px"
                      color="black.primary"
                      fontSize="18px"
                    >
                      Edit “On the Fly”{" "}
                    </Text>
                  </Flex>
                  <Flex mb={{ sm: "15px", md: "20px" }}>
                    <Icon
                      as={MdCheckCircleOutline}
                      fontSize="25px"
                      color="blue.primary"
                      mr="7px"
                    ></Icon>
                    <Text
                      lineHeight="22px"
                      color="black.primary"
                      fontSize="18px"
                    >
                      Automation
                    </Text>
                  </Flex>
                  <Flex mb={{ sm: "15px", md: "20px" }}>
                    <Icon
                      as={MdCheckCircleOutline}
                      fontSize="25px"
                      color="blue.primary"
                      mr="7px"
                    ></Icon>
                    <Text
                      lineHeight="22px"
                      color="black.primary"
                      fontSize="18px"
                    >
                      Business
                    </Text>
                  </Flex>
                  <Flex mb={{ sm: "15px", md: "20px" }}>
                    <Icon
                      as={MdHighlightOff}
                      fontSize="25px"
                      color="red.primary"
                      mr="7px"
                    ></Icon>
                    <Text
                      lineHeight="22px"
                      color="black.primary"
                      fontSize="18px"
                    >
                      CRM
                    </Text>
                  </Flex>
                  <Flex mb={{ sm: "15px", md: "20px" }}>
                    <Icon
                      as={MdHighlightOff}
                      fontSize="25px"
                      color="red.primary"
                      mr="7px"
                    ></Icon>
                    <Text
                      lineHeight="22px"
                      color="black.primary"
                      fontSize="18px"
                    >
                      Funnel
                    </Text>
                  </Flex>
                </Box>
                {/* Growth Plus Plan ------------------*/}

                {/* Growth Plus Plan----------------- */}
              </Flex>
              <Flex
                w="100%"
                bg="white.primary"
                // p={{ sm: "30px", lg: "40px" }}

                justifyContent="center"
              >
                <Box
                  d="inline-flex"
                  alignItems="flex-start"
                  justifyContent="flex-start"
                  flexDir="column"
                  minW="400px"
                  borderWidth="2px"
                  borderRadius="lg"
                  borderColor={`${
                    auth.user?.role === "Growth Subscriber"
                      ? "blue.primary"
                      : "black.lighten"
                  }`}
                  p={{ sm: "5", md: "6" }}
                  rounded="md"
                  bg="white"
                >
                  <Flex w="100%">
                    <Box textAlign="left">
                      <Text
                        fontSize="16px"
                        fontWeight="600"
                        lineHeight="22px"
                        color="blue.primary"
                      >
                        Growth Plus
                      </Text>
                      <Flex m="10px 0" alignItems="flex-end">
                        <Text
                          fontSize="50px"
                          fontWeight="600"
                          lineHeight="50px"
                          color="black.primary"
                        >
                          $187
                        </Text>
                        <Text fontSize="20px" as="span" ml="4px">
                          /mon
                        </Text>
                      </Flex>
                    </Box>
                    {auth.user?.role === "Growth Plus Subscriber" && (
                      <Box ml="auto" pl="15px">
                        <Icon
                          fontSize="38px"
                          color="blue.primary"
                          as={IoIosCheckmarkCircle}
                        ></Icon>
                      </Box>
                    )}
                  </Flex>
                  <Text fontSize="16px" lineHeight="22px" color="gray.primary">
                    Get your business up and running
                  </Text>
                  <Box display="block" m="32px 0 15px" w="100%">
                    {auth.user?.role !== "Growth Plus Subscriber" && (
                      <Button
                        variant="outlined"
                        width="100%"
                        height="45px"
                        m="10px 0"
                        onClick={() => planSubmit("Growth Plus")}
                        borderWidth="2px"
                        fontWeight="600"
                      >
                        Subscribe Now
                      </Button>
                    )}
                  </Box>
                  <Text
                    fontSize="16px"
                    fontWeight="600"
                    lineHeight="22px"
                    color="black.primary"
                    mb="18px"
                  >
                    Features
                  </Text>
                  <Flex mb={{ sm: "15px", md: "20px" }}>
                    <Icon
                      as={MdCheckCircleOutline}
                      fontSize="25px"
                      color="blue.primary"
                      mr="7px"
                    ></Icon>
                    <Text
                      lineHeight="22px"
                      color="black.primary"
                      fontSize="18px"
                    >
                      Dynamic Report writer
                    </Text>
                  </Flex>
                  <Flex mb={{ sm: "15px", md: "20px" }}>
                    <Icon
                      as={MdHighlightOff}
                      fontSize="25px"
                      color="red.primary"
                      mr="7px"
                    ></Icon>
                    <Text
                      lineHeight="22px"
                      color="black.primary"
                      fontSize="18px"
                    >
                      Create Unlimited Inspectors
                    </Text>
                  </Flex>
                  <Flex mb={{ sm: "15px", md: "20px" }}>
                    <Icon
                      as={MdCheckCircleOutline}
                      fontSize="25px"
                      color="blue.primary"
                      mr="7px"
                    ></Icon>
                    <Text
                      lineHeight="22px"
                      color="black.primary"
                      fontSize="18px"
                    >
                      Template Editor
                    </Text>
                  </Flex>
                  <Flex mb={{ sm: "15px", md: "20px" }}>
                    <Icon
                      as={MdCheckCircleOutline}
                      fontSize="25px"
                      color="blue.primary"
                      mr="7px"
                    ></Icon>
                    <Text
                      lineHeight="22px"
                      color="black.primary"
                      fontSize="18px"
                    >
                      Edit “On the Fly”{" "}
                    </Text>
                  </Flex>
                  <Flex mb={{ sm: "15px", md: "20px" }}>
                    <Icon
                      as={MdCheckCircleOutline}
                      fontSize="25px"
                      color="blue.primary"
                      mr="7px"
                    ></Icon>
                    <Text
                      lineHeight="22px"
                      color="black.primary"
                      fontSize="18px"
                    >
                      Automation
                    </Text>
                  </Flex>
                  <Flex mb={{ sm: "15px", md: "20px" }}>
                    <Icon
                      as={MdCheckCircleOutline}
                      fontSize="25px"
                      color="blue.primary"
                      mr="7px"
                    ></Icon>
                    <Text
                      lineHeight="22px"
                      color="black.primary"
                      fontSize="18px"
                    >
                      Business
                    </Text>
                  </Flex>
                  <Flex mb={{ sm: "15px", md: "20px" }}>
                    <Icon
                      as={MdCheckCircleOutline}
                      fontSize="25px"
                      color="blue.primary"
                      mr="7px"
                    ></Icon>
                    <Text
                      lineHeight="22px"
                      color="black.primary"
                      fontSize="18px"
                    >
                      CRM
                    </Text>
                  </Flex>
                  <Flex mb={{ sm: "15px", md: "20px" }}>
                    <Icon
                      as={MdCheckCircleOutline}
                      fontSize="25px"
                      color="blue.primary"
                      mr="7px"
                    ></Icon>
                    <Text
                      lineHeight="22px"
                      color="black.primary"
                      fontSize="18px"
                    >
                      Funnel
                    </Text>
                  </Flex>
                </Box>
                <Box
                  d="inline-flex"
                  alignItems="flex-start"
                  justifyContent="flex-start"
                  flexDir="column"
                  minW="400px"
                  borderWidth="2px"
                  borderRadius="lg"
                  borderColor={`${
                    auth.user?.role === "Growth Subscriber"
                      ? "blue.primary"
                      : "black.lighten"
                  }`}
                  p={{ sm: "5", md: "6" }}
                  rounded="md"
                  bg="white"
                  ml="20px"
                >
                  <Flex w="100%">
                    <Box textAlign="left">
                      <Text
                        fontSize="16px"
                        fontWeight="600"
                        lineHeight="22px"
                        color="blue.primary"
                      >
                        Pro Plus
                      </Text>
                      <Flex m="10px 0" alignItems="flex-end">
                        <Text
                          fontSize="50px"
                          fontWeight="600"
                          lineHeight="50px"
                          color="black.primary"
                        >
                          $207
                        </Text>
                        <Text fontSize="20px" as="span" ml="4px">
                          /mon
                        </Text>
                      </Flex>
                    </Box>
                    {auth.user?.role === "Pro Plus Subscriber" && (
                      <Box ml="auto" pl="15px">
                        <Icon
                          fontSize="38px"
                          color="blue.primary"
                          as={IoIosCheckmarkCircle}
                        ></Icon>
                      </Box>
                    )}
                  </Flex>
                  <Text fontSize="16px" lineHeight="22px" color="gray.primary">
                    Get your business up and running
                  </Text>
                  <Text
                    mt="6px"
                    as="span"
                    fontSize="18px"
                    display="block"
                    fontWeight="500"
                  >
                    Additional Inspectors:{" "}
                    <Text fontWeight="600" as="span">
                      $99/month
                    </Text>
                  </Text>
                  <Box display="block" mb="15px" w="100%">
                    {auth.user?.role !== "Pro Plus Subscriber" && (
                      <Button
                        variant="outlined"
                        width="100%"
                        height="45px"
                        m="10px 0"
                        onClick={() => planSubmit("Pro Plus")}
                        borderWidth="2px"
                        fontWeight="600"
                      >
                        Subscribe Now
                      </Button>
                    )}
                  </Box>
                  <Text
                    fontSize="16px"
                    fontWeight="600"
                    lineHeight="22px"
                    color="black.primary"
                    mb="18px"
                  >
                    Features
                  </Text>
                  <Flex mb={{ sm: "15px", md: "20px" }}>
                    <Icon
                      as={MdCheckCircleOutline}
                      fontSize="25px"
                      color="blue.primary"
                      mr="7px"
                    ></Icon>
                    <Text
                      lineHeight="22px"
                      color="black.primary"
                      fontSize="18px"
                    >
                      Dynamic Report writer
                    </Text>
                  </Flex>
                  <Flex mb={{ sm: "15px", md: "20px" }}>
                    <Icon
                      as={MdCheckCircleOutline}
                      fontSize="25px"
                      color="blue.primary"
                      mr="7px"
                    ></Icon>
                    <Text
                      lineHeight="22px"
                      color="black.primary"
                      fontSize="18px"
                    >
                      Create Unlimited Inspectors
                    </Text>
                  </Flex>
                  <Flex mb={{ sm: "15px", md: "20px" }}>
                    <Icon
                      as={MdCheckCircleOutline}
                      fontSize="25px"
                      color="blue.primary"
                      mr="7px"
                    ></Icon>
                    <Text
                      lineHeight="22px"
                      color="black.primary"
                      fontSize="18px"
                    >
                      Template Editor
                    </Text>
                  </Flex>
                  <Flex mb={{ sm: "15px", md: "20px" }}>
                    <Icon
                      as={MdCheckCircleOutline}
                      fontSize="25px"
                      color="blue.primary"
                      mr="7px"
                    ></Icon>
                    <Text
                      lineHeight="22px"
                      color="black.primary"
                      fontSize="18px"
                    >
                      Edit “On the Fly”{" "}
                    </Text>
                  </Flex>
                  <Flex mb={{ sm: "15px", md: "20px" }}>
                    <Icon
                      as={MdCheckCircleOutline}
                      fontSize="25px"
                      color="blue.primary"
                      mr="7px"
                    ></Icon>
                    <Text
                      lineHeight="22px"
                      color="black.primary"
                      fontSize="18px"
                    >
                      Automation
                    </Text>
                  </Flex>
                  <Flex mb={{ sm: "15px", md: "20px" }}>
                    <Icon
                      as={MdCheckCircleOutline}
                      fontSize="25px"
                      color="blue.primary"
                      mr="7px"
                    ></Icon>
                    <Text
                      lineHeight="22px"
                      color="black.primary"
                      fontSize="18px"
                    >
                      Business
                    </Text>
                  </Flex>
                  <Flex mb={{ sm: "15px", md: "20px" }}>
                    <Icon
                      as={MdCheckCircleOutline}
                      fontSize="25px"
                      color="blue.primary"
                      mr="7px"
                    ></Icon>
                    <Text
                      lineHeight="22px"
                      color="black.primary"
                      fontSize="18px"
                    >
                      CRM
                    </Text>
                  </Flex>
                  <Flex mb={{ sm: "15px", md: "20px" }}>
                    <Icon
                      as={MdCheckCircleOutline}
                      fontSize="25px"
                      color="blue.primary"
                      mr="7px"
                    ></Icon>
                    <Text
                      lineHeight="22px"
                      color="black.primary"
                      fontSize="18px"
                    >
                      Funnel
                    </Text>
                  </Flex>
                </Box>
              </Flex>
            </>
          )}
        </Box>
      </Box>
      <Modal
        variant="centerModal"
        isOpen={showCancelSubscription}
        onClose={closeCancelSubscription}
        size="xl"
        scrollBehavior="inside"
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader
            p="25px 30px"
            fontSize="21px"
            lineHeight="26px"
            color="black.lighten"
            fontWeight="600"
          >
            Cancel Subscription
          </ModalHeader>
          <Icon
            color="black.primary"
            fontSize="24px"
            as={IoMdClose}
            onClick={closeCancelSubscription}
            cursor="pointer"
            position="absolute"
            top="29px"
            right="29px"
          ></Icon>
          <ModalBody p="0 30px 30px">
            <Text fontSize="20px" color="black.lighten" margin="0">
              Are you sure you want to cancel the subscription?
            </Text>
          </ModalBody>
          <ModalFooter>
            <Button
              variant="grey"
              minW="120px"
              onClick={closeCancelSubscription}
            >
              No
            </Button>
            <Button
              variant="primary2"
              ml="auto"
              minW="120px"
              onClick={handleCancelSubscription}
            >
              Yes
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default Subscription;
