import { Box, Button, Icon, Input, InputGroup, InputLeftElement, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Select, SimpleGrid, Text, Tooltip, useDisclosure, Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react'
import { useEffect, useRef, useState } from 'react'
import DataTable from 'react-data-table-component'
import { MdDelete, MdEdit } from 'react-icons/md'
import { TbFileInvoice } from "react-icons/tb";
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../redux/store'
import { deleteReportById, saveReportToCloud, generateInvoice, getAllReports, getInvoiceReport, ReportState, getReportForAdmin, getReportDetail } from '../../../../redux/modules/report'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import { IoMdClose } from 'react-icons/io'
import moment from 'moment'
import Loader from '../../../../components/common/loader'
import { resetTemplate, resetTemplateTabs } from '../../../../redux/modules/template';
import { toastr } from 'react-redux-toastr';
import DateTimePicker from 'react-datetime-picker';
import { MultiSelect } from 'react-multi-select-component';
import { ImSearch } from 'react-icons/im';
import { authState } from '../../../../redux/modules/auth'
import { getReportsBySuperAdmin, superAdminState } from '../../../../redux/modules/superAdmin/superAdmin';
import { FiArrowLeft } from 'react-icons/fi';
import { BsFillCloudArrowDownFill } from 'react-icons/bs';

export function ReportListing() {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const report: ReportState = useSelector((state: RootState) => state.report);
  const auth: authState = useSelector((state: RootState) => state.auth);
  const superAdmin: superAdminState = useSelector((state: RootState) => state.superAdmin)

  const [deleteReportId, setDeleteReportId] = useState(null);
  const { isOpen: reportFilter, onOpen: openReportFilter, onClose: closeReportFilter } = useDisclosure();
  const { isOpen: deleteConfirmation, onOpen: openDeleteConfirmation, onClose: closeDeleteConfirmation } = useDisclosure();
  const { isOpen: paymentConfirmation, onOpen: openPaymentConfirmation, onClose: closePaymentConfirmation } = useDisclosure();
  const [reports, setReports] = useState([]);
  const [reportsForAdmin, setReportsForAdmin] = useState([]);
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const [selectedClient, setSelectedClient] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [superAdminReportList, setSuperAdminReportList] = useState(null);
  const [activeTab, setActiveTab] = useState(0);
  const [clientOptions, setClientOptions] = useState([]);
  const initialRef = useRef(null);
  const [viewInvoice, setViewInvoice] = useState(null);
  const [invoiceReport, setInvoiceReport] = useState(null);

  // Initial value loder
  // ... render page resources
  useEffect(() => {
    if ((/reports/gi.test(location.pathname) && auth.user?.user_Type !== "super_admin")) {
      dispatch(getAllReports());
    }
    if ((/reports/gi.test(location.pathname) && auth.user?.user_Type === "company_admin")) {
      dispatch(getReportForAdmin());
    }
  }, [auth.user?.user_Type, dispatch, location.pathname]);


  useEffect(() => {
    if ((/reports/gi.test(location.pathname) && auth.user?.user_Type === "super_admin")) {
      dispatch(getReportsBySuperAdmin({ paramId: params?.id }))
    }
  },
    [auth.user?.user_Type, dispatch, location.pathname, params?.id])

  useEffect(() => {
    setSuperAdminReportList(superAdmin?.superAdminReports)
  }, [superAdmin?.superAdminReports])

  useEffect(() => {
    setReports(report.allReports);
    const reportDates = report.allReports.map((x) => x.inspectorDetail.inspectionDate);
    reportDates.sort((a: string, b: string) => { return Date.parse(a) - Date.parse(b); });
    if (reportDates.length) {
      setFromDate(new Date(reportDates[0]));
    }
  }, [report.allReports]);

  useEffect(() => {
    setReportsForAdmin(report.reportsForAdmin);
  }, [report.reportsForAdmin]);

  useEffect(() => {
    if (searchKeyword.match(/^[a-zA-Z0-9!@. ]+$/g)) {
      let data: any = [];
      if (!activeTab) {
        data.push(...report.allReports.filter((reportInfo) => reportInfo.inspectorDetail?.reportId && reportInfo.inspectorDetail?.reportId.match(searchKeyword)));
        data.push(...report.allReports.filter((reportInfo) => reportInfo.inspectorDetail?.inspectorName && reportInfo.inspectorDetail?.inspectorName.match(searchKeyword)));
        data.push(...report.allReports.filter((reportInfo) => reportInfo.clientinformation?.firstName && `${reportInfo.clientinformation?.firstName} ${reportInfo.clientinformation?.lastName}`.match(searchKeyword)));

        data = data.filter((value, index, self) =>
          index === self.findIndex((t) => (
            t.id === value.id
          ))
        )
        setReports(data);
      }
      else if (activeTab) {
        data.push(...report.reportsForAdmin.filter((reportInfo) => reportInfo.inspectorDetail?.reportId && reportInfo.inspectorDetail?.reportId.match(searchKeyword)));
        data.push(...report.reportsForAdmin.filter((reportInfo) => reportInfo.inspectorDetail?.inspectorName && reportInfo.inspectorDetail?.inspectorName.match(searchKeyword)));
        data.push(...report.reportsForAdmin.filter((reportInfo) => reportInfo.clientinformation?.firstName && `${reportInfo.clientinformation?.firstName} ${reportInfo.clientinformation?.lastName}`.match(searchKeyword)));

        data = data.filter((value, index, self) =>
          index === self.findIndex((t) => (
            t.id === value.id
          ))
        )
        setReportsForAdmin(data);
      }
    }
    else {
      setReportsForAdmin(report.reportsForAdmin);
      setReports(report.allReports);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchKeyword]);

  const tableColumns = [
    {
      name: 'Report #',
      selector: (row: any) => row.report_no,
      sortable: true,
      width: '140px'
    },
    {
      name: 'Inspectors',
      selector: (row: any) => row.inspectors,
      sortable: true,
      minWidth: '250px'
    },
    {
      name: 'Inspection Date / Time',
      selector: (row: any) => row.dateTime,
      sortable: true,
      minWidth: '218px'
    },
    {
      name: 'Client',
      selector: (row: any) => row.client,
      sortable: true,
      minWidth: '110px'
    },
    {
      name: 'Client Ratings',
      selector: (row: any) => row.property_ratings,
      minWidth: '180px'
    },
    {
      name: 'Amount Paid',
      selector: (row: any) => row.amount_paid,
      minWidth: '170px'
    },
    {
      name: 'Payment Mode',
      selector: (row: any) => row.payment_mode,
      minWidth: '170px'
    },
    {
      name: 'Payment Status',
      selector: (row: any) => row.payment_status,
      minWidth: '170px'
    },
    {
      name: 'Report expiration',
      selector: (row: any) => row.report_expiration,
      sortable: true,
      minWidth: '240px'
    },
    {
      name: 'Manage',
      selector: (row: any) => row.manage,
      minWidth: '170px'
    }
  ];
  const adminHomeInspectortableColumns = [
    {
      name: 'Report #',
      selector: (row: any) => row.report_no,
      sortable: true,
      width: '140px'
    },
    {
      name: 'Inspectors',
      selector: (row: any) => row.inspectors,
      sortable: true,
      minWidth: '250px'
    },
    {
      name: 'Inspection Date / Time',
      selector: (row: any) => row.dateTime,
      sortable: true,
      minWidth: '218px'
    },
    {
      name: 'Client',
      selector: (row: any) => row.client,
      sortable: true,
      minWidth: '110px'
    },
    {
      name: 'Client Ratings',
      selector: (row: any) => row.property_ratings,
      minWidth: '180px'
    },
    {
      name: 'Amount Paid',
      selector: (row: any) => row.amount_paid,
      minWidth: '170px'
    },
    {
      name: 'Payment Mode',
      selector: (row: any) => row.payment_mode,
      minWidth: '170px'
    },
    {
      name: 'Payment Status',
      selector: (row: any) => row.payment_status,
      minWidth: '170px'
    }
  ];

  const superAdminTableColumns = [
    {
      name: 'Report #',
      selector: (row: any) => row.report_no,
      sortable: true,
      width: '140px'
    },
    {
      name: 'Inspectors',
      selector: (row: any) => row.inspectors,
      sortable: true,
      minWidth: '250px'
    },
    {
      name: 'Inspection Date / Time',
      selector: (row: any) => row.dateTime,
      sortable: true,
      minWidth: '218px'
    },
    {
      name: 'Client',
      selector: (row: any) => row.client,
      sortable: true,
      minWidth: '110px'
    },
    {
      name: 'Client Ratings',
      selector: (row: any) => row.property_ratings,
      minWidth: '180px'
    }
  ];

  const reportExpiration = (expiryTime: any) => {
    const date1: any = new Date(expiryTime);
    const date2: any = new Date();
    const diffTime = Math.abs(date2 - date1);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
  }

  const tableDataItems = (auth?.user?.user_Type === "super_admin" ? superAdminReportList : reports)?.map((reportDetails: any) => {
    return {
      "report_no": reportDetails.inspectorDetail?.reportId,
      "inspectors": `${reportDetails.inspectorDetail?.inspectorName}`,
      "dateTime": moment(reportDetails.inspectorDetail?.inspectionEndDate).format("MM-DD-YYYY, hh:mm A"),
      "client": reportDetails.clientinformation?.firstName ? `${reportDetails.clientinformation?.firstName} ${reportDetails.clientinformation?.lastName}` : '',
      "property_ratings": <Box w='100%' textAlign='center' p='0 50px'><Text>{+(reportDetails.clientinformation?.clientRating) || 0}/5</Text></Box>,
      "amount_paid": <>{reportDetails['payment-detail']?.status
        && <Box w='72px' justifyContent='center' bg='yellow.primary' fontSize='13px' color='yellow.dark' d='inline-flex' alignItems='center' h='26px' p='0 8px' borderRadius='30px'>{reportDetails['payment-detail']?.status === 'Completed'
          ? 'Paid' : 'Unpaid'}</Box>}</>,
      "fee": <Text color='blue.primary' fontWeight='600'>{reportDetails['payment-detail']?.totalAmount}</Text>,
      "payment_mode": <>{reportDetails['payment-detail']?.paymentMode && <Box justifyContent='center' bg={reportDetails['payment-detail']?.paymentMode == 'Online Payment' ? 'green.lighten9' : 'yellow.primary'} fontSize='13px' color={reportDetails['payment-detail']?.paymentMode == 'Online Payment' ? 'green.lighten' : 'yellow.dark'} d='inline-flex' alignItems='center' h='26px' p='0 8px' borderRadius='30px'>{reportDetails['payment-detail']?.paymentMode}</Box>}</>,
      "payment_status": <>{reportDetails['payment-detail']?.status && <Select fontSize='xl' border='none' bg={reportDetails['payment-detail']?.status == 'Pending' ? '#ffaaaa' : 'green.lighten9'} textAlign='center' variant='Simple' w='120px' h='30px' value={reportDetails['payment-detail']?.status ? reportDetails['payment-detail'].status.toLowerCase() : ''} borderRadius='15px' isDisabled={reportDetails['payment-detail']?.status && reportDetails['payment-detail'].status.toLowerCase() === 'completed'} onChange={(e) => {
        if (e.target.value === 'completed') {
          openPaymentConfirmation();
          setInvoiceReport(reportDetails);
        }
      }}>
        <option value='pending'>Pending</option>
        <option value='completed'>Completed</option>
      </Select>}</>,
      "report_expiration": <Box justifyContent='center' bg={reportDetails.expireTime === "store in cloud" ? 'green.lighten6' : 'yellow.primary'} fontSize='13px' color={reportDetails.expireTime === "store in cloud" ? 'green.darken' : 'yellow.dark'} d='inline-flex' alignItems='center' h='28px' p='0 16px' borderRadius='30px'><Text fontWeight={reportDetails.expireTime === "store in cloud" ? 400 : 600}>{reportDetails.expireTime === "store in cloud" ? 'Stored on Cloud' : `${reportExpiration(+reportDetails.expireTime)} days left`}</Text></Box>,
      "manage": <><Tooltip hasArrow label='Edit Report' fontSize='14px' bg='gray.light12' color='white.primary' placement='top'><Button variant='iconBtn' minW='unset' p='0' color='gray.primary' mr='15px' onClick={() => handleEditReport(reportDetails.id)}><Icon fontSize='18px' as={MdEdit}></Icon></Button></Tooltip><Tooltip hasArrow label='Delete Report' fontSize='14px' bg='gray.light12' color='white.primary' placement='top'><Button fontSize='18px' variant='iconBtn' minW='unset' p='0' color='gray.primary' mr='15px' onClick={() => handleDeleteReportModal(reportDetails.id)}><Icon as={MdDelete}></Icon></Button></Tooltip>
        {reportDetails['payment-detail']?.status === 'Completed' && <Tooltip fontSize='14px' bg='gray.light12' color='white.primary' hasArrow label='View Invoice' placement='top'><Button fontSize='18px' variant='iconBtn' minW='unset' p='0' color='gray.primary' mr='15px' onClick={() => handleInvoiceDetails(reportDetails)}><Icon as={TbFileInvoice}></Icon></Button></Tooltip>}
        {auth?.user?.user_Type !== "super_admin" && reportDetails.expireTime !== "store in cloud" &&
          <Tooltip hasArrow label='Save to Cloud' fontSize='14px' bg='gray.light12' color='white.primary' placement='top'><Button fontSize='18px' variant='iconBtn' minW='unset' p='0' color='gray.primary' mr='15px' onClick={() => handleSaveReportCloud(reportDetails.id)}><Icon as={BsFillCloudArrowDownFill}></Icon></Button></Tooltip>}
      </>,
      "id": reportDetails.id
    }
  });

  const adminTableDataItems = reportsForAdmin.map((reportDetails: any) => {
    return {
      "report_no": reportDetails.inspectorDetail?.reportId,
      "inspectors": `${reportDetails.inspectorDetail?.inspectorName}`,
      "dateTime": moment(reportDetails.inspectorDetail?.inspectionEndDate).format("MM-DD-YYYY, hh:mm A"),
      "client": reportDetails.clientinformation?.firstName ? `${reportDetails.clientinformation?.firstName} ${reportDetails.clientinformation?.lastName}` : '',
      "status": reportDetails.inspectorDetail?.status ? reportDetails.inspectorDetail?.status : '',
      "property_ratings": <Box w='100%' textAlign='center' p='0 50px'><Text>{+(reportDetails.clientinformation?.clientRating) || 0}/5</Text></Box>,
      "amount_paid": <>{reportDetails['payment-detail']?.status && <Box w='72px' justifyContent='center' bg='yellow.primary' fontSize='13px' color='yellow.dark' d='inline-flex' alignItems='center' h='26px' p='0 8px' borderRadius='30px'>{reportDetails['payment-detail']?.status === 'Completed' ? 'Paid' : 'Unpaid'}</Box>}</>,
      "fee": <Text color='blue.primary' fontWeight='600'>{reportDetails['payment-detail']?.totalAmount}</Text>,
      "payment_mode": <>{reportDetails['payment-detail']?.paymentMode && <Box justifyContent='center' bg={reportDetails['payment-detail']?.paymentMode == 'Online Payment' ? 'green.lighten9' : 'yellow.primary'} fontSize='13px' color={reportDetails['payment-detail']?.paymentMode == 'Online Payment' ? 'green.lighten' : 'yellow.dark'} d='inline-flex' alignItems='center' h='26px' p='0 8px' borderRadius='30px'>{reportDetails['payment-detail']?.paymentMode}</Box>}</>,
      "payment_status": <>{reportDetails['payment-detail']?.status && <Select fontSize='xl' disabled border='none' bg={reportDetails['payment-detail']?.status == 'Pending' ? '#ffaaaa' : 'green.lighten9'} textAlign='center' variant='Simple' w='120px' h='30px' value={reportDetails['payment-detail']?.status ? reportDetails['payment-detail'].status.toLowerCase() : ''} borderRadius='15px' isDisabled={reportDetails['payment-detail']?.status && reportDetails['payment-detail'].status.toLowerCase() === 'completed'} onChange={(e) => {
        if (e.target.value === 'completed') {
          openPaymentConfirmation();
          setInvoiceReport(reportDetails);
        }
      }}>
        <option value='pending'>Pending</option>
        <option value='completed'>Completed</option>
      </Select>}</>,
      "manage": <><Tooltip hasArrow label='Edit Report' fontSize='14px' bg='gray.light12' color='white.primary' placement='top'><Button variant='iconBtn' minW='unset' p='0' color='gray.primary' mr='15px' onClick={() => handleEditReport(reportDetails.id)}><Icon fontSize='18px' as={MdEdit}></Icon></Button></Tooltip><Tooltip hasArrow label='Delete Report' fontSize='14px' bg='gray.light12' color='white.primary' placement='top'><Button fontSize='18px' variant='iconBtn' minW='unset' p='0' color='gray.primary' mr='15px' onClick={() => handleDeleteReportModal(reportDetails.id)}><Icon as={MdDelete}></Icon></Button></Tooltip>
        {reportDetails['payment-detail']?.status === 'Completed' && <Tooltip fontSize='14px' bg='gray.light12' color='white.primary' hasArrow label='View Invoice' placement='top'><Button fontSize='18px' variant='iconBtn' minW='unset' p='0' color='gray.primary' mr='15px' onClick={() => handleInvoiceDetails(reportDetails)}><Icon as={TbFileInvoice}></Icon></Button></Tooltip>}</>,
      "id": reportDetails.id
    }
  });

  useEffect(() => {

    const handlePaymentConfirmation = async () => {
      if (viewInvoice) {
        if (!(invoiceReport.clientinformation.id && invoiceReport['payment-detail']?.id)) {
          toastr.warning("", "Please update the Client and Payment information to complete the payment");
          return;
        }
        new Promise(() => {
          const data1: any = dispatch(generateInvoice({ reportId: invoiceReport.id, companyId: auth.company.id, clientId: invoiceReport.clientinformation.id, paymentId: invoiceReport['payment-detail']?.id }))
          data1.then(function (value) {
            if (value.type === 'report/generate/invoice/fulfilled') {
              toastr.success("", "Invoice generated successfully");
              closePaymentConfirmation();
              dispatch(getAllReports());
              dispatch(getInvoiceReport({ reportId: invoiceReport.id }));
              setViewInvoice(false);
            }
          })
        });
      }
    }
    handlePaymentConfirmation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, invoiceReport, viewInvoice]);

  const handleEditReport = (reportId: string) => {
    dispatch(resetTemplate('all'));
    dispatch(resetTemplateTabs('all'));
    navigate(`/edit-report/${reportId}`);
  }
  const handleInvoiceDetails = (reportDetails: any) => {
    navigate(`/invoice/${reportDetails.id}`);
  }

  const handleSaveReportCloud = (reportId: any) => {
    new Promise(() => {
      const data2: any = dispatch(
        getReportDetail({ paramId: reportId })
      );
      data2.then(function (value) {
        if (value.type === "report/getReportDetail/fulfilled") {
          new Promise(() => {
            const data3: any = dispatch(saveReportToCloud({
              reportId: reportId,
            }));
            data3.then(function (value1) {
              if (value1.type === "report/savetocloud/fulfilled") {
                toastr.success('', 'Report successfully saved in cloud');
                dispatch(getAllReports());
              }
            })
          });
        }
      })
    });
  }

  const handleDeleteReport = async (reportId: string) => {
    new Promise(() => {
      const data3: any = dispatch(deleteReportById({ paramId: reportId }));
      data3.then(function (value) {
        if (value.type === 'report/deleteReportById/fulfilled') {
          if (!activeTab) {
            dispatch(getAllReports());
          } else {
            dispatch(getReportForAdmin());
          }
          closeDeleteConfirmation(); // Close Confirmation Modal
          toastr.success("", "Report deleted successfully");
        }
      })
    });
  }

  const handleDeleteReportModal = (reportId: string) => {
    setDeleteReportId(reportId);
    openDeleteConfirmation(); // Open Confirmation Modal
  }
  const handleReportFilter = () => {
    if (selectedClient.length <= 0) {
      toastr.warning("", "Please select a value to filter");
    }
    else {
      if (!activeTab) {
        let filteredReports: any = report.allReports.filter((reportDetails: any) => new Date(reportDetails.inspectorDetail.inspectionDate) >= fromDate && new Date(reportDetails.inspectorDetail.inspectionDate) <= toDate);
        const selectedClientValues = selectedClient.map(x => x.value);
        if (selectedClient.length > 0) {
          filteredReports = filteredReports.filter((reportDetails: any) => selectedClientValues.includes(`${reportDetails?.clientinformation?.firstName} ${reportDetails?.clientinformation?.lastName}`));
        }
        setReports(filteredReports);
        closeReportFilter();
      }
      else {
        let filteredReports: any = report.reportsForAdmin.filter((reportDetails: any) => new Date(reportDetails.inspectorDetail.inspectionDate) >= fromDate && new Date(reportDetails.inspectorDetail.inspectionDate) <= toDate);
        const selectedClientValues = selectedClient.map(x => x.value);
        if (selectedClient.length > 0) {
          filteredReports = filteredReports.filter((reportDetails: any) => selectedClientValues.includes(`${reportDetails?.clientinformation?.firstName} ${reportDetails?.clientinformation?.lastName}`));
        }
        setReportsForAdmin(filteredReports);
        closeReportFilter();
      }
    }
  }

  const handleCancelFilter = () => {
    if (selectedClient.length <= 0) {
      setReportsForAdmin(report.reportsForAdmin);
      setReports(report.allReports);
    }
    closeReportFilter();
  }

  useEffect(() => {
    if (!activeTab) {
      const filterClientOptions = report.allReports.filter((reportDetails: any) => reportDetails.clientinformation?.firstName !== '' && reportDetails.clientinformation?.firstName !== undefined).map((reportDetails: any) => `${reportDetails.clientinformation.firstName} ${reportDetails.clientinformation.lastName}`);
      const clientsSet = Array.from(new Set(filterClientOptions));
      const clientsOptionsArray = clientsSet.map((reportDetails: any) => {
        return { label: reportDetails, value: reportDetails }
      });
      setClientOptions(clientsOptionsArray);
    }
    else {
      const filterClientOptions = report.reportsForAdmin.filter((reportDetails: any) => reportDetails.clientinformation?.firstName !== '' && reportDetails.clientinformation?.firstName !== undefined).map((reportDetails: any) => `${reportDetails.clientinformation.firstName} ${reportDetails.clientinformation.lastName}`);
      const clientsSet = Array.from(new Set(filterClientOptions));
      const clientsOptionsArray = clientsSet.map((reportDetails: any) => {
        return { label: reportDetails, value: reportDetails }
      });
      setClientOptions(clientsOptionsArray);
    }
  }, [report.allReports, report.reportsForAdmin, activeTab]);


  const setPreviousRoute = () => {
    navigate(-1);
  }

  return (
    <>
      {(auth.loginLoading.length !== 0 || report.loading.length !== 0 || superAdmin.loading.length !== 0) && <Loader />}
      {auth.user?.user_Type !== 'company_admin' ? <Box p='29px 30px 20px 20px' minH='calc(100vh - 70px)'>
        <Box display='flex' justifyContent={{ sm: 'flex-start', md: 'space-between' }} alignItems='center' flexWrap='wrap'>
          <Box display='inline-flex' alignItems='center' mb='18px'>
            {auth?.user?.user_Type === "super_admin" && <Icon as={FiArrowLeft} color='black.lighten' cursor='pointer' fontSize='26px' m='2px 12px 2px 0' onClick={setPreviousRoute}></Icon>}
            <Text as='h1' mr='20px' color='black.lighten' fontSize='30px' fontWeight='700'>Reports</Text>
            <Box bg='orange.primary' p='0 8px' h='18px' borderRadius='5px'>
              <Text fontSize='12px' color='white.primary'>{auth?.user?.user_Type === "super_admin" ? superAdminReportList?.length : report?.allReports?.length}</Text>
            </Box>
          </Box>
          <Box display='inline-flex' w={{ sm: '100%', md: 'auto' }} mb='18px'>
            <InputGroup w='282px' mr='20px'>
              <InputLeftElement left='12px' top='0' _before={{ content: '""', position: 'absolute', right: '-8px', height: '23px', width: '1px', background: 'blue.light2' }} children={<Icon fontSize='17px' color='blue.dark3' as={ImSearch} />} />
              <Input variant='Search' placeholder='Search Keyword' h='40px' value={searchKeyword} onChange={(e) => setSearchKeyword(e.target.value)} />
            </InputGroup>
            <Button variant='secondary' mr='20px' onClick={openReportFilter}>Filter</Button>
            {auth?.user?.user_Type !== "super_admin" &&
              <Link to='/cloud-reports'><Button variant='primary3' mr='20px'><Icon as={BsFillCloudArrowDownFill} mr='10px' color='white' fontSize='20px'></Icon>Cloud Reports</Button></Link>
            }
            {auth?.user?.user_Type !== "super_admin" && <Link to='/add-report'><Button variant='primary3'>Add Reports</Button></Link>}
          </Box>
        </Box>
        <Box borderRadius='12px' overflow='hidden' boxShadow='0px 0px 16px #f1f1f1c7' pt='10px'>
          <DataTable
            className='hi-datatable-component customize-scrollbar'
            data={tableDataItems}
            columns={auth.user?.user_Type === "super_admin" ? superAdminTableColumns : tableColumns}
            pagination
            highlightOnHover
            fixedHeader
            fixedHeaderScrollHeight='calc(100vh - 252px)'
            onRowClicked={(reportDetails) => navigate(`/report-detail/${reportDetails.id}`)}
          />
        </Box>
      </Box> : <Box p='29px 30px 20px 20px' h='calc(100vh - 70px)' overflow='auto' w='100%'> <Box display='inline-flex' w='100%' alignItems='center'>
        <Box display='flex' justifyContent='space-between' w='100%' flexWrap='wrap'>
          <Text as='h1' mb={{ sm: '16px', xl: '26px' }} color='black.lighten' fontSize='24px' lineHeight='30px' fontWeight='600'>Reports</Text>
          <Box display='inline-flex' justifyContent={{ sm: 'flex-start', md: 'flex-end' }} w={{ sm: '100%', md: 'auto' }} mb='20px'>
            <InputGroup w='282px' mr='20px'>
              <InputLeftElement left='12px' top='0' _before={{ content: '""', position: 'absolute', right: '-8px', height: '23px', width: '1px', background: 'blue.light2' }} children={<Icon fontSize='17px' color='blue.dark3' as={ImSearch} />} />
              <Input variant='Search' placeholder='Search Keyword' h='40px' value={searchKeyword} onChange={(e) => setSearchKeyword(e.target.value)} />
            </InputGroup>
            <Button variant='secondary' mr='20px' onClick={openReportFilter}>Filter</Button>
            <Link to='/cloud-reports'><Button variant='primary3' mr='20px'><Icon as={BsFillCloudArrowDownFill} mr='10px' color='white' fontSize='20px'></Icon>Cloud Reports</Button></Link>
            <Link to='/add-report'><Button variant='primary3'>Add Reports</Button></Link>
          </Box>
        </Box>
      </Box>
        <Box bg='white.primary' borderRadius='4px'>
          <Tabs index={activeTab} onChange={(index: number) => setActiveTab(index)} className='tab-with-icons'>
            <TabList borderColor='gray.light3' borderBottomWidth='1px'>
              <Tab _selected={{ color: 'black.lighten' }} border='none' p='19px 17px'><Text fontSize={{ sm: '18px', xxl: '19px' }} lineHeight={{ sm: '24px', xxl: '26px' }} color='black.lighten'>My Reports</Text><Box bg='orange.primary' ml='5px' p='0 8px' h='18px' borderRadius='5px'>
                <Text fontSize='12px' color='white.primary'>{report.allReports.length}</Text>
              </Box></Tab>
              <Tab _selected={{ color: 'black.lighten' }} border='none' p='19px 17px'><Text fontSize={{ sm: '18px', xxl: '19px' }} lineHeight={{ sm: '24px', xxl: '26px' }} color='black.lighten'>Home Inspector Reports</Text><Box bg='orange.primary' ml='5px' p='0 8px' h='18px' borderRadius='5px'>
                <Text fontSize='12px' color='white.primary'>{report.reportsForAdmin.length}</Text>
              </Box></Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                <Box p='0px 30px 20px 20px' minH='calc(100vh - 275px)'>
                  <Box borderRadius='12px' overflow='hidden' boxShadow='0px 0px 16px #f1f1f1c7' pt='10px'>
                    <DataTable
                      className='hi-datatable-component customize-scrollbar'
                      data={tableDataItems}
                      columns={tableColumns}
                      pagination
                      highlightOnHover
                      fixedHeader
                      fixedHeaderScrollHeight='calc(100vh - 370px)'
                      onRowClicked={(reportDetails) => navigate(`/report-detail/${reportDetails.id}`)}
                    />
                  </Box>
                </Box>
              </TabPanel>
              <TabPanel>
                <Box p='0px 30px 20px 20px' minH='calc(100vh - 275px)'>
                  <Box borderRadius='12px' overflow='hidden' boxShadow='0px 0px 16px #f1f1f1c7' pt='10px'>
                    <DataTable
                      className='hi-datatable-component customize-scrollbar'
                      data={adminTableDataItems}
                      columns={adminHomeInspectortableColumns}
                      pagination
                      highlightOnHover
                      fixedHeader
                      fixedHeaderScrollHeight='calc(100vh - 370px)'
                      onRowClicked={(reportDetails) => navigate(`/admin-report-detail/${reportDetails.id}`)}
                    />
                  </Box>
                </Box>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Box> </Box>}
      <Modal isOpen={deleteConfirmation} onClose={closeDeleteConfirmation} size='xl' scrollBehavior='inside'>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader p='30px 30px 35px' fontSize='21px' lineHeight='26px' color='black.lighten' fontWeight='600'>Delete Report</ModalHeader>
          <Icon color='black.primary' fontSize='24px' as={IoMdClose} onClick={closeDeleteConfirmation} cursor='pointer' position='absolute' top='29px' right='29px'></Icon>
          <ModalBody p='0 30px 25px'>
            <Text fontSize='18px' color='black.lighten'>Are you sure you want to delete?</Text>
          </ModalBody>
          <ModalFooter>
            <Button variant='primary2' minW='120px' onClick={() => handleDeleteReport(deleteReportId)}>Yes</Button>
            <Button variant='grey' ml='15px' minW='120px' onClick={closeDeleteConfirmation}>No</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal isOpen={reportFilter} onClose={closeReportFilter} size='2xl' initialFocusRef={initialRef}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader p='30px 30px 25px' fontSize='21px' lineHeight='26px' color='black.lighten' fontWeight='600'>Filter Reports</ModalHeader>
          <Icon color='black.primary' fontSize='24px' as={IoMdClose} onClick={handleCancelFilter} cursor='pointer' position='absolute' top='29px' right='29px'></Icon>
          <ModalBody p='0 30px 30px'>
            <SimpleGrid columns={2} gap='20px'>
              <Box d='inline-flex' alignItems='center' justifyContent='space-between' flexWrap='wrap'>
                <Text w='100%' as='label' fontSize={{ sm: '16px', xxl: 'xxl' }} mb={{ sm: '4px', xxl: '0' }} color='black.lighten' pr={{ sm: '6px', xxl: '8px' }} textAlign='left'>From</Text>
                <Box zIndex='2' position='relative' w='100%'>
                  <DateTimePicker minDate={new Date(1900, 11, 32)} disableClock={true} className='dateTime-picker filter-dateTime-picker' name='inspectionDate' value={fromDate} onChange={setFromDate} format='MM-dd-yy hh:mm a' />
                </Box>
              </Box>
              <Box d='inline-flex' alignItems='center' justifyContent='space-between' flexWrap='wrap'>
                <Text w='100%' as='label' fontSize={{ sm: '16px', xxl: 'xxl' }} mb={{ sm: '4px', xxl: '0' }} color='black.lighten' pr={{ sm: '6px', xxl: '8px' }} textAlign='left'>To</Text>
                <Box zIndex='2' position='relative' w='100%'>
                  <DateTimePicker minDate={new Date(1900, 11, 32)} disableClock={true} className='dateTime-picker filter-dateTime-picker' name='inspectionDate' value={toDate} onChange={setToDate} format='MM-dd-yy hh:mm a' />
                </Box>
              </Box>
              <Box d='inline-flex' alignItems='center' justifyContent='space-between' flexWrap='wrap'>
                <Text w='100%' as='label' fontSize={{ sm: '16px', xxl: 'xxl' }} mb={{ sm: '4px', xxl: '0' }} color='black.lighten' pr={{ sm: '6px', xxl: '8px' }} textAlign='left'>Select Client</Text>
                <MultiSelect
                  options={clientOptions}
                  value={selectedClient}
                  onChange={setSelectedClient}
                  labelledBy="Select"
                  className="multiselect-dropdown"
                />
              </Box>
            </SimpleGrid>
          </ModalBody>
          <ModalFooter>
            <Button variant='primary2' minW='120px' onClick={handleReportFilter} ref={initialRef}>Filter</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal isOpen={paymentConfirmation} onClose={closePaymentConfirmation} size='xl' scrollBehavior='inside'>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader p='30px 30px 35px' fontSize='21px' lineHeight='26px' color='black.lighten' fontWeight='600'>Payment Confirmation</ModalHeader>
          <Icon color='black.primary' fontSize='24px' as={IoMdClose} onClick={closePaymentConfirmation} cursor='pointer' position='absolute' top='29px' right='29px'></Icon>
          <ModalBody p='0 30px 25px'>
            <Text fontSize='18px' color='black.lighten'>Are you sure the payment is recieved?</Text>
          </ModalBody>
          <ModalFooter>
            <Button variant='primary2' minW='120px' onClick={() => setViewInvoice(true)}>Yes</Button>
            <Button variant='grey' ml='15px' minW='120px' onClick={closePaymentConfirmation}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default ReportListing