import { Box, Flex, Icon, Select, SimpleGrid, Text } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import Chart from 'react-google-charts'
import { BsFillCaretDownFill } from 'react-icons/bs'
import { HiArrowDown, HiArrowUp } from 'react-icons/hi'
import { useDispatch, useSelector } from 'react-redux'
import { superAdmininspectionDetailForDashboard, referralDetails, superAdminRevenueDetails, superAdminState } from '../../redux/modules/superAdmin/superAdmin'
import { RootState } from '../../redux/store'
import Loader from '../common/loader'
function SuperAdminDashboard() {
  const superAdmin: superAdminState = useSelector((state: RootState) => state.superAdmin)
  const dispatch = useDispatch();
  const [revenueGraph, setRevenueGraph] = useState(null);
  const [usersGraph, setUsersGraph] = useState(null);
  const [clientGraph, setClientGraph] = useState(null);
  const [superAdminInpectionDetail, setSuperAdminInpectionDetail] = useState('monthly');
  const [referralDetail, setReferralDetail] = useState('monthly');
  const [inspectionDetail, setInpectionDetail] = useState(null);
  const [referralDetaillData, setReferralDetailData] = useState(null);
  const [filterStatistics, setFilterStatistics] = useState('monthly');
  const [superAdminDashboardRevenueDetails, setSuperAdminDashboardRevenueDetails] = useState(null);
  const [superAdminDashboardInspectionDetails, setSuperAdminDashboardInspectionDetails] = useState(null);

  useEffect(() => {
    dispatch(superAdminRevenueDetails({ type: filterStatistics }));
  }, [dispatch, filterStatistics])

  useEffect(() => {
    setSuperAdminDashboardRevenueDetails(superAdmin.superAdminRevenueDetails);
    setSuperAdminDashboardInspectionDetails(superAdmin.superAdminInspectionDetails);
  }, [superAdmin.superAdminInspectionDetails, superAdmin.superAdminRevenueDetails, superAdminDashboardRevenueDetails])

  useEffect(() => {
    if (superAdmin?.referralDetails) {
      const referalColor = ['color: #e95950', 'color: #009EE1', 'color: #FF0000', 'color: orange'];
      const referralData: any = Object.entries(superAdmin?.referralDetails);
      const referralData1 = referralData.map((x, index) => [x[0], Number(x[1]), referalColor[index]])
      referralData1.unshift(["Month", "Sales", { role: 'style' }]);
      setReferralDetailData(referralData1)
    }
    if (superAdminDashboardInspectionDetails?.inspectionData) {
      const inspeactionData: any = Object.entries(superAdminDashboardInspectionDetails?.inspectionData);
      inspeactionData.unshift(["Month", "Sales"]);
      setInpectionDetail(inspeactionData)
    }
    if (superAdminDashboardRevenueDetails?.RevenueGraph) {
      const revenueData: any = Object.entries(superAdminDashboardRevenueDetails?.RevenueGraph);
      revenueData.unshift(["Month", "Sales"]);
      setRevenueGraph(revenueData)
    }
    if (superAdminDashboardRevenueDetails?.UsersGraph) {
      const reportData: any = Object.entries(superAdminDashboardRevenueDetails?.UsersGraph);
      reportData.unshift(["Month", "Sales"]);
      setUsersGraph(reportData)
    }
    if (superAdminDashboardRevenueDetails?.ClientGraph) {
      const reportData: any = Object.entries(superAdminDashboardRevenueDetails?.ClientGraph);
      reportData.unshift(["Month", "Sales"]);
      setClientGraph(reportData)
    }

  }, [superAdmin?.referralDetails, superAdminDashboardInspectionDetails?.inspectionData, superAdminDashboardRevenueDetails?.ClientGraph, superAdminDashboardRevenueDetails?.RevenueGraph, superAdminDashboardRevenueDetails?.UsersGraph])

  const options = {
    curveType: "function",
    legend: { position: "none" },
    hAxis: {
      textPosition: 'none',
      gridlines: { color: "#CCCCCC" }
    },
    vAxis: {
      textPosition: 'none',
      gridlines: { color: 'transparent' }
    },
    chartArea: {
      left: 0,
      top: 0,
      right: 0,
      bottom: 0,
      width: '100%'
    },
    colors: ['#00CA72'],
  };
  const options1 = {
    curveType: "function",
    legend: { position: "none" },
    hAxis: {
      textPosition: 'none',
      gridlines: { color: "#CCCCCC" }
    },
    vAxis: {
      textPosition: 'none',
      gridlines: { color: 'transparent' }
    },
    chartArea: {
      left: 0,
      top: 0,
      right: 0,
      bottom: 0,
      width: '100%'
    },
    colors: ['#F74A00'],
  };
  const options2 = {
    curveType: "function",
    legend: { position: "none" },
    hAxis: {
      gridlines: { color: "#F1F1F1" }, title: 'Referral Count %'
    },
    vAxis: {
      gridlines: { color: "#F1F1F1" }, title: 'Referral Source'
    },
    chartArea: {
      left: 100,
      top: 10,
      right: 0,
      bottom: 50,
      width: '100%'
    },
    colors: ['#009EE1']
  };
  const options3 = {
    curveType: "function",
    legend: { position: "none" },
    hAxis: {
      gridlines: { color: "#F1F1F1" }, title: `${superAdminInpectionDetail.charAt(0).toUpperCase() + superAdminInpectionDetail.slice(1)}`,
    },
    vAxis: {
      gridlines: { color: "#F1F1F1" }, title: 'Number of Inspections',
    },
    chartArea: {
      left: 30,
      top: 10,
      right: 0,
      bottom: 50,
      width: '100%'
    },
    colors: ['#F89d2c']
  };
  const options4 = {
    curveType: "function",
    legend: { position: "none" },
    hAxis: {
      textPosition: 'none',
      gridlines: { color: "#CCCCCC" }
    },
    vAxis: {
      textPosition: 'none',
      gridlines: { color: 'transparent' }
    },
    chartArea: {
      left: 0,
      top: 0,
      right: 0,
      bottom: 0,
      width: '100%'
    },
    colors: ['#009EE1'],
  };
  const calculatePercentage = (previousData: number, currData: number) => {
    if (previousData && currData) {
      const amountChanged = (previousData - currData);
      const percentageCalc = Math.floor((amountChanged / previousData) * 100);
      return percentageCalc;
    }
  }

  useEffect(() => {
    dispatch(referralDetails({ type: referralDetail }));
  }, [dispatch, referralDetail]);

  useEffect(() => {
    dispatch(superAdmininspectionDetailForDashboard({ type: superAdminInpectionDetail }));
  }, [dispatch, superAdminInpectionDetail]);
  return (
    <>
      {superAdmin.loading.length !== 0 && <Loader />}
      <Box p='26px 20px 20px' h='calc(100vh - 70px)' overflow='auto'>
        <Flex alignItems='center' p='0 0 20px'>
          <Text as='h1' fontSize={{ sm: '24px', xxl: '26px' }} lineHeight='30px' fontWeight='600' color='black.lighten'>Dashboard</Text>
          <Select bg='white.primary' fontSize='lg' border='none' variant='Simple' w='140px' p='0' h='30px' ml='30px' borderRadius='3px' icon={<Box fontSize='14px'><BsFillCaretDownFill /></Box>} value={filterStatistics} onChange={(e) => setFilterStatistics(e.target.value)}>
            <option value='monthly'>Monthly</option>
            <option value='weekly'>Weekly</option>
          </Select>
        </Flex>
        <Box d='flex' flexWrap='wrap' justifyContent='space-between' mb={{ sm: '20px', xxl: '30px' }}>
          <Box w='100%'>
            <SimpleGrid columns={{ sm: 2, lg: 3 }} spacing={{ sm: '15px', xxl: '30px' }} mb={{ sm: '20px', xxl: '30px' }}>
              <Box bg='white.primary' borderRadius='16px' alignItems='center' p={{ sm: '5px 14px 11px', xxl: '5px 20px 11px' }} justifyContent='space-between' w='100%'>
                <Box w='100%'>
                  <Chart
                    chartType="LineChart"
                    className='statistics-line-chart'
                    data={revenueGraph}
                    options={options}
                  />
                </Box>
                <Box d='flex' alignItems='flex-start'>
                  <Box d='inline-flex' alignItems='flex-start' width='auto'>
                    <Box>
                      <Text as='h3' fontSize={{ sm: '22px', xxl: '24px' }} lineHeight='26px' mb='2px' fontWeight='600' color='black.lighten'>
                        ${Math.trunc(superAdminDashboardRevenueDetails?.currentTotalAmount) || 0}
                      </Text>
                      <Text as='span' fontSize='14px' color='gray.darken'>{filterStatistics.charAt(0).toUpperCase() + filterStatistics.slice(1)} Revenue</Text>
                    </Box>
                  </Box>
                  <Box ml='auto' pr='5px' d='inline-flex' alignItems='center' textAlign='end'>
                    <Text as='span' fontSize='18px' color={calculatePercentage(superAdminDashboardRevenueDetails?.monthlyPreviousTotalAmt, superAdminDashboardRevenueDetails?.currentTotalAmount) <= 0 ? 'red.lighten2' : 'green.ligthen3'} ml='auto' pl='8px'>
                      {calculatePercentage(superAdminDashboardRevenueDetails?.monthlyPreviousTotalAmt, superAdminDashboardRevenueDetails?.monthlyPreviousTotalAmt) || 0}%
                    </Text>
                    <Icon as={calculatePercentage(superAdminDashboardRevenueDetails?.monthlyPreviousTotalAmt, superAdminDashboardRevenueDetails?.currentTotalAmount) <= 0 ? HiArrowDown : HiArrowUp} color={calculatePercentage(superAdminDashboardRevenueDetails?.monthlyPreviousTotalAmt, superAdminDashboardRevenueDetails?.currentTotalAmount) <= 0 ? 'red.lighten2' : 'green.ligthen4'} ml='2px' fontSize='16px'></Icon>
                  </Box>
                </Box>
              </Box>
              <Box bg='white.primary' borderRadius='16px' alignItems='center' p={{ sm: '5px 14px 11px', xxl: '5px 20px 11px' }} justifyContent='space-between' w='100%'>
                <Box w='100%'>
                  <Chart
                    chartType="LineChart"
                    className='statistics-line-chart'
                    data={usersGraph}
                    options={options1}
                  />
                </Box>
                <Box d='flex' alignItems='flex-start'>
                  <Box d='inline-flex' alignItems='flex-start'>
                    <Box>
                      <Text as='h3' fontSize={{ sm: '22px', xxl: '24px' }} lineHeight='26px' mb='2px' fontWeight='600' color='black.lighten'>
                        {superAdminDashboardRevenueDetails?.currentMonthlyUsersDetails?.count || 0}
                      </Text>
                      <Text as='span' fontSize='14px' color='gray.darken'>Total User</Text>
                    </Box>
                  </Box>
                  <Box ml='auto' pr='5px' d='inline-flex' alignItems='center' textAlign='center'>
                    <Text as='span' fontSize='18px' color={calculatePercentage(superAdminDashboardRevenueDetails?.previousMonthlyUsers?.count, superAdminDashboardRevenueDetails?.currentMonthlyUsersDetails?.count) <= 0 ? 'red.lighten2' : 'green.ligthen3'} ml='auto' pl='8px'>
                      {calculatePercentage(superAdminDashboardRevenueDetails?.previousMonthlyUsers?.count, superAdminDashboardRevenueDetails?.currentMonthlyUsersDetails?.count) || 0}%
                    </Text>
                    <Icon as={calculatePercentage(superAdminDashboardRevenueDetails?.previousMonthlyUsers?.count, superAdminDashboardRevenueDetails?.currentMonthlyUsersDetails?.count) <= 0 ? HiArrowDown : HiArrowUp} color={calculatePercentage(superAdminDashboardRevenueDetails?.previousreportDetails?.count, superAdminDashboardRevenueDetails?.currentMonthlyUsersDetails?.count) <= 0 ? 'red.lighten2' : 'green.ligthen4'} ml='2px' fontSize='16px'></Icon>
                  </Box>
                </Box>
              </Box>
              <Box bg='white.primary' borderRadius='16px' alignItems='center' p={{ sm: '5px 14px 11px', xxl: '5px 20px 11px' }} justifyContent='space-between' w='100%'>
                <Box w='100%'>
                  <Chart
                    chartType="LineChart"
                    className='statistics-line-chart'
                    data={clientGraph}
                    options={options4}
                  />
                </Box>
                <Box d='flex' alignItems='flex-start'>
                  <Box d='inline-flex' alignItems='flex-start'>
                    <Box>
                      <Text as='h3' fontSize={{ sm: '22px', xxl: '24px' }} lineHeight='26px' mb='2px' fontWeight='600' color='black.lighten'>
                        +{superAdminDashboardRevenueDetails?.currentClientdetails?.count || 0}
                      </Text>
                      <Text as='span' fontSize='14px' color='gray.darken'>New Client</Text>
                    </Box>
                  </Box>
                  <Box ml='auto' pr='5px' d='inline-flex' alignItems='center' textAlign='end'>
                    <Text as='span' fontSize='18px' color={calculatePercentage(superAdminDashboardRevenueDetails?.monthlyPreviousClientDetails?.count, superAdminDashboardRevenueDetails?.currentClientdetails?.count) <= 0 ? 'red.lighten2' : 'green.ligthen3'} ml='auto' pl='8px'>
                      {calculatePercentage(superAdminDashboardRevenueDetails?.monthlyPreviousClientDetails?.count, superAdminDashboardRevenueDetails?.currentClientdetails?.count) || 0}%
                    </Text>
                    <Icon as={calculatePercentage(superAdminDashboardRevenueDetails?.monthlyPreviousClientDetails?.count, superAdminDashboardRevenueDetails?.currentClientdetails?.count) <= 0 ? HiArrowDown : HiArrowUp} color={calculatePercentage(superAdminDashboardRevenueDetails?.monthlyPreviousClientDetails?.count, superAdminDashboardRevenueDetails?.currentClientdetails?.count) <= 0 ? 'red.lighten2' : 'green.ligthen4'} ml='2px' fontSize='16px'></Icon>
                  </Box>
                </Box>
              </Box>
            </SimpleGrid>
            <Box bg='white.primary' borderRadius='12px' w='100%' overflow='hidden' mb={{ sm: '20px', xxl: '30px' }} minH='294px'>
              <Box bg='orange.primary' d='flex' alignItems='center' mb='10px' p='7px 11px 7px 18px'>
                <Text as='h3' fontSize={{ sm: '22px', xxl: '24px' }} lineHeight='1' fontWeight='600' color='white.primary' d='inline-block'>Inspection</Text>
                <Box ml='auto'>
                  <Select bg='white.primary' display='inline-block' fontSize='lg' border='none' variant='Simple' w='140px' p='0' h='30px' ml='30px' borderRadius='3px' icon={<Box fontSize='14px'><BsFillCaretDownFill /></Box>} value={superAdminInpectionDetail} onChange={(e) => setSuperAdminInpectionDetail(e.target.value)}>
                    <option value='monthly'>Monthly</option>
                    <option value='weekly'>Weekly</option>
                  </Select>
                </Box>
              </Box>
              <Chart
                chartType="LineChart"
                width="100%"
                className='revenue-line-chart'
                data={inspectionDetail}
                options={options3}
              />
            </Box>
            <Box bg='white.primary' borderRadius='12px' w='100%' overflow='hidden' mb={{ sm: '20px', xxl: '30px' }} minH='294px'>
              <Box bg='orange.primary' d='flex' alignItems='center' mb='10px' p='7px 11px 7px 18px'>
                <Text as='h3' fontSize={{ sm: '22px', xxl: '24px' }} lineHeight='1' fontWeight='600' color='white.primary' d='inline-block'>Referral</Text>
                <Box ml='auto'>
                  <Select bg='white.primary' display='inline-block' fontSize='lg' border='none' variant='Simple' w='140px' p='0' h='30px' ml='30px' borderRadius='3px' icon={<Box fontSize='14px'><BsFillCaretDownFill /></Box>} value={referralDetail} onChange={(e) => setReferralDetail(e.target.value)}>
                    <option value='monthly'>Monthly</option>
                    <option value='weekly'>Weekly</option>
                  </Select>
                </Box>
              </Box>
              <Chart
                chartType="BarChart"
                width="100%"
                className='revenue-line-chart'
                data={referralDetaillData}
                options={options2}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  )
}

export default SuperAdminDashboard