import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../api';

export interface subscriptionState {
  error: any;
  loading: boolean[];
  subscriptionPlans: any[];
  subscriptionPlan: any[];
  isSubscriptionExpire: boolean;
  subscriptiondetails: object[];
  allsubscriptiondetails: object[];
}

const initialState: subscriptionState = {
  error: null,
  loading: [],
  subscriptionPlans: [],
  subscriptionPlan: [],
  subscriptiondetails: [],
  isSubscriptionExpire: false,
  allsubscriptiondetails: []
};

export const createSubscription = createAsyncThunk(
  'subscription/create',
  async (data: { payload: object }) => {
    const { payload } = data;
    const response = await api.post('subscription/create/subscription', { data: payload });
    console.log("Asyncy", response.data)
    return response.data;
  }
);

export const cancelAdminSubscriptionPlan = createAsyncThunk(
  'subscription/cancelAdminSubscriptionPlan',
  async (data: { payload: object }) => {
    const { payload } = data;
    const response = await api.post('admin/cancel/subscription', { data: payload });
    return response.data;
  }
);

export const lastsubscriptiondetails = createAsyncThunk(
  'subscription/lastsubscriptiondetails',
  async () => {
    const response = await api.get('subscription/lastsubscriptiondetails');
    return response.data;
  }
);

export const getAllLastsubscriptionDetails = createAsyncThunk(
  'subscription/getAllLastsubscriptionDetails',
  async () => {
    const response = await api.get('superadmin/get/invoice/subscription');
    return response.data;
  }
);


export const subscriptionSlice = createSlice({
  name: 'subscription',
  initialState,
  reducers: {
    setSubscriptionError: (state, action) => {
      state.error = action.payload;
    },
    setSubscriptionExpiration: (state, action) => {
      state.isSubscriptionExpire = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(createSubscription.pending, (state) => {
        state.error = null;
        state.loading.push(true);
      })
      .addCase(createSubscription.rejected, (state, action) => {
        state.loading.pop();
        state.error = action.error;
      })
      .addCase(createSubscription.fulfilled, (state) => {
        state.error = null;
        state.loading.pop();
      })
      .addCase(cancelAdminSubscriptionPlan.pending, (state) => {
        state.error = null;
        state.loading.push(true);
      })
      .addCase(cancelAdminSubscriptionPlan.rejected, (state, action) => {
        state.loading.pop();
        state.error = action.error;
      })
      .addCase(cancelAdminSubscriptionPlan.fulfilled, (state) => {
        state.error = null;
        state.loading.pop();
      })
      .addCase(lastsubscriptiondetails.pending, (state) => {
        state.error = null;
        state.loading.push(true);
      })
      .addCase(lastsubscriptiondetails.rejected, (state, action) => {
        state.loading.pop();
        state.error = action.error;
      })
      .addCase(lastsubscriptiondetails.fulfilled, (state, action) => {
        state.error = null;
        state.loading.pop();
        state.subscriptiondetails = action.payload;
      })
      .addCase(getAllLastsubscriptionDetails.pending, (state) => {
        state.error = null;
        state.loading.push(true);
      })
      .addCase(getAllLastsubscriptionDetails.rejected, (state, action) => {
        state.loading.pop();
        state.error = action.error;
      })
      .addCase(getAllLastsubscriptionDetails.fulfilled, (state, action) => {
        state.error = null;
        state.loading.pop();
        state.allsubscriptiondetails = action.payload;
      })
  }
});

export const { setSubscriptionExpiration, setSubscriptionError } = subscriptionSlice.actions;
export default subscriptionSlice.reducer;