import { Box, Button, Icon, Input, InputGroup, InputLeftElement, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Select, SimpleGrid, Text, Tooltip, useDisclosure } from '@chakra-ui/react'
import { useEffect, useRef, useState } from 'react'
import DataTable from 'react-data-table-component'
import { MdDelete, MdEdit } from 'react-icons/md'
import { TbFileInvoice } from "react-icons/tb";
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../redux/store'
import { deleteReportById, generateInvoice, getInvoiceReport, ReportState, getAllReportsCloudStorage } from '../../../../redux/modules/report'
import { Link, useNavigate } from 'react-router-dom'
import { IoMdClose } from 'react-icons/io'
import moment from 'moment'
import Loader from '../../../../components/common/loader'
import { resetTemplate, resetTemplateTabs } from '../../../../redux/modules/template';
import { toastr } from 'react-redux-toastr';
import DateTimePicker from 'react-datetime-picker';
import { MultiSelect } from 'react-multi-select-component';
import { ImSearch } from 'react-icons/im';
import { authState } from '../../../../redux/modules/auth'
import { FiArrowLeft } from "react-icons/fi";
import { superAdminState } from '../../../../redux/modules/superAdmin/superAdmin';

export function ReportListing() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const report: ReportState = useSelector((state: RootState) => state.report);
  const auth: authState = useSelector((state: RootState) => state.auth);
  const superAdmin: superAdminState = useSelector((state: RootState) => state.superAdmin)

  const [deleteReportId, setDeleteReportId] = useState(null);
  const { isOpen: reportFilter, onOpen: openReportFilter, onClose: closeReportFilter } = useDisclosure();
  const { isOpen: deleteConfirmation, onOpen: openDeleteConfirmation, onClose: closeDeleteConfirmation } = useDisclosure();
  const { isOpen: paymentConfirmation, onOpen: openPaymentConfirmation, onClose: closePaymentConfirmation } = useDisclosure();
  const [reports, setReports] = useState([]);
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const [selectedClient, setSelectedClient] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [clientOptions, setClientOptions] = useState([]);
  const initialRef = useRef(null);
  const [viewInvoice, setViewInvoice] = useState(null);
  const [invoiceReport, setInvoiceReport] = useState(null);

  // Initial value loder
  // ... render page resources

  useEffect(() => {
    dispatch(getAllReportsCloudStorage());
  }, [dispatch]);

  useEffect(() => {
    setReports(report.allCloudReports);
    const reportDates = report.allCloudReports.map((x) => x.inspectorDetail.inspectionDate);
    reportDates.sort((a: string, b: string) => { return Date.parse(a) - Date.parse(b); });
    if (reportDates.length) {
      setFromDate(new Date(reportDates[0]));
    }
  }, [report.allCloudReports]);

  useEffect(() => {
    if (searchKeyword.match(/^[a-zA-Z0-9!@. ]+$/g)) {
      let data: any = [];
      data.push(...report.allCloudReports.filter((reportInfo) => reportInfo.inspectorDetail?.reportId && reportInfo.inspectorDetail?.reportId.match(searchKeyword)));
      data.push(...report.allCloudReports.filter((reportInfo) => reportInfo.inspectorDetail?.inspectorName && reportInfo.inspectorDetail?.inspectorName.match(searchKeyword)));
      data.push(...report.allCloudReports.filter((reportInfo) => reportInfo.clientinformation?.firstName && `${reportInfo.clientinformation?.firstName} ${reportInfo.clientinformation?.lastName}`.match(searchKeyword)));

      data = data.filter((value, index, self) =>
        index === self.findIndex((t) => (
          t.id === value.id
        ))
      )
      setReports(data);
    }
    else {
      setReports(report.allCloudReports);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchKeyword]);

  const tableColumns = [
    {
      name: 'Report #',
      selector: (row: any) => row.report_no,
      sortable: true,
      width: '140px'
    },
    {
      name: 'Inspectors',
      selector: (row: any) => row.inspectors,
      sortable: true,
      minWidth: '250px'
    },
    {
      name: 'Inspection Date / Time',
      selector: (row: any) => row.dateTime,
      sortable: true,
      minWidth: '218px'
    },
    {
      name: 'Client',
      selector: (row: any) => row.client,
      sortable: true,
      minWidth: '110px'
    },
    {
      name: 'Client Ratings',
      selector: (row: any) => row.property_ratings,
      minWidth: '180px'
    },
    {
      name: 'Amount Paid',
      selector: (row: any) => row.amount_paid,
      minWidth: '170px'
    },
    {
      name: 'Payment Mode',
      selector: (row: any) => row.payment_mode,
      minWidth: '170px'
    },
    {
      name: 'Payment Status',
      selector: (row: any) => row.payment_status,
      minWidth: '170px'
    },
    {
      name: 'Manage',
      selector: (row: any) => row.manage,
      minWidth: '170px'
    }
  ];

  const tableDataItems = reports?.map((reportInfo: any) => {
    return {
      "report_no": reportInfo.inspectorDetail?.reportId,
      "inspectors": `${reportInfo.inspectorDetail?.inspectorName}`,
      "dateTime": moment(reportInfo.inspectorDetail?.inspectionDate).format("MM-DD-YYYY, HH:MM A"),
      "client": reportInfo.clientinformation?.firstName ? `${reportInfo.clientinformation?.firstName} ${reportInfo.clientinformation?.lastName}` : '',
      "property_ratings": <Box w='100%' textAlign='center' p='0 50px'><Text>{+(reportInfo.propertyDetail?.rateProperty) || 0}/5</Text></Box>,
      "amount_paid": <>{reportInfo['payment-detail']?.status
        && <Box w='72px' justifyContent='center' bg='yellow.primary' fontSize='13px' color='yellow.dark' d='inline-flex' alignItems='center' h='26px' p='0 8px' borderRadius='30px'>{reportInfo['payment-detail']?.status === 'Completed'
          ? 'Paid' : 'Unpaid'}</Box>}</>,
      "fee": <Text color='blue.primary' fontWeight='600'>{reportInfo['payment-detail']?.totalAmount}</Text>,
      "payment_mode": <>{reportInfo['payment-detail']?.paymentMode && <Box justifyContent='center' bg={reportInfo['payment-detail']?.paymentMode == 'Online Payment' ? 'green.lighten9' : 'yellow.primary'} fontSize='13px' color={reportInfo['payment-detail']?.paymentMode == 'Online Payment' ? 'green.lighten' : 'yellow.dark'} d='inline-flex' alignItems='center' h='26px' p='0 8px' borderRadius='30px'>{reportInfo['payment-detail']?.paymentMode}</Box>}</>,
      "payment_status": <>{reportInfo['payment-detail']?.status && <Select fontSize='xl' border='none' bg={reportInfo['payment-detail']?.status == 'Pending' ? '#ffaaaa' : 'green.lighten9'} textAlign='center' variant='Simple' w='120px' h='30px' value={reportInfo['payment-detail']?.status ? reportInfo['payment-detail'].status.toLowerCase() : ''} borderRadius='15px' isDisabled={reportInfo['payment-detail']?.status && reportInfo['payment-detail'].status.toLowerCase() === 'completed'} onChange={(e) => {
        if (e.target.value === 'completed') {
          openPaymentConfirmation();
          setInvoiceReport(reportInfo);
        }
      }}>
        <option value='pending'>Pending</option>
        <option value='completed'>Completed</option>
      </Select>}</>,
      "manage": <><Tooltip hasArrow label='Edit Report' fontSize='14px' bg='gray.light12' color='white.primary' placement='top'><Button variant='iconBtn' minW='unset' p='0' color='gray.primary' mr='15px' onClick={() => handleEditReport(reportInfo.id)}><Icon fontSize='18px' as={MdEdit}></Icon></Button></Tooltip><Tooltip hasArrow label='Delete Report' fontSize='14px' bg='gray.light12' color='white.primary' placement='top'><Button fontSize='18px' variant='iconBtn' minW='unset' p='0' color='gray.primary' mr='15px' onClick={() => handleDeleteReportModal(reportInfo.id)}><Icon as={MdDelete}></Icon></Button></Tooltip>
        {reportInfo['payment-detail']?.status === 'Completed' && <Tooltip fontSize='14px' bg='gray.light12' color='white.primary' hasArrow label='View Invoice' placement='top'><Button fontSize='18px' variant='iconBtn' minW='unset' p='0' color='gray.primary' mr='15px' onClick={() => handleInvoiceDetails(reportInfo)}><Icon as={TbFileInvoice}></Icon></Button></Tooltip>}
      </>,
      "id": reportInfo.id
    }
  });

  useEffect(() => {

    const handlePaymentConfirmation = () => {
      if (viewInvoice) {
        if (!(invoiceReport.clientinformation.id && invoiceReport['payment-detail']?.id)) {
          toastr.warning("", "Please update the Client and Payment information to complete the payment");
          return;
        }
        new Promise(() => {
          const data2: any = dispatch(generateInvoice({ reportId: invoiceReport.id, companyId: auth.company.id, clientId: invoiceReport.clientinformation.id, paymentId: invoiceReport['payment-detail']?.id }))
          data2.then(function (value) {
            if (value.type === 'report/generate/invoice/fulfilled') {
              toastr.success("", "Invoice generated successfully");
              closePaymentConfirmation();
              dispatch(getAllReportsCloudStorage());
              dispatch(getInvoiceReport({ reportId: invoiceReport.id }));
              setViewInvoice(false);
            }
          })
        });
      }
    }
    handlePaymentConfirmation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, invoiceReport, viewInvoice]);

  const handleEditReport = (reportId: string) => {
    dispatch(resetTemplate('all'));
    dispatch(resetTemplateTabs('all'));
    navigate(`/edit-report/${reportId}`);
  }
  const handleInvoiceDetails = (reportInfo: any) => {
    navigate(`/invoice/${reportInfo.id}`);
  }

  const handleDeleteReport = async (reportId: string) => {
    new Promise(() => {
      const data3: any = dispatch(deleteReportById({ paramId: reportId }));
      data3.then(function (value) {
        if (value.type === 'report/deleteReportById/fulfilled') {
          dispatch(getAllReportsCloudStorage());
          closeDeleteConfirmation(); // Close Confirmation Modal
          toastr.success("", "Report deleted successfully");
        }
      })
    });
  }

  const handleDeleteReportModal = (reportId: string) => {
    setDeleteReportId(reportId);
    openDeleteConfirmation(); // Open Confirmation Modal
  }
  const handleReportFilter = () => {
    if (selectedClient.length <= 0) {
      toastr.warning("", "Please select a value to filter");
    }
    else {
      let filteredReports: any = report.allCloudReports.filter((reportInfo: any) => new Date(reportInfo.inspectorDetail.inspectionDate) >= fromDate && new Date(reportInfo.inspectorDetail.inspectionDate) <= toDate);
      const selectedClientValues = selectedClient.map(x => x.value);
      if (selectedClient.length > 0) {
        filteredReports = filteredReports.filter((reportInfo: any) => selectedClientValues.includes(`${reportInfo?.clientinformation?.firstName} ${reportInfo?.clientinformation?.lastName}`));
      }
      setReports(filteredReports);
      closeReportFilter();
    }
  }

  useEffect(() => {
    const filterClientOptions = report.allCloudReports.filter((reportInfo: any) => reportInfo.clientinformation?.firstName !== '' && reportInfo.clientinformation?.firstName !== undefined).map((reportInfo: any) => `${reportInfo.clientinformation.firstName} ${reportInfo.clientinformation.lastName}`);
    const clientsSet = Array.from(new Set(filterClientOptions));
    const clientsOptionsArray = clientsSet.map((reportInfo: any) => {
      return { label: reportInfo, value: reportInfo }
    });
    setClientOptions(clientsOptionsArray);
  }, [report.allCloudReports]);


  const setPreviousRoute = () => {
    navigate(-1);
  }

  return (
    <>
      {(auth.loginLoading.length !== 0 || report.loading.length !== 0 || superAdmin.loading.length !== 0) && <Loader />}
      <Box p='29px 30px 20px 20px' minH='calc(100vh - 70px)'>
        <Box display='flex' justifyContent={{ sm: 'flex-start', md: 'space-between' }} alignItems='center' flexWrap='wrap'>
          <Box display='inline-flex' alignItems='center' mb='18px'>
            {auth?.user?.user_Type === "super_admin" && <Icon as={FiArrowLeft} color='black.lighten' cursor='pointer' fontSize='26px' m='2px 12px 2px 0' onClick={setPreviousRoute}></Icon>}
            <Text as='h1' mr='20px' color='black.lighten' fontSize='30px' fontWeight='700'>
              <Link to='/reports'>
                <Button
                  mr="2px"
                  p="0"
                  variant="iconBtn"
                  justifyContent="flex-start"
                >
                  <Icon
                    as={FiArrowLeft}
                    color="black.lighten"
                    cursor="pointer"
                    fontSize="26px"
                    m="2px 8px 2px 0"
                  ></Icon>
                </Button>
              </Link>
              Cloud reports
            </Text>
            <Box bg='orange.primary' p='0 8px' h='18px' borderRadius='5px'>
              <Text fontSize='12px' color='white.primary'>{report?.allCloudReports?.length}</Text>
            </Box>
          </Box>
          <Box display='inline-flex' w={{ sm: '100%', md: 'auto' }} mb='18px'>
            <InputGroup w='282px' mr='20px'>
              <InputLeftElement left='12px' top='0' _before={{ content: '""', position: 'absolute', right: '-8px', height: '23px', width: '1px', background: 'blue.light2' }} children={<Icon fontSize='17px' color='blue.dark3' as={ImSearch} />} />
              <Input variant='Search' placeholder='Search Keyword' h='40px' value={searchKeyword} onChange={(e) => setSearchKeyword(e.target.value)} />
            </InputGroup>
            <Button variant='secondary' onClick={openReportFilter}>Filter</Button>
          </Box>
        </Box>
        <Box borderRadius='12px' overflow='hidden' boxShadow='0px 0px 16px #f1f1f1c7' pt='10px'>
          <DataTable
            className='hi-datatable-component customize-scrollbar'
            data={tableDataItems}
            columns={tableColumns}
            pagination
            highlightOnHover
            fixedHeader
            fixedHeaderScrollHeight='calc(100vh - 252px)'
            onRowClicked={(reportDetails) => navigate(`/report-detail/${reportDetails.id}`)}
          />
        </Box>
      </Box>
      <Modal isOpen={deleteConfirmation} onClose={closeDeleteConfirmation} size='xl' scrollBehavior='inside'>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader p='30px 30px 35px' fontSize='21px' lineHeight='26px' color='black.lighten' fontWeight='600'>Delete Report</ModalHeader>
          <Icon color='black.primary' fontSize='24px' as={IoMdClose} onClick={closeDeleteConfirmation} cursor='pointer' position='absolute' top='29px' right='29px'></Icon>
          <ModalBody p='0 30px 25px'>
            <Text fontSize='18px' color='black.lighten'>Are you sure you want to delete?</Text>
          </ModalBody>
          <ModalFooter>
            <Button variant='primary2' minW='120px' onClick={() => handleDeleteReport(deleteReportId)}>Yes</Button>
            <Button variant='grey' ml='15px' minW='120px' onClick={closeDeleteConfirmation}>No</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal isOpen={reportFilter} onClose={closeReportFilter} size='2xl' initialFocusRef={initialRef}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader p='30px 30px 25px' fontSize='21px' lineHeight='26px' color='black.lighten' fontWeight='600'>Filter Reports</ModalHeader>
          <Icon color='black.primary' fontSize='24px' as={IoMdClose} onClick={closeReportFilter} cursor='pointer' position='absolute' top='29px' right='29px'></Icon>
          <ModalBody p='0 30px 30px'>
            <SimpleGrid columns={2} gap='20px'>
              <Box d='inline-flex' alignItems='center' justifyContent='space-between' flexWrap='wrap'>
                <Text w='100%' as='label' fontSize={{ sm: '16px', xxl: 'xxl' }} mb={{ sm: '4px', xxl: '0' }} color='black.lighten' pr={{ sm: '6px', xxl: '8px' }} textAlign='left'>From</Text>
                <Box zIndex='2' position='relative' w='100%'>
                  <DateTimePicker minDate={new Date(1900, 11, 32)} disableClock={true} className='dateTime-picker filter-dateTime-picker' name='inspectionDate' value={fromDate} onChange={setFromDate} format='MM-dd-yy hh:mm a' />
                </Box>
              </Box>
              <Box d='inline-flex' alignItems='center' justifyContent='space-between' flexWrap='wrap'>
                <Text w='100%' as='label' fontSize={{ sm: '16px', xxl: 'xxl' }} mb={{ sm: '4px', xxl: '0' }} color='black.lighten' pr={{ sm: '6px', xxl: '8px' }} textAlign='left'>To</Text>
                <Box zIndex='2' position='relative' w='100%'>
                  <DateTimePicker minDate={new Date(1900, 11, 32)} disableClock={true} className='dateTime-picker filter-dateTime-picker' name='inspectionDate' value={toDate} onChange={setToDate} format='MM-dd-yy hh:mm a' />
                </Box>
              </Box>
              <Box d='inline-flex' alignItems='center' justifyContent='space-between' flexWrap='wrap'>
                <Text w='100%' as='label' fontSize={{ sm: '16px', xxl: 'xxl' }} mb={{ sm: '4px', xxl: '0' }} color='black.lighten' pr={{ sm: '6px', xxl: '8px' }} textAlign='left'>Select Client</Text>
                <MultiSelect
                  options={clientOptions}
                  value={selectedClient}
                  onChange={setSelectedClient}
                  labelledBy="Select"
                  className="multiselect-dropdown"
                />
              </Box>
            </SimpleGrid>
          </ModalBody>
          <ModalFooter>
            <Button variant='primary2' minW='120px' onClick={handleReportFilter} ref={initialRef}>Filter</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal isOpen={paymentConfirmation} onClose={closePaymentConfirmation} size='xl' scrollBehavior='inside'>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader p='30px 30px 35px' fontSize='21px' lineHeight='26px' color='black.lighten' fontWeight='600'>Payment Confirmation</ModalHeader>
          <Icon color='black.primary' fontSize='24px' as={IoMdClose} onClick={closePaymentConfirmation} cursor='pointer' position='absolute' top='29px' right='29px'></Icon>
          <ModalBody p='0 30px 25px'>
            <Text fontSize='18px' color='black.lighten'>Are you sure the payment is recieved?</Text>
          </ModalBody>
          <ModalFooter>
            <Button variant='primary2' minW='120px' onClick={() => setViewInvoice(true)}>Yes</Button>
            <Button variant='grey' ml='15px' minW='120px' onClick={closePaymentConfirmation}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default ReportListing