import { Box, Text } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component'
import { useDispatch, useSelector } from 'react-redux'
import { authState, setAuthError } from '../../redux/modules/auth'
import { RootState } from '../../redux/store'
import moment from 'moment';
import { toastr } from 'react-redux-toastr'
import { getAllLastsubscriptionDetails, lastsubscriptiondetails, setSubscriptionError, subscriptionState } from '../../redux/modules/subscription'
import Loader from '../common/loader'

export function InvoiceListing() {
  const dispatch = useDispatch();
  const auth: authState = useSelector((state: RootState) => state.auth);
  const subscription: subscriptionState = useSelector((state: RootState) => state.subscription);

  const [subscriptionDetails, setSubscriptionDetails] = useState([]);

  // Initial value loder
  // ... render page resources
  useEffect(() => {
    if(auth?.user?.user_Type === "super_admin"){
      dispatch(getAllLastsubscriptionDetails());
    }else{
      dispatch(lastsubscriptiondetails());
    }

  }, [auth?.user?.user_Type,dispatch]);

  const columns = [
    {
      name: 'Payment Date / Time',
      selector: (row: any) => row.payment_date_time,
      sortable: true,
      minWidth: '210px'
    },
    {
      name: 'Customer Name',
      selector: (row: any) => row.customer_name,
      minWidth: '210px'
    },
    {
      name: 'Paid To',
      selector: (row: any) => row.paid_to,
      minWidth: '210px'
    },
    {
      name: 'Contact No',
      selector: (row: any) => row.contact_no,
      minWidth: '150px'
    },
    {
      name: 'Plan',
      selector: (row: any) => row.plan,
      minWidth: '150px'
    },
    {
      name: 'Duration',
      selector: (row: any) => row.durations,
      minWidth: '150px'
    },
    {
      name: 'Fee',
      selector: (row: any) => row.fee,
      maxWidth: '200px'
    }
  ];

  const columnsSuperAdmin = [
    {
      name: 'Payment Date / Time',
      selector: (row: any) => row.payment_date_time,
      sortable: true,
      minWidth: '210px'
    },
    {
      name: 'Customer Name',
      selector: (row: any) => row.customer_name,
      minWidth: '210px'
    },
    {
      name: 'Paid To',
      selector: (row: any) => row.paid_to,
      minWidth: '210px'
    },
    {
      name: 'Plan',
      selector: (row: any) => row.plan,
      minWidth: '150px'
    },
    {
      name: 'Duration',
      selector: (row: any) => row.durations,
      minWidth: '150px'
    },
    {
      name: 'Fee',
      selector: (row: any) => row.fee,
      maxWidth: '200px'
    }
  ];

  const tableDataItems =(auth?.user?.user_Type === "super_admin" ? subscription.allsubscriptiondetails:subscriptionDetails).map((transaction: any) => ({
    "payment_date_time": moment(transaction.createdAt).format("DD-MM-YYYY, hh:mm A"),
    "paid_to": transaction.paidTo,
    "customer_name": transaction.customerName,
    "plan": <Box minW='82px' justifyContent='center' bg={transaction.plan === 'Monthly' ? 'blue.light7' : 'green.lighten6'} fontSize='13px' color={transaction.plan === 'Monthly' ? 'blue.dark5' : 'green.darken'} d='inline-flex' alignItems='center' h='26px' p='0 8px' borderRadius='30px' fontWeight='600'>{transaction.plan}</Box>,
    "durations": <Box w='82px' justifyContent='center' bg={transaction.duration === 'Year' ? 'red.ligthen4' : 'purple.light'} fontSize='13px' color={transaction.duration === 'Year' ? 'red.darken2' : 'purple.dark'} d='inline-flex' alignItems='center' h='26px' p='0 8px' borderRadius='30px' fontWeight='600'>{transaction.duration}</Box>,
    "fee": <Text color='blue.primary' fontWeight='600'>{`$ ${Number(transaction.fee).toFixed(2)}`}</Text>,
  }));

  useEffect(() => {
    if (subscription.subscriptiondetails.length > 5) {
      setSubscriptionDetails(subscription.subscriptiondetails.slice(0, 5));
    } else {
      setSubscriptionDetails(subscription.subscriptiondetails);
    }
  }, [subscription.subscriptiondetails]);

  useEffect(() => {
    if (auth.error) {
      const { message } = auth.error;
      toastr.error('', message ? message : '');
    } else if (subscription.error) {
      const { message } = subscription.error;
      toastr.error('', message ? message : '');
    }

    return () => {
      dispatch(setAuthError(null));
      dispatch(setSubscriptionError(null));
    }
  }, [auth.error, dispatch, subscription.error]);

  return (
    <>
      {(auth.loginLoading.length !== 0 || subscription.loading.length !== 0) && <Loader />}
      <Box p='29px 30px 20px 20px' minH='calc(100vh - 70px)'>
        <Box d='flex' justifyContent='space-between' alignItems='center' mb='28px'>
          <Text as='h1' color='black.lighten' fontSize='24px' lineHeight='30px' pr='10px' fontWeight='600'>Invoice</Text>
        </Box>
        <Box borderRadius='12px' overflow='hidden' boxShadow='0px 0px 16px #f1f1f1c7'>
          <DataTable
            className='hi-datatable-component'
            data={tableDataItems}
            columns={(auth?.user?.user_Type === "super_admin") ? columnsSuperAdmin : columns}
            highlightOnHover
            fixedHeader
            fixedHeaderScrollHeight='calc(100vh - 237px)'
          />
        </Box>
      </Box>
    </>
  )
}

export default InvoiceListing