import type { ComponentSingleStyleConfig } from "@chakra-ui/react";


const Switch: ComponentSingleStyleConfig = {

  variants: {
    primary: {
      track: {
        w: '32px',
        h: '16px',
        bg: 'gray.light10',
        _checked : {
          bg: 'blue.primary'
        },
        _focus : {
          outline : '0px',
          boxShadow : '0px',
        }
      },
      thumb: {
        bg: 'gray.light10',
        border: '4px solid',
        borderColor: 'white.primary',
        _checked : {
          bg: 'white.primary',
        },
      },
    },
    secondary: {
      track: {
        w: '32px',
        h: '16px',
        bg: 'gray.light10',
        _checked : {
          bg: 'red.ligthen6',
        },
        _focus : {
          outline : '0px',
          boxShadow : '0px',
        }
      },

    }
  }

}
export default Switch