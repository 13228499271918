import React from 'react';
import { Outlet } from 'react-router-dom';
import { Box, Flex } from '@chakra-ui/react';
import Header from '../../../components/Header';
import Sidebar from '../../../components/Sidebar';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import { authState } from '../../../redux/modules/auth';

export default function Main() {
  const auth: authState = useSelector((state: RootState) => state.auth);
  return (
    <>
      <Flex bg='white.lighten3' overflowX='hidden'>
        <Sidebar />
        <Box w={{ sm: '100%', lg: `${!auth.collapseSidebar ? 'calc(100% - 245px)' : 'calc(100% - 81px)'}`, xxl: `${!auth.collapseSidebar ? 'calc(100% - 266px)' : 'calc(100% - 81px)'}` }}>
          <Header />
          <Outlet />
        </Box>
      </Flex>
    </>
  )
}