import React, { useState, useEffect } from "react";
import {
  Box,
  Image,
  Heading,
  Input,
  Button,
  Text,
  InputGroup,
  InputRightElement,
  Flex,
  Icon,
} from "@chakra-ui/react";
import loginBanner from "../../../assets/images/login-banner.svg";
import logo from "../../../assets/images/logo.png";
import visibilityOff from "../../../assets/images/visibility_off.svg";
import visibilityOn from "../../../assets/images/visibility_on.svg";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import {
  forgotPassword,
  authState,
  setAuthError,
} from "../../../redux/modules/auth";
import { toastr } from "react-redux-toastr";
import { useForm } from "../../../utils/hooks";
import { Link, useNavigate, useParams } from "react-router-dom";
import Loader from "../../../components/common/loader";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { FaQuoteLeft } from "react-icons/fa";
import whitePattern from "../../../assets/images/white-pattern.jpg";

export default function ForgotPassword() {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const auth: authState = useSelector((state: RootState) => state.auth);

  const FORGOT_EMAIL_FORM = {
    default: {
      password: "",
      confirmPassword: "",
    },
    field: {
      required: true,
      inputs: ["password", "confirmPassword"],
    },
  };

  const [showPassword, setShowPassword] = useState(false);
  const [showConfPassword, setShowConfPassword] = useState(false);

  const {
    values: forgotPassValues,
    change: forgotPassChange,
    touched: forgotPassTouched,
    errors: forgotPassErrors,
    reset: forgotPassReset,
  } = useForm(FORGOT_EMAIL_FORM.default, FORGOT_EMAIL_FORM.field);

  const forgotPasswordSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();

    if (Object.keys(forgotPassTouched).length <= 0) {
      toastr.warning("", "Please first fill the form");
      return;
    }

    if (Object.keys(forgotPassErrors).length > 0) {
      toastr.warning("", forgotPassErrors[Object.keys(forgotPassErrors)[0]]);
      return;
    }

    // Call forgotPassword api

    new Promise(() => {
      const data1: any = dispatch(
        forgotPassword({
          token: params.token,
          payload: {
            token: params.token,
            password: forgotPassValues.password,
          },
        })
      );
      data1.then(function (value) {
        if (value.type === "auth/forgotPassword/fulfilled") {
          forgotPassReset();
          navigate("/login");
          toastr.success("", "Password changed successfully");
        } else if (value.type === "auth/forgotPassword/rejected") {
          if (value.error.message.includes("jwt expired")) {
            toastr.error("", "The link is expired");
          }
        }
      });
    });
  };

  useEffect(() => {
    if (auth?.error) {
      const { message } = auth.error;
      if (!message.includes("jwt expired")) {
        toastr.error("", message ? message : "");
      }
    }

    return () => {
      dispatch(setAuthError(null));
    };
  }, [auth.error, dispatch]);

  return (
    <>
      {auth.loginLoading.length !== 0 && <Loader />}
      <Box display="flex" minH="100vh">
        <Box
          minW={{ sm: "50%", lg: "45%", xxl: "40%" }}
          w={{ sm: "50%", lg: "45%", xxl: "40%" }}
          py="20px"
          px="20px"
          display="inline-flex"
          alignItems="center"
          flexDirection="column"
          justifyContent="center"
          pos="relative"
          _before={{
            content: '""',
            bgImg: whitePattern,
            pos: "absolute",
            w: "100%",
            h: "100%",
            top: "0",
            left: "0",
            right: "0",
            bottom: "0",
            bgSize: "cover",
            opacity: "0.2",
          }}
        >
          <Carousel
            autoPlay
            interval={4500}
            infiniteLoop={true}
            stopOnHover={false}
            showArrows={false}
            showStatus={false}
          >
            <Box
              display="inline-flex"
              alignItems="center"
              justifyContent="center"
              flexDirection="column"
              pos="relative"
            >
              <Icon
                color="#456db5"
                as={FaQuoteLeft}
                top="205px"
                fontSize="70px"
                left="15px"
                opacity="0.09"
                pos="absolute"
              ></Icon>
              <Icon
                color="#456db5"
                as={FaQuoteLeft}
                bottom="110px"
                fontSize="55px"
                right="15px"
                opacity="0.09"
                pos="absolute"
                transform="rotate(180deg)"
              ></Icon>
              <Image
                maxH={{ sm: "82px", xxl: "102px" }}
                src={logo}
                alt="Sign Up Logo"
                mb={{ sm: "18px", xxl: "34px" }}
              />
              <Flex
                minHeight="220px"
                alignItems="center"
                flexDir="column"
                justifyContent="flex-start"
                mb="40px"
              >
                <Text fontSize={{ sm: "24px", xxl: "26px" }} textAlign="center">
                  "This inspection software is a BIG improvement from the one I
                  had before! It takes me much less time to complete my
                  inspection reports! Thank you."
                </Text>
                <Text
                  as="h3"
                  mt="10px"
                  fontSize={{ sm: "24px", xxl: "28px" }}
                  fontWeight="600"
                  textAlign="center"
                >
                  F.Menesis
                </Text>
              </Flex>
            </Box>
            <Box
              display="inline-flex"
              alignItems="center"
              justifyContent="center"
              flexDirection="column"
              pos="relative"
            >
              <Icon
                color="#456db5"
                as={FaQuoteLeft}
                top="205px"
                fontSize="70px"
                left="15px"
                opacity="0.09"
                pos="absolute"
              ></Icon>
              <Icon
                color="#456db5"
                as={FaQuoteLeft}
                bottom="110px"
                fontSize="55px"
                right="15px"
                opacity="0.09"
                pos="absolute"
                transform="rotate(180deg)"
              ></Icon>
              <Image
                maxH={{ sm: "82px", xxl: "102px" }}
                src={logo}
                alt="Sign Up Logo"
                mb={{ sm: "18px", xxl: "34px" }}
              />
              <Flex
                minHeight="220px"
                alignItems="center"
                flexDir="column"
                justifyContent="flex-start"
                mb="40px"
              >
                <Text fontSize={{ sm: "24px", xxl: "26px" }} textAlign="center">
                  “I really like the fact that I can go to the Property without
                  my clipboard and complete the whole inspection with Aurora.”
                </Text>
                <Text
                  as="h3"
                  mt="10px"
                  fontSize={{ sm: "24px", xxl: "28px" }}
                  fontWeight="600"
                  textAlign="center"
                >
                  R. Dunn
                </Text>
              </Flex>
            </Box>
          </Carousel>
        </Box>
        <Box
          minW={{ sm: "50%", lg: "55%", xxl: "60%" }}
          w={{ sm: "50%", lg: "55%", xxl: "60%" }}
          pos="relative"
          bg="#0A1F3E"
          overflow="auto"
          bgImg={loginBanner}
          bgRepeat="no-repeat"
          bgSize="cover"
          d="flex"
          alignItems="center"
          justifyContent="center"
          p="40px 0"
        >
          <Box maxW={{ sm: "300px", xxl: "340px" }} w="100%">
            <Heading as="h1" color="#fff" fontSize="25px" mb="5px">
              Forgot Password
            </Heading>
            <Text color="#fff" fontSize="xl" lineHeight="22px" mb="22px">
              Please enter your new password.
            </Text>
            <Box mb="35px">
              <Heading
                as="h4"
                fontSize="xxl"
                fontWeight="normal"
                color="#fff"
                mb="10px"
              >
                Password
              </Heading>
              <InputGroup>
                <Input
                  pr="45px"
                  type={showPassword ? "text" : "password"}
                  variant="Simple"
                  name="password"
                  value={forgotPassValues.password}
                  placeholder="*****************"
                  onChange={forgotPassChange}
                />
                <InputRightElement top="8px" right="12px">
                  <Button
                    p="0"
                    minWidth="unset"
                    bg="transparent"
                    _hover={{ bg: "transparent" }}
                    _focus={{ outline: "none" }}
                    _active={{ bg: "transparent" }}
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? (
                      <Image
                        w="18px"
                        src={visibilityOff}
                        alt="visibility off"
                      />
                    ) : (
                      <Image w="18px" src={visibilityOn} alt="visibility on" />
                    )}
                  </Button>
                </InputRightElement>
              </InputGroup>

              <Heading
                as="h4"
                fontSize="xxl"
                fontWeight="normal"
                color="#fff"
                mb="10px"
                mt="20px"
              >
                Confirm Password
              </Heading>
              <InputGroup>
                <Input
                  type={showConfPassword ? "text" : "password"}
                  variant="Simple"
                  placeholder="*****************"
                  name="confirmPassword"
                  value={forgotPassValues.confirmPassword}
                  onChange={forgotPassChange}
                />
                <InputRightElement top="8px" right="12px">
                  <Button
                    p="0"
                    minWidth="unset"
                    bg="transparent"
                    _hover={{ bg: "transparent" }}
                    _focus={{ outline: "none" }}
                    _active={{ bg: "transparent" }}
                    onClick={() => setShowConfPassword(!showConfPassword)}
                  >
                    {showConfPassword ? (
                      <Image
                        w="18px"
                        src={visibilityOff}
                        alt="visibility off"
                      />
                    ) : (
                      <Image w="18px" src={visibilityOn} alt="visibility on" />
                    )}
                  </Button>
                </InputRightElement>
              </InputGroup>
            </Box>
            <Button
              variant="primary"
              w="100%"
              mb="10px"
              onClick={forgotPasswordSubmit}
            >
              Submit
            </Button>
            <Text
              color="#ffffff"
              textAlign="center"
              fontSize="xl"
              d="block"
              cursor="pointer"
              transition="0.4s all ease"
              textDecoration="underline"
              _hover={{ color: "#d1d1d1", textDecoration: "underline" }}
            >
              <Link to="/login">Back To Login</Link>
            </Text>
          </Box>
        </Box>
      </Box>
    </>
  );
}
