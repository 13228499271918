import { ComponentMultiStyleConfig } from "@chakra-ui/react";

const Checkbox: ComponentMultiStyleConfig = {
  parts: ['control', 'icon', 'container'],
  variants: {
    simple: {
      control: {
        bg: '#FFFFFF',
        color: '#00AEEF',
        borderRadius: '4px',
        width: '24px',
        height: '24px',
        _focus: {
          outline: '0px',
          boxShadow: '0px',
        }
      },
      icon: {
        fontSize: '11px'
      },
      container: {
        alignItems: 'flex-start'
      },
      label: {
        color: '#ffffff',
        fontSize: '15px',
        marginLeft: '10px',
        textAlign: 'left'
      }
    },
    bold: {
      control: {
        bg: 'transparent',
        borderRadius: '4px',
        width: '24px',
        height: '24px',
        border: '1px solid',
        borderColor: 'gray.primary',
        _focus: {
          outline: '0px',
          boxShadow: '0px',
        }
      },
      icon: {
        fontSize: '12px',
        color: 'black.lighten'
      },
      container: {
        alignItems: 'flex-start'
      },
      label: {
        color: 'gray.primary',
        fontSize: '18px',
        marginLeft: '10px',
        textAlign: 'left'
      }
    },
    transparent: {
      _selected:{
        borderColor: '#fce6bc',
        bg:'transparent'
      },
      control: {
        bg: 'transparent',
        border: '1px solid',
        borderColor: 'black.lighten',
        borderRadius: '4px',
        width: '24px',
        height: '24px',
        _focus: {
          outline: '0px',
          boxShadow: '0px',
        },
        _checked:{
          borderColor:'#FCE6BC'
        }
      },
      icon: {
        fontSize: '11px',
        color:'orange.light'
      },
      label: {
        color: 'black.lighten',
        fontSize: '18px',
        marginLeft: '15px',
        textAlign: 'left', 
        width: 'calc(100% - 0.5rem)'
      }
    },
    transparentSmall: {
      _selected:{
        borderColor: '#fce6bc',
        bg:'transparent'
      },
      control: {
        bg: 'transparent',
        border: '1px solid',
        borderColor: 'black.lighten',
        borderRadius: '4px',
        width: '20px',
        height: '20px',
        _focus: {
          outline: '0px',
          boxShadow: '0px',
        },
        _checked:{
          borderColor:'#FCE6BC'
        }
      },
      icon: {
        fontSize: '11px',
        color:'orange.light'
      },
      label: {
        color: 'black.lighten',
        fontSize: '18px',
        marginLeft: '15px',
        textAlign: 'left'
      }
    },
  },
}

export default Checkbox