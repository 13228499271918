import { ComponentMultiStyleConfig } from "@chakra-ui/react";

const Accordion: ComponentMultiStyleConfig = {
  parts: ['root', 'container', 'button', 'panel'],
  variants: {
    simple: {
      root: {
        border: 'none',
        _focus: {
          outline: '0px',
          boxShadow: '0px',
        }
      },
      container: {
        border: 'none',
        borderBottom: '1px solid #D4D4D4',
        p: '30px 125px',
        _focus: {
          outline: '0px',
          boxShadow: '0px',
        }
      },
      button: {
        p: '0',
        _hover: {
          bg: 'transparent'
        },
        _focus: {
          boxShadow: 'none'
        }
      }
    }
  },
}

export default Accordion