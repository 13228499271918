import {
  Button,
  useDisclosure,
  Icon,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { MdDelete } from "react-icons/md";
import { useDispatch } from "react-redux";
interface deletenarrativeImageProps {
  deleteIllustrativeSingleImage?: any;
  mapData?: any;
}
const DeleteIllustrativeImageModal = ({
  deleteIllustrativeSingleImage,
  mapData,
}: deletenarrativeImageProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const dispatch = useDispatch();
  return (
    <>
      <Button
        variant="iconBtn"
        bg="blue.primary"
        zIndex="1"
        minW="unset"
        p="0"
        borderRadius="50%"
        h="21px"
        w="21px"
        ml="15px"
        onClick={onOpen}
      >
        <Icon fontSize="16px" color="white.primary" as={MdDelete}></Icon>
      </Button>

      {/* Delete modal */}
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Are your sure want to delete this image?</ModalHeader>
          <ModalCloseButton />
          {/* <ModalBody>
            please confirm before delete this narrative image
          </ModalBody> */}

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Cancel
            </Button>
            <Button
              variant="ghost"
              onClick={() =>
                dispatch(deleteIllustrativeSingleImage(mapData?.id))
              }
            >
              Delete
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default DeleteIllustrativeImageModal;
