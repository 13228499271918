import { Box, Heading, Image, Link, Text } from '@chakra-ui/react';
import paymentFailed from '../../../assets/images/payment-failed.png';
import { Link as ReactLink } from "react-router-dom";

export default function PaymentFailed() {
  return (
    <>
      <Box d='flex' justifyContent='center' alignItems='center' flexDirection='column' h='100vh'>
        <Image mb='30px' maxW='160px' src={paymentFailed} alt='payment-failed' />
        <Heading as='h1' fontSize={{ sm: '48px', lg: '52px' }} mb='10px' color='black.lighten'>Payment Failed</Heading>
        <Box p='30px 0' borderTop='1px solid' borderColor='gray.light3' mt='40px' w='30%' d='flex' justifyContent='center'>
          <Link as={ReactLink} to='/' _hover={{ textDecoration: 'none' }} fontSize='20px' lineHeight='22px' mb='10px' color='black.lighten' d='inline-block'>Go Back</Link>
        </Box>
      </Box>
    </>
  )
}