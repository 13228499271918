import { Box, Spinner } from "@chakra-ui/react";
export default function Loader() {
  return (
    <Box position='fixed' zIndex='9999' d='flex' alignItems='center' justifyContent='center' bg='black.lighten3' top='0' left='0' right='0' bottom='0'>
      <Spinner
        thickness='4px'
        speed='0.65s'
        emptyColor='gray.200'
        color='blue.500'
        size='xl'
      />
    </Box>
  );
}