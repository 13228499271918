import { Box, Button, Icon, Input, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text, useDisclosure } from "@chakra-ui/react";
import { useEffect } from "react";
import { IoMdClose } from "react-icons/io";
import { useSelector } from "react-redux";
import { toastr } from "react-redux-toastr";
import { useLocation } from "react-router-dom";
import { TemplateState } from "../../../redux/modules/template";
import { RootState } from "../../../redux/store";
import { useForm } from "../../../utils/hooks";

export default function AddChoiceModal({ open, setClose, states }: addChoiceModal) {
  const FIELDS_FORM = {
    default: {
      input: ""
    },
    field: {
      required: true,
      inputs: ["input"]
    }
  };

  const { isOpen, onOpen, onClose } = useDisclosure();
  const { values, change, touched, errors, reset, setValues } = useForm(FIELDS_FORM.default, FIELDS_FORM.field);
  const { isOpen: saveTemplate, onOpen: openSaveTemplate, onClose: closeSaveTemplate } = useDisclosure();
  const location = useLocation();
  const template: TemplateState = useSelector((state: RootState) => state.template);

  useEffect(() => {
    if (open === true) {
      onOpen(); // Modal
    }
  }, [onOpen, open]);

  useEffect(() => {
    if (states.dafaultValue) {
      setValues({ input: states.dafaultValue });
    }
  }, [setValues, states.dafaultValue]);

  const closeModel = () => {
    reset(); // Form
    onClose(); // Model
    setClose(false);
  }

  const submitField = (event: any) => {
    event.preventDefault();

    if (Object.keys(touched).length <= 0) {
      toastr.warning('', 'Please first fill the form');
      return;
    }
    
    if (Object.keys(errors).length > 0) {
      toastr.warning("", errors[Object.keys(errors)[0]]);
      return;
    }

    if (!values.input) {
      toastr.warning('', 'Please first fill the form');
      return;
    }
    onClose();
    if(/edit-report/ig.test(location.pathname) && template.futureTemplate) {
      openSaveTemplate();
    }
    else {
      states.callback(values.input, false);
      closeModel(); // Close Model
    }
  }

  const saveFutureTemplate = () => {
    states.callback(values.input, true);
    closeSaveTemplate(); // Close Save Model
    closeModel(); // Close Model
  }

  const saveCreateTemplate = () => {
    states.callback(values.input);
    closeSaveTemplate(); // Close Save Model
    closeModel(); // Close Model
  }

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} size='lg' scrollBehavior='inside'>
        <ModalOverlay />
        <ModalContent borderRadius='10px'>
          <ModalHeader borderBottom='1px solid #DBDBDB52' fontSize='21px' lineHeight='26px' color='black.lighten' fontWeight='600' p='28px 30px 25px 30px'>
            {states.fieldHeading ? `Add ${states.fieldHeading}` : `Add New Items`}
          </ModalHeader>
          <Icon color='black.primary' fontSize='24px' as={IoMdClose} onClick={closeModel} cursor='pointer' position='absolute' top='29px' right='29px'></Icon>
          <ModalBody p='0'></ModalBody>
          <ModalFooter flexWrap='wrap' p='25px 29px 35px 29px'>
            <Box d='flex' flexDirection='column' w='100%'>
              <Input type='text' maxW='unset' variant='Bordered' height='50px' fontSize='xl' name="input" value={values.input} onChange={change} onKeyDown={(e) => e.key === 'Enter' && submitField(e)} />
              <Button mt='32px' variant='primary2' w='117px' onClick={submitField}>
                Save
              </Button>
            </Box>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal isOpen={saveTemplate} onClose={closeSaveTemplate} size='xl' scrollBehavior='inside'>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader p='30px 30px 35px' fontSize='21px' lineHeight='26px' color='black.lighten' fontWeight='600'>Save Template</ModalHeader>
          <Icon color='black.primary' fontSize='24px' as={IoMdClose} onClick={closeSaveTemplate} cursor='pointer' position='absolute' top='29px' right='29px'></Icon>
          <ModalBody p='0 30px 25px'>
            <Text fontSize='18px' color='black.lighten'>Do you want to save it for the future templates?</Text>
          </ModalBody>
          <ModalFooter>
            <Button variant='primary2' minW='120px' onClick={saveFutureTemplate}>Yes</Button>
            <Button variant='grey' ml='15px' minW='120px' onClick={saveCreateTemplate}>No</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export interface addChoiceModalState {
  callback: Function;
  fieldHeading: string;
  dafaultValue: string;
}

interface addChoiceModal {
  open: boolean;
  setClose: Function;
  states: addChoiceModalState
}