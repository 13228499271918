import type { ComponentMultiStyleConfig } from "@chakra-ui/react";

const Select: ComponentMultiStyleConfig = {
  parts: ["field"],

  variants: {
    Simple: {
      field: {
        border: "1px",
        borderRadius: "5px",
        height: "48px",
        fontWeight: "normal",
        color: "black.lighten",
        fontSize: "xxl",
        cursor: 'pointer',
        borderColor: 'gray.light4',
        bg: 'transparent',
        paddingLeft:'10px',
      },
      icon: {
        fontSize: '20px',
        color: 'gray.light6'
      }
    },
  },
};
export default Select;