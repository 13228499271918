import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import { reducer as toastrReducer } from 'react-redux-toastr';

import authReducer from './modules/auth';
import reportReducer from './modules/report';
import templateReducer from './modules/template';
import dashboardReducer from './modules/dashboard';
import subscriptionReducer from './modules/subscription';
import inspectorReducer from './modules/admin/inspector';
import adminDashboardReducer from './modules/admin/adminDashboard';
import superAdminReducer from './modules/superAdmin/superAdmin';

export const store = configureStore({
  reducer: {
    toastr: toastrReducer,
    auth: authReducer,
    report: reportReducer,
    template: templateReducer,
    dashboard: dashboardReducer,
    subscription: subscriptionReducer,
    inspector: inspectorReducer, // TODO: We have to change it
    adminDashboard: adminDashboardReducer,
    superAdmin: superAdminReducer
  }
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
