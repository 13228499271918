// This function basically copy object properties
// ... which are found in intialObject. You can provide
// ... a array list of properties if requires in final object
function fillFormValues(initialObject: object, copyFromObject: object, requiredKey: string[] = []) {
  const obj = {};

  Object.keys(copyFromObject ?? {}).forEach((key) => {
    if (Object.keys(initialObject).includes(key) || requiredKey.includes(key)) {
      obj[key] = copyFromObject[key] ? copyFromObject[key] : '';
    }
  });

  return obj;
}

async function getBase64FromUrl(url: string) {
  const data = await fetch(url);
  const blob = await data.blob();

  if (data.status !== 200) {
    return null;
  }

  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = () => {
      resolve(reader.result);
    }
  });
}

const loadGoogleMapScript = (callback) => {
  if (typeof window.google === 'object' && typeof window.google.maps === 'object') {
    callback();
  } else {
    const googleMapScript = document.createElement("script");
    googleMapScript.src = `https://maps.googleapis.com/maps/api/js?key=${"AIzaSyBYDOgLZMgQTbs4AD3b9TUDQJhJG3fIReI"}&callback=initMap&libraries=places,geometry`;
    window.document.body.appendChild(googleMapScript);
    googleMapScript.addEventListener("load", callback);
  }
}

function googleMapAutocomplete(autocomplete): { streetNumber?: string, addressCity?: string, addressState?: string, addressCountry?: string, addressZipcode?: string } {

  let addressDetails: {
    streetNumber?: string,
    addressCity?: string,
    addressState?: string,
    addressCountry?: string,
    addressZipcode?: string
  } = {};

  const place = autocomplete.getPlace();

  for (const component of place.address_components || []) {
    const componentType = component.types[0];

    if (componentType === 'street_number') {
      addressDetails.streetNumber = component.long_name;
    } else if (componentType === 'route') {
      if (!addressDetails.streetNumber) {
        addressDetails.streetNumber = component.long_name;
      } else {
        addressDetails.streetNumber += " " + component.long_name;
      }
    } else if (componentType === 'locality') {
      if (!addressDetails.streetNumber) {
        addressDetails.streetNumber = component.long_name;
      }
      addressDetails.addressCity = component.long_name;
    } else if (componentType === 'administrative_area_level_1') {
      addressDetails.addressState = component.long_name;
    } else if (componentType === 'country') {
      addressDetails.addressCountry = component.long_name;
    } else if (componentType === 'postal_code') {
      addressDetails.addressZipcode = component.long_name;
    }
  }
  return addressDetails;
}

export { fillFormValues, getBase64FromUrl, googleMapAutocomplete, loadGoogleMapScript };
