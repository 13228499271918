import { Box, Heading, Image } from '@chakra-ui/react'
import signupIllustration from "../../../assets/images/signup-banner.svg";

export default function PaymentSuccess() {
  return (
    <>
      <Box d='flex' justifyContent='center' alignItems='center' flexDirection='column' h='100vh'>
        <Image mb='40px' maxW='320px' src={signupIllustration} alt='payment-failed' />
        <Heading as='h1' fontSize={{ sm: '32px', lg: '38px' }} mb='10px' color='black.lighten'>Your Payment has been done successfully!</Heading>
      </Box>
    </>
  )
}