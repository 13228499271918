import React, { useEffect, useState } from 'react';
import { Box, Heading, Image, Text } from '@chakra-ui/react';
import checked from '../../../assets/images/checked.svg';
import notVerified from '../../../assets/images/payment-failed.png';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { verifyMail } from '../../../redux/modules/auth';
import Loader from '../../../components/common/loader';

export default function EmailVerification() {
  const params = useParams();
  const dispatch = useDispatch();

  const [isEmailVerified, setEmailVerified] = useState(null);
  const [message, setMessage] = useState('Your Email is verified successfully!');

  useEffect(() => {
    async function mailVerification() {
      new Promise(() => {
        const data1: any = dispatch(verifyMail({ token: params.token }));
        data1.then(function (value) {
          if (value.type === "auth/verifyMail/fulfilled") {
            setEmailVerified(true);
            setTimeout(() => window.location.replace("/login"), 3000);

            if (value.payload.message.includes('User Already verified')) {
              setMessage('Your Email is Already verified!');
            }
          } else if (value.type === "auth/verifyMail/rejected") {
            if (value.error.message.includes('jwt expired')) {
              setEmailVerified(false);
              setMessage('The link is expired. Please try to login, We will send a email again.');
            }
          } else {
            setEmailVerified(false);
            setMessage('Your Email is not verified!');
          }
        })
      })
    }

    if (params.token && isEmailVerified === null) {
      mailVerification();
    }
  }, [dispatch, isEmailVerified, params.token]);

  return (
    <>
      {isEmailVerified === null && <Loader />}
      {isEmailVerified === true && <Box d='flex' justifyContent='center' alignItems='center' flexDirection='column' h='100vh'>
        <Image mb='30px' maxW='195px' src={checked} alt='verified' />
        <Heading as='h1' fontSize='56px' mb='10px' color='black.lighten'>Verified</Heading>
        <Text color='gray.primary' fontSize='26px'>{message}</Text>
      </Box>}

      {isEmailVerified === false && <Box d='flex' justifyContent='center' alignItems='center' flexDirection='column' h='100vh'>
        <Image mb='30px' maxW='195px' src={notVerified} alt='verified' />
        <Heading as='h1' fontSize='56px' mb='10px' color='black.lighten'>Not Verified</Heading>
        <Text color='gray.primary' fontSize='26px'>{message}</Text>
      </Box>}
    </>
  )
}