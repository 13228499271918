import { Box, Button, Icon, Image, Menu, MenuButton, MenuItem, MenuList, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Tab, TabList, TabPanel, TabPanels, Tabs, Text, useDisclosure, Wrap, WrapItem } from '@chakra-ui/react'
import { css } from '@emotion/react'
import React, { useEffect, useState } from 'react'
import auroraLogo from '../../../../assets/images/aurora-logo.png'
import companyLogo from '../../../../assets/images/company-logo.png'
import homeEssential from '../../../../assets/images/home-essential.png'
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../redux/store'
import { toastr } from 'react-redux-toastr'
import { IoMdAdd, IoMdClose } from 'react-icons/io'
import { cloneReportTemplate, cloneTemplate, deleteTemplate, getCompanyTemplates, getTemplateofsuperadmin, setCloneTemplate, getTemplates, resetTemplate, resetTemplateTabs, setTemplateError, TemplateState, updateUser, setPreviewTemplate } from '../../../../redux/modules/template'
import defaultTemplate from '../../../../assets/images/default-template-logo.png';
import Loader from '../../../../components/common/loader'
import { FiArrowLeft } from 'react-icons/fi'
import { BsThreeDotsVertical } from 'react-icons/bs'
import { authState, setAuthError } from '../../../../redux/modules/auth'
import store from 'store2'
import { setReportTemplateState } from '../../../../redux/modules/report'
import CreateTemplate from '../CreateTemplate'

export default function TemplateCenter() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const auth: authState = useSelector((state: RootState) => state.auth);
  const template: TemplateState = useSelector((state: RootState) => state.template);

  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [reportTemplate, setReportTemplate] = useState(null);
  const [cloneTemplateId, setCloneTemplateId] = useState(null);
  const [selectedFormType, setSelectedFormType] = useState(null);
  const { isOpen: viewMyTemplate, onOpen: openMyTemplate, onClose: closeMyTemplate } = useDisclosure();
  const { isOpen: viewReportTemplate, onOpen: openViewReportTemplate, onClose: closeReportTemplate } = useDisclosure();
  const { isOpen: viewPreviewTemplate, onOpen: openPreviewTemplate, onClose: closePreviewTemplate } = useDisclosure();

  // Initial value loder
  // ... render page resources
  useEffect(() => {
    if (auth.user?.user_Type !== 'super_admin') {
      dispatch(getTemplates());
    }
    if (auth.user?.user_Type === 'home_inspector') {
      dispatch(getCompanyTemplates({ payload: { adminId: auth.user.AdminID } }))
    }
  }, [auth?.user?.AdminID, auth.user?.user_Type, dispatch]);

  useEffect(() => {
    dispatch(getTemplateofsuperadmin());
  }, [dispatch]);

  const addDefaultTemplate = () => {
    if (reportTemplate) {
      dispatch(setReportTemplateState({ value: true }));
      new Promise(() => {
        const data1: any = dispatch(cloneReportTemplate({ templateId: cloneTemplateId, reportId: reportTemplate, selectedFrom: selectedFormType }));
        data1.then(function (value) {
          if (value.type === 'template/cloneReportTemplate/fulfilled') {
            toastr.success('', 'Template used successfully');
            navigate(`/edit-report/${reportTemplate}`);
            closeMyTemplate(); // Close modal
          }
        })
      })
    }
    else {
      new Promise(() => {
        const data2: any = dispatch(updateUser({ payload: { templateId: selectedTemplate } }));
        data2.then(function (value) {
          if (value.type === 'template/updateUser/fulfilled') {
            store.set("templateId", selectedTemplate);
            toastr.success('', 'Template used successfully');
            navigate('/template')
            closeMyTemplate(); // Close modal
          }
        })
      })
    }
  }
  const openAddTemplateModal = (action: string, templateId: string) => {
    if (reportTemplate) {
      setSelectedFormType(action);
      setCloneTemplateId(templateId);
      openViewReportTemplate();
    }
    else {
      openMyTemplate(); // Open Modal
    }
    setSelectedTemplate(templateId);
  }

  const previewEditTemplate = async (action: string, templateId?: string) => {
    console.log("Id", action, templateId)
    if (action === 'preview') {
      openPreviewTemplate();
      dispatch(setPreviewTemplate(templateId || selectedTemplate));
    }
    else {
      navigate(`/template/${action}-template/${templateId || selectedTemplate}`);
    }
    dispatch(resetTemplate('all'));
    dispatch(resetTemplateTabs('all'));
  }

  const handleCloneTemplate = (templateId: number) => {
    dispatch(resetTemplate('all'));
    dispatch(resetTemplateTabs('all'));
    dispatch(setCloneTemplate(true))
    new Promise(() => {
      const data3: any = dispatch(cloneTemplate({ payload: { templateId } }));
      data3.then(function (value) {
        if (value.type === 'template/cloneTemplate/fulfilled') {
          navigate(`/template/edit-template/${value.payload.id}`);
        }
      })
    })
  }

  const setPreviousRoute = () => {
    dispatch(resetTemplate('all'));
    dispatch(resetTemplateTabs('all'));
    navigate(-1);
  }

  const emptyTemplate = () => {
    dispatch(resetTemplate('all'));
    dispatch(resetTemplateTabs('all'));
  }

  const handleDeleteTemplate = (delTemplateId: number) => {
    new Promise(() => {
      const data4: any = dispatch(deleteTemplate({ templateId: delTemplateId }));
      data4.then(function (value) {
        if (value.type === 'template/deleteTemplate/fulfilled') {
          // Also remove it from user default template if
          // ... user set it to default template
          if (auth.user.templateId === delTemplateId) {
            dispatch(updateUser({ payload: { templateId: null } }));
          }
          // get api after delete template
          if (auth?.user?.user_Type === "super_admin") {
            dispatch(getTemplateofsuperadmin());
          } else {
            dispatch(getTemplates());
          }
        }
      })
    })
  }

  useEffect(() => {
    setReportTemplate(store.get('reportId'));

    return () => store.set('reportId', null);
  }, []);

  useEffect(() => {
    if (auth.error) {
      const { message } = auth.error;
      toastr.error('', message ? message : '');
    } else if (template.error) {
      const { message } = template.error;
      toastr.error('', message ? message : '');
    }

    return () => {
      dispatch(setAuthError(null));
      dispatch(setTemplateError(null));
    }
  }, [auth.error, dispatch, template.error]);

  return (
    <>
      {(auth.loginLoading.length !== 0 || template.loading.length !== 0) && <Loader />}
      <Box p='29px 20px 20px' h='calc(100vh - 70px)' overflow='auto' w='100%'>
        <Box d='flex' alignItems='flex-start' justifyContent='space-between'>
          <Box d='inline-flex' mb='22px'>
            <Icon as={FiArrowLeft} color='black.lighten' cursor='pointer' fontSize='26px' m='2px 12px 2px 0' onClick={() => { if (reportTemplate) { navigate(`/edit-report/${reportTemplate}`); } else { setPreviousRoute(); } }}></Icon>
            <Text as='h1' color='black.lighten' fontSize='24px' lineHeight='30px' fontWeight='600'>Template Center</Text>
          </Box>
          {!reportTemplate && <Box ml='auto'>
            <Link to='/template/create-template' onClick={emptyTemplate}>
              <Button variant='bordered' mr='15px'>
                <Icon fontSize='24px' mr='6px' as={IoMdAdd}></Icon>
                Create Template
              </Button>
            </Link>
            <Link to='/template' onClick={emptyTemplate}>
              <Button variant='bordered' mr='20px'>
                <Box mr='10px'>
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 4C0.45 4 0 4.45 0 5V18C0 19.1 0.9 20 2 20H15C15.55 20 16 19.55 16 19C16 18.45 15.55 18 15 18H3C2.45 18 2 17.55 2 17V5C2 4.45 1.55 4 1 4ZM18 0H6C4.9 0 4 0.9 4 2V14C4 15.1 4.9 16 6 16H18C19.1 16 20 15.1 20 14V2C20 0.9 19.1 0 18 0ZM16 9H8C7.45 9 7 8.55 7 8C7 7.45 7.45 7 8 7H16C16.55 7 17 7.45 17 8C17 8.55 16.55 9 16 9ZM12 13H8C7.45 13 7 12.55 7 12C7 11.45 7.45 11 8 11H12C12.55 11 13 11.45 13 12C13 12.55 12.55 13 12 13ZM16 5H8C7.45 5 7 4.55 7 4C7 3.45 7.45 3 8 3H16C16.55 3 17 3.45 17 4C17 4.55 16.55 5 16 5Z" fill="#888888" />
                  </svg>
                </Box>
                Template Editor
              </Button>
            </Link>
          </Box>}
        </Box>
        <Tabs>
          <TabList className='tab-with-icons' overflowY='hidden' overflowX='auto' pr='212px' borderColor='gray.light3' borderBottomWidth='1px' css={css({
            '::-webkit-scrollbar-track': {
              boxShadow: 'none',
              border: 'none',
              borderRadius: '10px',
              bg: '#E0E0E0'
            },
            '::-webkit-scrollbar-thumb': {
              bg: '#C5C7D0',
              borderRadius: '10px'
            },
            '::-webkit-scrollbar': {
              h: '7px'
            }
          })}>
            <Tab fontSize='xxl' color='black.lighten' border='none' p={{ lg: '10px 25px', xxl: '10px 35px' }} whiteSpace='nowrap' _selected={{ color: 'blue.dark', fontWeight: '600', border: 'none', boxShadow: 'none' }}>Aurora Templates</Tab>
            {(auth.user?.user_Type === 'home_inspector' && auth.user?.AdminID) && auth?.user?.user_Type !== "super_admin" && <Tab fontSize='xxl' color='black.lighten' border='none' p={{ lg: '10px 25px', xxl: '10px 35px' }} whiteSpace='nowrap' _selected={{ color: 'blue.dark', fontWeight: '600', border: 'none', boxShadow: 'none' }}>Company Templates</Tab>}
            {auth?.user?.user_Type !== "super_admin" && <Tab fontSize='xxl' color='black.lighten' border='none' p={{ lg: '10px 25px', xxl: '10px 35px' }} whiteSpace='nowrap' _selected={{ color: 'blue.dark', fontWeight: '600', border: 'none', boxShadow: 'none' }}>{auth.user?.user_Type === 'company_admin' ? 'Company Admin Templates' : 'My Templates'}</Tab>}
          </TabList>
          <TabPanels bg='white' p='30px 20px' minH='calc(100vh - 220px)'>
            <TabPanel p='0'>
              <Wrap spacing='10px'>
                {template?.superAdminTemplates?.map((x: any) => {
                  return <WrapItem key={x.id} boxShadow='0px 0px 12px #8888881a' d='inline-flex' flexDirection='column' w='100%' maxW={{ sm: '220px', xxl: '241px' }} h='220px' alignItems='center' p='8px 0' position='relative'>
                    <Menu>
                      <MenuButton as={Button} bg='transparent' h='24px' w='24px' justifyContent='center' minW='unset' d='inline-flex' alignItems='center' p='0' _hover={{ background: 'transparent' }} _focus={{ boxShadow: 'none' }} _active={{ bg: 'transparent' }} position='absolute' right='10px' top='20px'>
                        <Icon as={BsThreeDotsVertical} fontSize='24px'></Icon>
                      </MenuButton>
                      <MenuList p='0' border='1px solid' borderColor='gray.light3' maxW='70px'>
                        {!reportTemplate && auth?.user?.user_Type === "super_admin" && <><MenuItem _focus={{ bg: 'gray.light2' }} _hover={{ bg: 'gray.light2' }} borderBottom='2px solid' borderColor='gray.light3' onClick={() => previewEditTemplate('edit', x.id)}>Edit Template</MenuItem><MenuItem _focus={{ bg: 'gray.light2' }} _hover={{ bg: 'gray.light2' }} borderBottom='2px solid' borderColor='gray.light3' onClick={() => handleDeleteTemplate(x.id)}>Delete Template</MenuItem>
                        </>}
                        {!reportTemplate && auth?.user?.user_Type !== "super_admin" && <MenuItem _focus={{ bg: 'gray.light2' }} _hover={{ bg: 'gray.light2' }} borderBottom='2px solid' borderColor='gray.light3' onClick={() => handleCloneTemplate(x.id)}>Clone</MenuItem>}
                        {reportTemplate && <MenuItem _focus={{ bg: 'gray.light2' }} _hover={{ bg: 'gray.light2' }} borderBottom='2px solid' borderColor='gray.light3' onClick={() => openAddTemplateModal("super_admin", x.id)}>Use</MenuItem>}
                        <MenuItem _focus={{ bg: 'gray.light2' }} _hover={{ bg: 'gray.light2' }} onClick={() => previewEditTemplate("preview", x.id)}>Preview Template</MenuItem>
                      </MenuList>
                    </Menu>
                    <Box p='12px 16px' d='inline-flex' alignItems='center' justifyContent='center' h='126px'>
                      <Image maxH='100%' src={auroraLogo}></Image>
                    </Box>
                    <Box p='0 16px' d='inline-flex' justifyContent='center' mt='10px' flexWrap='wrap'>
                      <Text textAlign='center' as='p' fontSize='18px' lineHeight='24px' overflow='hidden' className='line-clamp-2'>
                        {x.template_name}
                      </Text>
                    </Box>
                  </WrapItem>
                })}
              </Wrap>
            </TabPanel>
            {(auth.user?.user_Type === 'home_inspector' && auth.user?.AdminID) && <TabPanel p='0'>
              <Wrap spacing='10px'>
                {template?.companyTemplate?.map((x: any) => {
                  return <WrapItem key={x.id} boxShadow='0px 0px 12px #8888881a' d='inline-flex' flexDirection='column' w='100%' maxW={{ sm: '220px', xxl: '241px' }} h='220px' alignItems='center' p='8px 0' position='relative'>
                    <Menu>
                      <MenuButton as={Button} bg='transparent' h='24px' w='24px' justifyContent='center' minW='unset' d='inline-flex' alignItems='center' p='0' _hover={{ background: 'transparent' }} _focus={{ boxShadow: 'none' }} _active={{ bg: 'transparent' }} position='absolute' right='10px' top='20px'>
                        <Icon as={BsThreeDotsVertical} fontSize='24px'></Icon>
                      </MenuButton>
                      <MenuList p='0' border='1px solid' borderColor='gray.light3' maxW='70px'>
                        {!reportTemplate && <MenuItem _focus={{ bg: 'gray.light2' }} _hover={{ bg: 'gray.light2' }} borderBottom='2px solid' borderColor='gray.light3'
                          onClick={() => handleCloneTemplate(x.id)} >Clone</MenuItem>}
                        {reportTemplate && <MenuItem _focus={{ bg: 'gray.light2' }} _hover={{ bg: 'gray.light2' }} borderBottom='2px solid' borderColor='gray.light3' onClick={() => openAddTemplateModal("company_admin", x.id)}>Use</MenuItem>}
                        <MenuItem _focus={{ bg: 'gray.light2' }} _hover={{ bg: 'gray.light2' }} onClick={() => previewEditTemplate("preview", x.id)}>Preview Template</MenuItem>
                      </MenuList>
                    </Menu>
                    <Box p='4px 16px' d='inline-flex' alignItems='center' justifyContent='center' h='126px'>
                      <Image maxH='100%' src={companyLogo}></Image>
                    </Box>
                    <Box p='0 16px' d='inline-flex' justifyContent='center' mt='10px' flexWrap='wrap'>
                      <Text textAlign='center' as='p' fontSize='18px' lineHeight='24px' overflow='hidden' className='line-clamp-2'>
                        {x.template_name}
                      </Text>
                    </Box>
                  </WrapItem>
                })}
              </Wrap>
            </TabPanel>}
            <TabPanel p='0'>
              <Wrap spacing='10px'>
                {template?.myTemplates?.map((x: any) => {
                  return <WrapItem key={x.id} boxShadow='0px 0px 12px #8888881a' d='inline-flex' flexDirection='column' w='100%' maxW={{ sm: '212px', xxl: '241px' }} h='220px' alignItems='center' p='8px 0' position='relative'>
                    <Menu>
                      <MenuButton as={Button} bg='transparent' h='24px' w='24px' justifyContent='center' minW='unset' d='inline-flex' alignItems='center' p='0' _hover={{ background: 'transparent' }} _focus={{ boxShadow: 'none' }} _active={{ bg: 'transparent' }} position='absolute' right='10px' top='20px'>
                        <Icon as={BsThreeDotsVertical} fontSize='24px'></Icon>
                      </MenuButton>
                      <MenuList p='0' border='1px solid' borderColor='gray.light3' maxW='70px'>
                        {!reportTemplate && <><MenuItem _focus={{ bg: 'gray.light2' }} _hover={{ bg: 'gray.light2' }} borderBottom='2px solid' borderColor='gray.light3' onClick={() => handleCloneTemplate(x.id)}>Clone Template</MenuItem>
                          <MenuItem _focus={{ bg: 'gray.light2' }} _hover={{ bg: 'gray.light2' }} borderBottom='2px solid' borderColor='gray.light3' onClick={() => previewEditTemplate('edit', x.id)}>Edit Template</MenuItem>
                          <MenuItem _focus={{ bg: 'gray.light2' }} _hover={{ bg: 'gray.light2' }} borderBottom='2px solid' borderColor='gray.light3' onClick={() => handleDeleteTemplate(x.id)}>Delete Template</MenuItem>
                        </>}
                        {reportTemplate && <MenuItem _focus={{ bg: 'gray.light2' }} _hover={{ bg: 'gray.light2' }} borderBottom='2px solid' borderColor='gray.light3' onClick={() => openAddTemplateModal((auth.user?.user_Type === "company_admin") ? "company_admin" : "home_inspector", x.id)}>Use Template</MenuItem>}
                        <MenuItem _focus={{ bg: 'gray.light2' }} _hover={{ bg: 'gray.light2' }} onClick={() => previewEditTemplate("preview", x.id)}>Preview Template</MenuItem>
                      </MenuList>
                    </Menu>
                    <Box p='4px 16px' d='inline-flex' alignItems='center' justifyContent='center' h='126px'>
                      <Image maxH='100px' src={defaultTemplate}></Image>
                    </Box>
                    <Box p='0 16px' d='inline-flex' justifyContent='center' mt='30px' flexWrap='wrap'>
                      <Text textAlign='center' as='p' fontSize='18px' lineHeight='24px' overflow='hidden' className='line-clamp-2'>{x.template_name}</Text>
                    </Box>
                  </WrapItem>
                })}
              </Wrap>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
      <Modal isOpen={viewMyTemplate} onClose={closeMyTemplate} size='3xl' scrollBehavior='inside'>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader p='30px 35px' fontSize='18px' lineHeight='24px' color='black.lighten' fontWeight='600'>Add Template</ModalHeader>
          <Icon color='black.primary' fontSize='24px' as={IoMdClose} onClick={closeMyTemplate} cursor='pointer' position='absolute' top='29px' right='29px'></Icon>
          <ModalBody p='8px 35px 30px'>
            <Box d='inline-flex' mr='20px' alignItems='center'>
              <Box maxW='170px'>
                <Image src={homeEssential} alt=''></Image>
              </Box>
              <Box>
                <Text as='h2' fontSize='18px' fontWeight='600' mb='5px' lineHeight='20px' color='black.lighten'>ASHI Residential Inspections</Text>
                <Text as='span' color='gray.primary' fontSize='16px' lineHeight='22px'>Aurorasoftware.LLC</Text>
              </Box>
            </Box>
            <Text mt='20px' fontSize='17px' color='black.lighten' lineHeight='21px' as='p'>Inspection Report by Big Ben Inspections</Text>
            <Box d='flex' justifyContent='space-between' mt='30px'>
              <Button variant='primary2' p='8px 30px' mr='15px' bg='white.lighten' color='black.lighten' onClick={() => previewEditTemplate('preview', null)}>Preview</Button>
              <Box>
                {reportTemplate && <Button variant='primary2' p='8px 30px' onClick={addDefaultTemplate}>Use Template</Button>}
              </Box>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
      <Modal isOpen={viewReportTemplate} onClose={closeReportTemplate} size='xl' scrollBehavior='inside'>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader p='30px 30px 35px' fontSize='21px' lineHeight='26px' color='black.lighten' fontWeight='600'>Use Template</ModalHeader>
          <Icon color='black.primary' fontSize='24px' as={IoMdClose} onClick={closeReportTemplate} cursor='pointer' position='absolute' top='29px' right='29px'></Icon>
          <ModalBody p='0 30px 25px'>
            <Text fontSize='18px' color='black.lighten'>Do you want to use this template for report?</Text>
          </ModalBody>
          <ModalFooter>
            <Button variant='primary2' minW='120px' onClick={addDefaultTemplate}>Yes</Button>
            <Button variant='grey' minW='120px' ml='15px' onClick={closeReportTemplate}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal isOpen={viewPreviewTemplate} onClose={closePreviewTemplate} size='full' scrollBehavior='inside'>
        <Box className='preview-template'>
          <ModalOverlay />
          <ModalContent pt='30px'>
            <Icon color='black.primary' fontSize='24px' as={IoMdClose} onClick={closePreviewTemplate} cursor='pointer' position='absolute' top='29px' right='29px'></Icon>
            <ModalBody p='0 30px 25px'>
              <CreateTemplate />
            </ModalBody>
          </ModalContent>
        </Box>
      </Modal>
    </>
  )
}