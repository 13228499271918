import { Avatar, Box, css, Flex, Icon, Image, Menu, MenuButton, MenuItem, MenuList, Text } from '@chakra-ui/react'
import { BsFillCaretDownFill, BsTextIndentLeft, BsTextIndentRight } from 'react-icons/bs';
import { MdOutlineSettings } from 'react-icons/md';
import logoutImg from '../../assets/images/logout.svg';
import { logout, setCollapseSidebar, setShowSidebar } from '../../redux/modules/auth';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { Link } from 'react-router-dom';

export function Header() {
  const dispatch = useDispatch();
  const auth: any = useSelector((state: RootState) => state.auth);

  const userCheck = () => {
    if (auth.user?.user_Type === 'company_admin') {
      return ('Admin');
    }
    else if (auth.user?.user_Type === 'super_admin') {
      return ('Super Admin');
    }
    else {
      return ('Home Inspector');
    }
  }

  return (
    <>
      {auth.showSidebar && <Box zIndex='4' position='fixed' bg='#0000007a' top='0' left='0' right='0' bottom='0' onClick={() => dispatch(setShowSidebar(!auth.showSidebar))}></Box>}
      <Box d={{ sm: 'inline-block', lg: 'none' }} position='fixed' left='20px' top='20px' zIndex='99' onClick={() => dispatch(setShowSidebar(!auth.showSidebar))}>
        <Box transform={`${auth.showSidebar ? 'rotate(-45deg) translate(-5px, 6px)' : ''}`} transition='0.4s' bg={`${auth.showSidebar ? 'white.primary' : 'blue.primary'}`} w='28px' h='3px' mb='6px'></Box>
        <Box opacity={`${auth.showSidebar ? '0' : ''}`} transition='0.4s' bg={`${auth.showSidebar ? 'white.primary' : 'blue.primary'}`} w='28px' h='3px' mb='6px'></Box>
        <Box transform={`${auth.showSidebar ? 'rotate(45deg) translate(-7px, -8px)' : ''}`} transition='0.4s' bg={`${auth.showSidebar ? 'white.primary' : 'blue.primary'}`} w='28px' h='3px'></Box>
      </Box>
      <Flex p={{ sm: '7px 20px', lg: '7px 30px 7px 20px' }} justifyContent={{ sm: 'flex-end', lg: 'space-between' }} alignItems='center' boxShadow='0px -3px 10px rgba(221, 221, 221, 0.75)' position='relative' zIndex='3' h='70px' bg='white.primary'>
        <Flex justifyContent='space-between' >
          <Box d={{ sm: 'none', lg: 'inline-flex' }} alignItems='center' onClick={() => dispatch(setCollapseSidebar(!auth.collapseSidebar))}>
            <Icon color='blue.dark' fontSize='28px' cursor='pointer' as={auth.collapseSidebar ? BsTextIndentLeft : BsTextIndentRight}></Icon>
          </Box>
        </Flex>

        <Flex alignItems='center'>
          <Menu>
            <MenuButton d='inline-flex' className='dropdown-btn'>
              <Box w='42px' h='42px' mr='10px' borderRadius='50%' overflow='hidden' d='inline-flex' alignItems='center'>
                <Avatar name={`${auth?.user?.firstName} ${auth?.user?.lastName}`} src={auth?.user?.AccessProfileUrl} h='100%' w='100%' bg='blue.dark' css={css({
                  '.chakra-avatar__initials': {
                    fontSize: '20px',
                    color: 'white'
                  },
                  '.chakra-avatar__img': {
                    borderRadius: '0',
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover'
                  }
                })} />
              </Box>
              <Box color='black.lighten' d='inline-flex' alignItems='center'>
                <Text fontSize='xl'>
                  {userCheck()}

                </Text>
                <Icon ml='8px' mt='3px' fontSize='12px' as={BsFillCaretDownFill}></Icon>
              </Box>
            </MenuButton>
            <MenuList boxShadow='0px 4px 8px #00000014' borderRadius='0' border='none' minW='unset' w='184px'>
              <Flex flexDirection='column' alignItems='center' p='16px 8px 22px' position='relative' mb='4px' _before={{ position: 'absolute', content: '""', bottom: '0', background: 'gray.light2', h: '1px', left: '4px', right: '4px' }}>
                <Box w='60px' h='60px' mr='10px' borderRadius='50%' overflow='hidden' d='inline-flex' alignItems='center' mb='8px'>
                  <Avatar name={`${auth?.user?.firstName} ${auth?.user?.lastName}`} src={auth?.user?.AccessProfileUrl} h='100%' w='100%' bg='blue.dark' css={css({
                    '.chakra-avatar__initials': {
                      fontSize: '20px',
                      color: 'white'
                    },
                    '.chakra-avatar__img': {
                      borderRadius: '0',
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover'
                    }
                  })} />
                </Box>
                <Text as="b">{`${auth?.user?.firstName} ${auth?.user?.lastName}`}</Text>
                <Text as="span" wordBreak='break-word' textAlign='center' whiteSpace='pre-wrap' fontSize='15px' lineHeight='19px'>{auth?.user?.email}</Text>
              </Flex>
              <MenuItem p='11px 34px' justifyContent='flex-start' alignItems='center' color='green.primary'><Link to='/settings'><Flex alignItems='center'><Box w='24px' d='inline-flex' justifyContent='center' alignItems='center'><Icon mr='14px' fontSize='24px' as={MdOutlineSettings}></Icon></Box><Text fontSize='14px' lineHeight='16px'>Settings</Text></Flex></Link></MenuItem>
              <MenuItem p='11px 34px' justifyContent='flex-start' alignItems='center' color='green.primary' onClick={() => dispatch(logout())}><Box w='24px' d='inline-flex' justifyContent='center' alignItems='center'><Image mr='14px' w='17px' src={logoutImg} alt="logout" /></Box><Text fontSize='14px' lineHeight='16px'>Logout</Text></MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </Flex>
    </>
  )
}

export default Header;