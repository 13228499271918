import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import store from 'store2';
import api from '../api';

export interface authState {
  user: any;
  error: any;
  company: any;
  agreements: [];
  userLoaded: boolean;
  showSidebar: boolean;
  collapseSidebar: boolean;
  loginLoading: boolean[];
  selectedAggrements: [];
  accountId: any;
  bankAccount: any;
  bankAccounts: any[];
  stripeAccount: any;
  stripeAccounts: any[];
  invoiceReport: any;
  passwordChange: any;
}

const initialState: authState = {
  user: null,
  error: null,
  company: null,
  agreements: [],
  userLoaded: false,
  showSidebar: false,
  collapseSidebar: false,
  loginLoading: [],
  selectedAggrements: [],
  accountId: null,
  bankAccount: null,
  bankAccounts: [],
  stripeAccount: null,
  stripeAccounts: [],
  invoiceReport: null,
  passwordChange: null,
};

export const login = createAsyncThunk(
  'auth/login',
  async (data: { payload: object }) => {
    const { payload } = data;
    const response = await api.post('users/login', { data: payload });
    if (response.data.message !== "Otp send to your email Id .Its valid for 10 minutes only") {
      store.set('authToken', response.data.token);
      store.set('authUser', response.data.user);
      return response.data.user;
    }
    return response.data;
  }
);

export const signup = createAsyncThunk(
  'auth/signup',
  async (data: { payload: object }) => {
    const { payload } = data;
    const response = await api.post('users/signup', { data: payload });
    return response.data.user;
  }
);

export const verifyOtp = createAsyncThunk(
  'auth/verifyOtp',
  async (data: { userId: number, payload: object }) => {
    const { userId, payload } = data;
    const response = await api.post(`users/match/user/${userId}`, { data: payload });
    store.set('authToken', response.data.token);
    store.set('authUser', response.data.user);
    return response.data;
  }
);

export const resendOtp = createAsyncThunk(
  'auth/regenerate/otp',
  async (data: { userId: number }) => {
    const { userId } = data;
    const response = await api.post(`users/regenerate/otp/${userId}`);
    return response.data;
  }
);

export const sendForgotPasswordEmail = createAsyncThunk(
  'auth/sendForgotPasswordEmail',
  async (data: { payload: object }) => {
    const { payload } = data;
    const response = await api.post('users/get/userdetailbyemail', { data: payload });
    return response.data;
  }
);

export const forgotPassword = createAsyncThunk(
  'auth/forgotPassword',
  async (data: { token: string, payload: object }) => {
    const { token, payload } = data;
    const response = await api.put(`users/resetpassword?token=${token}`, { data: payload });
    return response.data;
  }
);

export const updatePassword = createAsyncThunk(
  'auth/updatePassword',
  async (data: { userId: number, payload: object }) => {
    const { userId, payload } = data;
    const response = await api.put(`users/updatepassword/${userId}`, { data: payload });
    return response.data;
  }
);

export const profileUpdate = createAsyncThunk(
  'auth/profileUpdate',
  async (data: { userId: any, payload: object }) => {
    const { userId, payload } = data;
    const response = await api.put(`users/profileupdate/${userId}`, { data: payload });
    return response.data;
  }
);
export const updateUser = createAsyncThunk(
  'auth/updateUser',
  async (data: { userId: any, payload: object }) => {
    const { userId, payload } = data;
    const response = await api.put(`users/update/user/${userId}`, { data: payload });
    return response.data;
  }
);

export const companyProfileUpdate = createAsyncThunk(
  'auth/companyProfileUpdate',
  async (data: { companyId: number, payload: object }) => {
    const { companyId, payload } = data;
    const response = await api.put(`users/update/companyprofile/${companyId}`, { data: payload });
    return response.data;
  }
);

export const userDetailById = createAsyncThunk(
  'auth/userDetailById',
  async (data: { userId: any }) => {
    const { userId } = data;
    const response = await api.get(`users/get/userdetailbyid/${userId}`);
    return response.data;
  }
);

export const uploadCompanyImage = createAsyncThunk(
  'auth/uploadCompanyImage',
  async (data: { companyId: number, payload: any }) => {
    const { companyId, payload } = data;
    const response = await api.post(`users/uploadCompanyimage/${companyId}`, { data: payload });
    return response.data;
  }
);

export const uploadUserImage = createAsyncThunk(
  'auth/uploadUserImage',
  async (data: { userId: any, payload: any }) => {
    const { userId, payload } = data;
    const response = await api.post(`users/uploadimage/${userId}`, { data: payload });
    return response.data;
  }
);

export const verifyMail = createAsyncThunk(
  'auth/verifyMail',
  async (data: { token: string }) => {
    const { token } = data;
    const response = await api.put(`users/update/useractivity?token=${token}`, { data: token });
    return response;
  }
);

export const getAggrements = createAsyncThunk(
  'auth/getAggrements',
  async (data: { payload: object }) => {
    const { payload } = data;
    const response = await api.post('users/get/aggrement', { data: payload });
    return response.data;
  }
);

export const createAggrement = createAsyncThunk(
  'auth/createAggrement',
  async (data: { payload: object }, { dispatch, getState }) => {
    const { payload } = data;
    const state: any = getState();
    const response = await api.post('users/create/aggrement', { data: payload });

    dispatch(getAggrements({ payload: { userId: state.auth.user.id } }));
    return response.data;
  }
);

export const updateAggrement = createAsyncThunk(
  'auth/updateAggrement',
  async (data: { aggrementId: number, payload: object }, { dispatch, getState }) => {
    const state: any = getState();
    const { aggrementId, payload } = data;
    const response = await api.put(`users/update/aggrement/${aggrementId}`, { data: payload });

    dispatch(getAggrements({ payload: { userId: state.auth.user.id } }));
    return response.data;
  }
);

export const getSelectedAggrements = createAsyncThunk(
  'auth/getSelectedAggrements',
  async (data: { payload: object }) => {
    const { payload } = data;
    const response = await api.post('users/getSelectedAggrement', { data: payload });
    return response.data;
  }
);

export const createStripeAccount = createAsyncThunk(
  'auth/connectStripeAccount',
  async (data: { publishableKey: any, secretKey: any, accountId: any }, { dispatch }) => {
    const { publishableKey, secretKey, accountId } = data;
    const response = await api.post('users/connectStripeAccount', { data: { publicKey: publishableKey, stripeKey: secretKey, accountId: accountId } });

    dispatch(getStripeAccounts());
    return response.data;
  }
);

export const getStripeAccounts = createAsyncThunk(
  'auth/get/stripeaccount/by/userid',
  async () => {
    const response = await api.get('users/get/stripeaccount/by/userid');
    return response.data;
  }
);

export const updateStripeAccount = createAsyncThunk(
  'auth/update/stripeAccount',
  async (data: { accountId: number, payload: any }, { dispatch }) => {
    const { accountId, payload } = data;
    const response = await api.put(`users/update/stripeAccount/${accountId}`, { data: payload });

    dispatch(getStripeAccounts());
    return response.data;
  }
);

export const createBankAccount = createAsyncThunk(
  'auth/connect/BankAccount',
  async (data: { payload: any }, { dispatch }) => {
    const response = await api.post('users/connect/BankAccount', { data: data });

    dispatch(getBankAccounts());
    return response.data;
  }
);

export const updateBankAccount = createAsyncThunk(
  'auth/update/bankAccount',
  async (data: { accountId: number, payload: any }, { dispatch }) => {
    const { accountId, payload } = data;
    const response = await api.put(`users/update/bankAccount/${accountId}`, { data: payload });

    dispatch(getBankAccounts());
    return response.data;
  }
);

export const generateHomeInspectorPassword = createAsyncThunk(
  'auth/generate/Password',
  async (data: { token: string, payload: object }) => {
    const { token, payload } = data;
    const response = await api.put(`users/generate/Password?token=${token}`, { data: payload });
    return response.data;
  }
);

export const getBankAccounts = createAsyncThunk(
  'auth/get/bankAccounts',
  async () => {
    const response = await api.get('users/get/bankaccount/by/userid');
    return response.data;
  }
);

export const deleteStripeAccount = createAsyncThunk(
  'auth/delete/stripeAccount',
  async (data: { accountId: any }, { dispatch }) => {
    const { accountId } = data;
    const response = await api.del(`users/delete/stripeAccount/${accountId}`);

    dispatch(getStripeAccounts());
    return response.data;
  }
);

export const deleteBankAccount = createAsyncThunk(
  'auth/delete/bankAccount',
  async (data: { accountId: any }, { dispatch }) => {
    const { accountId } = data;
    const response = await api.del(`users/delete/bankAccount/${accountId}`);

    dispatch(getBankAccounts());
    return response.data;
  }
);

export const getInvoice = createAsyncThunk(
  'auth/get/invoice',
  async (data: { invoiceId: any }) => {
    const { invoiceId } = data;
    const response = await api.get(`users/get/invoice/${invoiceId}`);
    return response.data;
  }
);


export const updateSaveAgreement = createAsyncThunk(
  'auth/save/agreement',
  async (data: { reportId: any, payload: any }) => {
    const { reportId, payload } = data;
    const response = await api.put(`users/save/aggrementLink/${reportId}`, { data: payload });
    return response.data;
  }
);

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuthError: (state, action) => {
      state.error = action.payload;
    },
    loadUser: (state) => {
      const isTokenExpired = (token: string) => Date.now() >= (JSON.parse(atob(token.split('.')[1]))).exp * 1000;
      const token = store.get('authToken');

      if (token && isTokenExpired(token)) {
        store.remove('authUser');
        store.remove('authToken');
        window.location.replace('/login');
      } else {
        state.userLoaded = true;
        state.user = store.get('authUser');
      }
    },
    logout: () => {
      store.remove('authUser');
      store.remove('authToken');
      window.location.replace('/login');
    },
    setShowSidebar: (state, action) => {
      state.showSidebar = action.payload;
    },
    setCollapseSidebar: (state, action) => {
      state.collapseSidebar = action.payload;
    },
    setLoading: (state) => {
      state.loginLoading.push(true);
    },
    unsetLoading: (state) => {
      state.loginLoading.pop();
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.error = null;
        state.loginLoading.push(true);
      })
      .addCase(login.rejected, (state, action) => {
        state.loginLoading.pop();
        state.error = action.error;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.error = null;
        state.loginLoading.pop();
        state.user = action.payload;
      })
      .addCase(signup.pending, (state) => {
        state.error = null;
        state.loginLoading.push(true);
      })
      .addCase(signup.rejected, (state, action) => {
        state.loginLoading.pop();
        state.error = action.error;
      })
      .addCase(signup.fulfilled, (state) => {
        state.error = null;
        state.loginLoading.pop();
      })
      .addCase(verifyOtp.pending, (state) => {
        state.error = null;
        state.loginLoading.push(true);
      })
      .addCase(verifyOtp.rejected, (state, action) => {
        state.loginLoading.pop();
        state.error = action.error;
      })
      .addCase(verifyOtp.fulfilled, (state, action) => {
        state.error = null;
        state.loginLoading.pop();
        state.user = action.payload;
      })
      .addCase(resendOtp.pending, (state) => {
        state.error = null;
        state.loginLoading.push(true);
      })
      .addCase(resendOtp.rejected, (state, action) => {
        state.loginLoading.pop();
        state.error = action.error;
      })
      .addCase(resendOtp.fulfilled, (state) => {
        state.error = null;
        state.loginLoading.pop();
      })
      .addCase(sendForgotPasswordEmail.pending, (state) => {
        state.error = null;
        state.loginLoading.push(true);
      })
      .addCase(sendForgotPasswordEmail.rejected, (state, action) => {
        state.loginLoading.pop();
        state.error = action.error;
      })
      .addCase(sendForgotPasswordEmail.fulfilled, (state) => {
        state.error = null;
        state.loginLoading.pop();
      })
      .addCase(forgotPassword.pending, (state) => {
        state.error = null;
        state.loginLoading.push(true);
      })
      .addCase(forgotPassword.rejected, (state, action) => {
        state.loginLoading.pop();
        state.error = action.error;
      })
      .addCase(forgotPassword.fulfilled, (state) => {
        state.error = null;
        state.loginLoading.pop();
      })
      .addCase(updatePassword.pending, (state) => {
        state.error = null;
        state.loginLoading.push(true);
      })
      .addCase(updatePassword.rejected, (state, action) => {
        state.loginLoading.pop();
        state.error = action.error;
      })
      .addCase(updatePassword.fulfilled, (state) => {
        state.error = null;
        state.loginLoading.pop();
      })
      .addCase(profileUpdate.pending, (state) => {
        state.error = null;
        state.loginLoading.push(true);
      })
      .addCase(profileUpdate.rejected, (state, action) => {
        state.loginLoading.pop();
        state.error = action.error;
      })
      .addCase(profileUpdate.fulfilled, (state) => {
        state.error = null;
        state.loginLoading.pop();
      })
      .addCase(updateUser.pending, (state) => {
        state.error = null;
        state.loginLoading.push(true);
      })
      .addCase(updateUser.rejected, (state, action) => {
        state.loginLoading.pop();
        state.error = action.error;
      })
      .addCase(updateUser.fulfilled, (state) => {
        state.error = null;
        state.loginLoading.pop();
      })
      .addCase(companyProfileUpdate.pending, (state) => {
        state.error = null;
        state.loginLoading.push(true);
      })
      .addCase(companyProfileUpdate.rejected, (state, action) => {
        state.loginLoading.pop();
        state.error = action.error;
      })
      .addCase(companyProfileUpdate.fulfilled, (state) => {
        state.error = null;
        state.loginLoading.pop();
      })
      .addCase(userDetailById.pending, (state) => {
        state.error = null;
        state.loginLoading.push(true);
      })
      .addCase(userDetailById.rejected, (state, action) => {
        state.loginLoading.pop();
        state.error = action.error;
      })
      .addCase(userDetailById.fulfilled, (state, action) => {
        state.error = null;
        state.loginLoading.pop();
        state.user = action.payload.user;
        state.company = action.payload.companyDetail;
        store.set('authUser', action.payload.user);
      })
      .addCase(uploadCompanyImage.pending, (state) => {
        state.error = null;
        state.loginLoading.push(true);
      })
      .addCase(uploadCompanyImage.rejected, (state, action) => {
        state.loginLoading.pop();
        state.error = action.error;
      })
      .addCase(uploadCompanyImage.fulfilled, (state) => {
        state.error = null;
        state.loginLoading.pop();
      })
      .addCase(uploadUserImage.pending, (state) => {
        state.error = null;
        state.loginLoading.push(true);
      })
      .addCase(uploadUserImage.rejected, (state, action) => {
        state.loginLoading.pop();
        state.error = action.error;
      })
      .addCase(uploadUserImage.fulfilled, (state) => {
        state.error = null;
        state.loginLoading.pop();
      })
      .addCase(verifyMail.pending, (state) => {
        state.error = null;
        state.loginLoading.push(true);
      })
      .addCase(verifyMail.rejected, (state, action) => {
        state.loginLoading.pop();
        state.error = action.error;
      })
      .addCase(verifyMail.fulfilled, (state) => {
        state.error = null;
        state.loginLoading.pop();
      })
      .addCase(getAggrements.pending, (state) => {
        state.error = null;
        state.loginLoading.push(true);
      })
      .addCase(getAggrements.rejected, (state, action) => {
        state.loginLoading.pop();
        state.error = action.error;
      })
      .addCase(getAggrements.fulfilled, (state, action) => {
        state.error = null;
        state.loginLoading.pop();
        state.agreements = action.payload;
      })
      .addCase(createAggrement.pending, (state) => {
        state.error = null;
        state.loginLoading.push(true);
      })
      .addCase(createAggrement.rejected, (state, action) => {
        state.loginLoading.pop();
        state.error = action.error;
      })
      .addCase(createAggrement.fulfilled, (state) => {
        state.error = null;
        state.loginLoading.pop();
      })
      .addCase(updateAggrement.pending, (state) => {
        state.error = null;
        state.loginLoading.push(true);
      })
      .addCase(updateAggrement.rejected, (state, action) => {
        state.loginLoading.pop();
        state.error = action.error;
      })
      .addCase(updateAggrement.fulfilled, (state) => {
        state.error = null;
        state.loginLoading.pop();
      })
      .addCase(getSelectedAggrements.pending, (state) => {
        state.error = null;
        state.loginLoading.push(true);
      })
      .addCase(getSelectedAggrements.rejected, (state, action) => {
        state.loginLoading.pop();
        state.error = action.error;
      })
      .addCase(getSelectedAggrements.fulfilled, (state, action) => {
        state.error = null;
        state.loginLoading.pop();
        state.selectedAggrements = action.payload;
      })
      .addCase(createStripeAccount.pending, (state) => {
        state.error = null;
        state.loginLoading.push(true);
      })
      .addCase(createStripeAccount.rejected, (state, action) => {
        state.loginLoading.pop();
        state.error = action.error;
      })
      .addCase(createStripeAccount.fulfilled, (state, action) => {
        state.error = null;
        state.loginLoading.pop();
      })
      .addCase(createBankAccount.pending, (state) => {
        state.error = null;
        state.loginLoading.push(true);
      })
      .addCase(createBankAccount.rejected, (state, action) => {
        state.loginLoading.pop();
        state.error = action.error;
      })
      .addCase(createBankAccount.fulfilled, (state, action) => {
        state.error = null;
        state.loginLoading.pop();
        state.accountId = action.payload.id;
      })
      .addCase(updateBankAccount.pending, (state) => {
        state.error = null;
        state.loginLoading.push(true);
      })
      .addCase(updateBankAccount.rejected, (state, action) => {
        state.loginLoading.pop();
        state.error = action.error;
      })
      .addCase(updateBankAccount.fulfilled, (state, action) => {
        state.error = null;
        state.loginLoading.pop();
      })
      .addCase(updateSaveAgreement.pending, (state) => {
        state.error = null;
        state.loginLoading.push(true);
      })
      .addCase(updateSaveAgreement.rejected, (state, action) => {
        state.loginLoading.pop();
        state.error = action.error;
      })
      .addCase(updateSaveAgreement.fulfilled, (state, action) => {
        state.error = null;
        state.loginLoading.pop();
      })
      .addCase(updateStripeAccount.pending, (state) => {
        state.error = null;
        state.loginLoading.push(true);
      })
      .addCase(updateStripeAccount.rejected, (state, action) => {
        state.loginLoading.pop();
        state.error = action.error;
      })
      .addCase(updateStripeAccount.fulfilled, (state, action) => {
        state.error = null;
        state.loginLoading.pop();
      })
      .addCase(getBankAccounts.pending, (state) => {
        state.error = null;
        state.loginLoading.push(true);
      })
      .addCase(getBankAccounts.rejected, (state, action) => {
        state.loginLoading.pop();
        state.error = action.error;
      })
      .addCase(getBankAccounts.fulfilled, (state, action) => {
        state.error = null;
        state.loginLoading.pop();
        state.bankAccounts = action.payload;
      })
      .addCase(getInvoice.pending, (state) => {
        state.error = null;
        state.loginLoading.push(true);
      })
      .addCase(getInvoice.rejected, (state, action) => {
        state.loginLoading.pop();
        state.error = action.error;
      })
      .addCase(getInvoice.fulfilled, (state, action) => {
        state.error = null;
        state.loginLoading.pop();
        state.invoiceReport = action.payload;
      })
      .addCase(deleteBankAccount.pending, (state) => {
        state.error = null;
        state.loginLoading.push(true);
      })
      .addCase(deleteBankAccount.rejected, (state, action) => {
        state.loginLoading.pop();
        state.error = action.error;
      })
      .addCase(deleteBankAccount.fulfilled, (state, action) => {
        state.error = null;
        state.loginLoading.pop();
      })
      .addCase(deleteStripeAccount.pending, (state) => {
        state.error = null;
        state.loginLoading.push(true);
      })
      .addCase(deleteStripeAccount.rejected, (state, action) => {
        state.loginLoading.pop();
        state.error = action.error;
      })
      .addCase(deleteStripeAccount.fulfilled, (state, action) => {
        state.error = null;
        state.loginLoading.pop();
      })
      .addCase(getStripeAccounts.pending, (state) => {
        state.error = null;
        state.loginLoading.push(true);
      })
      .addCase(getStripeAccounts.rejected, (state, action) => {
        state.loginLoading.pop();
        state.error = action.error;
      })
      .addCase(getStripeAccounts.fulfilled, (state, action) => {
        state.error = null;
        state.loginLoading.pop();
        state.stripeAccounts = action.payload;
      })
      .addCase(generateHomeInspectorPassword.pending, (state) => {
        state.error = null;
        state.loginLoading.push(true);
      })
      .addCase(generateHomeInspectorPassword.rejected, (state, action) => {
        state.loginLoading.pop();
        state.error = action.error;
      })
      .addCase(generateHomeInspectorPassword.fulfilled, (state, action) => {
        state.error = null;
        state.loginLoading.pop();
        state.passwordChange = action.payload;
      })
  },
});

export const { setAuthError, loadUser, logout, setShowSidebar, setCollapseSidebar, setLoading, unsetLoading } = authSlice.actions;
export default authSlice.reducer;