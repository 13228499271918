import { Box, Button, Icon, Modal, ModalBody, ModalContent, ModalHeader, ModalOverlay, useDisclosure } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { Cropper } from 'react-cropper';
import { IoMdClose } from 'react-icons/io';

function CropImage({ src, name, setCroppedImage, open, setOpenCropModel }) {
  const [image, setImage] = useState<any>(null);
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    if (open === true) {
      onOpen(); // Model
    }
  }, [open, onOpen]);

  const closeModel = ()=> {
    onClose(); // Model
    setOpenCropModel(false);
  }

  const getCropedImage = () => {
    if (typeof image !== "undefined") {
      setCroppedImage({
        name: name,
        img: image.getCroppedCanvas().toDataURL()
      });
      closeModel();
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={'5xl'} scrollBehavior='inside' closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader p='25px 28px' fontSize='21px' lineHeight='26px' color='black.lighten' fontWeight='600'>
          Add Image
        </ModalHeader>
        <Icon
          color='black.primary'
          fontSize='24px'
          as={IoMdClose}
          onClick={closeModel}
          cursor='pointer'
          position='absolute'
          top='29px'
          right='29px'
        ></Icon>
        <ModalBody p='0 28px 25px'>
          <Box>
            <Box style={{ width: '100%' }}>
              <Cropper
                src={src}
                zoomTo={0}
                viewMode={1}
                guides={true}
                className='img-cropper'
                initialAspectRatio={1}
                preview='.img-preview'
                minCropBoxHeight={10}
                minCropBoxWidth={10}
                background={false}
                responsive={true}
                autoCropArea={1}
                checkOrientation={false}
                onInitialized={(instance) => setImage(instance)}
              />
            </Box>
            <Box m='20px 0' overflow='hidden'>
              <Box w='auto'>
                <Box className='img-preview' bg='gray.light3' borderRadius='4px' />
              </Box>
            </Box>
            <Button variant='primary2' onClick={getCropedImage}>
              Save
            </Button>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export function dataURLtoFile (dataurl: string, filename: string) {
  const arr = dataurl.split(',');
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n) {
    u8arr[n - 1] = bstr.charCodeAt(n - 1);
    n -= 1; // to make eslint happy
  }
  return new File([u8arr], filename, { type: mime });
}

export default CropImage;
