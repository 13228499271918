import { Box, Button, Flex, Icon, Image, Text, Table, Thead, Tbody, Tr, Th, Td, TableContainer } from '@chakra-ui/react'
import { MdPictureAsPdf } from 'react-icons/md';
import React, { useEffect } from "react";
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getInvoiceReport, getReportById, ReportState } from '../../../../redux/modules/report';
import { RootState } from '../../../../redux/store';
import { authState } from '../../../../redux/modules/auth';
import moment from 'moment';

export default function InvoiceDetails() {
    const params = useParams();
    const dispatch = useDispatch();
    const report: ReportState = useSelector((state: RootState) => state.report);
    const auth: authState = useSelector((state: RootState) => state.auth);
    useEffect(() => {
        dispatch(getReportById({ reportId: "3c14af63-6136-4459-8f3c-b161bea85b98" }));
    }, [dispatch, params.id]);

    useEffect(() => {
        dispatch(getInvoiceReport({ reportId: params.id }));
    }, [dispatch, params.id]);

    const handleDownloadPDF = () => {
        const finalUrl = report?.inspectionReportDetails?.paymentLink;
        const a = document.createElement('a');
        // @ts-ignore
        a.href = finalUrl;
        a.download = finalUrl;
        window.open(finalUrl, '_blank');
    }

    return (
        <>
            <Box p='20px 30px' h='calc(100vh - 70px)' overflowX='auto'>
                <Flex alignItems='center'>
                    <Box w='371px' h='220px' borderRadius='4px' border='1px solid' borderColor='gray.light11' d='inline-flex' alignItems='center' justifyContent='center'>
                        {auth.invoiceReport?.companyLogo && <Image h='100%' src={auth.invoiceReport?.companyLogo} />}
                        {!auth.invoiceReport?.companyLogo && <Text fontSize='17px'>Select a company logo to show here</Text>}
                    </Box>
                    <Box ml='40px'>
                        <Text fontSize='xxxl' fontWeight='600'>{auth.invoiceReport?.companyName}</Text>
                        <Text fontSize='xxl' mt='20px'>{auth.invoiceReport?.companyEmail}</Text>
                    </Box>
                </Flex>
                <Flex mt='30px'>
                    <Box>
                        <Text fontSize='xxxl' fontWeight='600' mb='10px'>Bill To</Text>
                        <Text fontSize='xxl' mt='10px'>{auth.invoiceReport?.clientName}</Text>
                        <Text fontSize='xxl' mt='10px'>{auth.invoiceReport?.clientMobile}</Text>
                        <Text fontSize='xxl' mt='10px'>{auth.invoiceReport?.clientEmail}</Text>
                    </Box>
                    <Box ml='95px'>
                        <Text fontSize='xxxl' fontWeight='600' mb='10px'>Property</Text>
                        <Text fontSize='xxl' mt='10px'>{auth.invoiceReport?.paymentService}</Text>
                        <Text fontSize='xxl' mt='10px'>{auth.invoiceReport?.clientAddress}</Text>
                    </Box>
                    <Flex ml='95px'>
                        <Text fontSize='xxxl' fontWeight='600'>Date</Text>
                        <Text fontSize='xxl' ml='20px'>{moment(auth.invoiceReport?.createdAt).format("MM-DD-YYYY")}</Text>
                    </Flex>
                </Flex>
                <TableContainer mt='95px'>
                    <Table id='table' variant='SimpleTable'>
                        <Thead>
                            <Tr>
                                <Th>Name</Th>
                                <Th>Amount</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            <Tr>
                                <Td>Residential Inspection</Td>
                                <Td>${auth.invoiceReport?.paymentAmount}</Td>
                            </Tr>
                            <Tr>
                                <Td className='table-data-no-border'>Total</Td>
                                <Td>${auth.invoiceReport?.paymentAmount}</Td>
                            </Tr>
                        </Tbody>
                    </Table>
                </TableContainer>
                <Flex w='100%' justifyContent='right' mt='30px'>
                    <Button onClick={handleDownloadPDF} variant='primary2' textAlign='right'><Icon color='white.primary' mr='10px' as={MdPictureAsPdf}></Icon>Download PDF</Button>
                </Flex>
            </Box>
        </>
    )

}