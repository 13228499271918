import {
  Button,
  Icon,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import { MdDelete } from "react-icons/md";
import {
  deleteNarrativeImage,
  deleteNarrativeImageFunction,
} from "../../../redux/modules/report";
import { useDispatch } from "react-redux";
interface deletenarrativeImageProps {
  index?: number;
  templateData?: any;
  deletenarrativeSingleImage?: any;
  mapData?: any;
  id?: any;
  reportId?: any;
}
const DeleteNarrativeImageModal = ({
  deletenarrativeSingleImage,
  index,
  templateData,
  mapData,
  reportId,
  id,
}: deletenarrativeImageProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const dispatch = useDispatch();
  console.log("mapDatamapData", mapData);
  return (
    <>
      <Button
        variant="iconBtn"
        bg="blue.primary"
        zIndex="1"
        minW="unset"
        p="0"
        borderRadius="50%"
        h="21px"
        w="21px"
        ml="15px"
        onClick={onOpen}
      >
        <Icon fontSize="16px" color="white.primary" as={MdDelete}></Icon>
      </Button>

      {/* Delete modal */}
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Are your sure want to delete this image?</ModalHeader>
          <ModalCloseButton />
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Cancel
            </Button>
            <Button
              variant="ghost"
              onClick={() =>
                dispatch(
                  deleteNarrativeImageFunction({
                    imageId: id ?? "",
                    narrativeKey: reportId,
                  })
                )
              }
            >
              Delete
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default DeleteNarrativeImageModal;
