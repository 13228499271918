import { useEffect, useRef, useState } from 'react'
import { Box, Button, Flex, FormControl, FormLabel, Icon, Input, Menu, MenuButton, MenuItem, MenuList, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Select, SimpleGrid, Text, useDisclosure } from '@chakra-ui/react'
import { IoIosCheckmarkCircle, IoMdClose } from 'react-icons/io'
import { BsFillCaretDownFill } from "react-icons/bs";
import { Chart } from "react-google-charts";
import { HiArrowDown, HiArrowUp, HiDotsCircleHorizontal, HiDotsVertical } from 'react-icons/hi'
import { Link, useNavigate } from 'react-router-dom'
import DataTable from 'react-data-table-component'
import { css } from '@emotion/react'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import 'react-big-calendar/lib/css/react-big-calendar.css';
import DateTimePicker from 'react-datetime-picker';
import { useDispatch, useSelector } from 'react-redux'
import { createScheduleJob, dashboardDetails, DashboardState, getClients, getScheduleJobs, inspectionDetails, revenueDetails, setAddJobValues, setDashboardError, updateScheduledJob, setTravelDistance, deleteScheduleJob } from '../../redux/modules/dashboard'
import { authState } from '../../redux/modules/auth';
import { RootState } from '../../redux/store'
import Loader from '../common/loader';
import { toastr } from 'react-redux-toastr';
import { useForm } from '../../utils/hooks';
import { fillFormValues, googleMapAutocomplete, loadGoogleMapScript } from '../../utils/common';
import greenMapMarker from '../../assets/images/map-marker-green.svg';
import yellowMapMarker from '../../assets/images/map-marker-yellow.svg';
import blueMapMarker from '../../assets/images/map-marker-blue.svg';
import brownMapMarker from '../../assets/images/map-marker-brown.svg';
import { getAllReports, ReportState } from '../../redux/modules/report';

export default function Dashboard() {
  const dispatch = useDispatch();
  const dashboard: DashboardState = useSelector((state: RootState) => state.dashboard);
  const auth: authState = useSelector((state: RootState) => state.auth);
  const report: ReportState = useSelector((state: RootState) => state.report);
  const { isOpen: addEvents, onOpen: openaddEvents, onClose: closeaddEvents } = useDisclosure();
  const { isOpen: deleteConfirmation, onOpen: openDeleteConfirmation, onClose: closeDeleteConfirmation } = useDisclosure();
  const { isOpen: sameTimeConfirmation, onOpen: openSameTimeConfirmation, onClose: closeSameTimeConfirmation } = useDisclosure();
  const navigate = useNavigate();
  const [inspectionDate, setInspectionDate] = useState(new Date());
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [showInspectionDetail, setShowInspectionDetail] = useState(false);
  const [inspectionDetail, setInspectionDetail] = useState(null);
  const [dateTime, setDateTime] = useState<any>({ inspectionDate: null, fromDate: null, toDate: null });
  const [revenue, setRevenue] = useState('yearly');
  const [revenueDetail, setRevenueDetail] = useState(null);
  const [revenueGraph, setRevenueGraph] = useState(null);
  const [clientGraph, setClientGraph] = useState(null);
  const [reportGraph, setReportGraph] = useState(null);
  const [inspectionType, setInspectionType] = useState('yearly');
  const [filterStatistics, setFilterStatistics] = useState('monthly');
  const [editJob, setEditJob] = useState(null);
  const [activeJobId, setActiveJobId] = useState(null);
  const [activeReportId, setActiveReportId] = useState(null);
  const SCHEDULED_JOB_FORM = {
    default: {
      clientName: '',
      clientLastName: '',
      email: '',
      phone: '',
      city: '',
      state: '',
      country: '',
      zipcode: '',
      status: 'pending',
      inspectionAddress: ''
    },
    field: {
      required: true,
      inputs: ["clientName", "clientLastName", "email", "phone", "city", "state", "country", "zipcode", "inspectionAddress"]
    }
  };
  const { values: scheduledJobValues, change: scheduledJobChange, touched: scheduledJobTouched, errors: scheduledJobErrors, reset: scheduledJobReset, setValues: setScheduledJobValues } = useForm(SCHEDULED_JOB_FORM.default, SCHEDULED_JOB_FORM.field);

  const handleInspectionDetail = (selectedJob: any) => {
    setInspectionDetail(selectedJob);
    setShowInspectionDetail(true);
  }

  const handleSelectedEvent = (event) => {
    setSelectedEvent(event);
  }

  const localizer = momentLocalizer(moment);

  const options = {
    curveType: "function",
    legend: { position: "none" },
    hAxis: {
      textPosition: 'none',
      gridlines: { color: "#CCCCCC" }
    },
    vAxis: {
      textPosition: 'none',
      gridlines: { color: 'transparent' }
    },
    chartArea: {
      left: 0,
      top: 0,
      right: 0,
      bottom: 0,
      width: '100%'
    },
    colors: ['#00CA72'],
  };

  const options2 = {
    curveType: "function",
    legend: { position: "none" },
    hAxis: {
      textPosition: 'none',
      gridlines: { color: "#CCCCCC" }
    },
    vAxis: {
      textPosition: 'none',
      gridlines: { color: 'transparent' }
    },
    chartArea: {
      left: 0,
      top: 0,
      right: 0,
      bottom: 0,
      width: '100%',
    },
    colors: ['#F74A00']
  };

  const options3 = {
    curveType: "function",
    legend: { position: "none" },
    hAxis: {
      gridlines: { color: "#F1F1F1" }, title: `${revenue.charAt(0).toUpperCase() + revenue.slice(1)}`
    },
    vAxis: {
      gridlines: { color: "#F1F1F1" }, format: 'short', title: 'Amount'
    },
    chartArea: {
      left: 30,
      top: 10,
      right: 0,
      bottom: 50,
      width: '100%'
    },
    colors: ['#009EE1']
  };

  const options4 = {
    curveType: "function",
    legend: { position: "none" },
    hAxis: {
      gridlines: { color: "#F1F1F1" }, title: `${inspectionType.charAt(0).toUpperCase() + inspectionType.slice(1)}`
    },
    vAxis: {
      gridlines: { color: "#F1F1F1" }, title: 'Number of Inspections'
    },
    chartArea: {
      left: 40,
      top: 10,
      right: 0,
      bottom: 50,
      width: '100%'
    },
    colors: ['#F89D2C']
  };

  const myEventsList = dashboard.scheduledJobs.map((scheduledJob) => {
    return {
      start: moment(scheduledJob.inspectionDate).toDate(),
      end: moment(scheduledJob.inspectionDate).toDate(),
      title: `${moment(scheduledJob.StartTime).format("hh:mm A")}- ${moment(scheduledJob.EndTime).format("hh:mm A")}`
    }
  });

  const columns = [
    {
      name: 'Id',
      selector: row => row.id,
      sortable: true,
      minWidth: '70px',
      width: '70px'
    },
    {
      name: 'Name',
      selector: row => row.name,
      sortable: true,
      minWidth: '120px'
    },
    {
      name: 'Email',
      selector: row => row.email,
      minWidth: '250px',
      allowOverflow: true
    },
    {
      name: 'Address',
      selector: row => row.address,
      minWidth: '140px'
    },
    {
      name: 'City',
      selector: row => row.city,
      minWidth: '110px'
    },
    {
      name: 'State',
      selector: row => row.state,
      minWidth: '60px'
    },
    {
      name: 'Country',
      selector: row => row.country,
      minWidth: '110px'
    },
    {
      name: 'Pincode',
      selector: row => row.pincode,
      minWidth: '100px'
    }
  ];

  const tableDataItems = dashboard?.clients?.map((client: any) => {
    return {
      "id": client?.id,
      "name": `${client?.firstName} ${client?.lastName}`,
      "email": <Text whiteSpace='pre-wrap' overflow='visible'>{client?.email}</Text>,
      "address": client?.address,
      "city": client?.city,
      "state": client?.state,
      "country": client?.country,
      "pincode": client?.pincode
    }
  });

  const Popover = () => {
    return (
      <Box boxShadow='0px 0px 16px #0000001a' borderRadius='12px' maxW='422px' pos='absolute' bottom='20px' bg='white.primary' zIndex='4' left='0' right='0' m='0 auto'>
        <Box maxH='300px' overflow='auto' p='20px 15px 0'>
          {dashboard.scheduledJobs.filter(scheduledJob => moment(scheduledJob.inspectionDate).toDate().toLocaleDateString() === selectedEvent.start.toLocaleDateString()).map((scheduledJob) => {
            return (
              <Box cursor='pointer' overflow='hidden' display='flex' alignItems='center' borderRadius='0 4px 4px 0' borderLeft='3px solid' borderColor='green.darken' p='15px 20px 15px' bg='green.lighten5' mb='12px' h='65px' onClick={() => handleEditJob(scheduledJob)}>
                <Box w='100%' display='inline-flex' alignItems='flex-start'>
                  <Box pr='10px' display='inline-flex' flexDirection='column'>
                    <>
                      <Text as='h4' fontSize='18px' lineHeight='20px' color='black.lighten' fontWeight='400' mb='3px'>{scheduledJob?.city} {scheduledJob?.state} {scheduledJob?.country}, {scheduledJob?.zipcode}</Text>
                      <Text as='span' fontSize='14px' color='green.darken' fontWeight='400'>{moment(scheduledJob?.inspectionDate).format("MM-DD-YYYY")} / {moment(scheduledJob?.StartTime).format("hh:mm A")} - {moment(scheduledJob?.EndTime).format("hh:mm A")}</Text>
                    </>
                  </Box>
                </Box>
              </Box>
            )
          })}
        </Box>
        <Box p='0 15px 20px' mt='20px' d='flex' justifyContent='center'>
          <Button variant='primaryExtraSmall' mr='10px' p='0 14px' minW='116px' onClick={() => setSelectedEvent(false)}>Close</Button>
          <Button variant='primaryExtraSmall' p='0 14px' minW='116px' onClick={handleAddJob}>Add Job</Button>
        </Box>
      </Box>
    )
  }

  const handleEditJob = (selectedJob) => {
    setActiveReportId(report?.allReports.find((reports) => reports?.scheduleId === `${selectedJob?.id}`)?.id);
    setDateTime({ inspectionDate: new Date(selectedJob.inspectionDate), fromDate: new Date(selectedJob.StartTime), toDate: new Date(selectedJob.EndTime) });
    setActiveJobId(selectedJob.id);
    setEditJob(true);
    setScheduledJobValues({ ...selectedJob, email: selectedJob.clientEmail, phone: selectedJob.clientPhoneNumber });
    setInspectionDate(new Date(selectedJob.inspectionDate));
    setFromDate(new Date(selectedJob.StartTime));
    setToDate(new Date(selectedJob.EndTime));
    openaddEvents();
  }

  const handleDeleteScheduleJobModal = (selectedJobId: string) => {
    setActiveJobId(selectedJobId);
    openDeleteConfirmation(); // Open Confirmation Modal
  }

  const handleDeleteScheduleJob = (selectedJobId: string) => {
    new Promise(() => {
      const data: any = dispatch(deleteScheduleJob({ paramId: selectedJobId }));
      data.then(function (value) {
        if (value.type === "dashboard/delete/schedulejob/fulfilled") {
          closeDeleteConfirmation();
          toastr.success("", "Job Deleted successfully");
        }
      })
    });
  }
  const handleAddJob = () => {
    scheduledJobReset();
    setInspectionDate(new Date());
    setFromDate(new Date());
    setToDate(new Date());
    setEditJob(false);
    setScheduledJobValues(fillFormValues(SCHEDULED_JOB_FORM.default, SCHEDULED_JOB_FORM.default, ['id']));
    openaddEvents();
  }

  const calculatePercentage = (previousData: number, currData: number) => {
    if (previousData && currData) {
      const amountChanged = (previousData - currData);
      const percentageCalc = Math.floor((amountChanged / previousData) * 100);
      return percentageCalc;
    }
  }
  useEffect(() => {
    if (filterStatistics) {
      dispatch(dashboardDetails({ type: filterStatistics }));
    }
    dispatch(getClients());
    dispatch(inspectionDetails({ type: inspectionType }));
  }, [dispatch, filterStatistics, inspectionType]);

  useEffect(() => {
    setRevenueDetail(dashboard.revenueDetails)
    if (dashboard.dashboardDetails?.RevenueGraph) {
      const revenueData: any = Object.entries(dashboard.dashboardDetails?.RevenueGraph);
      revenueData.unshift(["Month", "Sales"]);
      setRevenueGraph(revenueData)
    }
    if (dashboard.dashboardDetails?.ClientGraph) {
      const clientData: any = Object.entries(dashboard.dashboardDetails?.ClientGraph);
      clientData.unshift(["Month", "Sales"]);
      setClientGraph(clientData)
    }
    if (dashboard.dashboardDetails?.ReportGraph) {
      const reportData: any = Object.entries(dashboard.dashboardDetails?.ReportGraph);
      reportData.unshift(["Month", "Sales"]);
      setReportGraph(reportData)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dashboard.dashboardDetails?.ClientGraph, dashboard.dashboardDetails?.RevenueGraph, dashboard.revenueDetails])

  useEffect(() => {
    if (dashboard?.error) {
      const { message } = dashboard.error;
      toastr.error('', message ? message : '');
    }

    return () => { dispatch(setDashboardError(null)); }
  }, [dashboard.error, dispatch]);

  useEffect(() => {
    dispatch(revenueDetails({ type: revenue }));
    dispatch(getScheduleJobs());
    dispatch(getAllReports());
  }, [auth.user?.user_Type, dispatch, revenue]);

  const googleMapRef = useRef(null);
  const searchBoxRef = useRef(null);
  const [googleInitialized, setGoogleInitialized] = useState<any>(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const initGoogleMap = () => {
    if (googleInitialized === false) {
      const googleReference = new window.google.maps.Map(googleMapRef.current, {
        center: { lat: 0, lng: 0 },
        zoom: 1,
        streetViewControl: false,
        mapTypeControl: false,
        fullscreenControl: false
      });
      setGoogleInitialized(googleReference);
    }
  }

  useEffect(() => {
    dispatch(getScheduleJobs());
  }, [dispatch]);

  function geocodeAddress(geocoder, resultsMap, address, jobStatus) {
    geocoder.geocode({ 'address': address }, function (results, status) {
      if (status === 'OK') {
        new google.maps.Marker({
          map: resultsMap,
          position: results[0].geometry.location,
          icon: (jobStatus === 'Pending' && yellowMapMarker) || (jobStatus === 'In Progress' && blueMapMarker) || (jobStatus === 'Completed' && greenMapMarker) || (jobStatus === null && brownMapMarker)
        });
      }
    });
  }

  const formSubmit = (startReport?: boolean, confirmtime?: boolean) => {
    if (startReport && !activeReportId) {
      dispatch(setAddJobValues(activeJobId));
      navigate(`/add-report`);
      return;
    }
    if (startReport && activeReportId) {
      navigate(`/edit-report/${activeReportId}`)
      return;
    }
    if (Object.keys(scheduledJobTouched).length <= 0) {
      toastr.warning("", 'Please first fill the form');
      return;
    }
    if (!scheduledJobValues.inspectionAddress || scheduledJobValues.inspectionAddress === "") {
      toastr.warning("", "Inspection Address is required");
      return;
    }
    if (!scheduledJobValues.city || scheduledJobValues.city === "") {
      toastr.warning("", "City is required");
      return;
    }
    if (!scheduledJobValues.state || scheduledJobValues.state === "") {
      toastr.warning("", "State is required");
      return;
    }
    if (!scheduledJobValues.country || scheduledJobValues.country === "") {
      toastr.warning("", "Country is required");
      return;
    }
    if (!scheduledJobValues.zipcode || scheduledJobValues.zipcode === "") {
      toastr.warning("", "Zipcode is required");
      return;
    }

    // if (Object.keys(scheduledJobErrors).length > 0) {
    //   toastr.warning("", scheduledJobErrors[Object.keys(scheduledJobErrors)[0]]);
    //   return;
    // }
    if (fromDate.getTime() >= toDate.getTime()) {
      toastr.warning("", "Inspection Start Time should be less than Inspection End Time");
      return;
    }
    if (confirmtime && dashboard.scheduledJobs.find((x) => moment(x?.inspectionDate).format("MM-DD-YYYY") === moment(inspectionDate).format("MM-DD-YYYY") && new Date(x?.EndTime).getTime() >= fromDate.getTime() && new Date(x?.StartTime).getTime() <= toDate.getTime())) {
      openSameTimeConfirmation();
      return;
    }
    const address1 = scheduledJobValues.inspectionAddress.toLowerCase() + ' ' + scheduledJobValues.city.toLowerCase() + ' ' + scheduledJobValues.state.toLowerCase() + ' ' + scheduledJobValues.country.toLowerCase() + ' ' + scheduledJobValues.zipcode;
    var geocoder = new google.maps.Geocoder();
    let mapResult = null;
    geocoder.geocode({ 'address': address1 }, (results, status) => {
      if (status === 'OK') {
        mapResult = results[0].geometry.location;
        if (!editJob) {
          new Promise(() => {
            const data: any = dispatch(createScheduleJob({ payload: { ...scheduledJobValues, clientEmail: scheduledJobValues.email, clientPhoneNumber: scheduledJobValues.phone, inspectionDate, StartTime: fromDate, EndTime: toDate } }));
            data.then(function (value) {
              if (value.type === "dashboard/create/schedulejob/fulfilled") {
                closeaddEvents();
                closeSameTimeConfirmation();
                toastr.success("", "Job created successfully");
              }
            })
          })
        }
      } else {
        toastr.error('', 'Please enter a valid address');
        return;
      }
      if (mapResult) {
        const googleMap = googleInitialized;
        const address = scheduledJobValues.city.toLowerCase() + scheduledJobValues.state.toLowerCase() + scheduledJobValues.country.toLowerCase() + scheduledJobValues.zipcode;
        geocodeAddress(geocoder, googleMap, address, 'Pending');
      }
    });
    const service = new google.maps.DistanceMatrixService();
    const origin1 = scheduledJobValues.inspectionAddress + ", " + scheduledJobValues.city + ", " + scheduledJobValues.state;
    const destinationA = auth.company.companyAddress + ", " + auth.company.city + ", " + auth.company.state;
    const request = {
      origins: [origin1],
      destinations: [destinationA],
      travelMode: google.maps.TravelMode.DRIVING,
      unitSystem: google.maps.UnitSystem.METRIC,
      avoidHighways: false,
      avoidTolls: false,
    };
    service.getDistanceMatrix(request).then((response) => {
      // put response
      dispatch(setTravelDistance(response?.rows?.[0].elements?.[0]?.distance?.text))
    });
  }


  const updateForm = (confirmtime?: boolean) => {
    if (Object.keys(scheduledJobTouched).length <= 0 && moment(inspectionDate).format("MM-DD-YYYY") === moment(dateTime.inspectionDate).format("MM-DD-YYYY") && dateTime.fromDate.getTime() === fromDate.getTime() && dateTime.toDate.getTime() === toDate.getTime()) {
      toastr.warning("", 'Please first update the form');
      return;
    }
    if (!scheduledJobValues.inspectionAddress || scheduledJobValues.inspectionAddress === "") {
      toastr.warning("", "Inspection Address is required");
      return;
    }
    if (!scheduledJobValues.city || scheduledJobValues.city === "") {
      toastr.warning("", "City is required");
      return;
    }
    if (!scheduledJobValues.state || scheduledJobValues.state === "") {
      toastr.warning("", "State is required");
      return;
    }
    if (!scheduledJobValues.country || scheduledJobValues.country === "") {
      toastr.warning("", "Country is required");
      return;
    }
    if (!scheduledJobValues.zipcode || scheduledJobValues.zipcode === "") {
      toastr.warning("", "Zipcode is required");
      return;
    }
    // if (Object.keys(scheduledJobErrors).length > 0) {
    //   toastr.warning("", scheduledJobErrors[Object.keys(scheduledJobErrors)[0]]);
    //   return;
    // }
    if (fromDate.getTime() >= toDate.getTime()) {
      toastr.warning("", "Inspection Start Time should be less than Inspection End Time");
      return;
    }
    if (confirmtime && dashboard.scheduledJobs.find((x) => x?.id !== scheduledJobValues.id && moment(x?.inspectionDate).format("MM-DD-YYYY") === moment(inspectionDate).format("MM-DD-YYYY") && new Date(x?.EndTime).getTime() >= fromDate.getTime() && new Date(x?.StartTime).getTime() <= toDate.getTime())) {
      openSameTimeConfirmation();
      return;
    }
    const address = scheduledJobValues.inspectionAddress.toLowerCase() + ' ' + scheduledJobValues.city?.toLowerCase() + ' ' + scheduledJobValues.state?.toLowerCase() + ' ' + scheduledJobValues.country?.toLowerCase() + ' ' + scheduledJobValues.zipcode;
    var geocoder = new google.maps.Geocoder();
    geocoder.geocode({ 'address': address }, (results, status) => {
      if (status === 'OK') {
        new Promise(() => {
          const data1: any = dispatch(updateScheduledJob({ jobId: scheduledJobValues.id, payload: { ...scheduledJobValues, clientEmail: scheduledJobValues.email, clientPhoneNumber: scheduledJobValues.phone, inspectionDate, StartTime: fromDate, EndTime: toDate } }));
          data1.then(function (value) {
            if (value.type === "dashboard/update/scheduleJob/fulfilled") {
              closeaddEvents();
              closeSameTimeConfirmation();
              setEditJob(false);
              toastr.success("", "Job updated successfully");
            }
          })
        })
      } else {
        toastr.error('', 'Please enter a valid address');
        return;
      }
    });
    //Distance Matrix API
    const service = new google.maps.DistanceMatrixService();
    const origin1 = scheduledJobValues.inspectionAddress + ", " + scheduledJobValues.city + ", " + scheduledJobValues.state;
    const destinationA = auth.company.companyAddress + ", " + auth.company.city + ", " + auth.company.state;
    const request = {
      origins: [origin1],
      destinations: [destinationA],
      travelMode: google.maps.TravelMode.DRIVING,
      unitSystem: google.maps.UnitSystem.METRIC,
      avoidHighways: false,
      avoidTolls: false,
    };
    service.getDistanceMatrix(request).then((response) => {
      // put response
      setTravelDistance(response?.rows?.[0].elements?.[0]?.distance?.text)
    });
  }

  const [loadMap, setLoadMap] = useState(false);

  useEffect(() => {
    loadGoogleMapScript(() => {
      setLoadMap(true)
    });
  }, []);


  useEffect(() => {
    if (loadMap && dashboard.scheduledJobs) {
      const googleReference = new window.google.maps.Map(googleMapRef.current, {
        center: { lat: 0, lng: 0 },
        zoom: 1,
        streetViewControl: false,
        mapTypeControl: false,
        fullscreenControl: false
      });
      setGoogleInitialized(googleReference);
    }
  }, [dashboard.scheduledJobs, loadMap]);


  useEffect(() => {
    if (loadMap) {
      initGoogleMap();
    }
  }, [initGoogleMap, loadMap]);

  useEffect(() => {
    if (loadMap) {
      let autocomplete: google.maps.places.Autocomplete;
      const autocompleteInput = document.getElementById('autocomplete') as HTMLInputElement;
      autocomplete = new google.maps.places.Autocomplete(autocompleteInput);

      // When a place is selected from the autocomplete dropdown
      autocomplete.addListener('place_changed', () => {
        const inspectionAddressValues = googleMapAutocomplete(autocomplete);

        if (inspectionAddressValues) {
          setScheduledJobValues({
            id: scheduledJobValues.id,
            clientName: scheduledJobValues.clientName,
            clientLastName: scheduledJobValues.clientLastName,
            email: scheduledJobValues.email,
            phone: scheduledJobValues.phone,
            city: inspectionAddressValues.addressCity,
            state: inspectionAddressValues.addressState,
            country: inspectionAddressValues.addressCountry,
            zipcode: inspectionAddressValues.addressZipcode || "",
            inspectionAddress: inspectionAddressValues.streetNumber
          });
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scheduledJobValues.inspectionAddress]);


  useEffect(() => {
    if (loadMap && googleInitialized) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      const googleMap = googleInitialized;
      var geocoder = new google.maps.Geocoder();
      const scheduledjobs = dashboard.scheduledJobs;
      if (dashboard.scheduledJobs.length) {
        let i = 0;

        const abc = setInterval(() => {
          mapFunction();

          if (i >= scheduledjobs.length) {
            clearInterval(abc);
          }

          i++;
        }, 400);

        const mapFunction = () => {
          const job = scheduledjobs[i];
          const address = job?.city.toLowerCase() + job?.state.toLowerCase() + job?.country.toLowerCase() + job?.zipcode;


          if (address) {
            geocodeAddress(geocoder, googleMap, address, job?.status);
          }
        }
      } else {
        const address = auth?.company?.city.toLowerCase() + auth?.company?.state.toLowerCase() + auth?.company?.zipcode;
        const jobStatus = null;
        geocodeAddress(geocoder, googleMap, address, jobStatus);
      }

      setTimeout(() => {
        const searchBox = new google.maps.places.SearchBox(searchBoxRef.current);
        googleMap.controls[google.maps.ControlPosition.TOP_LEFT].push(searchBoxRef.current);
        searchBox.addListener('places_changed', function () {
          const places = searchBox.getPlaces();
          if (places.length === 0) {
            return;
          }
          const bounds = new google.maps.LatLngBounds();
          places.forEach(function (place) {
            if (!place.geometry) {
              return;
            }
            if (place.geometry.viewport) {
              bounds.union(place.geometry.viewport);
            } else {
              bounds.extend(place.geometry.location);
            }
          });
          googleMap.fitBounds(bounds);
        });
      }, 1500);
    }
  }, [loadMap, dashboard.scheduledJobs, googleInitialized]);

  return (
    <>
      {dashboard.loading.length !== 0 && <Loader />}
      <Box p='26px 20px 20px' h='calc(100vh - 70px)' overflow='auto'>
        <Flex alignItems='center' p='0 0 20px'>
          <Text as='h1' fontSize={{ sm: '24px', xxl: '26px' }} lineHeight='30px' fontWeight='600' color='black.lighten'>Dashboard</Text>
          <Select bg='white.primary' fontSize='lg' border='none' variant='Simple' w='140px' p='0' h='30px' ml='30px' borderRadius='3px' icon={<Box fontSize='14px'><BsFillCaretDownFill /></Box>} value={filterStatistics} onChange={(e) => setFilterStatistics(e.target.value)}>
            <option value='monthly'>Monthly</option>
            <option value='weekly'>Weekly</option>
          </Select>
        </Flex>
        <Box d='flex' flexWrap='wrap' justifyContent='space-between' mb={{ sm: '20px', xxl: '30px' }}>
          <Box w={{ sm: '100%', xl: '70%' }}>
            <SimpleGrid columns={{ sm: 2, lg: 3 }} spacing={{ sm: '15px', xxl: '30px' }} mb={{ sm: '20px', xxl: '30px' }}>
              <Box bg='white.primary' borderRadius='16px' alignItems='center' p={{ sm: '5px 14px 11px', xxl: '5px 20px 11px' }} justifyContent='space-between' w='100%'>
                <Box w='100%'>
                  <Chart
                    chartType="LineChart"
                    className='statistics-line-chart'
                    data={revenueGraph}
                    options={options}
                  />
                </Box>
                <Box d='flex' alignItems='flex-start'>
                  <Box d='inline-flex' alignItems='flex-start' width='auto'>
                    <Box>
                      <Text as='h3' fontSize={{ sm: '22px', xxl: '24px' }} lineHeight='26px' mb='2px' fontWeight='600' color='black.lighten'>
                        ${Math.trunc(dashboard?.dashboardDetails?.totalAmount) || 0}
                      </Text>
                      <Text as='span' fontSize='14px' color='gray.darken'>{filterStatistics.charAt(0).toUpperCase() + filterStatistics.slice(1)} Sale</Text>
                    </Box>
                  </Box>
                  <Box ml='auto' pr='5px' d='inline-flex' alignItems='center' textAlign='end'>
                    <Text as='span' fontSize='18px' color={calculatePercentage(dashboard?.dashboardDetails?.previousTotalAmount, dashboard?.dashboardDetails?.totalAmount) <= 0 ? 'red.lighten2' : 'green.ligthen3'} ml='auto' pl='8px'>
                      {calculatePercentage(dashboard?.dashboardDetails?.previousTotalAmount, dashboard?.dashboardDetails?.totalAmount) || 0}%
                    </Text>
                    <Icon as={calculatePercentage(dashboard?.dashboardDetails?.previousTotalAmount, dashboard?.dashboardDetails?.totalAmount) <= 0 ? HiArrowDown : HiArrowUp} color={calculatePercentage(dashboard?.dashboardDetails?.previousTotalAmount, dashboard?.dashboardDetails?.totalAmount) <= 0 ? 'red.lighten2' : 'green.ligthen4'} ml='2px' fontSize='16px'></Icon>
                  </Box>
                </Box>
              </Box>
              <Box bg='white.primary' borderRadius='16px' alignItems='center' p={{ sm: '5px 14px 11px', xxl: '5px 20px 11px' }} justifyContent='space-between' w='100%'>
                <Box w='100%'>
                  <Chart
                    chartType="LineChart"
                    className='statistics-line-chart'
                    data={reportGraph}
                    options={options}
                  />
                </Box>
                <Box d='flex' alignItems='flex-start'>
                  <Box d='inline-flex' alignItems='flex-start'>
                    <Box>
                      <Text as='h3' fontSize={{ sm: '22px', xxl: '24px' }} lineHeight='26px' mb='2px' fontWeight='600' color='black.lighten'>
                        {dashboard?.dashboardDetails?.reportDetails?.count || 0}
                      </Text>
                      <Text as='span' fontSize='14px' color='gray.darken'>Total Report</Text>
                    </Box>
                  </Box>
                  <Box ml='auto' pr='5px' d='inline-flex' alignItems='center' textAlign='center'>
                    <Text as='span' fontSize='18px' color={calculatePercentage(dashboard?.dashboardDetails?.previousreportDetails?.count, dashboard?.dashboardDetails?.reportDetails?.count) <= 0 ? 'red.lighten2' : 'green.ligthen3'} ml='auto' pl='8px'>
                      {calculatePercentage(dashboard?.dashboardDetails?.previousreportDetails?.count, dashboard?.dashboardDetails?.reportDetails?.count) || 0}%
                    </Text>
                    <Icon as={calculatePercentage(dashboard?.dashboardDetails?.previousreportDetails?.count, dashboard?.dashboardDetails?.reportDetails?.count) <= 0 ? HiArrowDown : HiArrowUp} color={calculatePercentage(dashboard?.dashboardDetails?.previousreportDetails?.count, dashboard?.dashboardDetails?.reportDetails?.count) <= 0 ? 'red.lighten2' : 'green.ligthen4'} ml='2px' fontSize='16px'></Icon>
                  </Box>
                </Box>
              </Box>
              <Box bg='white.primary' borderRadius='16px' alignItems='center' p={{ sm: '5px 14px 11px', xxl: '5px 20px 11px' }} justifyContent='space-between' w='100%'>
                <Box w='100%'>
                  <Chart
                    chartType="LineChart"
                    className='statistics-line-chart'
                    data={clientGraph}
                    options={options2}
                  />
                </Box>
                <Box d='flex' alignItems='flex-start'>
                  <Box d='inline-flex' alignItems='flex-start'>
                    <Box>
                      <Text as='h3' fontSize={{ sm: '22px', xxl: '24px' }} lineHeight='26px' mb='2px' fontWeight='600' color='black.lighten'>
                        +{dashboard?.dashboardDetails?.clientdetails?.count || 0}
                      </Text>
                      <Text as='span' fontSize='14px' color='gray.darken'>New Client</Text>
                    </Box>
                  </Box>
                  <Box ml='auto' pr='5px' d='inline-flex' alignItems='center' textAlign='end'>
                    <Text as='span' fontSize='18px' color={calculatePercentage(dashboard?.dashboardDetails?.previousclientdetails?.count, dashboard?.dashboardDetails?.clientdetails?.count) <= 0 ? 'red.lighten2' : 'green.ligthen3'} ml='auto' pl='8px'>
                      {calculatePercentage(dashboard?.dashboardDetails?.previousclientdetails?.count, dashboard?.dashboardDetails?.clientdetails?.count) || 0}%
                    </Text>
                    <Icon as={calculatePercentage(dashboard?.dashboardDetails?.previousclientdetails?.count, dashboard?.dashboardDetails?.clientdetails?.count) <= 0 ? HiArrowDown : HiArrowUp} color={calculatePercentage(dashboard?.dashboardDetails?.previousclientdetails?.count, dashboard?.dashboardDetails?.clientdetails?.count) <= 0 ? 'red.lighten2' : 'green.ligthen4'} ml='2px' fontSize='16px'></Icon>
                  </Box>
                </Box>
              </Box>
            </SimpleGrid>
            <Box bg='white.primary' borderRadius='12px' w='100%' overflow='hidden' mb={{ sm: '20px', xxl: '30px' }} minH='294px'>
              <Box bg='orange.primary' d='flex' alignItems='center' mb='10px' p='7px 11px 7px 18px'>
                <Text as='h3' fontSize={{ sm: '22px', xxl: '24px' }} lineHeight='1' fontWeight='600' color='white.primary' d='inline-block'>Revenue</Text>
                <Box ml='auto'>
                  <Select bg='white.primary' display='inline-block' fontSize='lg' border='none' variant='Simple' w='140px' p='0' h='30px' ml='30px' borderRadius='3px' icon={<Box fontSize='14px'><BsFillCaretDownFill /></Box>} value={revenue} onChange={(e) => setRevenue(e.target.value)}>
                    <option value='yearly'>Yearly</option>
                    <option value='monthly'>Monthly</option>
                    <option value='weekly'>Weekly</option>
                  </Select>
                </Box>
              </Box>
              <Chart
                chartType="LineChart"
                width="100%"
                className='revenue-line-chart'
                data={revenueDetail}
                options={options3}
              />
            </Box>
            <Box bg='white.primary' borderRadius='12px' overflow='hidden'>
              <Box bg='orange.primary' d='flex' alignItems='center' p='7px 11px 7px 18px'>
                <Text as='h3' fontSize={{ sm: '22px', xxl: '24px' }} lineHeight='1' color='white.primary' fontWeight='600'>Scheduled Job</Text>
                <Button ml='auto' minW='95px' bg='white.primary' variant='primary2' color='black.lighten' height='30px' onClick={handleAddJob}>Add Job</Button>
              </Box>
              <Box pos='relative' mt='10px'>
                <Calendar
                  localizer={localizer}
                  events={myEventsList}
                  startAccessor="start"
                  endAccessor="end"
                  style={{ height: 376 }}
                  onSelectEvent={(x) => handleSelectedEvent(x)}
                />
                {selectedEvent && <Popover />}
              </Box>
            </Box>
          </Box>
          <Box w={{ sm: '100%', xl: '28%' }} d='flex' flexWrap='wrap' justifyContent='space-between' mt={{ sm: '20px', xl: '0' }}>
            <Box w={{ sm: 'calc(100% - 335px)', lg: 'calc(100% - 365px)', xl: '100%' }} h={{ sm: '450px', xl: '658px', xxl: '580px' }} mb={{ sm: '20px', xxl: '30px' }} borderRadius={{ sm: '12px', xl: '12px 12px 0 0' }} overflow='hidden' pos='relative'>
              <Box h='100%' w='100%' >
                {loadMap && <>
                  <input className='google-map-input' placeholder='Search' style={{ display: 'none' }} ref={searchBoxRef} type="text" />
                  <div
                    ref={googleMapRef}
                    style={{ width: '100%', height: '100%' }}></div>
                </>}
              </Box>
              {showInspectionDetail && <Box pos='absolute' bottom='0' left='0' right='0' zIndex={1} bg='white.primary' borderRadius={{ sm: '12px', xl: '12px 12px 0 0' }}>
                <Box p='20px 30px' d='flex' alignItems='flex-start' borderBottom='1px solid' borderColor='gray.light3'>
                  <Box>
                    <Text as='h3' fontSize={{ sm: '22px', xxl: '24px' }} color='black.lighten' mb='8px' fontWeight='600'>Inspection Detail</Text>
                    <Box w='100%' color={(inspectionDetail?.status === 'In Progress' && 'blue.dark') || (inspectionDetail?.status === 'Completed' && 'green.ligthen3') || (inspectionDetail?.status === 'Pending' && 'yellow.lighten')} d='inline-flex' alignItems='center'>
                      <Icon as={(inspectionDetail?.status === 'In Progress' && HiDotsCircleHorizontal) || (inspectionDetail?.status === 'Completed' && IoIosCheckmarkCircle) || (inspectionDetail?.status === 'Pending' && HiDotsCircleHorizontal)} mr='5px'></Icon>
                      <Text fontSize='14px'>{inspectionDetail?.status}</Text>
                    </Box>
                  </Box>
                  <Icon color='gray.primary' fontSize='22px' as={IoMdClose} cursor='pointer' m='4px 0 0 auto' onClick={() => setShowInspectionDetail(!showInspectionDetail)}></Icon>
                </Box>
                <SimpleGrid p='12px 30px' columns={2} spacing='18px 5px'>
                  <Text as='span' fontSize='18px' color='black.lighten'>Client</Text>
                  <Text as='span' fontSize='16px' color='gray.primary'>{inspectionDetail?.clientName} {inspectionDetail?.clientLastName}</Text>
                  <Text as='span' fontSize='18px' color='black.lighten'>Phone Number</Text>
                  <Text as='span' fontSize='16px' color='gray.primary'>{inspectionDetail?.clientPhoneNumber}</Text>
                  <Text as='span' fontSize='18px' color='black.lighten'>Email</Text>
                  <Text as='span' fontSize='16px' color='gray.primary'>{inspectionDetail?.clientEmail}</Text>
                  <Text as='span' fontSize='18px' color='black.lighten'>Address</Text>
                  <Text as='span' fontSize='16px' color='gray.primary'>{inspectionDetail?.city} {inspectionDetail?.state} {inspectionDetail?.country}, {inspectionDetail?.zipcode}</Text>
                  <Text as='span' fontSize='18px' color='black.lighten'>Date / Time</Text>
                  <Text as='p'>
                    <Text as='span' fontSize='16px' color='gray.primary'>{moment(inspectionDetail?.inspectionDate).format("MM-DD-YYYY")} / </Text>
                    <Text as='span' fontSize='16px' color='gray.primary'>{moment(inspectionDetail?.StartTime).format("hh:mm A")} - </Text>
                    <Text as='span' fontSize='16px' color='gray.primary'>{moment(inspectionDetail?.EndTime).format("hh:mm A")}</Text>
                  </Text>
                </SimpleGrid>
                <Button variant='transparent' w='100%' fontSize='16px' color='black.lighten' m='10px 0' onClick={() => setShowInspectionDetail(!showInspectionDetail)}>Close</Button>
              </Box>}
            </Box>
            <Box w={{ sm: '320px', lg: '350px', xl: '100%' }} p={{ sm: '20px 15px', xxl: '15px 10px' }} bg='white.primary' borderRadius='12px' h='100%' maxH={{ sm: '450px', xl: '290px', xxl: '314px' }} overflow='auto' css={css({
              '::-webkit-scrollbar-track': {
                boxShadow: 'none',
                border: 'none',
                borderRadius: '10px',
                background: '#E0E0E0'
              },
              '::-webkit-scrollbar-thumb': {
                background: '#C5C7D0',
                borderRadius: '10px'
              },
              '::-webkit-scrollbar': {
                width: '7px'
              }
            })}>
              {dashboard.scheduledJobs.map((scheduledJob: any, id: number) => {
                return (
                  <Box d='flex' key={id} alignItems='center' borderBottom='1px solid' borderColor='gray.light3' pb={{ sm: '10px', xxl: '15px' }} mb={{ sm: '10px', xxl: '15px' }}>
                    <Box w='100%' d='flex'>
                      <Box pr={{ sm: '4px', xxl: '10px' }} w='calc(100% - 29px)'>
                        <Text as='h3' fontSize={{ sm: '16px', xxl: '18px' }} lineHeight='18px' color='black.lighten' className='line-clamp-2' fontWeight='400' mb='8px'>{scheduledJob?.city} {scheduledJob?.state} {scheduledJob?.country}, {scheduledJob?.zipcode}</Text>
                        <Box w='100%' color={(scheduledJob.status === 'In Progress' && 'blue.dark') || (scheduledJob.status === 'Completed' && 'green.ligthen3') || (scheduledJob.status === 'Pending' && 'yellow.lighten')} d='inline-flex' alignItems='center'>
                          <Icon as={(scheduledJob.status === 'In Progress' && HiDotsCircleHorizontal) || (scheduledJob.status === 'Completed' && IoIosCheckmarkCircle) || (scheduledJob.status === 'Pending' && HiDotsCircleHorizontal)} mr='5px'></Icon>
                          <Text fontSize={{ sm: '13px', xxl: '14px' }}>{scheduledJob.status}</Text>
                        </Box>
                      </Box>
                      <Box ml='auto'>
                        <Menu>
                          <MenuButton as={Button} variant='transparent' h='auto' color='gray.primary' minW='25px'>
                            <Icon ml='auto' fontSize='20px' as={HiDotsVertical}></Icon>
                          </MenuButton>
                          <MenuList p='0' border='1px solid' borderColor='gray.light3' maxW='70px'>
                            <MenuItem _focus={{ bg: 'gray.light2' }} _hover={{ bg: 'gray.light2' }} borderBottom='2px solid' borderColor='gray.light3' onClick={() => handleInspectionDetail(scheduledJob)}>View</MenuItem>
                            <MenuItem _focus={{ bg: 'gray.light2' }} _hover={{ bg: 'gray.light2' }} borderBottom='2px solid' borderColor='gray.light3' onClick={() => handleEditJob(scheduledJob)}>Edit</MenuItem>
                            <MenuItem _focus={{ bg: 'gray.light2' }} _hover={{ bg: 'gray.light2' }} borderBottom='2px solid' borderColor='gray.light3' onClick={() => handleDeleteScheduleJobModal(scheduledJob.id)}>Delete</MenuItem>
                          </MenuList>
                        </Menu>
                      </Box>
                    </Box>
                  </Box>
                )
              })}
            </Box>
          </Box>
        </Box>
        <Box d='flex' justifyContent='space-between' flexWrap='wrap'>
          <Box bg='white.primary' borderRadius='12px' overflow='hidden' w={{ sm: '100%', lg: '54%' }}>
            <Box bg='orange.primary' d='flex' alignItems='center' mb='10px' justifyContent='space-between' p='7px 11px 7px 18px'>
              <Text as='h3' fontSize={{ sm: '22px', xxl: '24px' }} lineHeight='1' fontWeight='600' color='white.primary' display='inline-block'>Inspection</Text>
              <Box ml={{ sm: '20px', xxl: '40px' }}>
                <Select bg='white.primary' variant='Simple' border='none' fontSize='lg' w='152px' h='30px' ml='30px' icon={<Box fontSize='14px'><BsFillCaretDownFill /></Box>} value={inspectionType} onChange={(e) => setInspectionType(e.target.value)}>
                  <option value='yearly'>Yearly</option>
                  <option value='monthly'>Monthly</option>
                  <option value='weekly'>Weekly</option>
                </Select>
              </Box>
            </Box>
            <Chart
              chartType="LineChart"
              width="100%"
              height="440px"
              data={dashboard.inspectionDetails}
              options={options4}
            />
          </Box>
          <Box bg='white.primary' borderRadius='12px' p={{ sm: '20px 15px 15px', xxl: '20px' }} w={{ sm: '100%', lg: '44%' }} mt={{ sm: '20px', lg: '0' }}>
            <Box d='flex' alignItems='flex-end'>
              <Box>
                <Text as='h3' fontSize={{ sm: '22px', xxl: '24px' }} fontWeight='600' color='black.lighten' display='inline-block'>Client Details</Text>
                <Box w='100%' color='green.ligthen3' d='inline-flex' alignItems='center'>
                  <Icon as={IoIosCheckmarkCircle} mr='5px'></Icon>
                  <Text fontSize='14px' color='gray.primary'>{dashboard.clients.length} Inspection done this month</Text>
                </Box>
              </Box>
              <Text as='span' fontSize='14px' color='gray.light11' ml='auto' mb='2px'>
                <Link to='/reports'>
                  View More
                </Link>
              </Text>
            </Box>
            <DataTable
              className='hi-datatable-component customize-scrollbar'
              data={tableDataItems}
              columns={columns}
              pagination
              highlightOnHover
              fixedHeader
              fixedHeaderScrollHeight='378px'
            />
          </Box>
        </Box>
      </Box >
      <Modal isOpen={addEvents} onClose={closeaddEvents} size='xl' scrollBehavior='inside' autoFocus={false}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader p='30px 38px 15px' fontSize='21px' lineHeight='26px' color='black.lighten' fontWeight='600'>{editJob ? 'Update Job' : 'Add Job'}</ModalHeader>
          <Icon color='black.primary' fontSize='24px' as={IoMdClose} onClick={closeaddEvents} cursor='pointer' position='absolute' top='29px' right='29px'></Icon>
          <ModalBody p='8px 38px'>
            <Box>
              <Text fontSize={{ sm: '16px', xxl: 'xxl' }} mb='4px' fontWeight='600'>Schedule Inspection</Text>
              <SimpleGrid columns={2} spacing='15px 10px'>
                <Box d='flex' alignItems='center' justifyContent='space-between' flexWrap='wrap'>
                  <Text w='100%' as='label' fontSize={{ sm: '16px', xxl: 'xxl' }} mb='4px' color='black.lighten' textAlign='left'>Date</Text>
                  <DateTimePicker minDate={new Date(1900, 11, 32)} disableClock={true} className='dateTime-picker add-job-date-calender inspection-datePicker w-100 datepicker-ui' name='inspectionDate' value={inspectionDate} onChange={setInspectionDate} format='MM-dd-yy' />
                </Box>
                <Box display='flex' alignItems='center' justifyContent='space-between' flexWrap='wrap'>
                  <Text w='100%' as='label' fontSize={{ sm: '16px', xxl: 'xxl' }} mb='4px' color='black.lighten' textAlign='left'  >From</Text>
                  <DateTimePicker minDate={new Date(1900, 11, 32)} disableClock={true} className='dateTime-picker inspection-datePicker w-100 date-picker' name='inspectionFrom' value={fromDate} onChange={setFromDate} disableCalendar={true} format="MM-dd-yy hh:mm a" />
                </Box>
                <Box display='flex' alignItems='center' justifyContent='space-between' flexWrap='wrap'>
                  <Text w='100%' as='label' fontSize={{ sm: '16px', xxl: 'xxl' }} mb='4px' color='black.lighten' textAlign='left' >To</Text>
                  <DateTimePicker minDate={new Date(1900, 11, 32)} disableClock={true} className='dateTime-picker inspection-datePicker add-job-to-calender w-100 date-picker' name='inspectionTo' value={toDate} onChange={setToDate} disableCalendar={true} format="MM-dd-yy hh:mm a" />
                </Box>
              </SimpleGrid>
            </Box>
            <Box mt='15px'>
              <Text fontSize={{ sm: '16px', xxl: 'xxl' }} mb='4px' fontWeight='600'>Client Details</Text>
              <SimpleGrid columns={2} spacing='15px 10px'>
                <FormControl
                  isRequired
                  d="inline-flex"
                  alignItems="center"
                  justifyContent="space-between"
                  flexWrap={{ sm: "wrap", xxl: "nowrap" }}
                >
                  <Box d='flex' alignItems='center' justifyContent='space-between' flexWrap='wrap'>
                    <FormLabel w='100%' as='label' fontSize={{ sm: '16px', xxl: 'xxl' }} mb='4px' color='black.lighten' textAlign='left'>First Name</FormLabel>
                    <Input w='100%' maxW='unset' type="text" variant='Bordered' name='clientName' value={scheduledJobValues.clientName} onChange={scheduledJobChange} isInvalid={scheduledJobTouched['clientName'] && scheduledJobErrors['clientName']} />
                  </Box>
                </FormControl>
                <FormControl
                  isRequired
                  d="inline-flex"
                  alignItems="center"
                  justifyContent="space-between"
                  flexWrap={{ sm: "wrap", xxl: "nowrap" }}
                >
                  <Box d='flex' alignItems='center' justifyContent='space-between' flexWrap='wrap'>
                    <FormLabel w='100%' as='label' fontSize={{ sm: '16px', xxl: 'xxl' }} mb='4px' color='black.lighten' textAlign='left'>Last Name</FormLabel>
                    <Input w='100%' maxW='unset' type="text" variant='Bordered' name='clientLastName' value={scheduledJobValues.clientLastName} onChange={scheduledJobChange} isInvalid={scheduledJobTouched['clientLastName'] && scheduledJobErrors['clientLastName']} />
                  </Box>
                </FormControl>
                <FormControl
                  isRequired
                  d="inline-flex"
                  alignItems="center"
                  justifyContent="space-between"
                  flexWrap={{ sm: "wrap", xxl: "nowrap" }}
                >
                  <Box d='flex' alignItems='center' justifyContent='space-between' flexWrap='wrap'>
                    <FormLabel w='100%' as='label' fontSize={{ sm: '16px', xxl: 'xxl' }} mb='4px' color='black.lighten' textAlign='left'>Email</FormLabel>
                    <Input w='100%' maxW='unset' type="text" variant='Bordered' name='email' value={scheduledJobValues.email} onChange={scheduledJobChange} isInvalid={scheduledJobTouched['email'] && scheduledJobErrors['email']} />
                  </Box>
                </FormControl>
                <FormControl
                  isRequired
                  d="inline-flex"
                  alignItems="center"
                  justifyContent="space-between"
                  flexWrap={{ sm: "wrap", xxl: "nowrap" }}
                >
                  <Box d='flex' alignItems='center' justifyContent='space-between' flexWrap='wrap'>
                    <FormLabel w='100%' as='label' fontSize={{ sm: '16px', xxl: 'xxl' }} mb='4px' color='black.lighten' textAlign='left'>Phone Number</FormLabel>
                    <Input w='100%' maxW='unset' type="number" variant='Bordered' name='phone' value={scheduledJobValues.phone} onChange={scheduledJobChange} isInvalid={scheduledJobTouched['phone'] && scheduledJobErrors['phone']} />
                  </Box>
                </FormControl>
              </SimpleGrid>
            </Box>
            <Box mt='15px'>
              <Text fontSize={{ sm: '16px', xxl: 'xxl' }} mb='4px' fontWeight='600'>Inspection Location</Text>
              <SimpleGrid columns={2} spacing='15px 10px'>
                <FormControl
                  isRequired
                  d="inline-flex"
                  alignItems="center"
                  justifyContent="space-between"
                  flexWrap={{ sm: "wrap", xxl: "nowrap" }}
                >
                  <Box d='flex' alignItems='center' justifyContent='space-between' flexWrap='wrap'>
                    <FormLabel w='100%' as='label' fontSize={{ sm: '16px', xxl: 'xxl' }} mb='4px' color='black.lighten' textAlign='left'>Inspection Address</FormLabel>
                    <Input id='autocomplete' autoComplete='off' w='100%' maxW='unset' type="text" variant='Bordered' name='inspectionAddress' value={scheduledJobValues.inspectionAddress} onChange={scheduledJobChange} isInvalid={scheduledJobTouched['inspectionAddress'] && scheduledJobErrors['inspectionAddress']} />
                  </Box>
                </FormControl>
                <FormControl
                  isRequired
                  d="inline-flex"
                  alignItems="center"
                  justifyContent="space-between"
                  flexWrap={{ sm: "wrap", xxl: "nowrap" }}
                >
                  <Box d='flex' alignItems='center' justifyContent='space-between' flexWrap='wrap'>
                    <FormLabel w='100%' as='label' fontSize={{ sm: '16px', xxl: 'xxl' }} mb='4px' color='black.lighten' textAlign='left'>City</FormLabel>
                    <Input id='city' w='100%' maxW='unset' type="text" variant='Bordered' name='city' value={scheduledJobValues.city} onChange={scheduledJobChange} isInvalid={scheduledJobTouched['city'] && scheduledJobErrors['city']} />
                  </Box>
                </FormControl>
                <FormControl
                  isRequired
                  d="inline-flex"
                  alignItems="center"
                  justifyContent="space-between"
                  flexWrap={{ sm: "wrap", xxl: "nowrap" }}
                >
                  <Box d='flex' alignItems='center' justifyContent='space-between' flexWrap='wrap'>
                    <FormLabel w='100%' as='label' fontSize={{ sm: '16px', xxl: 'xxl' }} mb='4px' color='black.lighten' textAlign='left'>State</FormLabel>
                    <Input id='state' w='100%' maxW='unset' type="text" variant='Bordered' name='state' value={scheduledJobValues.state} onChange={scheduledJobChange} isInvalid={scheduledJobTouched['state'] && scheduledJobErrors['state']} />
                  </Box>
                </FormControl>
                <FormControl
                  isRequired
                  d="inline-flex"
                  alignItems="center"
                  justifyContent="space-between"
                  flexWrap={{ sm: "wrap", xxl: "nowrap" }}
                >
                  <Box d='flex' alignItems='center' justifyContent='space-between' flexWrap='wrap'>
                    <FormLabel w='100%' as='label' fontSize={{ sm: '16px', xxl: 'xxl' }} mb='4px' color='black.lighten' textAlign='left'>Country</FormLabel>
                    <Input id='country' w='100%' maxW='unset' type="text" variant='Bordered' name='country' value={scheduledJobValues.country} onChange={scheduledJobChange} isInvalid={scheduledJobTouched['country'] && scheduledJobErrors['country']} />
                  </Box>
                </FormControl>
                <FormControl
                  isRequired
                  d="inline-flex"
                  alignItems="center"
                  justifyContent="space-between"
                  flexWrap={{ sm: "wrap", xxl: "nowrap" }}
                >
                  <Box d='flex' alignItems='center' justifyContent='space-between' flexWrap='wrap'>
                    <FormLabel w='100%' as='label' fontSize={{ sm: '16px', xxl: 'xxl' }} mb='4px' color='black.lighten' textAlign='left'>Zip Code</FormLabel>
                    <Input id='zipcode' w='100%' maxW='unset' type="text" variant='Bordered' name='zipcode' value={scheduledJobValues.zipcode} onChange={scheduledJobChange} isInvalid={scheduledJobTouched['zipcode'] && scheduledJobErrors['zipcode']} />
                  </Box>
                </FormControl>
              </SimpleGrid>
            </Box>
          </ModalBody>
          <ModalFooter justifyContent='space-between' p='30px 38px'>
            <Button disabled={!editJob} variant='primary' fontWeight='normal' height='40px' mr='10px' fontSize='16px' onClick={() => formSubmit(true, false)}>{activeReportId ? "Edit Report" : "Start Report"}</Button>
            <Box>
              {!editJob && <Button variant='primary' fontWeight='normal' w='117px' height='40px' fontSize='16px' onClick={() => formSubmit(false, true)}>Add Job</Button>}
              {editJob && <Button variant='primary' fontWeight='normal' w='117px' height='40px' fontSize='16px' onClick={() => updateForm(true)}>Update Job</Button>}
            </Box>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal isOpen={deleteConfirmation} onClose={closeDeleteConfirmation} size='xl' scrollBehavior='inside'>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader p='30px 30px 35px' fontSize='21px' lineHeight='26px' color='black.lighten' fontWeight='600'>Delete Schedule Job</ModalHeader>
          <Icon color='black.primary' fontSize='24px' as={IoMdClose} onClick={closeDeleteConfirmation} cursor='pointer' position='absolute' top='29px' right='29px'></Icon>
          <ModalBody p='0 30px 25px'>
            <Text fontSize='18px' color='black.lighten'>Are you sure you want to delete?</Text>
          </ModalBody>
          <ModalFooter>
            <Button variant='primary2' minW='120px' onClick={() => handleDeleteScheduleJob(activeJobId)}>Yes</Button>
            <Button variant='grey' ml='15px' minW='120px' onClick={closeDeleteConfirmation}>No</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal isOpen={sameTimeConfirmation} onClose={closeSameTimeConfirmation} size='xl' scrollBehavior='inside'>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader p='30px 30px 35px' fontSize='21px' lineHeight='26px' color='black.lighten' fontWeight='600'>Same Time Confirmation</ModalHeader>
          <Icon color='black.primary' fontSize='24px' as={IoMdClose} onClick={closeSameTimeConfirmation} cursor='pointer' position='absolute' top='29px' right='29px'></Icon>
          <ModalBody p='0 30px 25px'>
            <Text fontSize='18px' color='black.lighten'>Are you sure to create new job at Same Time ?</Text>
          </ModalBody>
          <ModalFooter>
            {!editJob && <Button variant='primary2' minW='120px' onClick={() => formSubmit(false, false)}>Yes</Button>}
            {editJob && <Button variant='primary2' minW='120px' onClick={() => updateForm(false)}>Yes</Button>}
            <Button variant='grey' ml='15px' minW='120px' onClick={closeSameTimeConfirmation}>No</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}